import createPersistedState from 'vuex-persistedstate'
import * as Cookies from "js-cookie";

export default ({ store }, inject) => {

  /****
   *
   * debug with Firefox for 'Invalid storage instance given' error
   *  goto - about:config
   *  search - dom.storage.enabled
   *  set value as false
   *
   *  */

  let mzLocalStorage,
    hasLocalStorageAccess = false,
    hasCookiesAccess = false;

  try {
    if (localStorage) {
      localStorage.setItem('mztestenable', true);
      if (localStorage.getItem('mztestenable')) {
        localStorage.removeItem('mztestenable');
        hasLocalStorageAccess = true;
      }
    } else {
      console.warn('LocalStorage localStorage is undefined ', localStorage);
    }
  } catch (error) {
    console.error('LocalStorage access error', error);
    hasLocalStorageAccess = false;
  }

  try {
    if (Cookies) {
      Cookies.set('mztestenable', true, { expires: 365, secure: process.env.VUE_NODE_ENV === 'production' });
      if (Cookies.get('mztestenable')) {
        Cookies.remove('mztestenable');
        hasCookiesAccess = true;
      }
    } else {
      console.warn('LocalStorage Cookies is undefined ', Cookies);
    }
  } catch (error) {
    console.error('LocalStorage cookies access error', error);
    hasCookiesAccess = false;
  }

  console.log('LocalStorage - has local storage access ' + hasLocalStorageAccess);
  console.log('LocalStorage - has cookies  access ' + hasCookiesAccess);

  if (hasLocalStorageAccess) {
    mzLocalStorage = {
      storageIsDisabled: false,
      getItem: (key) => {
        return localStorage.getItem(key);
      },
      setItem: (key, value) => {
        return localStorage.setItem(key, value);
      },
      removeItem: (key) => {
        return localStorage.removeItem(key);
      },
    };
  } else if (hasCookiesAccess) {
    console.warn('LocalStorage - not enable, use cookies in env ' + process.env.VUE_NODE_ENV);
    mzLocalStorage = {
      storageIsDisabled: false,
      getItem: (key) => {
        return Cookies.get(key);
      },
      setItem: (key, value) => {
        return Cookies.set(key, value, { expires: 365, secure: process.env.VUE_NODE_ENV === 'production' })
      },
      removeItem: (key) => {
        return Cookies.remove(key)
      },
    };
  } else {
    // simule local storage without save anything
    console.warn('LocalStorage - not enable, and cookies not enable - set storage in disabled mode');
    mzLocalStorage = {
      storageIsDisabled: true,
      getItem: (key) => {
        return null;
      },
      setItem: (key, value) => {
        return null
      },
      removeItem: (key) => {
        return null
      },
    };
  }

  if (hasLocalStorageAccess) {
    try {
      createPersistedState({
        key: 'madmagz-athena',
        paths: [
          "app",
          "api",
          "fabric",
        ]
      })(store);
    } catch (error) {
      console.error('Unable to create persisted state', error);
    }
  }

  inject('mzLocalStorage', mzLocalStorage);
}