<template>
  <el-tag
    :class="{ 'offer-tag': true, 'offer-tag-truncate': !showCompleteTag }"
    size="mini"
    :title="tooltipText"
    >{{ tagText }}</el-tag
  >
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";

/*
:style="{
      'background-color': bgColor,
      'border-color': bgColor,
      color: textColor,
    }"
    */

export default {
  name: "MzOfferTag",
  props: ["offer", "showCompleteTag"],

  computed: {
    ...mapGetters("offer", ["offerTagData"]),

    textColor() {
      if (this.offer && this.offer.tag && this.offerTagData[this.offer.tag]) {
        return this.offerTagData[this.offer.tag].textColor;
      }
      return null;
    },
    bgColor() {
      if (this.offer && this.offer.tag && this.offerTagData[this.offer.tag]) {
        return this.offerTagData[this.offer.tag].backgroundColor;
      }
      return "#cccccc";
    },
    tagText() {
      return this.offer && this.offer.name ? this.offer.name : "unknown";
    },
    tooltipText() {
      if (!this.showCompleteTag && this.tagText.length > 30) {
        return this.tagText;
      }
      return null;
    },
  },
};
</script>

<style scoped lang="scss">
.offer-tag {
  user-select: none;
}

.offer-tag-truncate {
  max-width: 180px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
</style>