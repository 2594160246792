import { fabric } from "fabric";
import Utils from "~/common/utils/misc";

export default () => {
  fabric.util.object.extend(fabric.MzCanvas.prototype, /** @lends fabric.MzCanvas.prototype */ {

    proximityDrawTreshold: 10,
    proximitySnapTreshold: 10,
    currentSnappingObj: null,
    snappingEnabled: false,

    switchSnapMode: function (value) {
      this.snappingEnabled = value;
      if (this.snappingEnabled === true) {
        this.activateSnapping();
      } else {
        this.disableSnapping();
      }
    },

    activateSnapping: function () {
      this.on('object:moving', this.onSnappingObjectMoving);
      this.on('object:scaling', this.onSnappingObjectScaling);
    },
    disableSnapping: function () {
      this.off('object:moving', this.onSnappingObjectMoving);
      this.off('object:scaling', this.onSnappingObjectScaling);
      if (this.currentSnappingObj) {
        this.currentSnappingObj.hasProximityControl = false;
        this.requestRenderAll();
      }
    },

    onSnappingObjectMoving(e) {
      if (e.target.angle != 0) return; //unsupported rotated objects
      this.findMatchingCoord(e.target);
      this.moveSnapTo(e.target);
    },
    onSnappingObjectScaling(e) {
      this.findMatchingCoord(e.target);
    },

    findMatchingCoord(obj) {
      let oPoints = obj.getSnapPoints()
      let tPoints = [];

      obj.proximityLR = [];
      obj.proximityTB = [];
      obj.hasProximityControl = false;
      this.currentSnappingObj = obj;

      this.forEachObject(o => {
        if (o.id === obj.id) return;
        if (o.type === 'MzNonInteractiveRect' || o.type === 'MzNonInteractiveLine' || o.type === 'MzNonInteractiveGroup') return;
        if (o.angle != 0) return; //unsupported rotated objects

        tPoints = o.getSnapPoints();

        obj.proximityLR.push(...tPoints.filter(th => {
          return oPoints.find(c => Utils.isWithinRange(c.x, th.x, this.proximityDrawTreshold))
        }))

        obj.proximityTB.push(...tPoints.filter(tv => {
          return oPoints.find(c => Utils.isWithinRange(c.y, tv.y, this.proximityDrawTreshold))
        }))

        obj.hasProximityControl = (obj.proximityTB.length > 0 || obj.proximityLR.length > 0);
      })
    },

    moveSnapTo: function (obj) {
      let dim = obj._getTransformedDimensions();
      let oPoints = obj.getSnapPoints();

      obj.proximityLR.some(proxPoint => {
        // Left
        if (Utils.isWithinRange(oPoints[0].x, proxPoint.x, this.proximitySnapTreshold)) {
          obj.left = proxPoint.x + (obj.left - oPoints[0].x);
          return true
        }
        // Right
        if (Utils.isWithinRange(oPoints[1].x, proxPoint.x, this.proximitySnapTreshold)) {
          obj.left = proxPoint.x + (obj.left - oPoints[1].x);
          return true
        }
        // center
        if (Utils.isWithinRange(oPoints[2].x, proxPoint.x, this.proximitySnapTreshold)) {
          obj.left = proxPoint.x - dim.x * 0.5;
          return true
        }
      })

      obj.proximityTB.some(proxPoint => {
        // Top
        if (Utils.isWithinRange(oPoints[0].y, proxPoint.y, this.proximitySnapTreshold)) {
          obj.top = proxPoint.y + (obj.top - oPoints[0].y);
          return true
        }
        // Bottom
        if (Utils.isWithinRange(oPoints[1].y, proxPoint.y, this.proximitySnapTreshold)) {
          obj.top = proxPoint.y + (obj.top - oPoints[1].y);
          return true
        }
        // center
        if (Utils.isWithinRange(oPoints[2].y, proxPoint.y, this.proximitySnapTreshold)) {
          obj.top = proxPoint.y - dim.y * 0.5;
          return true
        }

      })
    },

    scaleSnapTo: function (obj, by, pointer) {
      let dim = obj._getTransformedDimensions();
      let tPoint;
      let newX = pointer.x;
      let newY = pointer.y;

      if (by === 'x' || by === 'equally') {
        obj.proximityLR.forEach(proxPoint => {
          tPoint = obj.toLocalPoint(proxPoint);
          if (Utils.isWithinRange(pointer.x, tPoint.x, this.proximitySnapTreshold)) {
            newX = tPoint.x;
          }
          if (Math.abs(tPoint.x) <= this.proximitySnapTreshold && Utils.isWithinRange(pointer.x, dim.x, this.proximitySnapTreshold)) {
            newX = dim.x - tPoint.x;
          }
        })
      }

      if (by === 'y' || by === 'equally') {
        obj.proximityTB.forEach(proxPoint => {
          tPoint = obj.toLocalPoint(proxPoint);
          if (Utils.isWithinRange(pointer.y, tPoint.y, this.proximitySnapTreshold)) {
            newY = tPoint.y;
          }
          if (Math.abs(tPoint.y) <= this.proximitySnapTreshold && Utils.isWithinRange(pointer.y, dim.y, this.proximitySnapTreshold)) {
            newY = dim.y - tPoint.y;
          }

        })
      }

      return { x: newX, y: newY }
    }


  });
};