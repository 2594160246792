<template>
  <el-dialog
    :visible="visible"
    class="offline"
    center
    :title="$t('offline.title')"
    :show-close="false"
  >
    <div class="container">
      <div class="content">
        <div>{{ $t("offline.disconnect") }}</div>
        <div>{{ $t("offline.checkInternet") }}</div>
        <div>{{ $t("offline.contactSupport") }}</div>
      </div>

      <div class="retry">
        {{
          $tc("offline.retryIn", secondsBeforeRetry, { nb: secondsBeforeRetry })
        }}
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "OfflineModal",
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      secondsBeforeRetry: 0,
    };
  },
  computed: {
    time() {
      return this.$now.time;
    },
  },
  watch: {
    time() {
      // we use a watcher instead of a computed to avoid handling the delay from the $now.time value and we simply react to the update
      let nb = Math.max(
        Math.floor((this.$online.nextRetryTime - Date.now()) / 1000),
        0
      );
      this.secondsBeforeRetry = nb <= 2 ? 0 : nb;
    },
  },
};
</script>

<style scoped>
.container {
  text-align: center;
}
.content {
  margin-bottom: 1rem;
}
.retry {
  opacity: 0.5;
}
</style>