import { fabric } from 'fabric';
import MzMixins from './mixins/MzCommonMixins';

/**
 * Rectangle that rezize instead of scaling
 */
export default () => {
  fabric.MzLine = fabric.util.createClass(fabric.Line, {
    type: 'MzLine',

    initialize: function (element, options) {
      // inject Mz common properties
      Object.assign(this, MzMixins);
      options = this.initMzObject(options);

      this.callSuper('initialize', element, options);
    }
  });

  /**
   * Returns fabric.Line instance from an SVG element
   * @static
   * @memberOf fabric.Line
   * @param {SVGElement} element Element to parse
   * @param {Object} [options] Options object
   * @param {Function} [callback] callback function invoked after parsing
   */
  fabric.MzLine.fromElement = function (element, callback, options) {
    options = options || {};
    var parsedAttributes = fabric.parseAttributes(element, fabric.Line.ATTRIBUTE_NAMES),
      points = [parsedAttributes.x1 || 0, parsedAttributes.y1 || 0, parsedAttributes.x2 || 0, parsedAttributes.y2 || 0];
    callback(new fabric.MzLine(points, extend(parsedAttributes, options)));
  };
  /* _FROM_SVG_END_ */

  /**
   * Returns fabric.Line instance from an object representation
   * @static
   * @memberOf fabric.Line
   * @param {Object} object Object to create an instance from
   * @param {function} [callback] invoked with new instance as first argument
   */
  fabric.MzLine.fromObject = function (object, callback) {
    function _callback(instance) {
      delete instance.points;
      callback && callback(instance);
    }
    var options = fabric.util.object.clone(object, true);
    options.points = [object.x1, object.y1, object.x2, object.y2];
    fabric.Object._fromObject('MzLine', options, _callback, 'points');
  };
};
