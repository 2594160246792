
/**
 * Redirect root path to /login
 */

// default redirect route is make by authenticated middleware (to login or to client)
export default async function ({ store, redirect, route }) {
  //console.log("route: " + route.fullPath, route)
  // if (route.fullPath === '/') {
  //   return redirect('/login');//{ name: 'login', query: { ...route.query, ...route.params } });
  // }
}
