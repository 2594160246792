import Vue from 'vue';
import '../assets/css/index.scss';
import lang from 'element-ui/lib/locale/lang/en';
import locale from 'element-ui/lib/locale';
import {
  Pagination,
  Dialog,
  Autocomplete,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Menu,
  Submenu,
  MenuItem,
  MenuItemGroup,
  Input,
  InputNumber,
  Radio,
  RadioGroup,
  RadioButton,
  Checkbox,
  CheckboxButton,
  CheckboxGroup,
  Switch,
  Select,
  Option,
  OptionGroup,
  Button,
  ButtonGroup,
  Table,
  TableColumn,
  DatePicker,
  // TimeSelect,
  // TimePicker,
  Popover,
  Tooltip,
  // Breadcrumb,
  // BreadcrumbItem,
  Form,
  FormItem,
  Tabs,
  TabPane,
  Tag,
  Tree,
  Alert,
  Slider,
  Icon,
  Row,
  Col,
  Upload,
  Progress,
  Spinner,
  Badge,
  Card,
  // Rate,
  Steps,
  Step,
  Carousel,
  CarouselItem,
  Collapse,
  CollapseItem,
  Cascader,
  //ColorPicker,
  Transfer,
  Container,
  Header,
  Aside,
  Main,
  Footer,
  // Timeline,
  // TimelineItem,
  Link,
  Divider,
  Image,
  // Calendar,
  // Backtop,
  // PageHeader,
  // CascaderPanel,
  Loading,
  MessageBox,
  Message,
  Notification
} from 'element-ui';

export default () => {
  locale.use(lang);
  Vue.use(Pagination);
  Vue.use(Dialog);
  Vue.use(Autocomplete);
  Vue.use(Dropdown);
  Vue.use(DropdownMenu);
  Vue.use(DropdownItem);
  Vue.use(Menu);
  Vue.use(Submenu);
  Vue.use(MenuItem);
  Vue.use(MenuItemGroup);
  Vue.use(Input);
  Vue.use(InputNumber);
  Vue.use(Radio);
  Vue.use(RadioGroup);
  Vue.use(RadioButton);
  Vue.use(Checkbox);
  Vue.use(CheckboxButton);
  Vue.use(CheckboxGroup);
  Vue.use(Switch);
  Vue.use(Select);
  Vue.use(Option);
  Vue.use(OptionGroup);
  Vue.use(Button);
  Vue.use(ButtonGroup);
  Vue.use(Table);
  Vue.use(TableColumn);
  Vue.use(DatePicker);
  // Vue.use(TimeSelect);
  // Vue.use(TimePicker);
  Vue.use(Popover);
  Vue.use(Tooltip);
  // Vue.use(Breadcrumb);
  // Vue.use(BreadcrumbItem);
  Vue.use(Form);
  Vue.use(FormItem);
  Vue.use(Tabs);
  Vue.use(TabPane);
  Vue.use(Tag);
  Vue.use(Tree);
  Vue.use(Alert);
  Vue.use(Slider);
  Vue.use(Icon);
  Vue.use(Row);
  Vue.use(Col);
  Vue.use(Upload);
  Vue.use(Progress);
  Vue.use(Spinner);
  Vue.use(Badge);
  Vue.use(Card);
  // Vue.use(Rate);
  Vue.use(Steps);
  Vue.use(Step);
  Vue.use(Carousel);
  Vue.use(CarouselItem);
  Vue.use(Collapse);
  Vue.use(CollapseItem);
  Vue.use(Cascader);
  //Vue.use(ColorPicker);
  Vue.use(Transfer);
  Vue.use(Container);
  Vue.use(Header);
  Vue.use(Aside);
  Vue.use(Main);
  Vue.use(Footer);
  // Vue.use(Timeline);
  // Vue.use(TimelineItem);
  Vue.use(Link);
  Vue.use(Divider);
  Vue.use(Image);
  // Vue.use(Calendar);
  // Vue.use(Backtop);
  // Vue.use(PageHeader);
  // Vue.use(CascaderPanel);

  Vue.use(Loading.directive);

  Vue.prototype.$loading = Loading.service;
  Vue.prototype.$msgbox = MessageBox;
  Vue.prototype.$alert = MessageBox.alert;
  Vue.prototype.$confirm = MessageBox.confirm;
  Vue.prototype.$prompt = MessageBox.prompt;
  Vue.prototype.$notify = Notification;

  /* override for set default duration time of all message */
  Vue.prototype.$message = (options) => {
    if (typeof options === 'string') {
      options = {
        message: options
      };
    }
    if (options.duration === undefined) {
      options.duration = 10000;
    }
    if (options.showClose === undefined) {
      options.showClose = true;
    }

    if (!options.message || options.message.trim().length === 0) {
      return false;
    }

    return Message(options);
  };
  Vue.prototype.$messageError = (options) => {
    if (typeof options === 'string') {
      options = {
        message: options,
        type: 'error'
      };
    } else if (options.type === undefined) {
      options.type = 'error';
    }
    return Vue.prototype.$message(options);
  }
  Vue.prototype.$messageWarning = (options) => {
    if (typeof options === 'string') {
      options = {
        message: options,
        type: 'warning'
      };
    } else if (options.type === undefined) {
      options.type = 'warning';
    }
    return Vue.prototype.$message(options);
  }
  Vue.prototype.$messageInfo = (options) => {
    if (typeof options === 'string') {
      options = {
        message: options,
        type: 'info'
      };
    } else if (options.type === undefined) {
      options.type = 'info';
    }
    return Vue.prototype.$message(options);
  }
  Vue.prototype.$messageSuccess = (options) => {
    if (typeof options === 'string') {
      options = {
        message: options,
        type: 'success'
      };
    } else if (options.type === undefined) {
      options.type = 'success';
    }
    return Vue.prototype.$message(options);
  }
};
