<template>
  <el-tooltip
    :placement="tooltipPlacement ? tooltipPlacement : 'bottom'"
    effect="light"
    :disabled="tooltipContent === null"
    :enterable="false"
    :open-delay="500"
  >
    <div slot="content" v-if="tooltipContent" v-html="tooltipContent"></div>

    <span
      class="user-role single-line-text"
      v-bind:class="{
        'is-pending': isPending,
        'is-disabled': isDisabled,
        'is-current-user': applyCurrentUserStyle && isCurrentUser,
      }"
    >
      <i v-if="icon" v-bind:class="icon + ' role-icon'"></i>

      {{
        isCurrentUser
          ? $t("public.collaboration.assign_modal.user_list_me")
          : name
      }}
      <el-tooltip v-if="tag && !hideRole" :content="tooltipTag" placement="top">
        <el-tag
          size="mini"
          :type="isCurrentUser ? 'primary' : 'info'"
          :effect="isCurrentUser ? 'dark' : 'plain'"
          class="ml-0"
          >{{ tag }}</el-tag
        >
      </el-tooltip>
    </span>
  </el-tooltip>
</template>

<script>
export default {
  name: "UserRoleTag",
  props: [
    "user",
    "hideRole",
    "applyCurrentUserStyle",
    "icon",
    "tooltipKey",
    "tooltipPlacement",
  ],
  computed: {
    name() {
      if (!this.user || !this.user.name) {
        return "";
      }
      return this.user.name;
    },
    tag() {
      if (!this.user || !this.user.role) {
        return null;
      }
      return this.$t("public.collaboration.roles_tag." + this.user.role);
    },
    tooltipTag() {
      if (!this.user || !this.user.role) {
        return null;
      }
      return this.$t("public.collaboration.roles." + this.user.role);
    },
    isPending() {
      return this.user && this.user.status === "pending";
    },
    isDisabled() {
      return this.user && this.user.status === "disabled";
    },
    isCurrentUser() {
      return this.user && this.user.isCurrentUser;
    },
    tooltipContent() {
      let msg = [];
      if (this.tooltipKey) {
        msg.push(
          this.$t(this.tooltipKey, {
            name: this.name,
            role: this.tag,
          })
        );
      }
      if (this.isPending) {
        msg.push(this.$t("public.collaboration.userInfo.tooltipUserNotActive"));
      } else if (this.isDisabled) {
        msg.push(this.$t("public.collaboration.userInfo.tooltipUserDisabled"));
      }

      if (msg.length > 0) {
        return msg.join("<br/>");
      }

      return null;
    },
  },
};
</script>

<style scoped lang="scss">
@import "~assets/css/mz-variables.scss";

.user-role {
  font-size: 0.75rem;
  overflow: hidden;
  color: $public-color-grey-1;

  // &.is-pending {
  //   opacity: 0.5;
  // }
  &.is-disabled {
    opacity: 0.5;
    color: rgb(197, 53, 53);
  }
  &.is-current-user {
    color: $public-color-blue;
  }
}

.role-icon {
  font-size: 14px;
}
</style>