var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"notification"},[_c('div',{staticClass:"icon"}),_vm._v(" "),_c('div',[_c('div',{domProps:{"innerHTML":_vm._s(_vm.message)}}),_vm._v(" "),(_vm.pagesList !== null)?_c('ul',_vm._l((_vm.pagesList),function(page){return _c('li',{key:page.pageId},[_c('el-link',{attrs:{"type":"primary","underline":false},on:{"click":function($event){return _vm.goto({
              name: 'client-magazine-edit-magazineId',
              params: {
                magazineId: _vm.notification.data.magazineId,
              },
              query: {
                page: page.pageId,
              },
            })}}},[_vm._v("\n          "+_vm._s(page.pageName
              ? page.pageName
              : _vm.translate(
                  page.double
                    ? "public.edit_magazine.double_page_name"
                    : "public.edit_magazine.page_name",
                  {
                    number: page.pageNumber,
                  }
                ))+"\n        ")])],1)}),0):_vm._e(),_vm._v(" "),_c('div',{staticClass:"time"},[_vm._v("\n      "+_vm._s(_vm._f("moment")(new Date(_vm.notification.createdAt),_vm.translate("public.notifications.dateFormat")))+"\n      "),(_vm.go2Params)?_c('el-link',{staticClass:"link",attrs:{"type":"primary","underline":false},on:{"click":function($event){return _vm.goto(_vm.go2Params)}}},[_c('i',{staticClass:"el-icon-caret-right"})]):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }