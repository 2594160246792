import { fabric } from 'fabric';

export default () => {
  fabric.util.object.extend(
    fabric.MzTextbox.prototype,
    /** @lends fabric.MzTextbox.prototype */
    {
      parentTextBoxId: null,
      childTextBoxId: null,
      parentTextBox: null,
      textgroupId: null,

      initReflow: function (text, options) {
        this.set('parentTextBoxId', options.parentTextBoxId || null);
        this.set('childTextBoxId', options.childTextBoxId || null);
        this.set('hasReflow', options.parentTextBoxId !== null || options.childTextBoxId !== null);
        this.toObjectProps.push('parentTextBoxId', 'childTextBoxId');
      },

      activateReflow: function () {
        this.initMzTextGroup(true);

        // attach linked textbox when all items are loaded
        if (this.parentTextBoxId) {
          this.setParentTextBox(this.canvas.getObjectById(this.parentTextBoxId));
        }
        if (this.childTextBoxId) {
          this.setChildTextBox(this.canvas.getObjectById(this.childTextBoxId));
        }
      },

      removeReflowLink: function () {
        this.parentTextBoxId = null;
        this.childTextBoxId = null;
        this.hasReflow = false;

        if (this.textgroupId) {
          const textgroup = fabric.textGroups.get(this.textgroupId);
          if (textgroup) {
            textgroup.delete();
          }
        }
      },

      initMzTextGroup: function (checkError = false) {

        let pid = this.parentTextBoxId || this.childTextBoxId;
        if (this.textgroupId || !pid) {
          // Skip if no linked text or already initialized
          return
        }
        let p = this.canvas.getObjectById(pid);
        let reflowIsBroken = false;
        // ascent to get root parent
        while (p && p.parentTextBoxId) {
          pid = p.parentTextBoxId;
          p = this.canvas.getObjectById(pid)
        }
        if (checkError && !p) {
          reflowIsBroken = true;
        } else {

          let textgroup = new fabric.MzTextGroup();
          textgroup.init();

          let hasChildrens = true;
          // descent to last children to store and init MzTextGroup
          while (p && hasChildrens) {
            textgroup.add(p);
            if (p.childTextBoxId) {
              p = this.canvas.getObjectById(p.childTextBoxId);
            } else {
              hasChildrens = false; //break the loop
            }
          }
          if (checkError && (!p || !textgroup.containsTextbox(this.id))) {
            reflowIsBroken = true;
            textgroup.delete();
          }
        }

        if (checkError && reflowIsBroken) {
          console.log('reflow is broken for ' + this.id)
          this.parentTextBoxId = null;
          this.childTextBoxId = null;
          this.hasReflow = false;
        }
      },

      /**
       * Send overflow text to childrens
       * @param {} newtext result of _splitText()
       */
      updateReflowText: function (newText) {
        if (!this.canvas || !this.canvas.getObjectById) {
          return;
        }
        let childTextBox = this.canvas.getObjectById(this.childTextBoxId);
        childTextBox.setStyledText({
          text: newText.overflowText,
          flatStyle: newText.overflowStyle,
        })
      },

      getRootTextbox: function () {
        const textGroup = this.textgroupId && fabric.textGroups ? fabric.textGroups.get(this.textgroupId) : null;
        return textGroup ? textGroup.getRootTextbox() : null;
      },

      isLinkedWith: function (id) {
        return this.hasReflow && (this.parentTextBoxId == id || this.childTextBoxId == id);
      },

      isRootTextbox: function () {
        return this.hasReflow && !this.parentTextBoxId;
      },

      setChildTextBox: function (value) {
        if (value === this) {
          return;
        }

        this.childTextBoxId = value.id;
        this.hasReflow = true;

        // If first linked text, create abastract
        if (!this.textgroupId) {
          this.initMzTextGroup();
        }
        // add new children to textgroup
        if (!value.textgroupId) {
          let textgroup = fabric.textGroups.get(this.textgroupId)
          textgroup.add(value)
        }
      },

      setParentTextBox: function (value) {
        if (!value || value === this) {
          return;
        }
        this.parentTextBoxId = value.id;
        this.hasReflow = true;
      },

      updateReflowStackOrder: function (nbLoop = 0) {
        if (!this.isRootTextbox() || nbLoop > 50) {
          return;
        }

        let hasChange = false;
        let currentIndex = this.canvas._objects.indexOf(this);
        let childTextBox = this.canvas.getObjectById(this.childTextBoxId);
        while (childTextBox) {
          currentIndex++;
          if (currentIndex !== this.canvas._objects.indexOf(childTextBox)) {
            console.log('reorganize reflow index for ' + childTextBox.id)
            this.canvas.moveTo(childTextBox, currentIndex);
            hasChange = true;
          }
          childTextBox = this.canvas.getObjectById(childTextBox.childTextBoxId);
        }

        // loop until has nothing to change
        if (hasChange) {
          this.updateReflowStackOrder(nbLoop + 1);
        }
      },

      setAsStyleForAllReflowStack: function () {
        const textGroup = this.textgroupId && fabric.textGroups ? fabric.textGroups.get(this.textgroupId) : null;
        if (textGroup) {
          for (let textbox of textGroup.textBoxes) {
            if (textbox.id !== this.id) {
              textbox.blockType = this.blockType;
              textbox._textStyleKey = this._textStyleKey;
              textbox._dropCapStyleKey = this._dropCapStyleKey;
              textbox._bulletPointStyleKey = this._bulletPointStyleKey;
              textbox._endSignStyleKey = this._endSignStyleKey;
              textbox._textLinkStyleKey = this._textLinkStyleKey;
              textbox.fontFamily = this.fontFamily;
              textbox.fontWeight = this.fontWeight;
              textbox.fontSize = this.fontSize;
              textbox.lineHeight = this.lineHeight;
              textbox.fontStyle = this.fontStyle;
              textbox.underline = this.underline;
              textbox.linethrough = this.linethrough;
              textbox.textAlign = this.textAlign;
              textbox.fill = this.fill;
              textbox.textBackgroundColor = this.textBackgroundColor;
              textbox.paletteSwatchName = this.paletteSwatchName;
              textbox.textBackgroundPadding = this.textBackgroundPadding;
              textbox.charSpacing = this.charSpacing;
              textbox.bulletPoint = this.bulletPoint;
              textbox.bulletPointStyle = this.bulletPointStyle;
              textbox.textLink = this.textLink;
              textbox.textLinkStyle = this.textLinkStyle;

            }
          }
        }
      }

    }
  );
};
