import { fabric } from 'fabric';
import Utils from "~/common/utils/misc";

const gridColorA = "rgba(50,140,140,0.2)";
const gridColorB = "rgba(120,120,120,0.1)";
const fontStyle = '9px Helvetica Madmagz';

const rulerColorA = "rgba(50,140,140,0.5)";
const rulerColorB = "rgba(120,120,120,1)";
const rulerBgColorA = "rgba(120,0,0,0.1)";
const rulerBgColorB = "rgba(0,120,120,0.2)";
const rulerBgColorC = "rgba(0,0,0,0.1)";

export default () => {
  fabric.util.object.extend(fabric.MzCanvas.prototype, /** @lends fabric.MzCanvas.prototype */ {

    gridEnabled: false,
    gridSize: 1,
    gridSizeX: 1,
    gridSizeY: 1,
    gridOffsetX: 1,
    gridOffsetY: 1,
    gridCanvasEl: null,
    contextGrid: null,
    rulerEnabled: false,
    marginRendererGroup: null,
    colmunEnabled: false,
    columnMagnetMargin: 10,
    columnMagnetEnabled: false,
    columnRendererGroup: null,
    columnAnchors: [],
    unitMode: 0, // 0: pixels, 1: metric, 2: precent
    units: ['px', 'mm', '%'],

    params: {
      zoom: 1,
      mult: 1,
      rWidth: 1,
      rHeight: 1,
      rWidth: 1,
      stepX: 1,
      stepY: 1,
      lastValX: 1,
      lastValY: 1,
      unit: 'px',
      zeroX: 1,
      zeroY: 1,
      prevViewportTransform: [0, 0],
      mt: 0,
      ml: 0,
      mr: 0,
      mb: 0,
    },

    initGrid(options) {
      this.createGridCanvas();
    },

    /**
     * Overlay canvas for grid. fork of native canvas function to creat top canvas
     */
    createGridCanvas: function () {
      var lowerCanvasClass = this.lowerCanvasEl.className.replace(/\s*lower-canvas\s*/, '');

      // there is no need to create a new upperCanvas element if we have already one.
      if (this.gridCanvasEl) {
        this.gridCanvasEl.className = '';
      } else {
        this.gridCanvasEl = this._createCanvasElement();
      }
      fabric.util.addClass(this.gridCanvasEl, 'grid-canvas ' + lowerCanvasClass);

      this.wrapperEl.insertBefore(this.gridCanvasEl, this.upperCanvasEl);

      this._copyCanvasStyle(this.lowerCanvasEl, this.gridCanvasEl);
      this._applyCanvasStyle(this.gridCanvasEl);
      this.contextGrid = this.gridCanvasEl.getContext('2d');
    },

    updateZoom: function (force = false) {
      let newZoom = this.getZoom();
      if (force === false && this.params.zoom === newZoom) {
        return false
      }
      this.params.zoom = newZoom;

      this.params.ml = fabric.page.marginLeft * this.params.zoom;
      this.params.mt = fabric.page.marginTop * this.params.zoom;
      this.params.mr = fabric.page.marginRight * this.params.zoom;
      this.params.mb = fabric.page.marginBottom * this.params.zoom;

      this.params.rWidth = fabric.page.pageRect.width * this.params.zoom;
      this.params.rHeight = fabric.page.pageRect.height * this.params.zoom;
      this.params.unit = this.units[this.unitMode];
      this.params.mult = 1;
      switch (this.unitMode) {
        case 0: // px
          this.params.mult = this.params.zoom < 2.8 ? this.params.zoom < 0.5 ? 100 : 10 : 1;
          this.params.stepX = this.params.stepY = this.params.mult * this.params.zoom;
          this.params.lastValX = fabric.page.pageRect.width;
          this.params.lastValY = fabric.page.pageRect.height;
          this.gridSizeX = this.gridSizeY = this.params.mult;

          break;
        case 2: // %
          let factor = 0.01;

          this.params.stepX = this.params.rWidth * factor;
          this.params.stepY = this.params.rHeight * factor;
          if (this.params.stepX < 3 || this.params.stepY < 3) {
            factor = 0.05;
            this.params.stepX = this.params.rWidth * factor;
            this.params.stepY = this.params.rHeight * factor;
            this.params.mult = 5;
          }
          this.gridSizeX = fabric.page.pageRect.width * factor;
          this.gridSizeY = fabric.page.pageRect.height * factor;

          this.params.lastValX = this.params.lastValY = 100;
          break;

        case 1: // mm
        default:
          this.gridSizeX = fabric.page.format.canvasWidth / fabric.page.format.formatBase.pageWidth;
          this.gridSizeY = fabric.page.format.canvasHeight / fabric.page.format.formatBase.pageHeight;
          this.params.stepX = this.gridSizeX * this.params.zoom; // unit px/mm
          this.params.stepY = this.gridSizeY * this.params.zoom; // unit px/mm
          if (this.params.stepX < 3 || this.params.stepY < 3) {
            this.params.mult = 10;
            this.gridSizeX = this.gridSizeY = Utils.convertMilimetersToPixels(this.params.mult, fabric.page.format.dpi);
            this.params.stepX = this.params.stepY = Utils.convertMilimetersToPixels(this.params.zoom * this.params.mult, fabric.page.format.dpi);
            this.params.unit = 'cm'
          }

          this.params.lastValX = fabric.page.format.formatBase.pageWidth - (fabric.page.format.printMargins.left + fabric.page.format.printMargins.right);
          this.params.lastValY = fabric.page.format.formatBase.pageHeight - (fabric.page.format.printMargins.top + fabric.page.format.printMargins.bottom);
          break;
      }
      this.gridSizeX *= this.gridSize;
      this.gridSizeY *= this.gridSize;

      this.gridOffsetX = fabric.page.pageRect.left % this.gridSizeX;
      this.gridOffsetY = fabric.page.pageRect.top % this.gridSizeY;
      return true;
    },

    updatePan: function (force = false) {
      if (force === true
        || this.viewportTransform[4] !== this.params.prevViewportTransform[0]
        || this.viewportTransform[5] !== this.params.prevViewportTransform[1]) {

        this.params.prevViewportTransform[0] = this.viewportTransform[4];
        this.params.prevViewportTransform[1] = this.viewportTransform[5];
        this.params.zeroX = (fabric.page.pageRect.left * this.params.zoom + this.viewportTransform[4])
        this.params.zeroY = (fabric.page.pageRect.top * this.params.zoom + this.viewportTransform[5])
        return true
      } else {
        return false
      }
    },

    drawGrid: function (ctx) {
      let stepModX = this.params.stepX * this.gridSize;
      let stepModY = this.params.stepY * this.gridSize;

      let ox = 5 * stepModX * this.params.mult;
      let dx = Math.round(this.params.zeroX / ox) + 1;
      let fromX = this.params.zeroX - ox * dx + stepModX;

      let oy = 5 * stepModY * this.params.mult;
      let dy = Math.round(this.params.zeroY / oy) + 1;
      let fromY = this.params.zeroY - oy * dy + stepModY;

      let cnt = 0;

      for (let x = fromX; x <= this.width; x += stepModX) {
        ctx.beginPath();
        cnt++;
        ctx.strokeStyle = cnt % 5 === 0 ? gridColorA : gridColorB;
        ctx.moveTo(x, 0);
        ctx.lineTo(x, this.height);
        ctx.stroke();
      }

      cnt = 0;

      // Drawing horizontal lines
      for (let y = fromY; y <= this.height; y += stepModY) {
        ctx.beginPath();
        cnt++;
        ctx.strokeStyle = cnt % 5 === 0 ? gridColorA : gridColorB;
        ctx.moveTo(0, y);
        ctx.lineTo(this.width, y);
        ctx.stroke();
      }

    },

    drawRulers: function (ctx) {
      // draw rulers
      let cnt = 0;
      let lineStart = 20;
      const rulerHeight = 20;
      const rulerWidth = 20;
      const endX = this.params.zeroX + this.params.rWidth
      const endY = this.params.zeroY + this.params.rHeight
      const textOffset = 10;


      // draw background outside print margins (red)
      ctx.fillStyle = rulerBgColorA;
      ctx.fillRect(0, 0, this.params.zeroX - this.params.ml, rulerHeight);
      ctx.fillRect(endX + this.params.mr, 0, this.width - endX, rulerHeight);

      ctx.fillRect(0, 0, rulerWidth, this.params.zeroY - this.params.mt);
      ctx.fillRect(0, endY + this.params.mb, rulerWidth, this.height - endY);

      // draw background of print margins (teal)
      ctx.fillStyle = rulerBgColorB;
      ctx.fillRect(this.params.zeroX - this.params.ml, 0, this.params.ml, rulerHeight);
      ctx.fillRect(endX, 0, this.params.mr, rulerHeight);
      ctx.fillRect(0, this.params.zeroY - this.params.mt, rulerWidth, this.params.mt);
      ctx.fillRect(0, endY, rulerWidth, this.params.mb);

      // draw background inside print margins (grey)
      ctx.fillStyle = rulerBgColorC;
      ctx.fillRect(this.params.zeroX, 0, this.params.rWidth, rulerHeight);
      ctx.fillRect(0, this.params.zeroY, rulerWidth, this.params.rHeight);

      //display unit
      ctx.font = fontStyle;
      ctx.fillStyle = rulerColorB;
      ctx.fillText(this.params.unit, 5, textOffset);

      // --------- horizontal ruler ---------

      // draw ruler
      for (let rx = this.params.zeroX; rx <= endX; rx += this.params.stepX) {
        ctx.beginPath();
        if (cnt % 10 === 0 || cnt === 0) {
          ctx.strokeStyle = rulerColorB;
          lineStart = 0;
        } else {
          ctx.strokeStyle = rulerColorA;
          lineStart = cnt % 5 === 0 ? 10 : 15;
        }

        ctx.moveTo(rx, lineStart);
        ctx.lineTo(rx, rulerHeight);
        ctx.stroke();
        // show values every 10th
        if (cnt % 10 === 0) {
          ctx.fillStyle = rulerColorB;
          ctx.fillText((cnt * this.params.mult).toString(), rx + 2, textOffset);
        }
        cnt++;
      }
      cnt--; //remove last count

      // --------- vertical ruler ---------
      cnt = 0;
      // draw ruler
      for (let ry = this.params.zeroY; ry <= endY; ry += this.params.stepY) {
        ctx.beginPath();
        if (cnt % 10 === 0 || cnt === 0) {
          ctx.strokeStyle = rulerColorB;
          lineStart = 0;
        } else {
          ctx.strokeStyle = rulerColorA;
          lineStart = cnt % 5 === 0 ? 10 : 15;
        }

        ctx.moveTo(lineStart, ry);
        ctx.lineTo(rulerHeight, ry);
        ctx.stroke();
        // show values every 10th
        if (cnt % 10 === 0) {
          ctx.fillStyle = rulerColorB;
          // draw text vertically
          (cnt * this.params.mult).toString().split('').forEach((char, i) => {
            ctx.fillText(char, 5, ry + 2 + (1 + i) * 8);
          })
        }
        cnt++;
      }
      cnt--; //remove last count
    },

    renderGrid(force = false) {
      if (!this.contextGrid) {
        return;
      }

      let dirty = false;
      //check if we need to recompute values
      if (this.gridEnabled || this.rulerEnabled || (this.colmunEnabled && this.columnMagnetEnabled)) {
        dirty = this.updateZoom(force);
        dirty = this.updatePan(force) || dirty;
      } else {
        if (force) {
          this.clearContext(this.contextGrid);
        }
        return
      }

      // no values changed, skip render
      if (dirty === false) {
        return
      }
      //render grid and rulers
      this.clearContext(this.contextGrid);

      if (this.gridEnabled) {
        this.drawGrid(this.contextGrid);
      }
      if (this.rulerEnabled) {
        this.drawRulers(this.contextGrid);
      }
    },

    setGridSize: function (value) {
      this.gridSize = parseInt(value);
      this.renderGrid(true);
    },
    setGridUnitMode: function (value) {
      this.unitMode = value;
      this.renderGrid(true);
    },

    switchGridMode: function (active) {
      this.gridEnabled = active;

      if (this.gridEnabled) {
        this.on('object:moving', this.moveSnapedToGrid);
      } else {
        this.off('object:moving', this.moveSnapedToGrid);
      }
      this.renderGrid(true);
    },

    switchRulerMode: function (active) {
      this.rulerEnabled = active;
      this.renderGrid(true);
    },

    switchMarginAndColumnMode: function (active) {
      this.colmunEnabled = active;
      if (this.columnRendererGroup) {
        this.columnRendererGroup.visible = active;
      }
      if (this.marginRendererGroup) {
        this.marginRendererGroup.visible = active;
      }
      this.requestRenderAll();
      this.renderGrid(true);
    },

    setColumnMagnetMargin: function (value) {
      this.columnMagnetMargin = parseInt(value);
      this.renderGrid(true);
    },
    switchColumnMagnetMode: function (active) {
      this.columnMagnetEnabled = active && fabric.page.format && fabric.page.format.grid && fabric.page.format.grid.column > 0;

      if (this.columnMagnetEnabled) {
        this.on('object:moving', this.moveSnapedToColumn);
      } else {
        this.off('object:moving', this.moveSnapedToColumn);
      }

      this.renderGrid(true);
    },

    roundToGrid: function (p, n) {
      //return (p % n < n / 2 ? p - (p % n) : p + n - (p % n)) - 1;
      return Math.round(p / n) * n
    },

    roundToColumn: function (left) {
      // get near columns anchors
      for (let anchor of this.columnAnchors) {
        if (anchor - this.columnMagnetMargin < left && anchor + this.columnMagnetMargin > left) {
          return anchor;
        }
      }
      return left;
    },

    moveSnapedToGrid: function (options) {
      if (this.colmunEnabled && this.columnMagnetEnabled) {
        return;
      }

      options.target.set({
        left: this.roundToGrid(options.target.left, this.gridSizeX) + this.gridOffsetX,
        top: this.roundToGrid(options.target.top, this.gridSizeY) + this.gridOffsetY
      }).setCoords();
      this.requestRenderAll();
    },

    moveSnapedToColumn: function (options) {
      if (!this.colmunEnabled) {
        return;
      }

      options.target.set({
        left: this.roundToColumn(options.target.left),
        top: this.gridEnabled ? this.roundToGrid(options.target.top, this.gridSizeY) + this.gridOffsetY : options.target.top
      }).setCoords();
      this.requestRenderAll();
    },

    snapPointToGrid: function (pointer, absoluteTo = null) {
      // TODO
      if (this.colmunEnabled && this.columnMagnetEnabled) {
        const point = {
          x: this.roundToColumn(pointer.x + (absoluteTo ? absoluteTo.left : 0)) - (absoluteTo ? absoluteTo.left : 0),
          y: this.gridEnabled ? this.roundToGrid(pointer.y - (absoluteTo ? 0 : this.gridOffsetY), this.gridSizeY) + (absoluteTo ? 0 : this.gridOffsetY) : pointer.y,
        };
        return point;
      }
      else if (this.gridEnabled) {
        const point = {
          x: this.roundToGrid(pointer.x - (absoluteTo ? 0 : this.gridOffsetX), this.gridSizeX) + (absoluteTo ? 0 : this.gridOffsetX),
          y: this.roundToGrid(pointer.y - (absoluteTo ? 0 : this.gridOffsetY), this.gridSizeY) + (absoluteTo ? 0 : this.gridOffsetY),
        };
        return point;
      } else {
        return pointer
      }
    }

  });
};
