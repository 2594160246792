const middleware = {}

middleware['authenticated'] = require('../middleware/authenticated.js')
middleware['authenticated'] = middleware['authenticated'].default || middleware['authenticated']

middleware['redirectToLogin'] = require('../middleware/redirectToLogin.js')
middleware['redirectToLogin'] = middleware['redirectToLogin'].default || middleware['redirectToLogin']

middleware['setAppMode'] = require('../middleware/setAppMode.js')
middleware['setAppMode'] = middleware['setAppMode'].default || middleware['setAppMode']

export default middleware
