<template>
  <el-row type="flex" align="left">
    <el-button
      type="primary"
      v-if="checkPerm('template:create')"
      @click="$go2Route({ name: 'admin-template-edit-templateId' })"
      v-t="'admin.templates.createTemplate'"
    ></el-button>
  </el-row>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "TemplatesHeader",

  props: [],
  data() {
    return {};
  },
  computed: {
    ...mapGetters("api", ["checkPerm"]),
  },

  methods: {},
};
</script>

<style scoped lang="scss">
</style>