<template>
  <el-dialog
    :title="dialogTitle"
    :visible.sync="displayModal"
    :close-on-click-modal="false"
    width="75%"
    append-to-body
  >
    <div v-loading="loading">
      <el-row type="flex" justify="center">
        <el-col :span="24">
          <el-form label-width="250px" :disabled="editDisabled">
            <el-row type="flex" justify="start" align="top" class="mt-2">
              <el-col :span="12">
                <el-form-item label="Name">
                  <el-input
                    v-model="name"
                    placeholder="please input text"
                  ></el-input>
                </el-form-item>

                <el-form-item label="Status">
                  <el-switch v-model="isPublic"></el-switch>
                  <el-tag
                    :type="isPublic ? 'success' : 'danger'"
                    effect="dark"
                    size="small"
                    class="ml-1"
                    >{{ isPublic ? "public" : "private" }}</el-tag
                  >
                </el-form-item>

                <el-form-item label="Tag icon">
                  <el-select v-model="tag" class="el-select-tag">
                    <el-option
                      v-for="item in listOfferTag"
                      :key="item.value"
                      :value="item.value"
                    >
                      <span>
                        <img :src="item.image" />
                      </span>
                    </el-option>
                  </el-select>
                  <MzOfferTag :offer="offer" class="display-tag-over-select" />
                </el-form-item>

                <el-form-item label="Stripe price ID">
                  <el-input v-model="stripePriceId" clearable></el-input>
                </el-form-item>

                <el-form-item
                  label="Stripe product price"
                  v-if="stripePriceId && stripePriceId.length > 0"
                >
                  <el-input :value="productPrice" :disabled="true"></el-input>
                </el-form-item>
              </el-col>

              <el-col :span="12" :xl="8">
                <el-form-item>
                  <span slot="label">Default B2C offer</span>
                  <el-switch v-model="isDefault"></el-switch>
                </el-form-item>

                <el-form-item label="Market">
                  <el-select v-model="marketType">
                    <el-option
                      v-for="item in listOfferMarketType"
                      :key="item.value"
                      :value="item.value"
                      :label="item.label"
                    ></el-option>
                  </el-select>
                </el-form-item>

                <el-form-item label="Plan Duration">
                  <el-row type="flex">
                    <el-date-picker
                      v-if="offer.duration.durationUnit === 'date'"
                      v-model="offerDurationDate"
                      type="date"
                      placeholder="End date"
                      :clearable="false"
                    >
                    </el-date-picker>
                    <el-input-number
                      v-else-if="offer.duration.durationUnit !== 'illimited'"
                      controls-position="right"
                      :value="offer.duration.durationTime"
                      @change="
                        updateField({
                          key: 'duration.durationTime',
                          value: $event,
                        })
                      "
                    />
                    <el-select
                      :value="offer.duration.durationUnit"
                      @change="
                        updateField({
                          key: 'duration.durationUnit',
                          value: $event,
                        })
                      "
                    >
                      <el-option value="year" label="Year" />
                      <el-option value="month" label="Month" />
                      <el-option value="day" label="Day" />
                      <el-option value="date" label="Date" />
                      <el-option value="illimited" label="Illimited" />
                    </el-select>
                  </el-row>
                </el-form-item>

                <el-form-item label="Upgrade index" v-if="offer.isPublic">
                  <el-input-number v-model="upgradeIndex"></el-input-number>
                </el-form-item>
              </el-col>
            </el-row>

            <div v-for="type in ['workspace', 'magazine']" :key="type">
              <el-divider></el-divider>

              <el-row>
                <h2>Options for {{ type }}</h2>
              </el-row>

              <el-row class="mt-2">
                <el-col :span="8">
                  <el-form-item
                    v-for="limitType in listExistingLimits[type]"
                    :key="limitType.value"
                    :label="limitType.label"
                  >
                    <el-row type="flex" align="middle">
                      <el-switch
                        :value="offerSettings.limits[limitType.value] === -1"
                        @change="
                          updateField({
                            key: 'offerSettings.limits.' + limitType.value,
                            value: $event ? -1 : 0,
                          })
                        "
                      ></el-switch>
                      <el-input-number
                        class="ml-1"
                        style="width: 100px"
                        controls-position="right"
                        :value="offerSettings.limits[limitType.value]"
                        @change="
                          updateField({
                            key: 'offerSettings.limits.' + limitType.value,
                            value: $event,
                          })
                        "
                        v-show="offerSettings.limits[limitType.value] !== -1"
                      />
                      <el-tag
                        v-show="offerSettings.limits[limitType.value] === -1"
                        type="info"
                        effect="dark"
                        class="ml-1"
                        >illimited</el-tag
                      >
                    </el-row>
                  </el-form-item>
                </el-col>

                <el-col :span="16">
                  <el-row>
                    <el-col
                      :span="12"
                      v-for="accessType in listExistingAccess[type]"
                      :key="accessType.value"
                    >
                      <el-form-item :label="accessType.label">
                        <el-switch
                          :value="
                            offer.offerSettings.accessList.indexOf(
                              accessType.value
                            ) !== -1
                          "
                          @change="switchAccessFor(accessType.value, $event)"
                        ></el-switch>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>

              <el-row class="mt-1" v-if="type === 'workspace'">
                <el-form-item label="Specific Available Templates">
                  <el-row type="flex" align="middle">
                    <el-select
                      class="el-col el-col-18"
                      v-model="availableTemplates"
                      filterable
                      multiple
                      placeholder="Select one or more specific template"
                    >
                      <el-option
                        v-for="template in templateMagazines"
                        :key="template._id"
                        :label="template.name"
                        :value="template._id"
                      >
                        <span style="float: left">{{ template.name }}</span>
                        <span
                          v-if="template.format.formatBase"
                          style="
                            float: left;
                            color: #8492a6;
                            font-size: 12px;
                            margin-left: 1rem;
                          "
                          >{{ template.format.formatBase.name }}</span
                        >
                      </el-option>
                    </el-select>
                    <el-col :span="6">
                      <el-tag type="info" effect="dark" class="ml-1">
                        {{ availableTemplates.length + " specific templates" }}
                      </el-tag>
                    </el-col>
                  </el-row>
                </el-form-item>
              </el-row>
            </div>
          </el-form>
        </el-col>
      </el-row>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="onClickCancel" v-t="'public.alert.cancel'"></el-button>
      <el-button
        v-if="!editDisabled"
        type="primary"
        v-t="'public.alert.confirm'"
        :disabled="validateButtonDisabled"
        @click="onClickValidate"
      ></el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";

import MzOfferTag from "~/components/offer/MzOfferTag";

export default {
  name: "EditOfferModal",

  components: { MzOfferTag },

  props: [],
  computed: {
    ...mapState("offer", ["offer"]),
    ...mapGetters("offer", [
      "listOfferTag",
      "listOfferMarketType",
      "listExistingAccess",
      "listExistingLimits",
    ]),
    ...mapState("magazine", ["templateMagazines"]),

    dialogTitle() {
      if (this.editDisabled) {
        return this.$t("admin.offer.showTitle");
      } else {
        return this.offer._id
          ? this.$t("admin.offer.editTitle")
          : this.$t("admin.offer.createTitle");
      }
    },
    productPrice() {
      if (this.stripePriceId && this.stripePriceId.length > 0) {
        if (
          this.offer.productPrice &&
          this.offer.productPrice.amount !== undefined &&
          this.offer.productPrice.currency !== undefined
        ) {
          return (
            this.offer.productPrice.amount / 100 +
            " " +
            this.offer.productPrice.currency
          );
        }
      }

      return "-";
    },

    name: {
      get() {
        return this.offer.name;
      },
      set(val) {
        this.updateField({ key: "name", value: val });
      },
    },
    stripePriceId: {
      get() {
        return this.offer.stripePriceId;
      },
      set(val) {
        this.updateField({ key: "stripePriceId", value: val });
      },
    },
    upgradeIndex: {
      get() {
        return this.offer.upgradeIndex;
      },
      set(val) {
        this.updateField({ key: "upgradeIndex", value: val });
      },
    },

    isPublic: {
      get() {
        return this.offer.isPublic;
      },
      set(val) {
        this.updateField({ key: "isPublic", value: val });
      },
    },

    isDefault: {
      get() {
        return this.offer.isDefault;
      },
      set(val) {
        this.updateField({ key: "isDefault", value: val });
      },
    },

    tag: {
      get() {
        return this.offer.tag;
      },
      set(val) {
        this.updateField({ key: "tag", value: val });
      },
    },

    marketType: {
      get() {
        return this.offer.marketType;
      },
      set(val) {
        this.updateField({ key: "marketType", value: val });
      },
    },

    duration: {
      get() {
        return this.offer.duration;
      },
      set(val) {
        this.updateField({ key: "duration", value: val });
      },
    },

    offerDurationDate: {
      get() {
        return this.offer.duration.durationDate;
      },
      set(val) {
        let duration = JSON.parse(JSON.stringify(this.offer.duration));
        duration.durationDate = val;
        this.updateField({
          key: "duration",
          value: duration,
        });
      },
    },

    offerSettings: {
      get() {
        return this.offer.offerSettings;
      },
      set(val) {
        this.updateField({ key: "offerSettings", value: val });
      },
    },

    availableTemplates: {
      get() {
        return this.offer.offerSettings.availableTemplates;
      },
      set(val) {
        this.updateField({
          key: "offerSettings.availableTemplates",
          value: val,
        });
      },
    },

    formatedTemplates() {
      return [];
    },

    validateButtonDisabled() {
      return (
        this.loading === true ||
        this.name === null ||
        this.name.trim().length < 2
      );
    },
  },
  data() {
    return {
      loading: false,
      displayModal: false,
      editDisabled: false,
    };
  },

  mounted() {
    this.loadTemplateMagazines({
      filter: { status: "Published" },
      sort: "name",
    }).then(() => {
      console.log(
        "templateMagazines",
        JSON.parse(JSON.stringify(this.templateMagazines))
      );
    });
  },
  methods: {
    ...mapActions({
      loadOffer: "offer/LOAD_OFFER",
      loadOffers: "offer/LOAD_OFFERS",
      saveOffer: "offer/SAVE_OFFER",
      loadTemplateMagazines: "magazine/LOAD_TEMPLATE_MAGAZINES",
    }),
    ...mapMutations({
      updateField: "offer/UPDATE_FIELD",
      resetOffer: "offer/RESET_OFFER",
      resetOffers: "offer/RESET_OFFERS",
    }),

    hide() {
      this.displayModal = false;
    },
    show(offerId) {
      this.loading = true;
      this.loadOffer(offerId).then(() => {
        this.loading = false;
      });
      this.editDisabled = true;
      this.displayModal = true;
    },
    showCreate() {
      this.resetOffer();
      this.editDisabled = false;
      this.displayModal = true;
    },
    showEdit(offerId) {
      this.loading = true;
      this.loadOffer(offerId).then(() => {
        this.loading = false;
      });
      this.editDisabled = false;
      this.displayModal = true;
    },
    showDuplicate(offerId) {
      this.loading = true;
      this.resetOffer();
      this.loadOffer(offerId).then(() => {
        this.updateField({
          key: "_id",
          value: null,
        });
        this.updateField({
          key: "isDefault",
          value: false,
        });
        this.loading = false;
      });
      this.editDisabled = false;
      this.displayModal = true;
    },
    switchAccessFor(type, value) {
      if (this.editDisabled) {
        return;
      }

      const currentAccesList = [...this.offerSettings.accessList],
        index = currentAccesList.indexOf(type);

      if (value) {
        if (index === -1) {
          currentAccesList.push(type);
          this.updateField({
            key: "offerSettings.accessList",
            value: currentAccesList,
          });
        }
      } else {
        if (index >= 0) {
          currentAccesList.splice(index, 1);
          this.updateField({
            key: "offerSettings.accessList",
            value: currentAccesList,
          });
        }
      }
    },
    onClickCancel() {
      this.hide();
    },
    onClickValidate() {
      if (this.editDisabled) {
        this.hide();
        return;
      }

      this.loading = true;

      this.saveOffer().then((success) => {
        this.loading = false;
        if (success) {
          this.$emit("save", this.offer);
          this.hide();
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.el-form-item {
  margin-bottom: 1rem;
}
.el-select-tag {
  width: 100%;
}
.display-tag-over-select {
  position: absolute;
  left: 10px;
  top: 10px;
  pointer-events: none;
}
</style>