import { fabric } from 'fabric';
import Utils from '~/common/utils/misc';

// use for save precision in toObject
fabric.Object.NUM_FRACTION_DIGITS = 10;

export default {
  id: 0,
  paletteSwatchName: {},
  toObjectProps: ['id', 'paletteSwatchName', 'clientSideLocked', 'adminSideLocked'], // props to save in json export/import
  locked: false,
  clientSideLocked: false,
  adminSideLocked: false,

  initMzObject: function (options) {
    if (!options) {
      options = {};
    }

    options.strokeUniform = false;
    this.id = options.id || Utils.uniqidplus();
    this.paletteSwatchName = options.paletteSwatchName || {};
    this.locked = options.locked || false;

    if (options.readOnly === true) {
      this.locked = true;
      this.selectable = false;
      this.evented = false;
    } else if (options.locked === true) {
      if (options.type === 'MzImage'
        || options.type === 'MzTextbox'
        || (options.type === 'MzShapeGroup' && options.variantGroup)) {
        this.hasControls = false; // for display/hide border selection control
        this.hasBorders = false; // for display/hide border selection control
        this.perPixelTargetFind = true; // for only detect mouse over if pixel not transparent
        this.lockRotation = true;
        this.lockScalingX = true;
        this.lockScalingY = true;
        this.lockMovementX = true;
        this.lockMovementY = true;
        this.hoverCursor = 'pointer';
        this.editable = false;
      } else if (options.type === 'MzGroup') {
        this.selectInsideGroup = true;
        this.selectable = false;
      } else {
        this.selectable = false;
        this.evented = false;
      }
    }

    return options;
  },

  changeReadOnly(value) {
    if (value) {
      this.locked = true;
      this.selectable = false;
      this.evented = false;
    } else {
      this.locked = false;
      this.selectable = true;
      this.evented = true;
    }
  },


  toObject: function (propertiesToInclude) {
    const object = this.callSuper('toObject', this.toObjectProps.concat(propertiesToInclude));
    if (this.type === 'MzImage') {
      return this.formatToObjectResult(object);
    } else {
      return object;
    }
  },

};
