export default function ({ app }, inject) {
  inject('go2Route', (routeParams) => {
    // const to = app.localePath(routeParams);
    // if (app.router.currentRoute.path === to) {
    //   window.location.reload();
    // } else {
    //   app.router.push(to)
    // }
    app.router.push(app.localePath(routeParams));
  })
}