<template>
  <MzIconButton
    v-show="isInProgress && nbProduct > 0"
    src="/icons/cart.svg"
    @click="$emit('click')"
    :tag="nbProduct"
  />
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";

import MzIconButton from "~/components/MzIconButton";

export default {
  name: "CurrentCartIcon",
  components: {
    MzIconButton,
  },

  computed: {
    ...mapState("order", ["currentCart"]),

    isInProgress() {
      return this.currentCart && this.currentCart.status === "draft";
    },
    nbProduct() {
      return this.currentCart && this.currentCart.printProducts
        ? this.currentCart.printProducts.length
        : 0;
    },
  },
  mounted() {
    this.loadCurrentCart();
  },
  methods: {
    ...mapActions({
      loadCurrentCart: "order/LOAD_CURRENT_CART",
    }),
  },
};
</script>

<style scoped lang="scss">
</style>