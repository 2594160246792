
import { fabric } from "fabric";

/**
 * Non interactive Rectangle used to be drawn on background canvas in MZPage
 */
export default () => {
  fabric.MzNonInteractiveGroup = fabric.util.createClass(fabric.Group, {
    type: 'MzNonInteractiveGroup',
    topLayer: false,
    backLayer: false,

    initialize: function (objects = [], options = {}, isAlreadyGrouped = false) {
      this.topLayer = options.topLayer || false;
      this.backLayer = options.backLayer || false;
      options.selectable = false;
      options.evented = false;
      options.hasControls = false;
      options.hasBorders = false;
      options.excludeFromExport = true;
      this.callSuper('initialize', objects, options, isAlreadyGrouped);
    },

  });

  fabric.MzNonInteractiveGroup.fromObject = function (object, callback) {
    return fabric.Object._fromObject('MzNonInteractiveGroup', object, callback);
  }
}