<template>
  <el-row class="row-fit-parent mr-2" type="flex" align="middle">
    <span v-show="magazine._id">{{ magazine.name }}</span>

    <el-dropdown
      class="ml-2"
      trigger="click"
      placement="bottom-start"
      v-if="magazine.status !== 'Published'"
      :disabled="!magazine._id || !isOwner"
    >
      <el-button type="default" plain>
        {{ $t("public.edit_magazine.preview") }}
        <i class="el-icon-arrow-down el-icon--right ml-1"></i>
      </el-button>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item @click.native="onClickViewPdf">
          {{ $t("public.preview.web_version") }}
        </el-dropdown-item>
        <el-dropdown-item @click.native="onClickDownloadPdf">
          {{ $t("public.preview.pdf_version") }}
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>

    <el-button
      v-t="
        magazine.status === 'Published'
          ? 'public.edit_magazine.unpublish'
          : 'public.edit_magazine.publish'
      "
      type="primary"
      plain
      class="ml-2"
      @click="onClickPublishMagazine"
      :disabled="!canSwitchPublish"
    />

    <el-row class="row-fit-parent" type="flex" align="middle" justify="end">
      <el-tabs
        v-model="activeTabName"
        @tab-click="onTabClick"
        class="mr-2 app-header-nav"
      >
        <el-tab-pane
          label="Flatplan"
          name="flatplan"
          :disabled="!magazine._id"
        ></el-tab-pane>
        <el-tab-pane
          label="Themes"
          name="themes"
          :disabled="!magazine._id"
        ></el-tab-pane>
        <el-tab-pane
          label="Library"
          name="library"
          :disabled="!magazine._id"
        ></el-tab-pane>
        <el-tab-pane
          label="Settings"
          name="settings"
          :disabled="!magazine._id"
        ></el-tab-pane>
      </el-tabs>
    </el-row>

    <RequestPdfOrPublishModal ref="requestPdfOrPublishModal" />
  </el-row>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";

import ImageUtils from "~/common/utils/imageUtils";

import RequestPdfOrPublishModal from "~/components/magazine/RequestPdfOrPublishModal";

export default {
  name: "EditTemplateHeader",
  components: { RequestPdfOrPublishModal },

  props: [],
  computed: {
    ...mapGetters("api", ["checkPerm", "getUID"]),
    ...mapState("magazine", ["magazine"]),
    ...mapState("app", ["appGUI"]),

    isOwner() {
      return this.magazine.owner === this.getUID;
    },

    canSwitchPublish() {
      if (!this.magazine._id) {
        return false;
      } else {
        return this.isOwner || this.checkPerm("template:editPublish");
      }
    },

    previewPdfData() {
      return this.magazine && this.magazine.exportedPdfs
        ? this.magazine.exportedPdfs.find((item) => item.version == "draft")
        : undefined;
    },
    pdfIsOutOfDate() {
      if (this.previewPdfData) {
        return (
          this.previewPdfData.exportedAt &&
          this.previewPdfData.magazineDate !== this.magazine.lastVisualChangeAt
        );
      }

      return false;
    },
    pdfIsGenerated() {
      if (this.previewPdfData) {
        return (
          this.previewPdfData.exportedAt &&
          this.previewPdfData.files &&
          this.previewPdfData.files.magazine
        );
      }
      return false;
    },
    pdfIsInProgress() {
      if (this.previewPdfData) {
        return (
          this.previewPdfData.requestedAt && !this.previewPdfData.exportedAt
        );
      }

      return false;
    },
  },
  data() {
    return {
      activeTabName: "",
    };
  },
  created() {
    this.$bus.$on("flatplanExtended", this.flatplanExtended);
    this.updateActiveTabName();
  },
  beforeDestroy() {
    this.$bus.$off("flatplanExtended", this.flatplanExtended);
  },
  methods: {
    updateActiveTabName() {
      const routeName = this.$nuxt.$route.name.split("___")[0];
      switch (routeName) {
        case "admin-editor-magazineId-paletteId":
          this.activeTabName = "flatplan";
          break;

        case "admin-library-edit-libraryId":
          this.activeTabName = "library";
          break;

        case "admin-theme-list-templateId":
          this.activeTabName = "themes";
          break;

        case "admin-template-edit-templateId":
          this.activeTabName = "settings";
          break;

        default:
          this.activeTabName = "";
          break;
      }
    },

    onTabClick() {
      switch (this.activeTabName) {
        case "flatplan":
          this.go2Editor();
          break;
        case "library":
          this.go2Library();
          break;
        case "themes":
          this.go2ThemeList();
          break;
        case "settings":
          this.go2Compose();
          break;
      }
    },
    flatplanExtended() {
      this.activeTabName =
        this.appGUI.flatplan.state === "extended" ? "flatplan" : "edition";
    },

    go2Compose() {
      this.$go2Route({
        name: "admin-template-edit-templateId",
        params: { templateId: this.magazine._id },
      });
    },
    go2Editor() {
      this.$go2Route({
        name: "admin-editor-magazineId-paletteId",
        params: { magazineId: this.magazine._id },
      });
      // force extend flatplan if already in editor page
      this.$bus.$emit("extendFlatplan", true);
    },
    go2ThemeList() {
      this.$go2Route({
        name: "admin-theme-list-templateId",
        params: { templateId: this.magazine._id },
      });
    },
    go2Library() {
      this.$go2Route({
        name: "admin-library-edit-libraryId",
        params: { libraryId: this.magazine.library },
      });
    },

    onClickPublishMagazine() {
      this.$refs.requestPdfOrPublishModal.switchPublish();
    },

    onClickViewPdf(checkOutOfDate = true) {
      if (
        !this.pdfIsGenerated ||
        this.pdfIsInProgress ||
        (checkOutOfDate && this.pdfIsOutOfDate)
      ) {
        this.$refs.requestPdfOrPublishModal.requestPdf(
          "draft",
          () => {
            if (this.pdfIsOutOfDate) {
              // specific case, magazine has change during pdf generation
              this.$confirm(
                this.$t(
                  "public.preview.magazineHasChangeDuringGeneratePdf.message"
                ),
                "",
                {
                  confirmButtonText: this.$t(
                    "public.preview.magazineHasChangeDuringGeneratePdf.confirm"
                  ),
                  cancelButtonText: this.$t(
                    "public.preview.magazineHasChangeDuringGeneratePdf.cancel"
                  ),
                }
              )
                .then(() => {
                  // confirm
                  this.onClickViewPdf(true);
                })
                .catch(() => {
                  // cancel
                  this.onClickViewPdf(false);
                });
            } else {
              this.onClickViewPdf(false);
            }
          },
          false,
          this.$t("public.preview.open_preview")
        );
        return;
      }

      if (
        this.previewPdfData &&
        this.previewPdfData.files &&
        this.previewPdfData.files.magazine
      ) {
        const url =
          process.env.VUE_APP_URL +
          this.localePath({
            name: "viewer-magazineId",
            params: { magazineId: this.magazine._id },
            query: { preview: 1 },
          });
        window.open(url, "_blank");
      } else {
        console.log(
          "onClickViewPdf without files",
          JSON.parse(JSON.stringify(this.previewPdfData))
        );
      }
    },
    onClickDownloadPdf(checkOutOfDate = true) {
      if (
        !this.pdfIsGenerated ||
        this.pdfIsInProgress ||
        (checkOutOfDate && this.pdfIsOutOfDate)
      ) {
        this.$refs.requestPdfOrPublishModal.requestPdf(
          "draft",
          () => {
            if (this.pdfIsOutOfDate) {
              // specific case, magazine has change during pdf generation
              this.$confirm(
                this.$t(
                  "public.preview.magazineHasChangeDuringGeneratePdf.message"
                ),
                "",
                {
                  confirmButtonText: this.$t(
                    "public.preview.magazineHasChangeDuringGeneratePdf.confirm"
                  ),
                  cancelButtonText: this.$t(
                    "public.preview.magazineHasChangeDuringGeneratePdf.cancel"
                  ),
                }
              )
                .then(() => {
                  // confirm
                  this.onClickDownloadPdf(true);
                })
                .catch(() => {
                  // cancel
                  this.onClickDownloadPdf(false);
                });
            } else {
              this.onClickDownloadPdf(false);
            }
          },
          false,
          this.$t("public.preview.open_preview")
        );
        return;
      }

      if (
        this.previewPdfData &&
        this.previewPdfData.files &&
        this.previewPdfData.files.magazine
      ) {
        const url = ImageUtils.getPdfSrc(
          this.magazine._id,
          "draft",
          this.previewPdfData.files.magazine
        );
        window.open(url, "_blank");
      } else {
        console.log(
          "onClickDownloadPdf without files",
          JSON.parse(JSON.stringify(this.previewPdfData))
        );
      }
    },
  },
  watch: {
    $route() {
      this.updateActiveTabName();
    },
  },
};
</script>

<style scoped lang="scss">
@import "~assets/css/mz-variables.scss";

.row-fit-parent {
  flex: 1;
  height: 100%;
}

.el-dropdown-menu {
  padding: $margin-1;
  min-width: 240px;

  & .el-button {
    width: 100%;
  }
  & .el-dropdown-menu__item {
    line-height: 1.25rem;
    padding: 0.5rem $margin-1;
    &:hover {
      border-radius: 4px;
    }
  }
}
</style>