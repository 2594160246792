<template>
  <el-row
    class="edit-magazine-header row-fit-parent"
    type="flex"
    align="middle"
  >
    <!-- magazine name and issue -->
    <el-popover
      placement="bottom"
      trigger="click"
      class="magazine-name-content ml-1 mr-1"
      width="300"
      :visible-arrow="false"
    >
      <el-row type="flex" align="middle" justify="start">
        <el-col :span="6">
          <small>{{ $t("public.edit_magazine.magazineName") }}</small>
        </el-col>
        <el-col :span="18">
          <EditableText
            :value="magazine.name"
            @change="changeMagazineProp('name', $event)"
            :disableEditing="!canAccessChangeName"
            class="text-blue"
            :autoSuggest="autoSuggestMagazineName"
          ></EditableText>
        </el-col>
      </el-row>
      <el-row type="flex" align="middle" justify="start">
        <el-col :span="6">
          <small>{{ $t("public.edit_magazine.magazineIssue") }}</small>
        </el-col>
        <el-col :span="18">
          <EditableText
            :value="magazine.issue"
            @change="changeMagazineProp('issue', $event)"
            :disableEditing="!canAccessChangeName"
            class="text-style-small"
          ></EditableText>
        </el-col>
      </el-row>
      <el-row
        type="flex"
        align="middle"
        justify="start"
        v-if="userCollaboration"
      >
        <el-col :span="6">
          <small>{{ $t("public.edit_magazine.magazineUserRole") }}</small>
        </el-col>
        <el-col :span="18">
          <UserRoleTag
            :user="userCollaboration"
            :applyCurrentUserStyle="true"
          />
        </el-col>
      </el-row>

      <el-button
        type="text"
        class="text-style-3 text-blue text-left mt-0"
        slot="reference"
      >
        {{ magazine.name }}<br />
        <small>{{ magazine.issue }}</small>
      </el-button>
    </el-popover>

    <!-- preview access -->
    <el-dropdown
      class="ml-2"
      trigger="click"
      placement="bottom-start"
      v-if="canAccessPreview"
      :disabled="!checkPerm('magazine:update')"
    >
      <el-button type="primary">
        {{ $t("public.edit_magazine.preview") }}
        <i class="el-icon-arrow-down el-icon--right ml-1"></i>
      </el-button>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item
          :disabled="!pdfIsGenerated && !canGeneratePdfPreview"
          @click.native="onClickViewPdf"
        >
          {{ $t("public.preview.web_version") }}
        </el-dropdown-item>
        <el-dropdown-item
          :disabled="!pdfIsGenerated && !canGeneratePdfPreview"
          @click.native="onClickDownloadPdf"
        >
          {{ $t("public.preview.pdf_version") }}
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>

    <!-- current magazine offer -->
    <div
      v-if="
        checkPerm('magazine:createWithPublicOffer') &&
        magazine.recursiveActiveOffer
      "
      class="ml-2 btn-upgrade-offer"
      @click="onClickUpgradeOffer"
    >
      <MzOfferTag
        :offer="magazine.recursiveActiveOffer"
        :showCompleteTag="true"
      />
    </div>

    <!-- magazine action tabs -->
    <el-row class="row-fit-parent" type="flex" align="middle" justify="end">
      <!--
        Not good here, we shouldn't use tabs, it's not made for navigation and you can't open it on a new tab with it, it will create accessibility issues
        A navigation component would be more appropriate
      -->
      <el-tabs
        v-model="activeTabName"
        @tab-click="onTabClick"
        class="mr-2 app-header-nav"
        v-if="magazineForEditingLoadComplete"
      >
        <el-tab-pane
          v-if="canAccessEdition"
          :label="$t('public.edit_magazine.edition')"
          name="edition"
        ></el-tab-pane>
        <el-tab-pane
          v-if="canAccessEdition"
          :label="$t('public.edit_magazine.flatplan')"
          name="flatplan"
        ></el-tab-pane>
        <el-tab-pane
          :label="$t('public.edit_magazine.collaboration')"
          name="collaboration"
          v-if="canAccessCollaboration"
        ></el-tab-pane>
        <!-- <el-tab-pane
          :label="$t('public.edit_magazine.publication')"
          name="publication"
          v-if="canAccessPublication"
        ></el-tab-pane> -->
      </el-tabs>
    </el-row>

    <!-- publish access -->
    <el-button
      v-t="
        magazine.status === 'Published'
          ? 'public.edit_magazine.unpublish'
          : 'public.edit_magazine.publish'
      "
      type="primary"
      class="mr-1"
      @click="onClickPublishMagazine"
      v-if="
        (magazine.status === 'Published' &&
          checkMagazinePerm('magPerms:magazine:unpublish')) ||
        (magazine.status !== 'Published' &&
          checkMagazinePerm('magPerms:magazine:publish'))
      "
    />

    <RequestPdfOrPublishModal ref="requestPdfOrPublishModal" />
    <UpgradeOfferModal ref="upgradeOfferModal" />
  </el-row>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";

import ImageUtils from "~/common/utils/imageUtils";

import EditableText from "~/components/EditableText.vue";
import UserRoleTag from "~/components/collaboration/UserRoleTag";
import RequestPdfOrPublishModal from "~/components/magazine/RequestPdfOrPublishModal";
import MzOfferTag from "~/components/offer/MzOfferTag";
import UpgradeOfferModal from "~/components/magazine/UpgradeOfferModal";

export default {
  name: "EditMagazineHeader",

  components: {
    UserRoleTag,
    RequestPdfOrPublishModal,
    EditableText,
    MzOfferTag,
    UpgradeOfferModal,
  },

  props: [],
  computed: {
    ...mapState("magazine", [
      "magazine",
      "magazineForEditingLoadComplete",
      "userMagazines",
    ]),
    ...mapState("collaboration", ["userCollaboration"]),
    ...mapState("app", ["appGUI"]),
    ...mapGetters("magazine", ["checkMagazinePerm"]),
    ...mapGetters("api", ["checkPerm", "getUID"]),
    ...mapGetters("collaboration", ["collaborationIsActive"]),

    canAccessPreview() {
      return (
        this.magazine.status !== "Published" &&
        this.checkMagazinePerm("magPerms:magazine:preview")
      );
    },

    canGeneratePdfPreview() {
      return this.checkMagazinePerm("magPerms:magazine:pdfRequest:draft");
    },

    canAccessPublication() {
      return this.magazine.status === "Published";
    },

    canAccessCollaboration() {
      if (!this.canAccessEdition) {
        return false;
      }

      if (!this.collaborationIsActive) {
        return (
          this.checkMagazinePerm("magPerms:collaboration:create") ||
          !this.checkPerm("offer:hideUpgradeAction")
        );
      }

      return this.checkMagazinePerm("magPerms:collaboration:user:list");
    },

    canAccessChangeName() {
      return (
        this.magazine.status !== "Published" &&
        this.checkMagazinePerm("magPerms:magazine:updateMagazineField")
      );
    },

    canAccessEdition() {
      return /*this.isChief || */ this.magazine.status !== "Published";
    },

    isChief() {
      if (!this.collaborationIsActive) {
        return this.magazine.owner === this.getUID;
      } else {
        return (
          this.userCollaboration && this.userCollaboration.role === "chief"
        );
      }
    },

    previewPdfData() {
      return this.magazine && this.magazine.exportedPdfs
        ? this.magazine.exportedPdfs.find((item) => item.version == "draft")
        : undefined;
    },

    pdfIsOutOfDate() {
      if (this.previewPdfData) {
        return (
          this.previewPdfData.exportedAt &&
          this.previewPdfData.magazineDate !== this.magazine.lastVisualChangeAt
        );
      }

      return false;
    },

    pdfIsGenerated() {
      if (this.previewPdfData) {
        return (
          this.previewPdfData.exportedAt &&
          this.previewPdfData.files &&
          this.previewPdfData.files.magazine
        );
      }

      return false;
    },
    pdfIsInProgress() {
      if (this.previewPdfData) {
        return (
          this.previewPdfData.requestedAt && !this.previewPdfData.exportedAt
        );
      }

      return false;
    },

    magazineNames() {
      return this.userMagazines
        .reduce((list, magazine) => {
          if (
            magazine.owner === this.getUID &&
            list.indexOf(magazine.name) === -1
          ) {
            list.push(magazine.name);
          }
          return list;
        }, [])
        .sort((a, b) => a.toLowerCase() > b.toLowerCase());
    },
  },
  created() {
    this.$bus.$on("flatplanExtended", this.flatplanExtended);
    this.$bus.$on("showUpgradeOffer", this.onClickUpgradeOffer);

    this.updateActiveTabName();

    // force load magazines list if not loaded
    if (!this.userMagazines || this.userMagazines.length === 0) {
      this.loadUserMagazines();
    }
  },
  beforeDestroy() {
    this.$bus.$off("flatplanExtended", this.flatplanExtended);
    this.$bus.$off("showUpgradeOffer", this.onClickUpgradeOffer);
    this.resetAllMagazineDependency();
  },
  data() {
    return {
      activeTabName: "",
    };
  },
  methods: {
    ...mapActions({
      saveMagazine: "magazine/SAVE_MAGAZINE",
      resetAllMagazineDependency: "magazine/RESET_ALL_MAGAZINE_DEPENDENCY",
      loadUserMagazines: "magazine/LOAD_USER_MAGAZINES",
    }),
    ...mapMutations({
      updateMagazineField: "magazine/UPDATE_FIELD",
    }),

    updateActiveTabName() {
      const _routeName = this.$nuxt.$route.name;
      let neededTabName = null;
      if (_routeName.indexOf("client-magazine-edit-magazineId") === 0) {
        neededTabName = "edition";
        const split = this.$nuxt.$route.path.split("/"),
          endRouteName = split[split.length - 1];
        if (endRouteName === "collaboration") {
          neededTabName = "collaboration";
        } else if (endRouteName === "publication" || endRouteName === "order") {
          neededTabName = "publication";
        } else {
          if (this.$nuxt.$route.query.flatplan === "extended") {
            neededTabName = "flatplan";
          } else {
            neededTabName = "edition";
          }
        }
      }

      if (neededTabName && this.activeTabName != neededTabName) {
        this.activeTabName = neededTabName;
      }
    },

    onTabClick() {
      const _routeName = this.$nuxt.$route.name;
      if (
        this.activeTabName === "edition" ||
        this.activeTabName === "flatplan"
      ) {
        const routeMain = _routeName.split("_")[0];
        if (routeMain === "client-magazine-edit-magazineId") {
          // dispatch open/close flatplan
          this.$bus.$emit("extendFlatplan", this.activeTabName === "flatplan");
        } else {
          this.$go2Route({
            name: "client-magazine-edit-magazineId",
            params: {
              magazineId: this.magazine._id,
            },
            query: {
              flatplan:
                this.activeTabName === "flatplan" ? "extended" : "opened",
            },
          });
        }
      } else if (this.activeTabName === "collaboration") {
        this.$go2Route({
          name: "client-magazine-edit-magazineId-collaboration",
          params: {
            magazineId: this.magazine._id,
          },
        });
      } else if (this.activeTabName === "publication") {
        this.$go2Route({
          name: "client-magazine-edit-magazineId-publication",
          params: {
            magazineId: this.magazine._id,
          },
        });
      }
    },
    flatplanExtended() {
      this.activeTabName =
        this.appGUI.flatplan.state === "extended" ? "flatplan" : "edition";
    },
    onClickPublishMagazine() {
      this.$refs.requestPdfOrPublishModal.switchPublish(() => {
        // callback function after switch publish
        if (this.magazine.status === "Published") {
          this.$go2Route({
            name: "client-magazine-edit-magazineId-publication",
            params: {
              magazineId: this.magazine._id,
            },
          });
        } else {
          this.$go2Route({
            name: "client-magazine-edit-magazineId",
            params: {
              magazineId: this.magazine._id,
            },
            query: {
              flatplan: "extended",
            },
          });
        }
      });
    },
    onClickViewPdf(checkOutOfDate = true) {
      if (
        !this.pdfIsGenerated ||
        this.pdfIsInProgress ||
        (checkOutOfDate && this.pdfIsOutOfDate)
      ) {
        if (this.canGeneratePdfPreview) {
          this.$refs.requestPdfOrPublishModal.requestPdf(
            "draft",
            () => {
              if (this.pdfIsOutOfDate) {
                // specific case, magazine has change during pdf generation
                this.$confirm(
                  this.$t(
                    "public.preview.magazineHasChangeDuringGeneratePdf.message"
                  ),
                  "",
                  {
                    confirmButtonText: this.$t(
                      "public.preview.magazineHasChangeDuringGeneratePdf.confirm"
                    ),
                    cancelButtonText: this.$t(
                      "public.preview.magazineHasChangeDuringGeneratePdf.cancel"
                    ),
                  }
                )
                  .then(() => {
                    // confirm
                    this.onClickViewPdf(true);
                  })
                  .catch(() => {
                    // cancel
                    this.onClickViewPdf(false);
                  });
              } else {
                this.onClickViewPdf(false);
              }
            },
            false,
            this.$t("public.preview.open_preview")
          );
        }
        return;
      }

      if (
        this.previewPdfData &&
        this.previewPdfData.files &&
        this.previewPdfData.files.magazine
      ) {
        const url =
          process.env.VUE_APP_URL +
          this.localePath({
            name: "viewer-magazineId",
            params: { magazineId: this.magazine._id },
            query: { preview: 1 },
          });
        window.open(url, "_blank");
      } else {
        console.log(
          "onClickViewPdf without files",
          JSON.parse(JSON.stringify(this.previewPdfData))
        );
      }
    },
    onClickDownloadPdf(checkOutOfDate = true) {
      if (
        !this.pdfIsGenerated ||
        this.pdfIsInProgress ||
        (checkOutOfDate && this.pdfIsOutOfDate)
      ) {
        if (this.canGeneratePdfPreview) {
          this.$refs.requestPdfOrPublishModal.requestPdf(
            "draft",
            () => {
              if (this.pdfIsOutOfDate) {
                // specific case, magazine has change during pdf generation
                this.$confirm(
                  this.$t(
                    "public.preview.magazineHasChangeDuringGeneratePdf.message"
                  ),
                  "",
                  {
                    confirmButtonText: this.$t(
                      "public.preview.magazineHasChangeDuringGeneratePdf.confirm"
                    ),
                    cancelButtonText: this.$t(
                      "public.preview.magazineHasChangeDuringGeneratePdf.cancel"
                    ),
                  }
                )
                  .then(() => {
                    // confirm
                    this.onClickDownloadPdf(true);
                  })
                  .catch(() => {
                    // cancel
                    this.onClickDownloadPdf(false);
                  });
              } else {
                this.onClickDownloadPdf(false);
              }
            },
            false,
            this.$t("public.preview.open_preview")
          );
        }
        return;
      }

      if (
        this.previewPdfData &&
        this.previewPdfData.files &&
        this.previewPdfData.files.magazine
      ) {
        const url = ImageUtils.getPdfSrc(
          this.magazine._id,
          "draft",
          this.previewPdfData.files.magazine
        );
        window.open(url, "_blank");
      } else {
        console.log(
          "onClickDownloadPdf without files",
          JSON.parse(JSON.stringify(this.previewPdfData))
        );
      }
    },
    changeMagazineProp(key, value) {
      this.updateMagazineField({ key, value });
      this.saveMagazine().then((success) => {
        if (success) {
          // force flatplan to reload current page
          this.$bus.$emit("forceReloadPageFlatplan");
        }
      });
    },
    autoSuggestMagazineName(queryString, cb) {
      const list = this.magazineNames
        .filter(
          (name) => name.toLowerCase().indexOf(queryString.toLowerCase()) === 0
        )
        .map((name) => {
          return { value: name };
        });
      cb(list);
    },

    onClickUpgradeOffer() {
      this.$refs.upgradeOfferModal.show();
    },
  },
  watch: {
    $route() {
      this.updateActiveTabName();
    },
  },
};
</script>

<style scoped lang="scss">
@import "~assets/css/mz-variables.scss";

.edit-magazine-header {
  border-right: 1px solid $public-color-grey-bg;
}

.row-fit-parent {
  flex: 1;
  height: 100%;
}

.el-dropdown-link {
  color: $public-color-blue;
  font-size: 1.13rem;
  cursor: pointer;
}
.el-dropdown-menu {
  padding: $margin-1;
  min-width: 240px;

  & .el-button {
    width: 100%;
  }
  & .el-dropdown-menu__item {
    line-height: 1.25rem;
    padding: 0.5rem $margin-1;
    &:hover {
      border-radius: 4px;
    }
  }
}

.alert-pdf-out-of-date {
  padding: 0.5rem;
  font-size: 0.75rem !important;

  & a {
    font-size: 0.75rem !important;
  }
}

.magazine-name-content {
  max-width: 20vw;
}

.btn-upgrade-offer {
  cursor: pointer;
}
</style>