
import { fabric } from "fabric";
import Utils from "~/common/utils/misc";

/**
 * Represent a magazine page, need to pass canvas as parameter
 */
export default () => {
  fabric.MzPage = fabric.util.createClass(fabric.Object, {
    initialized: false,
    type: 'MzPage',
    id: 'page', // set default id for page for use with palette swatch
    canvas: null,
    pageRect: {},
    format: {},
    printMarginRect: {},
    safeZonenRect: {},
    displayBackground: null,
    displayPageRect: null,
    marginTop: 0,
    marginLeft: 0,
    marginBottom: 0,
    marginRight: 0,
    doublePage: false,
    specificMagazineData: null,
    paletteSwatchName: {},
    _backgroundFill: null,
    defaultPageBackgroundColor: '#ffffff',

    initialize: function (canvas, options) {

      console.log('init MzPage with options', options);

      this.canvas = canvas;
      this.pageRect = options.pageRect;
      this.format = options.format;
      this.doublePage = options.double;

      const originalPageWidth = this.pageRect.width;
      if (this.doublePage) {
        this.pageRect.width *= 2;
      }

      // save specific data to get from component (ex: default style for bullet point)
      this.specificMagazineData = options.specificMagazineData || null;
      if (this.specificMagazineData && this.specificMagazineData.defaultPageBackgroundColor) {
        this.defaultPageBackgroundColor = this.specificMagazineData.defaultPageBackgroundColor;
      }

      //compute print margin rect
      this.marginTop = Utils.convertMilimetersToPixels(this.format.printMargins.top, this.format.dpi);
      this.marginLeft = Utils.convertMilimetersToPixels(this.format.printMargins.left, this.format.dpi);
      this.marginRight = Utils.convertMilimetersToPixels(this.format.printMargins.right, this.format.dpi);
      this.marginBottom = Utils.convertMilimetersToPixels(this.format.printMargins.bottom, this.format.dpi);

      this.printMarginRect = {
        top: this.pageRect.top - this.marginTop,
        left: this.pageRect.left - this.marginLeft,
        width: this.pageRect.width + Utils.convertMilimetersToPixels(this.format.printMargins.left + this.format.printMargins.right, this.format.dpi),
        height: this.pageRect.height + Utils.convertMilimetersToPixels(this.format.printMargins.top + this.format.printMargins.bottom, this.format.dpi)
      };
      this.safeZoneRects = [];
      this.safeZoneRects.push(
        {
          top: this.pageRect.top + Utils.convertMilimetersToPixels(this.format.safeZone.top, this.format.dpi),
          left: this.pageRect.left + Utils.convertMilimetersToPixels(this.format.safeZone.left, this.format.dpi),
          width: originalPageWidth - Utils.convertMilimetersToPixels(this.format.safeZone.left + this.format.safeZone.right, this.format.dpi),
          height: this.pageRect.height - Utils.convertMilimetersToPixels(this.format.safeZone.top + this.format.safeZone.bottom, this.format.dpi)
        }
      );
      if (this.doublePage) {
        this.safeZoneRects.push(
          {
            top: this.pageRect.top + Utils.convertMilimetersToPixels(this.format.safeZone.top, this.format.dpi),
            left: this.pageRect.left + originalPageWidth + Utils.convertMilimetersToPixels(this.format.safeZone.left, this.format.dpi),
            width: originalPageWidth - Utils.convertMilimetersToPixels(this.format.safeZone.left + this.format.safeZone.right, this.format.dpi),
            height: this.pageRect.height - Utils.convertMilimetersToPixels(this.format.safeZone.top + this.format.safeZone.bottom, this.format.dpi)
          }
        );
      }

      // clippath global canvas
      if (options.hideOutContent) {
        const clipRect = new fabric.Rect({ ...this.pageRect, fill: 'red' });
        this.canvas.clipPath = clipRect;
      }

      this.initialized = true;
      this.callSuper('initialize', options);
    },

    drawPage: function (options) {
      //draw Background
      this.displayBackground = new fabric.MzNonInteractiveRect({
        ...this.pageRect,
        fill: this.defaultPageBackgroundColor,
        backLayer: true,
      });
      this.canvas.add(this.displayBackground);

      //draw displayed page with shadow
      this.displayPageRect = new fabric.MzNonInteractiveRect({
        ...this.pageRect,
        fill: null,
        topLayer: true,
        stroke: "#dddddd",
        shadow: 'rgba(0,0,0,0.2) 1px 1px 5px'
      });
      this.canvas.add(this.displayPageRect);

      if (!options || !options.hideMargin) {
        const marginGroup = new fabric.MzNonInteractiveGroup();

        //draw print margin rect
        const displayMarginRect = new fabric.MzNonInteractiveRect({
          ...this.printMarginRect,
          fill: null,
          topLayer: true,
          stroke: "#49BED6",
        });
        marginGroup.addWithUpdate(displayMarginRect);

        this.canvas.columnAnchors = [];
        for (let safeZoneRect of this.safeZoneRects) {
          //draw safe zone rect
          const displaySafeZone = new fabric.MzNonInteractiveRect({
            ...safeZoneRect,
            fill: null,
            topLayer: true,
            stroke: "#DE5FD6",
          });
          marginGroup.addWithUpdate(displaySafeZone);

          // draw column grid
          if (this.format.grid && this.format.grid.column > 1) {
            const columnGroup = new fabric.MzNonInteractiveGroup();

            const nbColumn = this.format.grid.column,
              nbGutter = this.format.grid.gutter > 0 ? nbColumn - 1 : 0,
              gutterWidth = this.format.grid.gutter > 0 ? Utils.convertMilimetersToPixels(this.format.grid.gutter, this.format.dpi) : 0;

            this.canvas.columnAnchors.push(safeZoneRect.left);
            const columnWidth = (safeZoneRect.width - (gutterWidth * nbGutter)) / nbColumn;
            for (let lineX = columnWidth; lineX < safeZoneRect.width; lineX += columnWidth + gutterWidth) {
              const line1 = new fabric.MzNonInteractiveLine(
                [0, 0, 0, safeZoneRect.height],
                {
                  top: safeZoneRect.top,
                  left: safeZoneRect.left + lineX,
                  fill: null,
                  topLayer: true,
                  stroke: "#DE5FD6",
                  strokeWidth: 1
                });
              columnGroup.addWithUpdate(line1);
              this.canvas.columnAnchors.push(safeZoneRect.left + lineX);

              if (gutterWidth > 0) {
                const line2 = new fabric.MzNonInteractiveLine(
                  [0, 0, 0, safeZoneRect.height],
                  {
                    top: safeZoneRect.top,
                    left: safeZoneRect.left + lineX + gutterWidth,
                    fill: null,
                    topLayer: true,
                    stroke: "#DE5FD6",
                    strokeWidth: 1
                  });
                columnGroup.addWithUpdate(line2);
                this.canvas.columnAnchors.push(safeZoneRect.left + lineX + gutterWidth);
              }
            }
            this.canvas.columnAnchors.push(safeZoneRect.left + safeZoneRect.width);
            this.canvas.add(columnGroup);
            this.canvas.columnRendererGroup = columnGroup;
          }
        }

        // draw doublePage limit
        if (this.doublePage) {
          const displayDoublePageRect = new fabric.MzNonInteractiveRect({
            top: this.printMarginRect.top,
            left: this.printMarginRect.left + this.printMarginRect.width / 2,
            width: 0,
            height: this.printMarginRect.height,
            fill: null,
            topLayer: true,
            stroke: "#49BED6",
          });
          marginGroup.addWithUpdate(displayDoublePageRect);
        }

        this.canvas.add(marginGroup);
        this.canvas.marginRendererGroup = marginGroup;
      }

      this.canvas.requestRenderAll();

    },

    /**
     * Return all objects within page boundaries
     */
    getObjectsWithin: function () {
      let objectList = [];
      let box = this.displayPageRect;
      this.canvas.forEachObject(function (obj) {
        // ignore non interactive objects
        if (obj.type != 'MzNonInteractiveRect' && obj.type != 'MzNonInteractiveLine' && obj.type != 'MzNonInteractiveGroup') {
          if (box.intersectsWithObject(obj)) {
            if (objectList.indexOf(obj) === -1) {
              objectList.push(obj)
            }
          }
        }
      });
      return objectList;
    },

    /**
     * Export all Objects in page as a group to JSON
     */
    export: function () {
      this.canvas.discardActiveObject();
      let objects = this.getObjectsWithin();
      let group = this.canvas.group(objects);
      // extract object json data
      let obj = group.toObject();
      this.canvas.ungroup(group);

      // add specific page data in object list to export
      obj.pageData = {
        backgroundFill: this.backgroundFill,
        paletteSwatchName: this.paletteSwatchName
      };

      return obj;
    },

    /**
     * Import a group in JSON format and add its objects to canvas
     */
    import: function (json) {
      this.canvas.discardActiveObject();
      let obj = json;

      // process export and import specific page data
      if (obj.pageData) {
        this.paletteSwatchName = obj.pageData.paletteSwatchName || {};
        this.backgroundFill = obj.pageData.backgroundFill || null;
        delete (obj.pageData);
      }

      let res = new Promise((resolve, reject) => {
        fabric.Group.fromObject(obj, group => {
          let items = group.getObjects();
          group.destroy(); //destroy group then add every object individually to canvas
          for (let i = 0; i < items.length; i++) {
            this.canvas.add(items[i]);
          }
          this.canvas.requestRenderAll();
          return this.importedCallback().then(() => { resolve(); })
        })
      })
      return res
    },

    importedCallback: function () {
      return new Promise((resolve, reject) => {
        this.canvas.forEachObject(obj => obj.activate ? obj.activate() : null);
        this.canvas.renderAll(); // force render canvas before send import finished
        console.log("----- canvas page loaded -----")
        resolve();
      })
    },

    clearFontCache: function () {
      console.log('force clear font cache of fabric and renderAll');
      fabric.util.clearFabricFontCache();
      const textboxes = this.canvas.getObjectsOfType('MzTextbox');
      for (let textbox of textboxes) {
        textbox.initDimensions();
        textbox.setCoords();
        textbox.dirty = true;
      }
      this.canvas.requestRenderAll();
    },

    getThumb() {
      const currentActiveObject = this.canvas.getActiveObject();

      this.canvas.discardActiveObject();
      let objects = this.getObjectsWithin();
      //add page background
      objects.unshift(this.displayBackground);
      let group = this.canvas.group(objects);

      let dataUrl = group.toDataURL({
        //format: "png",
        format: "jpeg",
        quality: 0.5,
        multiplier: 0.5,
        left: this.pageRect.left - group.left,
        top: this.pageRect.top - group.top,
        width: this.pageRect.width,
        height: this.pageRect.height,
        crossOrigin: 'anonymous'
      });

      this.canvas.ungroup(group);

      // restore active object after extracted png data
      if (currentActiveObject) {
        this.canvas.setActiveObject(currentActiveObject);
        this.canvas.requestRenderAll();
      }

      return dataUrl;
    },

    getSVG({ fonts, styles }) {
      console.log('MzPage getSVG', { fonts, styles })
      const currentActiveObject = this.canvas.getActiveObject();

      this.canvas.discardActiveObject();

      // declare specific fonts and style
      fabric.fontPaths = fonts;
      fabric.setTextStylesList(styles);

      // remove mask if present
      let currentClipPath = null;
      if (this.canvas.clipPath) {
        currentClipPath = this.canvas.clipPath;
        this.canvas.clipPath = null;
      }

      // add backgroundPage
      let backgroundRect = new fabric.Rect({
        ...this.printMarginRect,
        fill: this.displayBackground.fill,
      });
      this.canvas.add(backgroundRect);
      this.canvas.sendToBack(backgroundRect);

      const svg = this.canvas.toSVG({
        viewBox: {
          x: this.printMarginRect.left,
          y: this.printMarginRect.top,
          width: this.printMarginRect.width,
          height: this.printMarginRect.height
        },
        width: this.printMarginRect.width,
        height: this.printMarginRect.height,
      });

      this.canvas.remove(backgroundRect);

      // restore clipPath
      if (currentClipPath) {
        this.canvas.clipPath = currentClipPath;
      }

      // restore active object after extracted png data
      if (currentActiveObject) {
        this.canvas.setActiveObject(currentActiveObject);
        this.canvas.requestRenderAll();
      }

      return svg;
    },

    addObjectAtCenter(object) {
      object.left = (this.pageRect.width - object.width * object.scaleX) / 2;
      object.top = (this.pageRect.height - object.height * object.scaleY) / 2;
      this.canvas.add(object);
    },

  });

  fabric.MzPage.fromObject = function (object, callback) {
    return fabric.Object._fromObject('MzPage', callback, extraParam);
  }

  //accessors
  Object.defineProperty(fabric.MzPage.prototype, 'backgroundFill', {
    get: function () {
      return this._backgroundFill;
    },
    set(value) {
      this._backgroundFill = value;
      if (value === null) {
        // set default page background color
        this.displayBackground.set('fill', this.defaultPageBackgroundColor);
      } else {
        this.displayBackground.set('fill', value);
      }
    }
  });
}