import { fabric } from 'fabric';
import GraphicUtils from '~/common/utils/graphicUtils';

export default () => {
  let objectDrawControlsOverridden = fabric.Object.prototype.drawControls;

  fabric.Object.prototype.proximityLR = [];
  fabric.Object.prototype.proximityTB = [];
  fabric.Object.prototype.hasProximityControl = false;

  /**
   * return local coordinate of another object corner (tl, tr, br, bl)
   */
  fabric.Object.prototype.getObjectLocalCoord = function (targetObj, corner) {
    let tCoords = targetObj.calcCoords(true)[corner];

    return this.getPointLocalCoord(tCoords);
  };

  fabric.Object.prototype.getPointLocalCoord = function (point) {
    let oCenter = this.getCenterPoint();
    let zoom = this.canvas.getZoom();
    // get target relative position
    let newPoint = new fabric.Point((point.x - oCenter.x) * zoom, (point.y - oCenter.y) * zoom);
    // rotate target point to manage rotated origin textbox
    newPoint = fabric.util.rotatePoint(newPoint, new fabric.Point(0, 0), fabric.util.degreesToRadians(-this.angle));
    return newPoint;
  };

  fabric.Object.prototype.getSnapPoints = function () {
    let oBoundRect = this.getBoundingRect(true, true);
    let ocenter = this.getCenterPoint();
    return [
      new fabric.Point(oBoundRect.left, oBoundRect.top),
      new fabric.Point(oBoundRect.left + oBoundRect.width, oBoundRect.top + oBoundRect.height),
      { type: 'center', ...ocenter }
    ];
  };

  fabric.Object.prototype.drawProximityAlignement = function (ctx) {
    const sideColor = 'rgb(200, 0, 200)';
    const centerColor = 'rgb(0, 180, 180)';
    let dim = this._calculateCurrentDimensions(); // this controls dimmensions
    let tPoint, start, end;

    dim = dim.multiply(0.5);

    this.proximityTB.forEach(proxPoint => {
      tPoint = this.getPointLocalCoord(proxPoint);

      start = new fabric.Point(tPoint.x, tPoint.y);
      end = new fabric.Point(dim.x, start.y);

      if (proxPoint.type === 'center') {
        end.x = 0;
      } else if (start.x > end.x) {
        end.x = -dim.x;
      }

      GraphicUtils.drawProximityLine(ctx, start, end, proxPoint.type === 'center' ? centerColor : sideColor);
    });

    this.proximityLR.forEach(proxPoint => {
      tPoint = this.getPointLocalCoord(proxPoint);
      start = new fabric.Point(
        tPoint.x,
        tPoint.y //Math.min(tPoint.y, dim.y * -0.5),
      );
      end = new fabric.Point(start.x, dim.y);

      if (proxPoint.type === 'center') {
        end.y = 0;
      } else if (start.y > end.y) {
        end.y = -dim.y;
      }
      GraphicUtils.drawProximityLine(ctx, start, end, proxPoint.type === 'center' ? centerColor : sideColor);
    });
  };

  /**
   * Override control drawing for any objects
   * @param {*} ctx
   * @param {*} styleOverride
   * @override
   */
  fabric.Object.prototype.drawControls = function (ctx, styleOverride) {
    if (this.hasProximityControl) {
      ctx.globalAlpha = this.isMoving ? 0.8 : 1; // override moving opacity to 0.8 instead of defauld 0.4
      this.drawProximityAlignement(ctx);
    }
    objectDrawControlsOverridden.call(this, ctx, styleOverride);
  };
};
