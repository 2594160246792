import { fabric } from 'fabric';

export default () => {
  fabric.util.object.extend(fabric.MzTextbox.prototype, /** @lends fabric.MzTextbox.prototype */ {

    dodgingShapesIds: [],
    dodgingShapes: [],

    initDodging: function (text, options) {
      this.set('dodgingShapesIds', options.dodgingShapesIds || [])
      this.toObjectProps.push('dodgingShapesIds')
    },

    activateDodging: function () {
      // attach dodgingShapes when all items are loaded
      if (this.dodgingShapesIds.length > 0) {
        for (let i = 0; i < this.dodgingShapesIds.length; i++) {
          this.dodgingShapes.push(this.canvas.getObjectById(this.dodgingShapesIds[i]))
        }
      }
    },

    setDodgingShape: function (value) {
      if (value.type != 'MzRect' || this.dodgingShapesIds.indexOf(value.id) != -1) {
        return;
      }

      this.dodgingShapesIds.push(value.id);
      this.dodgingShapes.push(value);
    },

  });
};