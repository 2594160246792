import { fabric } from 'fabric';
import FontMetrics from '~/common/utils/fontMetrics';

export default () => {
  //accessors
  Object.defineProperty(fabric.MzTextbox.prototype, 'dropCap', {
    get: function () {
      return this._dropCap;
    },
    set(value) {
      if (this._dropCap && value == false) {
        this.removeDropCap();
      }
      this._dropCap = value;
    }
  });

  fabric.util.object.extend(
    fabric.MzTextbox.prototype,
    /** @lends fabric.MzTextbox.prototype */ {
      _mzDropCapProps: ['_dropCap', 'dropCapStyle'],
      _dropCap: false,

      dropCapStyle: {
        // props that can be modified outside
        dropCapHeight: 3,
        dropCapSpacing: 0,
        dropCapYOffset: 0,
        dropCapFontSizeOffset: 0,
        fill: '',
        fontFamily: '',
        fontStyle: 'normal',
        fontWeight: 'normal'
      },
      dropCapComputedStyle: {
        fontSize: 16,
        deltaY: 0
      },
      _prevLetter: '',
      _prevStyle: {},
      _metrics: {},

      initDropCap: function (text, options) {
        // this.stateProperties.push(...activeProps)
        // this.cacheProperties.push(...activeProps)
        this.toObjectProps.push('_dropCap', 'dropCapStyle');
      },

      activateDropCap: function () {
        this.setupState({ propertySet: '_mzDropCapProps' });
        this.updateDropCap();
      },

      /**
       * Update
       */
      updateDropCap() {
        if (!this._dropCap) {
          return;
        }
        this.removeDropCap();
        if (this._dropCap && this.text && this.text.length > 0) {
          // apply dropcap style
          this.setDropCapStyle(this.text.charAt(0));
          this.saveState({ propertySet: '_mzDropCapProps' });
        }
      },

      // reset dropcap style if found in firsts chars
      removeDropCap() {
        for (let i = 0; i < 3; i++) {
          let style = this.getStyleAtPosition(i);
          let nextStyle = this.getCompleteStyleDeclaration(0, i + 1);
          if (style && style.dropCapHeight) {
            this.setStyleAt(0, i, nextStyle);
          }
        }
        this.clearOffsetsOf('dropCap');
      },

      setDropCapStyle(dropCapChar) {
        // set dropcap computed values
        let dropCapSize = this.getDropCapSize(dropCapChar);

        this.dropCapComputedStyle.deltaY = dropCapSize.delta;
        this.dropCapComputedStyle.fontSize = dropCapSize.fontSize;

        let mergedStyle = { ...this.dropCapStyle, ...this.dropCapComputedStyle };

        this.setStyleAt(0, 0, mergedStyle);

        let info = this._measureChar(dropCapChar/*this._textLines[0][0]*/, mergedStyle);
        let dropCapWidth = info.kernedWidth;

        if (this.charSpacing !== 0) {
          let charSpacing = this._getWidthOfCharSpacing();
          dropCapWidth += charSpacing;
        }
        this.setDropCapOffsetLines(dropCapSize.height, dropCapWidth + this.dropCapStyle.dropCapSpacing);

        this._prevLetter = dropCapChar/*this._textLines[0][0]*/;
        this._prevStyle = mergedStyle;
      },

      /**
       * Compute and return dropcap font size and deltaY
       */
      getDropCapSize(dropCapChar) {
        if (
          this._prevStyle.fontFamily != this.dropCapStyle.fontFamily ||
          this._prevStyle.fontWeight != this.dropCapStyle.fontWeight
        ) {
          FontMetrics.settings.chars.capHeight = dropCapChar;// this._textLines[0][0];
          this._metrics = FontMetrics.measureCap({
            fontFamily: this.dropCapStyle.fontFamily,
            // Optional (defaults)
            fontWeight: this.dropCapStyle.fontWeight,
            fontSize: 1000,
            origin: 'capHeight'
          });
        }

        const firstLineHeight = this.fontSize * this.lineHeight * this._fontSizeMult;
        const totalLinesHeight = firstLineHeight * this.dropCapStyle.dropCapHeight;
        const totalInterlineHeight = this.lineHeight * this.dropCapStyle.dropCapHeight;

        let fontSize = totalLinesHeight / this._metrics.capBottom - totalInterlineHeight;
        fontSize += this.dropCapStyle.dropCapFontSizeOffset;

        let delta = totalLinesHeight - firstLineHeight;
        delta += this.dropCapStyle.dropCapYOffset;

        return { fontSize: fontSize, delta: delta, height: totalLinesHeight };
      },

      /**
       * Set offset to n text lines to let room for the dropcap
       * @param {*} value
       */
      setDropCapOffsetLines(top, left) {
        this.clearOffsetsOf('dropCap');
        for (let i = 1; i < this.dropCapStyle.dropCapHeight; i++) {
          this.setOffsetAt(i, 'dropCap', left, { maxTop: top });
        }
      },

      /**
       * custom function to get style property at certain position,
       * fabric one ( getValueOfPropertyAt )is not suitable for our use becaus we need to return null if no style is set
       * @param {*} lineIndex
       * @param {*} charIndex
       * @param {*} property
       */
      getCharStylePropertyAt(lineIndex, charIndex, property) {
        let charStyle = this._getStyleDeclaration(lineIndex, charIndex);
        if (charStyle && typeof charStyle[property] !== 'undefined') {
          return charStyle[property];
        }
        return null;
      },

      /**
       * Override fabric function to ignore dropcap chars when computing line height
       * @override getHeightOfChar
       * @param {*} line
       * @param {*} char
       */
      getHeightOfChar: function (line, char) {
        if (this._dropCap && line === 0 && this.getCharStylePropertyAt(line, char, 'dropCapHeight')) {
          return this.fontSize;
        }
        return this.getValueOfPropertyAt(line, char, 'fontSize');
      },

      setStyleAt: function (lineIndex, charIndex, style) {
        if (!this._getLineStyle(lineIndex)) {
          this._setLineStyle(lineIndex, {});
        }

        this._setStyleDeclaration(lineIndex, charIndex, style);
        this._forceClearCache = true;
      }
    }
  );
};
