<template>
  <div
    v-bind:class="{
      'avatar-content': true,
      'big-avatar': size === 'big',
    }"
  >
    {{ displayName }}
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "MzUserAvatar",

  props: ["user", "size"],
  data() {
    return {};
  },

  computed: {
    displayName() {
      let extractFrom = null;

      if (this.user && this.user.email) {
        extractFrom = this.user.email
          .substr(0, this.user.email.indexOf("@"))
          .split(".");
      } else if (this.user && this.user.name) {
        extractFrom = this.user.name.split(" ");
      }

      if (extractFrom) {
        let extract = extractFrom.map((value) => {
          return value.replace(new RegExp("[^a-zA-Z]", "g"), "").toUpperCase();
        });

        if (extract.length > 1) {
          return extract[0].substr(0, 1) + extract[1].substr(0, 1);
        } else {
          return extract[0].substr(0, 2);
        }
      } else {
        return "MZ";
      }
    },
  },

  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.avatar-content {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  overflow: hidden;
  background-color: #26c55c;
  border-width: 1px;
  border-radius: 50%;
  padding: 9px;
  font-size: 1rem;
  color: #fff;
  pointer-events: none;
  width: 27px;
  height: 27px;

  &.big-avatar {
    padding: 14px;
    font-size: 1.75rem;
    width: 40px;
    height: 40px;
  }
}
</style>