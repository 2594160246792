const axios = require('axios');

export default {

  fontListUrl(fontList) {
    const list = {};

    for (let i = 0; i < fontList.length; i++) {
      if (!fontList[i].name) {
        continue;
      }

      switch (fontList[i].provider) {

        case 'typekit':
          //TODO
          console.warn('get url for typekit not implemented');
          break;

        case 'custom':
          let url = fontList[i].url;
          const pos = url.indexOf('amazonaws.com/');
          if (pos >= 0) {
            // bugfix previous upload fonts with aws s3 bucket inside url
            url = process.env.COMMON_AWS_S3_URL + url.substr(pos + 14);
          } else {
            url = process.env.COMMON_AWS_S3_URL + url;
          }

          list[fontList[i].name] = encodeURI(url);
          break;

        case 'google':
        default:
          list[fontList[i].name] = "https://fonts.googleapis.com/css?family="
            + encodeURI(fontList[i].name)
            + ':' + (fontList[i].styles ? fontList[i].styles.join(',') : '')
            + '&amp;subset=' + (fontList[i].subsets ? fontList[i].subsets.join(',') : '')
          break;
      }
    }

    return list;
  },

  extractTTFdataFromUrl(url) {
    const baseUrl = url.substring(0, url.lastIndexOf('/'));

    return new Promise((resolve, reject) => {
      axios.get(url)
        .then(response => {
          if (200 == response.status) {
            let data = response.data;

            // The whole response has been received. Extract data from received value
            const result = [];
            if (data.length > 0) {
              data = data.replace(/\n|\r/g, '');  // remove all break line for find with regexp
              let match, extractFamily, extractStyle, extractWeight, extractUrl;
              // extract all font-face
              const regexp = /@font-face \{([^\}]*)\}/g
              while ((match = regexp.exec(data)) !== null) {
                // extract all needed data by font-face
                extractFamily = /font-family: '([^']*)';/.exec(match[1]);
                extractStyle = /font-style: ([^;]*);/.exec(match[1]);
                extractWeight = /font-weight: ([^;]*);/.exec(match[1]);
                extractUrl = /src:.*url\(([^)]*)\).*;/.exec(match[1]);
                if (extractFamily && extractStyle && extractWeight && extractUrl) {
                  let fontUrl = extractUrl[1];
                  // remove ' or " from extarcted url
                  if ((fontUrl.indexOf("'") === 0 && fontUrl.lastIndexOf("'") === fontUrl.length - 1) ||
                    (fontUrl.indexOf('"') === 0 && fontUrl.lastIndexOf('"') === fontUrl.length - 1)) {
                    fontUrl = fontUrl.substring(1, fontUrl.length - 1);
                  }
                  // specific case if url is relative
                  if (fontUrl.indexOf('./') === 0) {
                    fontUrl = baseUrl + '/' + fontUrl;
                  }
                  result.push({
                    family: extractFamily[1],
                    style: extractStyle[1],
                    weight: extractWeight[1],
                    url: fontUrl,
                  })
                }
              }
            }

            resolve(result.length > 0 ? result : false);
          }
          else {
            reject('error load font');
          }

        })
        .catch(error => {
          console.log('load font error ', error);
          reject(error);
        });
    });
  }
}
