import { fabric } from 'fabric';

export default () => {
  fabric.util.object.extend(
    fabric.MzTextbox.prototype,
    {
      _charIndexStyleDiffMap: {},

      getVariableList() {
        if (this.blockType.indexOf('Footer') === 0
          && fabric.page.specificMagazineData
          && fabric.page.specificMagazineData.footerVariables
          && fabric.page.specificMagazineData.footerVariables.list) {
          return fabric.page.specificMagazineData.footerVariables.list;
        } else {
          return null;
        }

      },
      getVariableData() {
        if (this.blockType.indexOf('Footer') === 0
          && fabric.page.specificMagazineData
          && fabric.page.specificMagazineData.footerVariables
          && fabric.page.specificMagazineData.footerVariables.footer) {
          return fabric.page.specificMagazineData.footerVariables.footer;
        } else {
          return null;
        }
      },
      updateVariableList(key, value) {
        if (this.blockType.indexOf('Footer') === 0
          && fabric.page.specificMagazineData
          && fabric.page.specificMagazineData.footerVariables
          && fabric.page.specificMagazineData.footerVariables.list) {
          fabric.page.specificMagazineData.footerVariables.list[key] = value;
          this.activateVariableList();
        }
      },

      activateVariableList() {
        if (this.blockType.indexOf('Footer') === 0) {
          const variableData = this.getVariableData();
          if (variableData) {
            const leftData = variableData.left ? JSON.parse(variableData.left) : null;
            const rightData = variableData.right ? JSON.parse(variableData.right) : null;
            if (leftData) {
              this.setStyledTextIfLeftPage(leftData);
            }
            if (rightData) {
              this.setStyledTextIfRightPage(rightData);
            }
          }
        }
      },

      getExistingVariablePage() {
        const variableList = this.getVariableList();
        if (variableList && variableList.page) {
          return variableList.page;
        } else if (variableList && variableList.doublePage) {
          return this.canvas && this.left >= this.canvas.maxScrollX / 2
            ? variableList.doublePage.right
            : variableList.doublePage.left;
        }
        else {
          return null;
        }
      },

      shouldInverseTextAlignByVariable(textAlign) {
        if (this.blockType.indexOf('Footer') === 0) {
          const page = this.getExistingVariablePage();
          if (page && page % 2 === 1) {
            if (textAlign === 'left')
              return 'right';
            if (textAlign === 'right')
              return 'left';
          }
        }

        return textAlign;
      },

      setStyledTextIfLeftPage(data) {
        if (this.blockType.indexOf('Footer') === 0) {
          const page = this.getExistingVariablePage();
          if (page && page % 2 === 0) {
            this.setStyledText(data)
          }
        }
      },

      setStyledTextIfRightPage(data) {
        if (this.blockType.indexOf('Footer') === 0) {
          const page = this.getExistingVariablePage();
          if (page && page % 2 === 1) {
            this.setStyledText(data)
          }
        }
      },

      textContainsVariable(name) {
        return this.text.indexOf('{' + name + '}') >= 0;
      },

      checkExistingVarIntoLine(line, lineIndex) {
        const variableList = this.getVariableList();
        if (!variableList) {
          return line;
        }

        const formatedVariableList = { ...variableList };
        if (formatedVariableList.doublePage) {
          formatedVariableList.page = this.getExistingVariablePage();
          delete formatedVariableList.doublePage;
        }

        let displayedLine = line,
          hasVar = false,
          diffMap = {};

        const varKeys = Object.keys(formatedVariableList);
        if (varKeys.length > 0) {
          // create regexp
          let found, previousDiff = 0;
          while ((found = displayedLine.match(new RegExp('\{(' + varKeys.join('|') + ')\}')))) {
            // replace variable in word
            const originalString = found[0],
              replaceString = '' + formatedVariableList[found[1]];

            displayedLine = displayedLine.substring(0, found.index) + replaceString + displayedLine.substring(found.index + originalString.length);
            hasVar = true;

            for (let i = found.index; i < displayedLine.length; i++) {

              if (i < (found.index + replaceString.length)) {
                // apply style for var word : get first char after '{' and apply style to all word
                diffMap[i] = previousDiff + found.index + 1 - i;
              } else {
                // remap style for rest of word
                diffMap[i] = previousDiff + originalString.length - replaceString.length;
              }
            }

            previousDiff += originalString.length - replaceString.length;
          }
        }

        if (hasVar) {
          this._charIndexStyleDiffMap[lineIndex] = diffMap;
        }

        return displayedLine;
      },


    }
  );
};
