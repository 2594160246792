import { fabric } from 'fabric';

export default () => {
  fabric.util.object.extend(fabric.MzTextbox.prototype, /** @lends fabric.MzTextbox.prototype */ {

    /**
     * what can indent lines ?
     * - dodging shape
     * - dropcap
     */

    offsetLines: [], // Map array

    initIndent: function (text, options) {
      this.offsetLines = [];
    },

    createOffsetMap: function () {
      return new Map()
    },

    setOffsetAt(index, key, value, conditions = null) {
      if (!this.offsetLines[index]) {
        this.offsetLines[index] = new Map()
      }
      this.offsetLines[index].set(key, { value, conditions });
    },

    clearOffsetsOf(key) {
      this.offsetLines.forEach(map => {
        map.delete(key);
      });
    },

    getNumOffsetAt: function (index) {
      if (!this.offsetLines[index]) {
        return 0;
      }
      return this.offsetLines[index].size
    },

    getOffsetAt: function (index) {
      let res = 0;
      if (this.offsetLines[index]) {
        for (let { value, conditions } of this.offsetLines[index].values()) {
          if (conditions) {
            if (conditions.maxTop) {
              const _top = this.getTopOfLine(index);
              if (_top <= conditions.maxTop) {
                res += value;
              }
            }
          } else {
            res += value;
          }
        }
      }
      return res
    },

  });
};