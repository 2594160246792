export const PdfProgressStatus = {
  StartProcess: "StartProcess",

  /* start pdf export process */
  GetAllSvgFromS3: "GetAllSvgFromS3",
  /* start puppeteer process*/
  CreateHtmlPage: "CreateHtmlPage",
  LaunchBrowser: "LaunchBrowser",
  LoadHtmlPage: "LoadHtmlPage",
  ExportHtmlAsPdf: "ExportHtmlAsPdf",
  CloseBrowser: "CloseBrowser",
  GhostScriptConvertPdf: "GhostScriptConvertPdf",
  ExportPdfAsBase64: "ExportPdfAsBase64",
  CleanExportPdf: "CleanExportPdf",
  /* end puppeteer process*/
  SendPdfToS3: "SendPdfToS3",
  /* end pdf export process */


  /* start export viewer process */
  GetPdfFromS3: "GetPdfFromS3",
  GenerateZip: "GenerateZip",
  AddFileZip: "AddFileZip",
  GenerateZipBuffer: "GenerateZipBuffer",
  SendZipToS3: "SendZipToS3",
  /* end export viewer process */

  SendCompletedRequest: "SendCompletedRequest",
  EndProcess: "EndProcess"
}

export const PdfProgressPercentageByStatus = {
  GetAllSvgFromS3: 5,
  CreateHtmlPage: 10,
  LoadHtmlPage: 20,
  ExportHtmlAsPdf: 40,
  GhostScriptConvertPdf: 50,
  SendPdfToS3: 75,

  GetPdfFromS3: 5,
  GenerateZip: 50,
  SendZipToS3: 75,

  SendCompletedRequest: 95,
  EndProcess: 100
}