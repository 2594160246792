<template>
  <div>
    <div v-if="stripeError">
      <el-alert
        :description="$t('public.layout.stripe.errorCode')"
        type="error"
        effect="dark"
        :closable="false"
        center
      >
        <div slot="title">
          <span v-html="$t('public.layout.stripe.errorMessage')"></span>
        </div>
      </el-alert>
    </div>

    <div id="checkout">
      <!-- Checkout will insert the payment form here -->
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "StripeCheckout",
  components: {},

  props: ["stripeClientSecret"],

  data() {
    return {
      isComplete: false,
      checkout: null,
      stripeError: false,
    };
  },

  mounted() {
    this.initElement();
  },
  beforeDestroy() {
    if (this.checkout) {
      this.checkout.destroy();
    }
  },
  methods: {
    async initElement() {
      if (!this.$stripe) {
        this.stripeError = true;
        console.error("stripe js library is not loaded");
        return;
      }
      if (!this.stripeClientSecret) {
        this.stripeError = true;
        console.error("stripe client secret is not defined");
        return;
      }

      if (this.checkout) {
        this.checkout.destroy();
      }

      this.checkout = await this.$stripe.initEmbeddedCheckout({
        clientSecret: this.stripeClientSecret,
        //onComplete: this.stripCheckoutComplete,
      });
      this.checkout.mount("#checkout");
    },

    stripCheckoutComplete() {
      this.isComplete = true;
      this.$emit("checkoutIsComplete", this.isComplete);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
