import { fabric } from 'fabric';
import Utils from '~/common/utils/misc';

export default () => {
  fabric.util.object.extend(
    fabric.MzTextbox.prototype,
    /** @lends fabric.MzTextbox.prototype */
    {
      _endSign: false,
      endSignStyle: {
        fill: "",
        character: ""
      },

      initEndSign: function (options) {
        if (options._endSign !== true) {
          // bugfix previous endSign params
          options._endSign = false;
        }

        this.toObjectProps.push('_endSign', 'endSignStyle');
        this._endSign = options._endSign === true;
        if (options.endSignStyle) {
          this.endSignStyle = options.endSignStyle;
        }
      },

      getEndSignCharacter() {
        if (this._endSign && this.endSignStyle.character) {
          return this.endSignStyle.character;
        } else {
          return "";
        }
      },

      getEndSignStyle() {
        if (this._endSign && this.endSignStyle.fill && this.endSignStyle.fill.length > 0) {
          return { 'fill': this.endSignStyle.fill };
        } else {
          return null;
        }
      }
    }
  );

  Object.defineProperty(fabric.MzTextbox.prototype, 'endSign', {
    get: function () {
      return this._endSign;
    },
    set(value) {
      this._endSign = value;
      if (!value) {
        this.endSignStyle = {
          fill: "",
          character: ""
        };
      }
      this._forceClearCache = true;
    }
  });
};
