import { fabric } from 'fabric';
import Utils from '~/common/utils/store';

export default () => {
  //accessors
  Object.defineProperty(fabric.MzTextbox.prototype, 'textStyleKey', {
    get: function () {
      return this._textStyleKey;
    },
    set(value) {
      this.applyTextStyle(value);
    }
  });
  Object.defineProperty(fabric.MzTextbox.prototype, 'dropCapStyleKey', {
    get: function () {
      return this._dropCapStyleKey;
    },
    set(value) {
      this.applyDropCapStyle(value);
    }
  });
  Object.defineProperty(fabric.MzTextbox.prototype, 'bulletPointStyleKey', {
    get: function () {
      return this._bulletPointStyleKey;
    },
    set(value) {
      this.applyBulletPointStyle(value);
    }
  });
  Object.defineProperty(fabric.MzTextbox.prototype, 'textLinkStyleKey', {
    get: function () {
      return this._textLinkStyleKey;
    },
    set(value) {
      this.applyTextLinkStyle(value);
    }
  });
  Object.defineProperty(fabric.MzTextbox.prototype, 'endSignStyleKey', {
    get: function () {
      return this._endSignStyleKey;
    },
    set(value) {
      this.applyEndSignStyle(value);
    }
  });

  fabric.util.object.extend(
    fabric.MzTextbox.prototype,
    /**
     * Textbox style methods, imported to MzTexbox
     *  @lends fabric.MzTextbox.prototype
     */
    {

      overrideStyle: null,  // used by client side to set specific color on text footer

      _textStyleKey: null,
      _dropCapStyleKey: null,
      _bulletPointStyleKey: null,
      _textLinkStyleKey: null,
      _endSignStyleKey: null,

      initOverrideStyle: function (options) {
        this.overrideStyle = options.overrideStyle || null;
        this.toObjectProps.push('overrideStyle');

        if (options.opacity === 0) {
          this.toogleHide(true);
        }
      },

      initThemeStyle: function (options) {
        this.toObjectProps.push('_textStyleKey', '_dropCapStyleKey', '_bulletPointStyleKey', '_textLinkStyleKey', '_endSignStyleKey');
        if (options._textStyleKey) {
          this.applyTextStyle(options._textStyleKey);
        }
        if (options._dropCapStyleKey) {
          this.applyDropCapStyle(options._dropCapStyleKey);
        }
        if (options._bulletPointStyleKey) {
          this.applyBulletPointStyle(options._bulletPointStyleKey);
        }
        if (options._textLinkStyleKey) {
          this.applyTextLinkStyle(options._textLinkStyleKey);
        }
        if (options._endSignStyleKey) {
          this.applyEndSignStyle(options._endSignStyleKey);
        }
      },

      applyTextStyle(styleKey) {
        this._textStyleKey = styleKey;
        this.paletteSwatchName = {}; // remove paletteSwatchName if use style key (swatch color is defined by styles)

        if (styleKey) {
          const styleToApply = fabric.textStylesList ? fabric.textStylesList[styleKey] : null;
          if (styleToApply) {
            Object.entries(styleToApply).forEach((obj) => {
              Utils.setProperty(obj[0], this, obj[1], false);
            });

            if (this.hasReflow) {
              this.setAsStyleForAllReflowStack();
            }
          }
        }
      },

      applyDropCapStyle(styleKey) {
        this._dropCapStyleKey = styleKey;
        if (styleKey) {
          const styleToApply = fabric.textStylesList ? fabric.textStylesList[styleKey] : null;
          if (styleToApply) {
            this.dropCapStyle = { ...styleToApply };
          }
        }
      },
      applyBulletPointStyle(styleKey) {
        this._bulletPointStyleKey = styleKey;
        if (styleKey) {
          const styleToApply = fabric.textStylesList ? fabric.textStylesList[styleKey] : null;
          if (styleToApply) {
            this.bulletPointStyle = { ...styleToApply };
          }
        }
      },
      applyTextLinkStyle(styleKey) {
        this._textLinkStyleKey = styleKey;
        if (styleKey) {
          const styleToApply = fabric.textStylesList ? fabric.textStylesList[styleKey] : null;
          if (styleToApply) {
            this.textLinkStyle = { ...styleToApply };
          }
        }
      },
      applyEndSignStyle(styleKey) {
        this._endSignStyleKey = styleKey;
        if (styleKey) {
          const styleToApply = fabric.textStylesList ? fabric.textStylesList[styleKey] : null;
          if (styleToApply) {
            this.endSignStyle = { ...styleToApply };
          }
        }
      },

      applyOverrideStyle(key, value) {
        if (!this.overrideStyle) {
          this.overrideStyle = {};
        }
        this.overrideStyle[key] = value;

        this._forceClearCache = true; // force refresh
        this.updateOnRender();
      },
      removeOverrideStyle() {
        this.overrideStyle = null;

        this._forceClearCache = true; // force refresh
        this.updateOnRender();
      },

      toogleHide(forceHide = false) {
        if (this.opacity == 1 || forceHide) {
          this.set({
            opacity: 0,
            selectable: false,
            evented: false
          });
        } else {
          this.set({
            opacity: 1,
            selectable: true,
            evented: true
          });
        }
      },

      /**
       * Generate an object that translates the style object so that it is
       * broken up by visual lines (new lines and automatic wrapping).
       * The original text styles object is broken up by actual lines (new lines only),
       * which is only sufficient for Text / IText
       * @private
       */
      _generateStyleMap: function (textInfo) {
        var realLineCount = 0,
          realLineCharCount = 0,
          charCount = 0,
          map = {};

        for (var i = 0; i < textInfo.graphemeLines.length; i++) {
          if (textInfo.graphemeText[charCount] === '\n' && i > 0) {
            realLineCharCount = 0;
            charCount++;
            realLineCount++;
          } else if (!this.graphemeSplit && this._reSpaceAndTab.test(textInfo.graphemeText[charCount]) && i > 0) {
            // this case deals with space's that are removed from end of lines when wrapping
            realLineCharCount++;
            charCount++;
          }

          map[i] = {
            line: realLineCount,
            offset: realLineCharCount
          };

          charCount += textInfo.graphemeLines[i].length;
          realLineCharCount += textInfo.graphemeLines[i].length;
        }

        return map;
      },

      /* only used for check and save style in themes */
      getTextBoxStyle: function (withSwatchName = true) {
        const _style = {
          fill: withSwatchName ? this.paletteSwatchName.fill || '' : this.fill,
          stroke: withSwatchName ? this.paletteSwatchName.stroke || '' : this.stroke,
          strokeWidth: this.strokeWidth,
          fontFamily: this.fontFamily,
          fontSize: this.fontSize,
          fontStyle: this.fontStyle,
          fontWeight: this.fontWeight,
          underline: this.underline,
          overline: this.overline,
          linethrough: this.linethrough,
          deltaY: this.deltaY,
          textBackgroundColor: withSwatchName ?
            this.paletteSwatchName.textBackgroundColor || '' : this.textBackgroundColor,
          lineHeight: this.lineHeight,
          textAlign: this.textAlign,
          charSpacing: this.charSpacing,
          textBackgroundPadding: this.textBackgroundPadding,
        };
        return _style;
      },

      resetAllStyles: function () {
        this.set('styles', {});
      },

      /**
       * Returns true if object has a style property or has it in a specified line
       * @param {Number} lineIndex
       * @return {Boolean}
       */
      styleHas: function (property, lineIndex) {
        if (this._styleMap && !this.isWrapping) {
          var map = this._styleMap[lineIndex];
          if (map) {
            lineIndex = map.line;
          }
        }

        //return fabric.Text.prototype.styleHas.call(this, property, lineIndex);
        if (!this.styles || !property || property === '') {
          return false;
        }
        if (typeof lineIndex !== 'undefined' && !this.styles[lineIndex]) {
          return false;
        }
        var obj = typeof lineIndex === 'undefined' ? this.styles : { 0: this.styles[lineIndex] };
        if (obj !== null && obj !== undefined) {
          // eslint-disable-next-line
          for (var p1 in obj) {
            if (p1 !== null && p1 !== undefined
              && obj[p1] !== null && obj[p1] !== undefined) {
              // eslint-disable-next-line
              for (var p2 in obj[p1]) {
                if (p2 !== null && p2 !== undefined
                  && obj[p1][p2] !== null && obj[p1][p2] !== undefined) {
                  if (obj[p1][p2][property] !== null && obj[p1][p2][property] !== undefined) {
                    return true;
                  } else if (obj[p1][p2]['textStyleKey']) {
                    // detect properties in text style
                    const styleToApply = fabric.textStylesList ? fabric.textStylesList[obj[p1][p2]['textStyleKey']] : null;
                    if (styleToApply &&
                      styleToApply[property] !== null &&
                      styleToApply[property] !== undefined &&
                      styleToApply[property] !== false &&
                      styleToApply[property] !== "") {
                      return true;
                    }
                  } else if (obj[p1][p2]['link']) {
                    const textLinkStyle = this.getTextLinkStyle();
                    if (textLinkStyle && textLinkStyle[property] !== null &&
                      textLinkStyle[property] !== undefined &&
                      textLinkStyle[property] !== false &&
                      textLinkStyle[property] !== "") {
                      return true;
                    }
                  }
                }
              }
            }
          }
        }
        return false;
      },

      /**
       * Returns true if object has no styling or no styling in a line
       * @param {Number} lineIndex , lineIndex is on wrapped lines.
       * @return {Boolean}
       */
      isEmptyStyles: function (lineIndex) {
        if (lineIndex === this.textLines.length - 1 && this.getEndSignStyle() !== null) {
          return false;
        }

        var offset = 0,
          nextLineIndex = lineIndex + 1,
          nextOffset, obj, shouldLimit = false;
        var map = this._styleMap[lineIndex];
        var mapNextLine = this._styleMap[lineIndex + 1];
        if (map) {
          lineIndex = map.line;
          offset = map.offset;
        }
        if (mapNextLine) {
          nextLineIndex = mapNextLine.line;
          shouldLimit = nextLineIndex === lineIndex;
          nextOffset = mapNextLine.offset;
        }
        obj = typeof lineIndex === 'undefined' ? this.styles : {
          line: this.styles[lineIndex]
        };
        for (var p1 in obj) {
          for (var p2 in obj[p1]) {
            if (p2 >= offset && (!shouldLimit || p2 < nextOffset)) {
              // eslint-disable-next-line no-unused-vars
              for (var p3 in obj[p1][p2]) {
                return false;
              }
            }
          }
        }
        return true;
      },

      /**
       * @param {Number} lineIndex
       * @param {Number} charIndex
       * @private
       */
      _getStyleDeclaration: function (lineIndex, charIndex) {
        // specific case for bullet point list style (-1 at charIndex)
        if (charIndex === -1) {
          const bulletStyle = this.getBulletPointStyle();
          if (bulletStyle) {
            return {
              ...this._getStyleDeclaration(lineIndex, 0),
              ...bulletStyle
            };
          } else {
            // get style from first real char in line if no fill defined from bullet point style
            charIndex = 0;
          }
        }

        // specific case for end sign style
        if (lineIndex === this.textLines.length - 1 && charIndex >= this.textLines[lineIndex].length - this.getEndSignCharacter().length) {
          const endSignStyle = this.getEndSignStyle();
          if (endSignStyle) {
            return endSignStyle;
          }
        }

        if (this._styleMap && !this.isWrapping) {
          const map = this._styleMap[lineIndex];
          if (!map) {
            return null;
          }
          lineIndex = map.line;
          charIndex = map.offset + charIndex;
        }

        if (this._charIndexStyleDiffMap) {
          const diffMap = this._charIndexStyleDiffMap[lineIndex];
          if (diffMap && diffMap[charIndex]) {
            charIndex += diffMap[charIndex];
          }
        }

        let style = fabric.Text.prototype._getStyleDeclaration.call(this, lineIndex, charIndex);
        if (style && style.textStyleKey) {
          style = this.applyStyleKeyToStyle(style);
        }
        if (style && style.link) {
          style = { ...style, ...this.getTextLinkStyle() };
        }
        if (style && style.script) {
          style = this.applyScriptToStyle(style);
        }
        // force with override style
        if (this.overrideStyle) {
          style = { ...style, ...this.overrideStyle };
        }
        return style;
      },

      applyStyleKeyToStyle: function (style) {
        if (style.textStyleKey) {
          const styleToApply = fabric.textStylesList ? fabric.textStylesList[style.textStyleKey] : null;
          if (styleToApply) {
            return { ...styleToApply, ...style };
          }
        }
        return style;
      },

      applyScriptToStyle: function (style) {
        let schema = null;
        if (style.script === 'super') {
          schema = this.superscript;
        } else if (style.script === 'sub') {
          schema = this.subscript;
        } else {
          return style;
        }

        const fontSize = style.fontSize ? style.fontSize : this.fontSize,
          dy = style.deltaY ? style.deltaY : this.deltaY;

        return { ...style, ...{ fontSize: fontSize * schema.size, deltaY: dy + fontSize * schema.baseline } };
      },

      /**
       * @param {Number} lineIndex
       * @param {Number} charIndex
       * @param {Object} style
       * @private
       */
      _setStyleDeclaration: function (lineIndex, charIndex, style) {
        var map = this._styleMap[lineIndex];
        lineIndex = map.line;
        charIndex = map.offset + charIndex;

        if (!this.styles[lineIndex]) {
          this.styles[lineIndex] = [];
        }
        this.styles[lineIndex][charIndex] = style;
      },

      /**
       * @param {Number} lineIndex
       * @param {Number} charIndex
       * @private
       */
      _deleteStyleDeclaration: function (lineIndex, charIndex) {
        var map = this._styleMap[lineIndex];
        lineIndex = map.line;
        charIndex = map.offset + charIndex;

        delete this.styles[lineIndex][charIndex];
      },

      /**
       * probably broken need a fix
       * @param {Number} lineIndex
       * @private
       */
      _getLineStyle: function (lineIndex) {
        var map = this._styleMap[lineIndex];
        return this.styles[map.line];
      },

      /**
       * probably broken need a fix
       * @param {Number} lineIndex
       * @param {Object} style
       * @private
       */
      _setLineStyle: function (lineIndex, style) {
        var map = this._styleMap[lineIndex];
        this.styles[map.line] = style;
      },

      /**
       * probably broken need a fix
       * @param {Number} lineIndex
       * @private
       */
      _deleteLineStyle: function (lineIndex) {
        var map = this._styleMap[lineIndex];
        delete this.styles[map.line];
      }

    }
  );
};
