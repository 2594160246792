import jwtDecode from 'jwt-decode';

function isTokenExpired(token) {
  let date = new Date(jwtDecode(token).exp * 1000);
  return Date.now() >= date.getTime();
};

/**
 * AXIOS auth token middlewar
 */
export default ({ $axios, redirect, store, app }) => {
  // routes that need to to ignore authentication
  const freeRoutes = ['/api/v1/users/token', '/api/v1/users/login', '/api/v1/users', '/api/v1/users/forgot']
  const refreshTokenRoute = '/api/v1/users/token';
  const disableRedirectForRoute = ["/api/v1/users/active", "/api/v1/users/logout"];

  const refreshTokenAndRetry = async (originalRequest) => {
    console.log('AXIOS DEBUG refreshTokenAndRetry for request ', originalRequest);

    if (!originalRequest.isRetry) {
      // send refresh token if not already a retry request
      const refreshToken = await store.dispatch('api/API_CHECK');

      // if new token received, re-send original request with new token
      if (refreshToken) {
        originalRequest.headers.Authorization = 'Token ' + refreshToken;
        originalRequest.isRetry = true;
        return originalRequest;
      }
    }

    await store.dispatch('api/API_LOGOUT', { debugSaveFrom: 'Axios received invalid API CHECK' });
    if (disableRedirectForRoute.indexOf(originalRequest.url) === -1) {
      console.log('AXIOS DEBUG redirect to login');
      redirect('/login');
    }
    return false;

  }

  if (store.getters['api/getToken']) {
    $axios.setToken(store.getters['api/getToken'], 'Token');
  }

  /**
   * AXIOS auth token middlewar
   *
   * Intercept requests to verify if token is set and is valid
   * Queue pending requests and replay them when token is renewed
   */
  $axios.interceptors.request.use(async config => {
    // send client interface language
    config.headers['mz-lang'] = app.i18n.locale;

    if (store.getters['app/forceMaintenanceAccess']) {
      // force maintenance access
      config.headers['force-maintenance-access'] = true;
    }

    // add websocket connectionId to header for transmit to server
    if (config.method !== 'get') {
      const wsConnectionId = app && app.$socket ? app.$socket.getConnectionId() : undefined;
      if (wsConnectionId) {
        config.headers['socket-connection'] = wsConnectionId;
      }
    }

    let path = config.url.split('?').shift();

    // check if route is excluded from authentification
    if (refreshTokenRoute === path || freeRoutes.indexOf(path) != -1) {
      return config;
    }

    // send notif that user is active
    app.$bus.$emit('user_is_active');

    return config;
  }, async error => {
    // Do something with request error
    console.log("ERROR AXIOS", error)
    return Promise.reject(error);
  });

  /**
   * AXIOS auth token middlewar
   *
   * Intercept errors and auto-renew token if expired.
   * Queue pending requests and replay them when token is renewed
   */
  $axios.interceptors.response.use(
    async response => {
      if (response && response.headers) {
        store.commit('app/UPDATE_SETTINGS', { key: 'maintenanceMode', value: response.headers['maintenance-mode'] == 'active' });
        store.commit('app/UPDATE_SETTINGS', { key: 'publicMessage', value: response.headers['public-message'] });
        store.commit('app/UPDATE_SETTINGS', { key: 'publicMessageClosable', value: response.headers['public-message-closable'] == 'true' });
      }

      // check app version out of date
      if (response && response.headers && response.config.method == 'get' && response.headers['needed-app-build']) {
        const neededVersion = response.headers['needed-app-build'].split('.'),
          currentVersion = store.getters['app/appBuild'].split('.');
        for (let i = 0; i < neededVersion.length; i++) {
          if (parseInt(neededVersion[i]) > parseInt(currentVersion[i])) {
            window.location.reload();
          }
          if (parseInt(neededVersion[i]) < parseInt(currentVersion[i])) {
            break;
          }
        }
      }
      return response;
    },
    async error => {
      const { config, response } = error;

      if (response && response.headers) {
        store.commit('app/UPDATE_SETTINGS', { key: 'maintenanceMode', value: response.headers['maintenance-mode'] == 'active' });
        store.commit('app/UPDATE_SETTINGS', { key: 'publicMessage', value: response.headers['public-message'] });
        store.commit('app/UPDATE_SETTINGS', { key: 'publicMessageClosable', value: response.headers['public-message-closable'] == 'true' });
      }

      if (response && response.status === 503) {
        redirect('/maintenance');
        return Promise.reject(response.data);
      }

      if (response && response.status === 401 && response.data && response.data.error && response.data.error.indexOf("UnauthorizedError") === 0) {
        const isLogged = store.getters['api/isLogged']
        const tokenExpired = isTokenExpired(store.getters['api/getToken']);
        const tokenIsRevoked = response.data.error == 'UnauthorizedError: The token has been revoked.';
        console.log('AXIOS DEBUG response received UnauthorizedError ' + JSON.stringify({ isLogged, tokenExpired, tokenIsRevoked }));
        if (isLogged) {
          if (tokenExpired || tokenIsRevoked) {
            const updatedRequest = await refreshTokenAndRetry(config);
            if (updatedRequest) {
              return $axios(updatedRequest);
            } else {
              console.log('AXIOS DEBUG response rejected')
              return Promise.reject(error);
            }
          }
        }
      }
      return Promise.reject(error)
    })

  // $axios.onError(error => {
  //   const code = parseInt(error.response && error.response.status)

  //   console.error("ERROR:", error)

  //   if (code === 400) {
  //     redirect('/400')
  //   }
  // })

}
