import { fabric } from 'fabric';
import MzMixins from './mixins/MzCommonMixins';

export default () => {
  fabric.MzTextbox = fabric.util.createClass(fabric.Text, {

    _wordJoiners: /[ \t\r]/,
    _reSpace: /\s|\n/,
    minWidth: 20,
    flatStyle: null,
    displayedText: '',
    superscript: {
      size: 0.70, // fontSize factor
      baseline: -0.35  // baseline-shift factor (upwards)
    },
    subscript: {
      size: 0.70, // fontSize factor
      baseline: 0.11  // baseline-shift factor (downwards)
    },

    // active properties
    _mzActiveProps: ['fontSize', 'fontFamily', 'fontStyle', 'lineHeight'],
    type: 'MzTextbox',
    blockType: 'Body',
    textOverflow: false,
    textOverflowLength: 0,

    // internal properties
    initialHeight: 200,

    _dimensionAffectingProps: fabric.Text.prototype._dimensionAffectingProps.concat('width', 'height'),

    initialize: function (text, options) {

      // inject Mz common properties
      Object.assign(this, MzMixins);
      options = this.initMzObject(options);

      this.toObjectProps.push('blockType', 'condensedFlatStyle');

      this.height = options.height || this.initialHeight;
      this.blockType = options.blockType || 'Body';
      if (options.condensedFlatStyle) {
        this.condensedFlatStyle = options.condensedFlatStyle;
      }


      this.initIndent(text, options);
      this.initList(options);
      this.initEndSign(options);
      this.initTextLink(options);
      this.initDropCap(text, options);
      this.initDodging(text, options);
      this.initReflow(text, options);
      this.initLegend(options);
      this.initPadding(options);
      this.initOverrideStyle(options);

      this.setupState({
        propertySet: '_mzActiveProps'
      });

      // bugfix apply justify on text load
      // delete default options.text in initialize and reset real text after initialize
      delete options.text;
      this.callSuper('initialize', '', options);

      // override options style data with theme style if present
      this.initThemeStyle(options);

      // apply styles and text after initialize for bugfix apply justify styles
      this.styles = options ? (options.styles || {}) : {};
      this.set('text', text);
      this.textOverflow = false;
    },

    /**
     * Update text and styles
     * @param { text: String, flatStyle: Array }
     */
    setStyledText(payload) {
      let {
        text,
        flatStyle
      } = payload;

      this.flatStyle = flatStyle;
      this.applyFlatStyle(text);
      // this.set('text', text);
      this.text = text;

      this._forceClearCache = true; // force refresh
      this.updateOnRender();
    },

    getMinWidth: function () {
      return 10;
    },

    getMinHeight: function () {
      return 10;
    },

    /**
     * Activate modules when all objects are loaded on page.
     */
    activate: function () {
      this.activateDropCap();
      this.activateDodging();
      this.activateReflow();
      this.activateLegend();
      this.activateVariableList();
    },

    render: function (ctx) {
      // do not render if object is not visible
      if (!this.visible) {
        return;
      }
      if (this.canvas && this.canvas.skipOffscreen && !this.group && !this.isOnScreen()) {
        return;
      }

      // custom properties changed observer:
      if (!this.isMoving) {
        if (this.hasStateChanged('_mzActiveProps') || this.hasStateChanged('_mzDropCapProps')) {
          this.updateOnRender();
        }
      }

      this.callSuper('render', ctx);
    },

    updateOnRender: function () {
      this.updateDropCap();

      this.saveState({
        propertySet: '_mzDropCapProps'
      });
      this.saveState({
        propertySet: '_mzActiveProps'
      });
    },

    /**
     * Unlike superclass's version of this function, Textbox does not update
     * its width.
     * @private
     * @override
     */
    initDimensions: function () {
      if (this.__skipDimension) {
        return;
      }

      this._clearCache();
      this._splitText()
      this.enlargeSpaces();
      this.saveState({
        propertySet: '_dimensionAffectingProps'
      });

      return
    },

    /**
     * Re-implement from textbox
     * Detect if the text line is ended with an hard break
     * text and itext do not have wrapping, return false
     * @param {Number} lineIndex text to split
     * @return {Boolean}
     * @override
     */
    isEndOfWrapping: function (lineIndex) {
      if (!this._styleMap[lineIndex + 1]) {
        // is last line, return true;
        return true;
      }
      if (this._styleMap[lineIndex + 1].line !== this._styleMap[lineIndex].line) {
        // this is last line before a line break, return true;
        return true;
      }
      return false;
    },

    /**
     * Divides text into lines of text and lines of graphemes.
     * <<< Override of fabricjs text.class.js >>
     * @override
     * @private
     */
    _splitText: function () {
      // split text to lines and check overflow
      let newText = this.processTextToLines(this.text + this.getEndSignCharacter());
      // Reflow or reset text of child textboxes if no text after
      if (this.childTextBoxId) {
        this.updateReflowText(newText);
        this.textOverflow = false;
      } else {
        this.textOverflow = newText.hasOverflow;
        this.textOverflowLength = newText.overflowText.length;
      }

      if (this.hasReflow) {
        let textGroup = fabric.textGroups.get(this.textgroupId);
        if (textGroup) {
          textGroup.updateGroupTextOverflow();
          if (this.canvas && textGroup.getLastTexBox().id === this.id) {
            this.canvas.fire('text:overflow', {
              id: this.id,
              value: this.textOverflow,
              length: this.textOverflow ? this.textOverflowLength : 0
            })
          }
        }
      } else {
        if (this.canvas) {
          this.canvas.fire('text:overflow', {
            id: this.id,
            value: this.textOverflow,
            length: this.textOverflow ? this.textOverflowLength : 0
          })
        };
      }

      return newText;
    },

    /**
     * Gets lines of text to render in the Textbox. This function calculates
     * text wrapping on the fly every time it is called.
     * @param {String} text text to split
     * @returns {Array} Array of lines in the Textbox.
     * @override
     */
    processTextToLines: function (text) {
      let newText = this.wrapAndSetLines(text);

      let displayToLineIndex = this.getLastFittingLineIndex();

      this.textOverflow = false;
      newText.overflowText = '';
      newText.overflowStyle = null;
      newText.hasOverflow = false;

      if (displayToLineIndex < newText.lines.length - 1) {
        newText.hasOverflow = true;

        let cutAtIndex = this._wrapLineOriginalTextMap && this._wrapLineOriginalTextMap[displayToLineIndex + 1] ?
          this._wrapLineOriginalTextMap[displayToLineIndex + 1].start :
          text.length + 1;
        newText.displayedText = text.slice(0, cutAtIndex);
        newText.overflowText = text.slice(cutAtIndex);

        //remove blank lines and spaces at the beginning of the next text
        while (newText.overflowText.charAt(0).match(this._reNewline) || newText.overflowText.charAt(0).match(this._reSpace)) {
          newText.overflowText = newText.overflowText.substring(1);
          cutAtIndex += 1;
        }

        if (this.flatStyle) {
          newText.overflowStyle = this.flatStyle.slice(cutAtIndex);
        }
        //recompute graphemeText and _unwrappedLines for visible text only
        let recomputed = this.splitTextIntoLines(newText.displayedText)
        newText.graphemeText = recomputed.graphemeText;
        newText._unwrappedLines = recomputed._unwrappedLines;

        newText.graphemeLines = newText.graphemeLines.slice(0, displayToLineIndex + 1)
        newText.lines = newText.lines.slice(0, displayToLineIndex + 1)

        this.textLines = newText.lines;
        this._textLines = newText.graphemeLines;
        this.displayedText = newText.displayedText;

      } else {
        newText.displayedText = text;
        this.displayedText = newText.displayedText;
      }

      return newText;
    },
    /**
     *
     */
    applyFlatStyle(text) {
      let output = {},
        line = 0,
        lineChar = 0;

      // start from 1 because Quill insert 1 invisible char at line 0
      if (this.flatStyle) {
        for (let char = 0; char < this.flatStyle.length; char++) {

          if (text.charAt(char).match(this._reNewline)) {
            line++;
            lineChar = 0;
          } else {
            if (this.flatStyle[char] && Object.keys(this.flatStyle[char]).length !== 0) {
              if (!output[line]) {
                output[line] = {};
              }
              output[line][lineChar] = { ... this.flatStyle[char] };  // clone style for not affect flatStyle
            }
            lineChar++;
          }
          // bugfix apply style for surrogate characters
          if (this.isHighSurrogateChar(text, char)) {
            char++;
          }
        }
      }

      this.styles = output;
    },

    // taken from mdn in the charAt doc page. (This function is a copy a fabric string util for detect surrogate char)
    isHighSurrogateChar(str, i) {
      var code = str.charCodeAt(i);

      if (isNaN(code)) {
        return false; // Position not found
      }
      if (code < 0xd800 || code > 0xdfff) {
        return false;
      }

      // High surrogate (could change last hex to 0xDB7F to treat high private
      // surrogates as single characters)
      if (0xd800 <= code && code <= 0xdbff) {
        if (str.length <= i + 1) {
          throw "Detect surrogate char Error - High surrogate without following low surrogate";
        }
        var next = str.charCodeAt(i + 1);
        if (0xdc00 > next || next > 0xdfff) {
          throw "Detect surrogate char Error - High surrogate without following low surrogate";
        }
        return true;
      }
      return false;
    },

    /**
     * Gets lines of text to render. This function calculates
     * text wrapping on the fly every time it is called.
     */
    wrapAndSetLines: function (text) {
      let newText = this.splitTextIntoLines(text);
      let graphemeLines = this._wrapText(newText.lines, this.width);
      let lines = new Array(graphemeLines.length);

      for (let i = 0; i < graphemeLines.length; i++) {
        lines[i] = graphemeLines[i].join('');
      }

      newText.lines = lines;
      newText.graphemeLines = graphemeLines;
      return newText;
    },

    /**
     * Returns the text as an array of lines.
     * @param {String} text text to split
     * @returns {Array} Lines in the text
     */
    splitTextIntoLines: function (text) {

      var lines = text.split(this._reNewline),
        newLines = new Array(lines.length),
        newLine = ['\n'],
        newText = [];

      for (var i = 0; i < lines.length; i++) {
        newLines[i] = fabric.util.string.graphemeSplit(lines[i]);
        newText = newText.concat(newLines[i], newLine);
      }
      newText.pop();

      // apply on the fly
      this._unwrappedTextLines = newLines;
      this._text = newText;

      return {
        _unwrappedLines: newLines,
        lines: lines,
        graphemeText: newText,
        graphemeLines: newLines
      };
    },

    /**
     * <<< Override of fabricjs textbox.class.js >>
     * @override
     * Wraps text using the 'width' property of Textbox. First this function
     * splits text on newlines, so we preserve newlines entered by the user.
     * Then it wraps each line using the width of the Textbox by calling
     * _wrapLine().
     * @param {Array} lines The string array of text that is split into lines
     * @param {Number} desiredWidth width you want to wrap to
     * @returns {Array} Array of lines
     */
    _wrapText: function (lines, desiredWidth) {
      let wrapped = [];

      // reset all varaible to re-apply them onFly and disable isWrapping variable
      //this.isWrapping = true;
      this.resetBeforeApplyGraphemeLineOnFly();

      this.clearLineList();
      for (let i = 0; i < lines.length; i++) {
        const line = this.checkExistingVarIntoLine(lines[i], i);
        wrapped = wrapped.concat(this._wrapLine(line, i, desiredWidth, 0, wrapped.length));
      }

      //this.isWrapping = false;

      return wrapped;
    },

    /**
     * Calculate and return last line index that fit in max height
     * @param {Array} lines array of lines to test
     */
    getLastFittingLineIndex: function () {
      let lineHeight,
        i = 0,
        len = this._textLines.length,
        height = 0;

      for (i, len; i < len; ++i) {
        lineHeight = this.getHeightOfLine(i);
        height += i === len - 1 ? lineHeight / this.lineHeight : lineHeight;
        if (height >= this.height) {
          break;
        }
      }
      return i - 1;
    },

    /**
     * <<< Override of fabricjs textbox.class.js >>
     * @override
     * Wraps a line of text using the width of the Textbox and a context.
     * @param {Array} _line The grapheme array that represent the line
     * @param {Number} lineIndex
     * @param {Number} desiredWidth width you want to wrap the line to
     * @param {Number} reservedSpace space to remove from wrapping for custom functionalities
     * @returns {Array} Array of line(s) into which the given text is wrapped
     * to.
     */
    _wrapLine: function (_line, lineIndex, desiredWidth, reservedSpace, wrappedLineIndex) {
      let lineWidth = 0,
        splitByGrapheme = this.splitByGrapheme,
        graphemeLines = [],
        line = [],
        // spaces in different languges?
        words = splitByGrapheme ? fabric.util.string.graphemeSplit(_line) : _line.split(this._wordJoiners),
        word = '',
        wordLengthWithSurrogate = 0,
        offset = 0,
        infix = splitByGrapheme ? '' : ' ',
        wordWidth = 0,
        infixWidth = 0,
        lineJustStarted = true,
        additionalSpace = splitByGrapheme ? 0 : this._getWidthOfCharSpacing(),
        lineOffset = 0,
        wrappedLineLength = 0,
        wrapWord,
        isFirstWrappedWord = false;

      this.setLineList(lineIndex, wrappedLineIndex);

      if (_line.length > 0) {
        reservedSpace = reservedSpace || 0;
        desiredWidth -= reservedSpace;
        for (let i = 0; i < words.length; i++) {
          // wrap word for match max line width
          lineOffset = this.getOffsetAt(wrappedLineIndex);
          wrapWord = this._nextWrappedWord(words[i], wrappedLineIndex, offset, desiredWidth - lineOffset);
          isFirstWrappedWord = true;
          if (wrapWord === null) {
            // bugfix multi space and space in end of line
            wrappedLineLength++;

            // TEST for debug multi space (test KO)
            // uncomment next lines if you want to display multi-space in fabric editor
            // line.push(infix);
            // lineWidth += infixWidth;
          }
          while (wrapWord !== null) {
            word = wrapWord.word;
            wordWidth = wrapWord.width;
            wordLengthWithSurrogate = word.join('').length;

            offset += word.length;
            lineWidth += infixWidth + wordWidth - additionalSpace;

            if (lineWidth >= desiredWidth - lineOffset && !lineJustStarted) {
              graphemeLines.push(line);

              // apply graphemeLines at all new line for detect height line during warpLineProcess
              this.applyGraphemeLineOnFly(line, lineIndex, false, wrappedLineIndex, wrappedLineLength, !isFirstWrappedWord);

              line = [];
              lineWidth = wordWidth;
              lineJustStarted = true;
              wrappedLineIndex++;
              wrappedLineLength = 0;
              lineOffset = this.getOffsetAt(wrappedLineIndex);
              offset = word.length;
            } else {
              lineWidth += additionalSpace;
            }

            if (!lineJustStarted && isFirstWrappedWord) {
              line.push(infix);
              wrappedLineLength++;
            }
            line = line.concat(word);

            wrappedLineLength += wordLengthWithSurrogate;

            if (isFirstWrappedWord) {
              infixWidth = this._measureWord([infix], wrappedLineIndex, offset);
              offset++;
            } else {
              infixWidth = 0;
            }

            // check if next wrapped word exist
            wrapWord = this._nextWrappedWord(wrapWord.next, wrappedLineIndex, offset, desiredWidth - lineOffset, false);

            isFirstWrappedWord = false;
            lineJustStarted = false;
          }
        }
      }

      graphemeLines.push(line);
      this.applyGraphemeLineOnFly(line, lineIndex, true, wrappedLineIndex, wrappedLineLength);

      return graphemeLines;
    },

    _nextWrappedWord: function (word, lineIndex, charOffset, maxWidth, isFirstWrap = true) {
      if (!word || word.length === 0) {
        return null;
      }
      const graphemeWord = fabric.util.string.graphemeSplit(word);
      let _wrappedWidth = 0,
        _wrappedWord = '',
        prevGrapheme;
      const skipLeft = true;

      charOffset = charOffset || 0;
      for (var i = 0, len = graphemeWord.length; i < len; i++) {
        var box = this._getGraphemeBox(graphemeWord[i], lineIndex, i + charOffset, prevGrapheme, skipLeft);
        let splitAt = -1;
        if (_wrappedWidth + box.kernedWidth > maxWidth) {
          splitAt = i;
        } else if (isFirstWrap && graphemeWord[i] === '-') {
          splitAt = i + 1;
        }
        if (splitAt >= 0) {
          if (this.isHighSurrogateChar(graphemeWord.join(''), splitAt)) {
            splitAt += 1;
          }
          _wrappedWord = graphemeWord.slice(0, splitAt).join('');//word.substring(0, splitAt);
          break;
        }
        _wrappedWidth += box.kernedWidth;
        prevGrapheme = graphemeWord[i];
      }

      if (_wrappedWord.length === 0) {
        // if complete loop
        _wrappedWord = word;
      }

      return {
        word: this.splitByGrapheme ? _wrappedWord : fabric.util.string.graphemeSplit(_wrappedWord),
        width: _wrappedWidth,
        next: word.substring(_wrappedWord.length)
      };
    },

    resetBeforeApplyGraphemeLineOnFly() {
      this._textLines = [];
      this.textLines = [];
      this._styleMap = {
        0: {
          line: 0,
          offset: 0
        }
      };
      this._charIndexStyleDiffMap = {};
      this._wrapLineOriginalTextMap = {};
    },

    applyGraphemeLineOnFly(line, realLineIndex, isRealEndOfLigne, wrappedLineIndex, wrappedLineLength, isCutInsideWord = false) {
      // generate styleMap on the fly
      const lineIndex = this._textLines.length;

      // generate default next line styleMap if needed in process
      if (isRealEndOfLigne) {
        this._styleMap[lineIndex + 1] = {
          line: realLineIndex + 1,
          offset: 0
        };
      } else {
        this._styleMap[lineIndex + 1] = {
          line: realLineIndex,
          offset: this._styleMap[lineIndex].offset + line.length + (isCutInsideWord ? 0 : 1)
        };
      }

      // save wrap line map to cut text when reflow
      let previousIndex = 0;
      if (wrappedLineIndex > 0) {
        previousIndex = this._wrapLineOriginalTextMap[wrappedLineIndex - 1].end;
        if (!this._wrapLineOriginalTextMap[wrappedLineIndex - 1].isCutInsideWord) {
          previousIndex += 1;
        }
      }
      this._wrapLineOriginalTextMap[wrappedLineIndex] = {
        start: previousIndex,
        end: previousIndex + wrappedLineLength/* + (isRealEndOfLigne ? 0 : 1)*/,
        length: wrappedLineLength,
        isCutInsideWord,
        //debugText: this.text.slice(previousIndex, previousIndex + wrappedLineLength)
      };

      // apply _textLines and textLines on the fly
      this._textLines.push(line);
      this.textLines.push(line.join(''));
    },

    _getGraphemeBox: function (grapheme, lineIndex, charIndex, prevGrapheme, skipLeft) {
      var style = this.getCompleteStyleDeclaration(lineIndex, charIndex),
        prevStyle = prevGrapheme ? this.getCompleteStyleDeclaration(lineIndex, charIndex - 1) : {},
        info = this._measureChar(grapheme, style, prevGrapheme, prevStyle),
        kernedWidth = info.kernedWidth,
        width = info.width,
        charSpacing;

      if (this.charSpacing !== 0) {
        charSpacing = this._getWidthOfCharSpacing();
        width += charSpacing;
        kernedWidth += charSpacing;
      }

      var box = {
        width: width,
        left: 0,
        height: style.fontSize,
        kernedWidth: kernedWidth,
        deltaY: style.deltaY
      };
      if (charIndex > 0 && !skipLeft) {
        var previousBox = this.__charBounds[lineIndex][charIndex - 1];
        box.left = previousBox.left + previousBox.width + info.kernedWidth - info.width;
      }
      if (this.dropCap && charIndex === 1 && lineIndex === 0) {
        // fix 1st letter spacing for dropCap
        box.left += this.dropCapStyle.dropCapSpacing;
        box.kernedWidth += this.dropCapStyle.dropCapSpacing;
      }
      return box;
    },

    /**
     * Fabricjs Override
     * @private
     * @param {Number} lineIndex index text line
     * @return {Number} Line left offset
     */
    _getLineLeftOffset: function (lineIndex) {
      if (this._textLines.length === 0) {
        return 0;
      }
      let lineWidth = this.getLineWidth(lineIndex);
      let offset = this.getOffsetAt(lineIndex);

      // check if current line has textAlign style
      const textAlign = this.getStyleTextAlignByLine ? this.getStyleTextAlignByLine(lineIndex) : this.textAlign;

      if (textAlign === 'left' || textAlign === 'justify-left') {
        return offset;
      }
      if (textAlign === 'center') {
        if (this.dropCap && lineIndex === 0) {
          // drop cap 1st line
          return offset;
        } else if (this.dropCap && lineIndex < this.dropCapStyle.dropCapHeight) {
          // drop cap next line
          return offset + (this.width - lineWidth) / 2;
        }
        return (offset + this.width - lineWidth) / 2;
      }
      if (textAlign === 'right') {
        if (this.dropCap && lineIndex === 0) {
          // drop cap 1st line
          return offset;
        }
        return offset + this.width - lineWidth;
      }
      if (textAlign === 'justify-center' && this.isEndOfWrapping(lineIndex)) {
        return (offset + this.width - lineWidth) / 2;
      }
      if (textAlign === 'justify-right' && this.isEndOfWrapping(lineIndex)) {
        return offset + this.width - lineWidth;
      }

      if (this.isEndOfWrapping(lineIndex)) {
        if (textAlign === 'justify-center' && this.isEndOfWrapping(lineIndex)) {
          return (this.width - lineWidth) / 2;
        }
        if (textAlign === 'justify-right' && this.isEndOfWrapping(lineIndex)) {
          return this.width - lineWidth;
        }
      }

      if (textAlign === 'justify' || textAlign === 'justify-center' || textAlign === 'justify-right') {
        return offset;
      }

      return 0;
    },

    /**
     * measure a text line measuring all characters.
     * @param {Number} lineIndex line number
     * @return {Number} Line width
     */
    measureLine: function (lineIndex) {
      let lineInfo = this._measureLine(lineIndex);
      if (this.charSpacing !== 0) {
        lineInfo.width -= this._getWidthOfCharSpacing();
      }
      if (lineInfo.width < 0) {
        lineInfo.width = 0;
      }
      lineInfo.width += this.getOffsetAt(lineIndex);
      return lineInfo;
    },

    /**
     * <<< Override of fabricjs itext.svg_export.js >>
     * Override to fix deltaY wrong position
     * Returns styles-string for svg-export
     * @private
     */
    _createTextCharSpan: function (_char, styleDecl, left, top) {
      if (styleDecl.link && styleDecl.fill === undefined) {
        // apply default fill color if has link for override default black link color
        styleDecl.fill = this.fill;
      }

      const multipleSpacesRegex = /  +/g;
      const shouldUseWhitespace = _char !== _char.trim() || _char.match(multipleSpacesRegex);
      const styleProps = this.getSvgSpanStyles(styleDecl, shouldUseWhitespace);
      const fillStyles = styleProps ? 'style="' + styleProps + '"' : '';
      const dy = ''; // <---Override here // dy = styleDecl.deltaY,
      const NUM_FRACTION_DIGITS = fabric.Object.NUM_FRACTION_DIGITS;
      const toFixed = fabric.util.toFixed;
      let dySpan = '';

      if (dy) {
        dySpan = ' dy="' + toFixed(dy, NUM_FRACTION_DIGITS) + '" ';
      }
      const _tspan = [
        '<tspan x="',
        toFixed(left, NUM_FRACTION_DIGITS),
        '" y="',
        toFixed(top, NUM_FRACTION_DIGITS),
        '" ',
        dySpan,
        fillStyles,
        '>',
        fabric.util.string.escapeXml(_char),
        '</tspan>'
      ].join('');

      if (styleDecl.link) {
        if (styleDecl.link.indexOf('#') === 0) {
          return ['<a href="', styleDecl.link, '" target="_self">', _tspan, '</a>'].join('');
        } else {
          return ['<a xlink:href="', styleDecl.link, '" target="_blank">', _tspan, '</a>'].join('');
        }
      } else {
        return _tspan;
      }
    },

    /**
     * Helper function to measure a string of text, given its lineIndex and charIndex offset
     * it gets called when charBounds are not available yet.
     * @param {CanvasRenderingContext2D} ctx
     * @param {String} text
     * @param {number} lineIndex
     * @param {number} charOffset
     * @returns {number}
     * @private
     */
    _measureWord: function (word, lineIndex, charOffset) {
      var width = 0,
        prevGrapheme, skipLeft = true;
      charOffset = charOffset || 0;
      for (var i = 0, len = word.length; i < len; i++) {
        var box = this._getGraphemeBox(word[i], lineIndex, i + charOffset, prevGrapheme, skipLeft);
        width += box.kernedWidth;
        prevGrapheme = word[i];
      }
      return width;
    },

    /**
     * measure every grapheme of a line, populating __charBounds
     * @param {Number} lineIndex
     * @return {Object} object.width total width of characters
     * @return {Object} object.widthOfSpaces length of chars that match this._reSpacesAndTabs
     */
    _measureLine: function (lineIndex) {
      var width = 0,
        i, grapheme, line = this._textLines[lineIndex],
        prevGrapheme,
        graphemeInfo, numOfSpaces = 0,
        lineBounds = new Array(line.length);

      this.__charBounds[lineIndex] = lineBounds;
      for (i = 0; i < line.length; i++) {
        grapheme = line[i];
        graphemeInfo = this._getGraphemeBox(grapheme, lineIndex, i, prevGrapheme);
        lineBounds[i] = graphemeInfo;
        width += graphemeInfo.kernedWidth;
        prevGrapheme = grapheme;
      }
      // this latest bound box represent the last character of the line
      // to simplify cursor handling in interactive mode.
      lineBounds[i] = {
        left: graphemeInfo ? graphemeInfo.left + graphemeInfo.width : 0,
        width: 0,
        kernedWidth: 0,
        height: this.fontSize
      };
      return {
        width: width,
        numOfSpaces: numOfSpaces
      };
    },

    /**
     * return Exportable textbox data to be saved in db,
     * If mulitple textboxes linked, send all group data
     */
    getSaveData: function () {
      const tbList = this.hasReflow ? fabric.textGroups.get(this.textgroupId).textBoxes : [this]
      let output = []

      for (let i = 0; i < tbList.length; i++) {
        const tb = tbList[i];
        output.push({
          id: tb.id,
          text: tb.text,
          styles: tb.styles,
          overrideStyle: tb.overrideStyle,
          opacity: tb.opacity,
          condensedFlatStyle: tb.condensedFlatStyle,
        })
      }

      return output;
    }

  });

  fabric.MzTextbox.fromObject = function (object, callback) {
    return fabric.Object._fromObject('MzTextbox', object, callback, 'text');
  };

  Object.defineProperty(fabric.MzTextbox.prototype, 'condensedFlatStyle', {
    get: function () {
      // only save flatstyle if text has reflow and is the first parent (for recalculate entire apply style if needed)
      let prevStyle = null,
        startAt = -1;
      const condensed = this.flatStyle && this.hasReflow && this.isRootTextbox() ?
        this.flatStyle.reduce((list, value, index) => {
          if (prevStyle !== null &&
            (JSON.stringify(prevStyle) != JSON.stringify(value) || index === this.flatStyle.length - 1)) {
            list[startAt + '-' + (index - 1)] = prevStyle;
            startAt = -1;
            prevStyle = null;
          }
          if (value && Object.keys(value).length > 0) {
            if (startAt === -1) {
              startAt = index;
              prevStyle = value;
            }
          }
          return list;
        }, {})
        : null;
      return condensed;
    },
    set(value) {
      this.flatStyle = value ?
        Object.keys(value).reduce((list, keys) => {
          const extractKey = keys.split('-');
          for (let i = parseInt(extractKey[0]); i <= parseInt(extractKey[1]); i++) {
            list[i] = value[keys];
          }
          return list;
        }, [])
        : null;
    }
  });

};
