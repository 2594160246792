import { fabric } from 'fabric';
import MzMixins from './mixins/MzCommonMixins';

/**
 * Rectangle that rezize instead of scaling
 */
export default () => {


  fabric.MzImage = fabric.util.createClass(fabric.Image, {
    type: 'MzImage',
    originalDefinitionData: null,
    originalClipPathData: null,
    link: '',
    _isFullTransparentCache: null,

    initialize: function (element, options) {
      // inject Mz common properties
      Object.assign(this, MzMixins);
      options = this.initMzObject(options);

      this.toObjectProps.push('link');

      this.originalDefinitionData = options.originalDefinitionData || null;
      this.link = options.link || '';

      this.initLegend(options);

      this.callSuper('initialize', element, options);

      if (options.originalDefinitionData && this.clipPath) {
        // save originalClipPathData
        this.originalClipPathData = {
          left: this.clipPath.left,
          top: this.clipPath.top,
          scaleX: this.clipPath.scaleX,
          scaleY: this.clipPath.scaleY,
        }
        // apply new scale to clipPath
        this.updateImageBeforeChangeSrc(options.scaleX, options.scaleY, options.originalDefinitionData.scaleX, options.originalDefinitionData.scaleY)
      }

    },

    /**
     * Activate modules when all objects are loaded on page.
     */
    activate: function () {
      this.activateLegend();
    },

    getOriginalSrc(filtered) {
      if (this.originalDefinitionData && this.originalDefinitionData.src) {
        return this.originalDefinitionData.src;
      } else {
        return this.getSrc(filtered);
      }
    },

    isFullTransparent() {
      if (this._isFullTransparentCache !== null) {
        return this._isFullTransparentCache;
      }

      if (!this._element) {
        return true;
      }

      if (this.opacity === 0) {
        return true;
      }

      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      canvas.width = this.width;
      canvas.height = this.height;
      context.drawImage(this._element, 0, 0);

      let isTransparent = true;
      const data = context.getImageData(0, 0, canvas.width, canvas.height).data;
      for (let i = 3; i < data.length; i += 4) {
        if (data[i] > 0) {
          isTransparent = false;
          break; // Stop if color found
        }
      }

      this._isFullTransparentCache = isTransparent;
      return isTransparent;
    },

    isLowDefinitionDisplay() {
      return this.originalDefinitionData !== null;
    },

    updateOriginalDefinitionData(originalData) {
      // apply new scale to originalClipPathData
      if (this.originalClipPathData) {
        const diffScaleX = this.originalDefinitionData.scaleX / originalData.scaleX,
          diffScaleY = this.originalDefinitionData.scaleY / originalData.scaleY;

        this.originalClipPathData.scaleX *= diffScaleX;
        this.originalClipPathData.scaleY *= diffScaleY;
        this.originalClipPathData.left *= diffScaleX;
        this.originalClipPathData.top *= diffScaleY;
      }

      // update orginialDefinitionData
      this.originalDefinitionData = Object.assign({}, this.originalDefinitionData, originalData);
    },

    formatToObjectResult(object) {
      if (this.isLowDefinitionDisplay()) {
        return Object.assign({}, object, this.originalDefinitionData, this.originalClipPathData ? { clipPath: this.originalClipPathData } : {});
      } else {
        return object;
      }
    },

    updateImageBeforeChangeSrc(newScaleX, newScaleY, previousScaleX = null, previousScaleY = null) {
      if (previousScaleX === null) previousScaleX = this.scaleX;
      if (previousScaleY === null) previousScaleY = this.scaleY;

      // need specific function to update clipPath
      if (this.clipPath) {
        // apply new scale to clipPath if exist
        const diffScaleX = previousScaleX / newScaleX,
          diffScaleY = previousScaleY / newScaleY;

        this.clipPath.scaleX *= diffScaleX;
        this.clipPath.scaleY *= diffScaleY;
        this.clipPath.left *= diffScaleX;
        this.clipPath.top *= diffScaleY;
      }

      this.set("scaleX", newScaleX);
      this.set("scaleY", newScaleY);
      this.set("cropX", 0);
      this.set("cropY", 0);
    },

    /**
    * @override
    * return orignal image as src in svg
    * */
    getSvgSrc() {
      return this.getOriginalSrc(true);
    },

    /**
     * @override
     * implement link into image
     * */
    _toSVG() {
      // bugfix if _element is null before export to svg
      if (!this._element) {
        return [];
      }

      const svgString = this.callSuper('_toSVG');
      if (this.link && this.link.length > 0) {
        if (this.link.indexOf('#') === 0) {
          svgString.unshift('<a href="' + this.link + '" target="_self">');
        } else {
          svgString.unshift('<a xlink:href="' + this.link + '" target="_blank">');
        }
        svgString.push('</a>');
      }

      // add original scale of image for pdf post traitement
      let indexEndImage = svgString.indexOf('></image>\n');
      if (indexEndImage >= 0 && this.originalDefinitionData) {
        svgString.splice(indexEndImage, 0, ` _original-scale-x="${this.originalDefinitionData.scaleX}" _original-scale-y="${this.originalDefinitionData.scaleY}" `)
      }

      return svgString;
    },

    /**
   * @override
   * return orignal image as src in svg
   * */
    setElement(element, options) {
      this._isFullTransparentCache = null;
      this.callSuper('setElement', element, options);
    },

  });


  /**
   * Creates an instance of fabric.Image from its object representation
   * @static
   * @param {Object} object Object to create an instance from
   * @param {Function} callback Callback to invoke when an image instance is created
   */
  fabric.MzImage.fromObject = function (_object, callback) {
    var object = fabric.util.object.clone(_object);
    fabric.util.loadImage(object.src, function (img, error) {

      fabric.MzImage.prototype._initFilters.call(object, object.filters, function (filters) {
        object.filters = filters || [];
        fabric.MzImage.prototype._initFilters.call(object, [object.resizeFilter], function (resizeFilters) {
          object.resizeFilter = resizeFilters[0];
          fabric.util.enlivenObjects([object.clipPath], function (enlivedProps) {
            object.clipPath = enlivedProps[0];
            var image = new fabric.MzImage(img, object);
            if (error) {
              image.backgroundColor = "#f5b1b1";
            }
            callback(image);
          });
        });
      });
    }, null, object.crossOrigin);
  };

  fabric.MzImage.fromURL = function (url, callback, imgOptions) {
    fabric.util.loadImage(url, function (img) {
      callback && callback(new fabric.MzImage(img, imgOptions));
    }, null, imgOptions && imgOptions.crossOrigin);
  };

  fabric.MzImage.ATTRIBUTE_NAMES =
    fabric.SHARED_ATTRIBUTES.concat('x y width height preserveAspectRatio xlink:href crossOrigin'.split(' '));

  fabric.MzImage.fromElement = function (element, callback, options) {
    var parsedAttributes = fabric.parseAttributes(element, fabric.MzImage.ATTRIBUTE_NAMES);
    fabric.MzImage.fromURL(parsedAttributes['xlink:href'], callback,
      extend((options ? fabric.util.object.clone(options) : {}), parsedAttributes));
  };

};
