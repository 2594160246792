<template>
  <el-container id="app">
    <el-header class="app-header">
      <span class="nav-button" @click="toogleLeftMenu">
        <img src="/icons/nav.svg" />
      </span>
      <span class="header-content ml-2">
        <TemplatesHeader v-if="headerType === 'templates'" />
        <EditTemplateHeader v-else-if="headerType === 'editTemplate'" />
        <FormatsHeader v-else-if="headerType === 'formats'" />
        <AdminUsersHeader v-else-if="headerType === 'adminUsers'" />
        <OffersHeader v-else-if="headerType === 'offers'" />
        <OrganizationsHeader v-else-if="headerType === 'organizations'" />
        <AuthStrategiesHeader v-else-if="headerType === 'authStrategies'" />
        <GroupOffersHeader v-else-if="headerType === 'groupOffers'" />
        <img v-else src="/logo-admin.svg" />
      </span>
      <span class="mr-2 text-right">
        {{ nameFromEmail }}
        <br />
        <el-tooltip v-for="role in user.roles" :key="role" :content="role">
          <img :src="'/icons/admin-role-' + role + '.svg'" class="mr-0 ml-0" />
        </el-tooltip>
      </span>
    </el-header>

    <div
      class="app-menu-clickout"
      v-if="menuIsVisible"
      @click="closeLeftMenu"
    />
    <transition name="openMenu">
      <el-aside class="app-menu" v-if="menuIsVisible">
        <el-container class="app-menu-content">
          <el-row type="flex" justify="center">
            <img src="/logo-admin.svg" />
          </el-row>

          <el-collapse v-model="currentCollapseOpened" accordion class="mt-2">
            <el-row type="flex" align="middle" class="mt-1">
              <img src="/icons/menu-stats.svg" />
              <el-link
                class="header-link-primary"
                :class="{
                  'active-link': activeRouteName === 'admin',
                }"
                @click="onClickMenuLink({ name: 'admin' })"
                >{{ $t("admin.layout.stats") }}</el-link
              >
            </el-row>

            <el-collapse-item
              name="editor"
              :disabled="!checkPerm('template:list')"
              class="mt-1"
            >
              <template slot="title">
                <img src="/icons/menu-editor.svg" />
                <el-link
                  class="header-link-primary"
                  :class="{
                    'active-link': activeRouteName === 'admin-template',
                  }"
                  @click="onClickMenuLink({ name: 'admin-template' })"
                  >{{ $t("admin.layout.editor") }}</el-link
                >
              </template>

              <el-row>
                <el-link
                  class="header-link-submenu"
                  :class="{
                    'active-link': activeRouteName === 'admin-template-list',
                  }"
                  @click="onClickMenuLink({ name: 'admin-template-list' })"
                  >{{ $t("admin.layout.templates") }}</el-link
                >
              </el-row>
              <el-row>
                <el-link
                  class="header-link-submenu"
                  :class="{
                    'active-link': activeRouteName === 'admin-format-list',
                  }"
                  @click="onClickMenuLink({ name: 'admin-format-list' })"
                  >{{ $t("admin.layout.formats") }}</el-link
                >
              </el-row>
            </el-collapse-item>

            <el-row type="flex" align="middle" class="mt-1">
              <img src="/icons/menu-users.svg" />
              <el-link
                :disabled="!checkPerm('users:clientList')"
                class="header-link-primary"
                :class="{
                  'active-link': activeRouteName === 'admin-users-clientList',
                }"
                @click="onClickMenuLink({ name: 'admin-users-clientList' })"
                >{{ $t("admin.layout.users") }}</el-link
              >
            </el-row>

            <el-row type="flex" align="middle" class="mt-1">
              <img src="/icons/menu-organizations.svg" />
              <el-link
                :disabled="!checkPerm('organization:list')"
                class="header-link-primary"
                :class="{
                  'active-link':
                    activeRouteName === 'admin-organization-list-byGroupOffer',
                }"
                @click="
                  onClickMenuLink({
                    name: 'admin-organization-list-byGroupOffer',
                  })
                "
                >{{ $t("admin.layout.groupOffers") }}</el-link
              >
            </el-row>
            <el-row type="flex" align="middle" class="mt-1">
              <img src="/icons/menu-organizations.svg" />
              <el-link
                :disabled="!checkPerm('organization:list')"
                class="header-link-primary"
                :class="{
                  'active-link': activeRouteName === 'admin-organization-list',
                }"
                @click="
                  onClickMenuLink({
                    name: 'admin-organization-list',
                  })
                "
                >{{ $t("admin.layout.organizations") }}</el-link
              >
            </el-row>
            <el-row type="flex" align="middle" class="mt-1">
              <img src="/icons/menu-organizations.svg" />
              <el-link
                :disabled="!checkPerm('workspace:list')"
                class="header-link-primary"
                :class="{
                  'active-link': activeRouteName === 'admin-workspace-list',
                }"
                @click="
                  onClickMenuLink({
                    name: 'admin-workspace-list',
                  })
                "
                >{{ $t("admin.layout.workspaces") }}</el-link
              >
            </el-row>

            <el-row type="flex" align="middle" class="mt-1">
              <img src="/icons/menu-magazines.svg" />
              <el-link
                :disabled="!checkPerm('magazine:clientList')"
                class="header-link-primary"
                :class="{
                  'active-link': activeRouteName === 'admin-magazine-list',
                }"
                @click="onClickMenuLink({ name: 'admin-magazine-list' })"
                >{{ $t("admin.layout.magazines") }}</el-link
              >
            </el-row>

            <el-row type="flex" align="middle" class="mt-1">
              <img src="/icons/menu-orders.svg" />
              <el-link
                :disabled="!checkPerm('order:adminList')"
                class="header-link-primary"
                :class="{
                  'active-link': activeRouteName === 'admin-order-list',
                }"
                @click="onClickMenuLink({ name: 'admin-order-list' })"
                >{{ $t("admin.layout.orders") }}</el-link
              >
            </el-row>

            <el-collapse-item name="settings" class="mt-1">
              <template slot="title">
                <img src="/icons/menu-settings.svg" />
                <el-link
                  class="header-link-primary"
                  :class="{
                    'active-link': activeRouteName === 'admin-settings',
                  }"
                  @click="onClickMenuLink({ name: 'admin-settings' })"
                  >{{ $t("admin.layout.settings") }}</el-link
                >
              </template>

              <el-row>
                <el-link
                  class="header-link-submenu"
                  :class="{
                    'active-link': activeRouteName === 'admin-users-adminList',
                  }"
                  @click="onClickMenuLink({ name: 'admin-users-adminList' })"
                  :disabled="!checkPerm('users:adminList')"
                  >{{ $t("admin.layout.adminUsers") }}</el-link
                >
              </el-row>
              <el-row>
                <el-link
                  class="header-link-submenu"
                  :class="{
                    'active-link':
                      activeRouteName === 'admin-authStrategy-list',
                  }"
                  @click="onClickMenuLink({ name: 'admin-authStrategy-list' })"
                  :disabled="!checkPerm('authStrategy:adminList')"
                  >Auth Strategies</el-link
                >
              </el-row>
              <el-row>
                <el-link
                  class="header-link-submenu"
                  :class="{
                    'active-link': activeRouteName === 'admin-offer-list',
                  }"
                  @click="onClickMenuLink({ name: 'admin-offer-list' })"
                  :disabled="!checkPerm('offer:list')"
                  >{{ $t("admin.layout.offers") }}</el-link
                >
              </el-row>
              <el-row>
                <el-link
                  class="header-link-submenu"
                  :class="{
                    'active-link':
                      activeRouteName === 'admin-settings-maintenance',
                  }"
                  @click="
                    onClickMenuLink({ name: 'admin-settings-maintenance' })
                  "
                  :disabled="!checkPerm('maintenance:update')"
                  >{{ $t("admin.layout.maintenance") }}</el-link
                >
              </el-row>
              <el-row>
                <el-link
                  class="header-link-submenu"
                  :class="{
                    'active-link': activeRouteName === 'admin-settings-message',
                  }"
                  @click="onClickMenuLink({ name: 'admin-settings-message' })"
                  :disabled="!checkPerm('maintenance:update')"
                  >{{ $t("admin.layout.message") }}</el-link
                >
              </el-row>
            </el-collapse-item>
          </el-collapse>

          <el-row class="app-menu-fit-height" />

          <el-row type="flex" align="middle" class="mt-3">
            <img src="/icons/menu-logout.svg" />
            <el-link class="header-link-secondary" @click="logout()">{{
              $t("public.layout.logout")
            }}</el-link>
          </el-row>

          <el-row class="mt-1" v-if="checkPerm('editor:all')">
            <el-link
              type="primary"
              class="header-link-client-interface"
              @click="onClickMenuLink({ name: 'client' })"
            >
              <img src="/icons/open_external_link.svg" />
              {{ $t("admin.layout.client_interface") }}
            </el-link>
          </el-row>
        </el-container>

        <el-container class="app-menu-extend-button">
          <img src="/icons/extend.svg" @click="closeLeftMenu" />
        </el-container>
      </el-aside>
    </transition>

    <el-main class="app-main" v-if="isAutorizedUser">
      <nuxt />
    </el-main>

    <div class="version">api: {{ apiBuild }} | app: {{ appBuild }}</div>

    <MaintenanceAlert />
    <InactiveUserAlert />

    <OfflineModal :visible="isOffline" />
  </el-container>
</template>

<script>
import { mapState, mapMutations, mapGetters, mapActions } from "vuex";

import OfflineModal from "~/components/OfflineModal";
import TemplatesHeader from "~/components/admin/TemplatesHeader";
import FormatsHeader from "~/components/admin/FormatsHeader";
import EditTemplateHeader from "~/components/admin/EditTemplateHeader";
import AdminUsersHeader from "~/components/admin/AdminUsersHeader";
import OffersHeader from "~/components/admin/OffersHeader";
import OrganizationsHeader from "~/components/admin/OrganizationsHeader";
import AuthStrategiesHeader from "~/components/admin/AuthStrategiesHeader";
import GroupOffersHeader from "~/components/admin/GroupOffersHeader";
import MaintenanceAlert from "~/components/MaintenanceAlert";
import InactiveUserAlert from "~/components/InactiveUserAlert";

export default {
  components: {
    OfflineModal,
    TemplatesHeader,
    FormatsHeader,
    EditTemplateHeader,
    AdminUsersHeader,
    OffersHeader,
    OrganizationsHeader,
    AuthStrategiesHeader,
    GroupOffersHeader,
    MaintenanceAlert,
    InactiveUserAlert,
  },
  data() {
    return {
      isAutorizedUser: true,
      menuIsVisible: false,
      currentCollapseOpened: "",
      activeRouteName: "",
    };
  },
  computed: {
    ...mapGetters("api", ["checkPerm", "nameFromEmail"]),
    ...mapState("api", ["user"]),
    ...mapState("app", ["apiBuild"]),
    ...mapGetters("app", ["appBuild"]),

    headerType() {
      return this.$route.matched.map((r) => {
        return r.components.default.options
          ? r.components.default.options.layoutHeaderType
          : r.components.default.layoutHeaderType;
      })[0];
    },
    isOffline() {
      return !this.$online.online;
    },
  },
  created() {
    this.getBuildVersion();
  },
  mounted() {
    if (!this.checkPerm("admin:all")) {
      this.isAutorizedUser = false;
      this.$alert(
        this.$t("layout.unauthorized_user.message"),
        this.$t("layout.unauthorized_user.title"),
        {
          confirmButtonText: this.$t("layout.unauthorized_user.button"),
          callback: this.logout,
        }
      );
    }

    this.updateCurrentCollapseOpened();
  },
  methods: {
    ...mapActions({
      doLogout: "api/API_LOGOUT",
      getBuildVersion: "app/GET_API_BUILD",
    }),
    logout() {
      this.doLogout({
        debugSaveFrom: "Admin logout",
      }).then(() => this.$go2Route({ name: "login" }));
    },
    toogleLeftMenu() {
      this.menuIsVisible = !this.menuIsVisible;
    },
    closeLeftMenu(event) {
      this.menuIsVisible = false;
    },
    onClickMenuLink(params) {
      this.$go2Route(params);
      this.closeLeftMenu();
    },
    updateCurrentCollapseOpened() {
      this.activeRouteName = this.$nuxt.$route.name.split("___")[0];
      switch (this.activeRouteName) {
        case "admin-template":
        case "admin-template-list":
        case "admin-template-edit-templateId":
        case "admin-format-list":
          this.currentCollapseOpened = "editor";
          break;

        case "admin-users-clientList":
          this.currentCollapseOpened = "clients";
          break;

        case "admin-magazine-list":
          this.currentCollapseOpened = "magazines";
          break;

        case "admin-order-list":
          this.currentCollapseOpened = "orders";
          break;

        case "admin-settings":
        case "admin-users-adminList":
        case "admin-sso-list":
        case "admin-offer-list":
        case "admin-authStrategy-list":
        case "admin-settings-maintenance":
          this.currentCollapseOpened = "settings";
          break;

        default:
          this.currentCollapseOpened = "";
          break;
      }
    },
  },
  watch: {
    $route() {
      this.updateCurrentCollapseOpened();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~assets/css/mz-variables.scss";

.app-menu {
  & .el-link.header-link-primary {
    color: $public-color-blue-dark;
    font-size: 1.44rem;
    margin-left: 1rem;
    margin-right: 2rem;

    &:hover {
      color: $public-color-blue-dark;
    }
  }

  & .el-link.header-link-secondary {
    color: $public-color-blue-dark;
    font-size: 1.13rem;
    margin-left: 1rem;

    &:hover {
      color: $public-color-blue-dark;
    }
  }

  & .el-link.header-link-submenu {
    color: $public-color-black;
    font-size: 1.13rem;
    margin-left: 2.5rem;
    margin-top: 0.5rem;

    &:hover {
      color: $public-color-blue-dark;
    }
  }

  & .el-link.header-link-client-interface {
    font-size: 0.63rem;
    font-weight: bold;
    & img {
      margin-right: 8px;
    }
  }

  & .el-link.active-link {
    color: $public-color-blue !important;
  }
}

.app-menu-content {
  & /deep/.el-collapse,
  & /deep/.el-collapse-item__header,
  & /deep/.el-collapse-item__wrap {
    border: none !important;
    line-height: unset !important;
    height: unset !important;
  }
  & /deep/.el-collapse-item__content {
    padding-bottom: 0.5rem;
  }
}

.version {
  font-size: 10px;
  position: absolute;
  bottom: 5px;
  right: 20px;
  color: #aaa;
  pointer-events: none;
}
</style>
