import { fabric } from 'fabric';

export default () => {
  fabric.util.object.extend(
    fabric.MzTextbox.prototype,
    /** @lends fabric.MzTextbox.prototype */
    {
      backgroundPadding: { left: 0, right: 0, top: 0, bottom: 0 },
      textBackgroundPadding: { left: 0, right: 0, top: 0, bottom: 0 },

      initPadding(options) {
        if (options.backgroundPadding) {
          this.backgroundPadding = options.backgroundPadding;
        }
        if (options.textBackgroundPadding) {
          this.textBackgroundPadding = options.textBackgroundPadding;
        }

        this.toObjectProps.push('backgroundPadding', 'textBackgroundPadding');
      },

      /**
       * @override
       */
      _renderBackground: function (ctx) {
        if (!this.backgroundColor) {
          return;
        }
        var dim = this._getNonTransformedDimensions();
        ctx.fillStyle = this.backgroundColor;

        ctx.fillRect(
          -dim.x / 2 - this.backgroundPadding.left,
          -dim.y / 2 - this.backgroundPadding.top,
          dim.x + this.backgroundPadding.left + this.backgroundPadding.right,
          dim.y + this.backgroundPadding.top + this.backgroundPadding.bottom
        );
        // if there is background color no other shadows
        // should be casted
        this._removeShadow(ctx);
      },

      /**
      * @override
      */
      _setSVGBg: function (textBgRects) {
        var toFixed = fabric.util.toFixed;

        if (this.backgroundColor) {
          var NUM_FRACTION_DIGITS = fabric.Object.NUM_FRACTION_DIGITS;
          textBgRects.push(
            '\t\t<rect ',
            this._getFillAttributes(this.backgroundColor),
            ' x="',
            toFixed(-this.width / 2 - this.backgroundPadding.left, NUM_FRACTION_DIGITS),
            '" y="',
            toFixed(-this.height / 2 - this.backgroundPadding.top, NUM_FRACTION_DIGITS),
            '" width="',
            toFixed(this.width + this.backgroundPadding.left + this.backgroundPadding.right, NUM_FRACTION_DIGITS),
            '" height="',
            toFixed(this.height + this.backgroundPadding.top + this.backgroundPadding.bottom, NUM_FRACTION_DIGITS),
            '"></rect>\n');
        }
      },

      /**
     * Renders the text background for lines, taking care of style
     * @override
     * @private
     * @param {CanvasRenderingContext2D} ctx Context to render on
     */
      _renderTextLinesBackground: function (ctx) {
        if (!this.textBackgroundColor && !this.styleHas('textBackgroundColor')) {
          return;
        }
        const originalFill = ctx.fillStyle;

        this.__renderStyleTextBackground(ctx);

        ctx.fillStyle = originalFill;
        // if there is text background color no other shadows should be casted
        this._removeShadow(ctx);
      },

      __renderStyleTextBackground: function (ctx) {
        if (!this.styleHas('textBackgroundColor') && !this.textBackgroundColor) {
          return;
        }

        var lineTopOffset = 0, heightOfLine,
          lineLeftOffset,
          line, lastColor,
          leftOffset = this._getLeftOffset(),
          topOffset = this._getTopOffset(),
          boxStart = 0, boxWidth = 0, charBox, currentColor,
          lastPadding, currentPadding;

        for (var i = 0, len = this._textLines.length; i < len; i++) {
          heightOfLine = this.getHeightOfLine(i);
          if (!this.styleHas('textBackgroundColor', i) && !this.textBackgroundColor) {
            lineTopOffset += heightOfLine;
            continue;
          }

          line = this._textLines[i];
          lineLeftOffset = this._getLineLeftOffset(i);
          boxWidth = 0;
          boxStart = 0;
          lastColor = this.getCharStylePropertyAt(i, 0, 'textBackgroundColor');
          lastPadding = this.getCharStylePropertyAt(i, 0, 'textBackgroundPadding');
          if (lastColor === null) {
            lastColor = this.textBackgroundColor;
            lastPadding = this.textBackgroundPadding;
          }

          for (var j = 0, jlen = line.length; j < jlen; j++) {
            charBox = this.__charBounds[i][j];
            currentColor = this.getCharStylePropertyAt(i, j, 'textBackgroundColor');
            currentPadding = this.getCharStylePropertyAt(i, j, 'textBackgroundPadding');
            if (currentColor === null) {
              currentColor = this.textBackgroundColor;
              currentPadding = this.textBackgroundPadding;
            }

            if (currentColor !== lastColor) {
              ctx.fillStyle = lastColor;
              lastColor && ctx.fillRect(
                leftOffset + lineLeftOffset + boxStart - (lastPadding ? lastPadding.left : 0),
                topOffset + lineTopOffset - (lastPadding ? lastPadding.top : 0),
                boxWidth + (lastPadding ? (lastPadding.left + lastPadding.right) : 0),
                (heightOfLine / this.lineHeight) + (lastPadding ? (lastPadding.top + lastPadding.bottom) : 0)
              );
              boxStart = charBox.left;
              boxWidth = charBox.width;
              lastColor = currentColor;
              lastPadding = currentPadding;
            }
            else {
              boxWidth += charBox.kernedWidth;
            }
          }

          if (currentColor) {
            ctx.fillStyle = currentColor;
            ctx.fillRect(
              leftOffset + lineLeftOffset + boxStart - (currentPadding ? currentPadding.left : 0),
              topOffset + lineTopOffset - (currentPadding ? currentPadding.top : 0),
              boxWidth + (currentPadding ? (currentPadding.left + currentPadding.right) : 0),
              (heightOfLine / this.lineHeight) + (currentPadding ? (currentPadding.top + currentPadding.bottom) : 0)
            );
          }
          lineTopOffset += heightOfLine;
        }
      },

      /**
       * @override
       */
      _pushTextBgRect: function (textBgRects, color, left, top, width, height) {
        var toFixed = fabric.util.toFixed;

        var NUM_FRACTION_DIGITS = fabric.Object.NUM_FRACTION_DIGITS;
        textBgRects.push(
          '\t\t<rect ',
          this._getFillAttributes(color),
          ' x="',
          toFixed(left - this.textBackgroundPadding.left, NUM_FRACTION_DIGITS),
          '" y="',
          toFixed(top - this.textBackgroundPadding.top, NUM_FRACTION_DIGITS),
          '" width="',
          toFixed(width + this.textBackgroundPadding.left + this.textBackgroundPadding.right, NUM_FRACTION_DIGITS),
          '" height="',
          toFixed(height + this.textBackgroundPadding.bottom + this.textBackgroundPadding.top, NUM_FRACTION_DIGITS),
          '"></rect>\n');
      },

      /**
       * @override
       */
      _getCacheCanvasDimensions: function () {
        var dims = this.callSuper('_getCacheCanvasDimensions');

        const left = Math.max(this.backgroundPadding.left, this.textBackgroundPadding.left),
          right = Math.max(this.backgroundPadding.right, this.textBackgroundPadding.right),
          top = Math.max(this.backgroundPadding.top, this.textBackgroundPadding.top),
          bottom = Math.max(this.backgroundPadding.left, this.textBackgroundPadding.bottom);

        dims.x -= left;
        dims.width += left + right;

        dims.y -= top;
        dims.height += top + bottom;

        return dims;
      },
    }
  );
};
