import { fabric } from 'fabric';
import Utils from '~/common/utils/store';

export default () => {
  fabric.canvas = null;
  fabric.page = null;
  fabric.textStylesList = {};

  Object.defineProperty(fabric, 'hasCanvas', {
    get: function () {
      return Boolean(this.canvas);
    }
  });
  Object.defineProperty(fabric, 'activeObject', {
    get: function () {
      return this.canvas ? this.canvas.getActiveObject() : null;
    }
  });

  fabric.createCanvas = function (elementId, width, height, options = {}) {
    if (this.canvas) {
      this.canvas.clear();
    } else {
      this.canvas = new fabric.MzCanvas(elementId, {
        width: width,
        height: height,
        backgroundColor: '#ffffff',
        ...options
      });
    }
  };

  fabric.createPage = function (options) {
    if (!this.canvas) {
      console.warn('Invalid create page, canvas is not instantiate');
      return;
    }

    // regenerate uniqueid (Unique id used internally when creating SVG elements)
    fabric.Object.__uid = parseInt(parseInt(Date.now() / 1000) + "" + parseInt(10 + Math.random() * 980) + "000");
    console.log('fabric.Object.__uid', fabric.Object.__uid);

    this.page = new fabric.MzPage(this.canvas, options);
    this.page.drawPage(options);
    // set canvas max scroll according to page height
    this.canvas.maxScrollY = options.pageRect.height + 20;
    this.canvas.maxScrollX = options.pageRect.width + 20;
  };

  // function used for create multi instance of page in same time (use for fabric on server)
  fabric.instanciateNewPage = function (canvasOptions, pageOptions) {
    const canvas = new fabric.MzCanvas('background', {
      backgroundColor: '#ffffff',
      ...canvasOptions
    });

    const page = new fabric.MzPage(canvas, pageOptions);
    page.drawPage(pageOptions);
    // set canvas max scroll according to page height
    canvas.maxScrollY = pageOptions.pageRect.height + 20;
    canvas.maxScrollX = pageOptions.pageRect.width + 20;

    return page;
  };

  fabric.getPositionInPage = function (object = null) {
    if (object === null) {
      object = this.canvas.getActiveObject();
    }
    if (!object) {
      return;
    }
    return {
      top: this.object.top - this.page.printMarginRect.top,
      left: this.object.left - this.page.printMarginRect.left
    };
  };
  /**
   * Common action on objects, copy, paste, delete ...
   */
  fabric.objectControlAction = function (type, object = null) {
    //'bringToFront', 'bringForward', 'sendBackwards', 'sendToBack', 'remove', 'copy'
    if (object === null) {
      object = this.canvas.getActiveObject();
    }
    if (!object) {
      return;
    }

    if (type === 'remove') {
      // specific case for remove active selection, need to remove each object
      if (object.type === 'activeSelection') {
        object.forEachObject((obj) => {
          this.objectControlAction('remove', obj);
        });
      }
      this.canvas.discardActiveObject();
    }

    if (object.group && type === 'remove') {
      // execute action in group instead of canvas
      const group = object.group;
      const result = object.group[type](object);
      this.refresh(group);
      return result;
    } else {
      return this.canvas[type](object);
    }

  };

  /**
   * Invoke fabricjs Object function and refresh render
   */
  fabric.callObjectFunction = function (type, payload, object = null, callIntoSelection = true) {
    if (object === null) {
      object = this.canvas.getActiveObject();
    }
    if (!object) {
      return;
    }

    if (object.type === 'activeSelection' && callIntoSelection) {
      // execute action on all object in selection
      object._objects.forEach((obj) => {
        obj[type](payload);
        this.refresh(obj);
      });
    } else {
      object[type](payload);
      this.refresh(object);
    }
  };

  /**
   * Update fabricjs Object property and refresh render
   */
  fabric.setObjectProp = function (name, value, object = null, useSet = false) {
    if (object === null) {
      object = this.canvas.getActiveObject();
    }
    if (!object) {
      return;
    }
    // multiple objects set:
    if (object.type === 'activeSelection' && !object.hasOwnProperty(name)) {
      this.setMultipleObjectProp(name, value, object._objects, useSet);
    } else {
      Utils.setProperty(name, object, value, useSet); // use set() if object is not a fabric type (eg: style object)
      // dispatch change event
      this.canvas.fire('object:modified', { target: object })
    }

    this.refresh(object);
  };

  fabric.setMultipleObjectProp = function (name, value, objects, useSet = false) {
    if (objects === null) {
      objects = this.canvas.getActiveObjects();
    }
    if (objects.length < 1) {
      return;
    }
    objects.forEach(object => {
      this.setObjectProp(name, value, object, useSet);
      this.refresh(object);
    });
  };

  /**
   * force updating object and render canvas
   */
  fabric.refresh = function (object = null) {
    if (!this.canvas) {
      return;
    }
    if (object === null) {
      object = this.canvas.getActiveObject();
    }

    if (object) {
      object.setCoords();
      if (object.group) {
        object.group.setCoords();
        object.group.dirty = true;
      }
    }
    this.canvas.requestRenderAll();
  };

  fabric.setTextStylesList = function (styles) {
    console.log('set styles list', JSON.parse(JSON.stringify(styles)));
    this.textStylesList = styles;

    // force reapply style on all textbox
    if (this.canvas) {
      let textboxes = this.canvas.getObjectsOfType("MzTextbox")
        .filter(textbox => textbox.textStyleKey !== null);
      for (let textbox of textboxes) {
        textbox.applyTextStyle(textbox.textStyleKey);
        this.refresh(textbox);
      }
    }
  }

  fabric.getTextBoxStyle = function (object = null, withSwatchName = true) {
    if (!this.canvas) {
      return;
    }
    if (object === null) {
      object = this.canvas.getActiveObject();
    }
    if (!object) {
      return;
    }
    if (object.type === 'activeSelection') {
      object = object._objects[0];
    }
    if (!object || object.type != 'MzTextbox') {
      return;
    }
    return object.getTextBoxStyle(withSwatchName);
  };

  fabric.disposeCanvas = function () {
    if (this.canvas) {
      this.canvas.dispose();
      this.canvas = null;
    }
  };

  fabric.reorganizeStackReflow = function () {
    let textboxes = this.canvas.getObjectsOfType("MzTextbox")
      .filter(textbox => textbox.isRootTextbox());

    for (let textbox of textboxes) {
      textbox.updateReflowStackOrder();
    }
  }

  fabric.updateStackReflowStyle = function (object = null) {
    if (!this.canvas) {
      return;
    }
    if (object === null) {
      object = this.canvas.getActiveObject();
    }
    if (!object || object.type !== 'MzTextbox' || !object.hasReflow) {
      return;
    }

    object.setAsStyleForAllReflowStack();
    this.refresh(object);
  }
}
