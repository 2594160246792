<template>
  <el-popover
    placement="bottom"
    trigger="click"
    v-model="displayPopover"
    :disabled="nbSelectChoice === 0"
  >
    <div slot="reference">
      <el-row
        type="flex"
        align="middle"
        justify="space-between"
        :class="{
          'content-workspace': true,
          'has-choices': nbSelectChoice > 0,
        }"
      >
        <MzSingleLineText
          :style="{ 'max-width': nbSelectChoice > 0 ? '200px' : '240px' }"
          >{{ labelButton }}
        </MzSingleLineText>

        <i class="el-icon-la-retweet" v-if="nbSelectChoice > 0"></i>
      </el-row>
    </div>

    <div class="mt-0 mb-0">
      <el-row class="mb-1">{{
        $t("public.switchWorkspace.myWorkspaces")
      }}</el-row>

      <div
        v-for="organization in filteredOrganizations"
        :key="organization._id"
      >
        <el-row v-if="organization.workspaces.length === 0" class="mt-0 ml-1">
          <el-badge is-dot type="success" v-if="organization.isCurrent">
            {{ organization.name + "&nbsp;&nbsp;" }}
          </el-badge>
          <el-link v-else @click="onSelectWorkspace(organization._id)">
            {{ organization.name }}
          </el-link>
        </el-row>
        <el-row
          v-else
          v-for="workspace in organization.workspaces"
          :key="workspace._id"
          class="mt-0 ml-1"
        >
          <el-badge is-dot type="success" v-if="workspace.isCurrent">
            {{ organization.name + " > " + workspace.name + "&nbsp;&nbsp;" }}
          </el-badge>
          <el-link
            v-else
            @click="onSelectWorkspace(organization._id, workspace._id)"
          >
            {{ organization.name + " > " + workspace.name }}
          </el-link>
        </el-row>
      </div>
    </div>
  </el-popover>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import MzSingleLineText from "~/components/MzSingleLineText";

export default {
  name: "SwitchWorkspaceButton",

  components: {
    MzSingleLineText,
  },
  props: {},

  data() {
    return {
      displayPopover: false,
    };
  },
  computed: {
    ...mapGetters("api", [
      "getOrganizations",
      "getCurrentOrganizationId",
      "getWorkspaces",
      "getCurrentWorkspaceId",
      "currentWorkspace",
      "currentOrganization",
      "getUID",
      "isB2cOrganization",
    ]),

    labelButton() {
      console.log(
        "currentWorkspace",
        JSON.parse(JSON.stringify(this.currentWorkspace))
      );
      console.log(
        "currentOrganization",
        JSON.parse(JSON.stringify(this.currentOrganization))
      );

      if (!this.isB2cOrganization) {
        if (this.currentWorkspace) {
          return this.currentWorkspace.name;
        }
        if (this.currentOrganization) {
          return this.currentOrganization.name;
        }
      }
      return this.$t("public.switchWorkspace.defaultLabel");
    },

    nbSelectChoice() {
      let nb = 0;
      for (const organization of this.filteredOrganizations) {
        if (organization.workspaces.length === 0) {
          if (!organization.isCurrent) {
            nb++;
          }
        } else {
          for (const workspace of organization.workspaces) {
            if (!workspace.isCurrent) {
              nb++;
            }
          }
        }
      }
      return nb;
    },

    filteredOrganizations() {
      const list = this.getOrganizations
        .map((organization) => {
          return {
            ...organization,
            isCurrent: organization._id === this.getCurrentOrganizationId,
            workspaces: this.getWorkspaces
              .filter(
                (workspace) => workspace.organization._id === organization._id
              )
              .map((workspace) => {
                return {
                  ...workspace,
                  isCurrent: workspace._id === this.getCurrentWorkspaceId,
                };
              }),
          };
        })
        .filter((organization) => {
          return (
            organization.workspaces.length > 0 ||
            organization.admin === this.getUID
          );
        });
      return list;
    },
  },

  methods: {
    ...mapActions({
      doChangeWorkspace: "api/API_CHANGE_WORKSPACE",
    }),

    hide() {
      this.displayPopover = false;
    },
    show() {
      this.displayPopover = true;
    },
    onSelectWorkspace(organizationId, workspaceId = null) {
      this.doChangeWorkspace({
        organizationId: organizationId,
        workspaceId: workspaceId,
      }).then((success) => {
        this.$go2Route({
          name: "client",
        });
        if (success) {
          this.hide();
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "~assets/css/mz-variables.scss";

.content-workspace {
  color: $public-color-black;
  font-size: 1.22rem;

  &.has-choices {
    cursor: pointer;

    &:hover {
      color: $public-color-blue;
    }
  }
}
</style>