<template>
  <el-row type="flex" align="left">
    <el-button
      v-if="checkPerm('organization:create')"
      type="primary"
      @click="showNewGroupOfferModal"
      >Create new group offer</el-button
    >

    <NewGroupOfferModal ref="newGroupOfferModal" />
  </el-row>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";

import NewGroupOfferModal from "~/components/organization/NewGroupOfferModal";

export default {
  name: "GroupOffersHeader",

  components: { NewGroupOfferModal },

  props: [],
  data() {
    return {};
  },
  computed: {
    ...mapGetters("api", ["checkPerm"]),
  },

  methods: {
    showNewGroupOfferModal() {
      this.$refs.newGroupOfferModal.show();
    },
  },
};
</script>

<style scoped lang="scss">
</style>