import Vue from 'vue'
import Utils from "~/common/utils/misc";

Vue.filter('px2mm', function (value, dpi) {
  if (!value) return 0

  if (typeof value === String) {
    value = parseFloat(value)
  }
  return Utils.convertPixelToMilimeters(value, dpi)
})

Vue.filter('mm2px', function (value, dpi) {
  if (!value) return 0

  if (typeof value === String) {
    value = parseFloat(value)
  }
  return Utils.convertMilimetersToPixels(value, dpi)
})

/**
 * Vue filter to round the decimal to the given place.
 * http://jsfiddle.net/bryan_k/3ova17y9/
 *
 * @param {String} value    The value string.
 * @param {Number} decimals The number of decimal places.
 */
Vue.filter('round', function (value, decimals) {
  if (!value) {
    value = 0;
  }

  if (!decimals) {
    decimals = 0;
  }

  value = Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals);
  return value;
});