export default {

  drawRoundedRect(ctx, x, y, width, height, radius) {
    if (width < 2 * radius) radius = width / 2;
    if (height < 2 * radius) radius = height / 2;
    ctx.beginPath();
    ctx.moveTo(x + radius, y);
    ctx.arcTo(x + width, y, x + width, y + height, radius);
    ctx.arcTo(x + width, y + height, x, y + height, radius);
    ctx.arcTo(x, y + height, x, y, radius);
    ctx.arcTo(x, y, x + width, y, radius);
    ctx.closePath();
    ctx.stroke();
  },

  clearRoundedRect(ctx, x, y, width, height, radius) {
    if (width < 2 * radius) radius = width / 2;
    if (height < 2 * radius) radius = height / 2;

    ctx.save();
    ctx.beginPath();
    ctx.moveTo(x + radius, y);
    ctx.arcTo(x + width, y, x + width, y + height, radius);
    ctx.arcTo(x + width, y + height, x, y + height, radius);
    ctx.arcTo(x, y + height, x, y, radius);
    ctx.arcTo(x, y, x + width, y, radius);
    ctx.closePath();
    ctx.clip();
    ctx.clearRect(x, y, width, height);
    ctx.restore();
  },

  drawProximityLine(ctx, from, to, color) {
    ctx.beginPath();
    ctx.moveTo(from.x, from.y);
    ctx.lineTo(to.x, to.y);
    ctx.strokeStyle = color;
    ctx.lineWidth = 1;
    ctx.setLineDash([2, 2]);
    ctx.stroke();
  },

  /**
   *
   * @param {*} ctx
   * @param {Point} from
   * @param {Point} to
   * @param {int} radius Arrow radius
   * @param {String} color hex format
   */
  drawArrow(ctx, from, to, radius, color) {
    let angle;
    let headlen = radius; // length of head in pixels
    let arrowSharpBase = .7;
    let arrWidth = Math.PI / 8;

    angle = Math.atan2(to.y - from.y, to.x - from.x);

    ctx.save();
    ctx.moveTo(from.x, from.y);
    ctx.lineTo(to.x, to.y);
    ctx.strokeStyle = color;
    ctx.lineWidth = 1;
    ctx.setLineDash([8, 4, 2, 4]);
    ctx.stroke();

    ctx.lineTo(to.x - headlen * Math.cos(angle - arrWidth), to.y - headlen * Math.sin(angle - arrWidth));

    ctx.lineTo(to.x - headlen * arrowSharpBase * Math.cos(angle), to.y - headlen * arrowSharpBase * Math.sin(angle));
    ctx.lineTo(to.x - headlen * Math.cos(angle + arrWidth), to.y - headlen * Math.sin(angle + arrWidth));
    ctx.lineTo(to.x, to.y);
    ctx.fillStyle = color;
    ctx.fill()
    ctx.restore();
  },

  drawGridImage(size, colorA = "#aaa", colorB = "#ddd") {
    let canvas = document.createElement('canvas')
    let ctx = canvas.getCtx('2d')


    canvas.width = size * 100;
    canvas.height = size * 100;

    let mod = size * 5;

    ctx.save();
    ctx.clearRect(0, 0, canvas.width, canvas.height)
    // Drawing vertical lines
    for (let x = 0; x <= canvas.width; x += size) {
      ctx.beginPath();
      ctx.strokeStyle = x % mod === 0 ? colorA : colorB;
      ctx.moveTo(x + 0.5, 0);
      ctx.lineTo(x + 0.5, canvas.height);
      ctx.stroke();
    }

    // Drawing horizontal lines
    for (let y = 0; y <= canvas.height; y += size) {
      ctx.beginPath();
      ctx.strokeStyle = y % mod === 0 ? colorA : colorB;
      ctx.moveTo(0, y + 0.5);
      ctx.lineTo(canvas.width, y + 0.5);
      ctx.stroke();
    }
    ctx.restore();

    return canvas.toDataURL("image/png");
  },

  tintImage(image, color, opacity = 0.5) {
    const buffer = document.createElement('canvas');
    buffer.width = image.width;
    buffer.height = image.height;
    const bufferContext = buffer.getContext('2d');

    bufferContext.save();
    bufferContext.fillStyle = color;
    bufferContext.globalAlpha = opacity;
    bufferContext.fillRect(0, 0, bufferContext.canvas.width, bufferContext.canvas.height);
    bufferContext.globalCompositeOperation = "destination-atop";
    bufferContext.globalAlpha = 1;
    bufferContext.drawImage(image, 0, 0);
    bufferContext.restore();

    return bufferContext.canvas;
  },
}
