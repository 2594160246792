import Vue from 'vue';
import { StripePlugin } from '@vue-stripe/vue-stripe';

const options = {
  pk: process.env.VUE_STRIPE_PUBLIC_KEY,
  locale: 'en'
};

try {
  Vue.use(StripePlugin, options);
} catch (error) {
  console.error('Unable to load Stripe plugin', error)
}