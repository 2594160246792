<template>
  <el-dialog
    :visible="visible"
    show-close
    @close="onClose"
    v-loading="loading"
    :close-on-click-modal="false"
    :append-to-body="true"
  >
    <span slot="title">
      <h2>
        {{
          $t(
            formatBase._id
              ? "admin.format.editTitle"
              : "admin.format.createTitle"
          )
        }}
        <el-tag size="small" style="float: right; margin-right: 2rem">{{
          orientation
        }}</el-tag>
      </h2>
    </span>

    <el-row class="modal-body-content">
      <el-form label-width="150px">
        <el-row :gutter="100" class="mt-1">
          <el-col :span="12">
            <el-form-item label="Name">
              <el-input
                :value="name"
                @input="updateFieldBase({ key: 'name', value: $event })"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-divider content-position="left">
          <small>{{ $t("layout.format_edit.page_dimensions") }}</small>
        </el-divider>

        <el-row :gutter="100">
          <el-col :span="12">
            <el-form-item label="Width">
              <el-input-number v-model="pageWidth"></el-input-number>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="Height">
              <el-input-number v-model="pageHeight"></el-input-number>
            </el-form-item>
          </el-col>
        </el-row>

        <el-divider content-position="left">
          <small>Tooltip description</small>
        </el-divider>

        <el-row :gutter="100" v-for="lang in existingLanguages" :key="lang">
          <el-col :span="18">
            <el-form-item :label="lang.toUpperCase()">
              <el-input
                :value="description[lang]"
                @input="updateDescription(lang, $event)"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-divider content-position="left">
          <small>Print products</small>
        </el-divider>

        <el-row :gutter="100">
          <el-col :span="24">
            <el-form-item label="Add new product :">
              <el-col :span="24">
                <el-input
                  v-model="newPrintProduct.productUid"
                  placeholder="product uid"
                ></el-input>
              </el-col>
              <el-col :span="7">
                <el-select
                  v-model="newPrintProduct.type"
                  placeholder="product type"
                >
                  <el-option label="Glued (>=56pages)" value="glued">
                  </el-option>
                  <el-option label="Stitched (<=52pages)" value="stitched">
                  </el-option>
                </el-select>
              </el-col>
              <el-col :span="7">
                <el-select v-model="newPrintProduct.printerKey">
                  <el-option label="Gelato" value="gelato"> </el-option>
                </el-select>
              </el-col>
              <el-col :span="3">
                <el-button @click="addNewPrintProduct">ADD</el-button>
              </el-col>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="100">
          <el-col :span="24">
            <el-form-item label="Existing products :">
              <el-row
                v-for="product in formatBase.printProducts"
                :key="product.productUid"
              >
                <div>
                  - Type : {{ product.printerKey }} / {{ product.type }}
                </div>
                <div class="ml-1">
                  {{ product.productUid }}
                  <el-button
                    size="mini"
                    type="danger"
                    plain
                    style="float: right"
                    @click="removePrintProduct(product)"
                    >remove</el-button
                  >
                </div>
              </el-row>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-row>

    <span slot="footer" class="dialog-footer">
      <el-button
        @click="onClose"
        v-t="'public.alert.cancel'"
        class="mt-1"
      ></el-button>
      <el-button
        type="primary"
        @click="onSaveFormat"
        class="mt-1"
        v-t="'layout.format_edit.save'"
      ></el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "AddOrEditFormatModal",
  components: {},

  data() {
    return {
      visible: false,
      loading: false,
      newPrintProduct: {
        productUid: null,
        type: null,
        printerKey: "gelato",
      },
    };
  },
  props: {},

  computed: {
    ...mapState("format", ["formatBase"]),
    ...mapState("app", ["existingLanguages"]),

    name: {
      get() {
        return this.formatBase.name;
      },
      set(val) {
        this.updateFieldBase({ key: "name", value: val });
      },
    },

    description: {
      get() {
        return this.formatBase.description;
      },
      set(val) {
        //this.updateFieldBase({ key: "description", value: val });
      },
    },

    pageWidth: {
      get() {
        return this.formatBase.pageWidth;
      },
      set(val) {
        this.updateFieldBase({ key: "pageWidth", value: val });
        this.updateOrientationBase();
      },
    },

    pageHeight: {
      get() {
        return this.formatBase.pageHeight;
      },
      set(val) {
        this.updateFieldBase({ key: "pageHeight", value: val });
        this.updateOrientationBase();
      },
    },

    orientation: {
      get() {
        return this.formatBase.orientation;
      },
      set(val) {
        this.updateFieldBase({ key: "orientation", value: val });
      },
    },
  },
  methods: {
    ...mapActions({
      loadFormatBase: "format/LOAD_FORMAT_BASE",
      saveFormatBase: "format/SAVE_FORMAT_BASE",
    }),
    ...mapMutations({
      updateOrientationBase: "format/REFRESH_ORIENTATION_BASE",
      updateFieldBase: "format/UPDATE_FIELD_BASE",
      updateFormatArrayBase: "format/UPDATE_ARRAY_BASE",
      resetFormatBase: "format/RESET_FORMAT_BASE",
    }),

    showEdit(formatId) {
      this.visible = true;
      this.loading = true;
      this.loadFormatBase(formatId).then((data) => {
        console.log(
          "Format Base loaded",
          JSON.parse(JSON.stringify(this.formatBase))
        );
        this.loading = false;
      });
    },
    showCreate() {
      this.resetFormatBase();
      this.visible = true;
    },
    onSaveFormat() {
      this.saveFormatBase()
        .then((success) => {
          if (success) {
            this.onClose();
          }
        })
        .catch((err) => console.error(err.stack));
    },
    onClose() {
      if (this.visible) {
        this.visible = false;
        this.$emit("close", this.formatBase._id);
        this.resetFormatBase();
      }
    },
    updateDescription(lang, value) {
      let newDescription = { ...this.description };
      newDescription[lang] = value;
      this.updateFieldBase({ key: "description", value: newDescription });
    },
    addNewPrintProduct() {
      if (!this.newPrintProduct.productUid || !this.newPrintProduct.type) {
        this.$messageError(
          "You must set a product uid and select a product type"
        );
      } else {
        this.updateFormatArrayBase({
          key: "printProducts",
          value: { ...this.newPrintProduct },
        });

        this.newPrintProduct.productUid = null;
        this.newPrintProduct.type = null;
        this.newPrintProduct.printerKey = "gelato";
      }
    },
    removePrintProduct(productToRemove) {
      this.updateFormatArrayBase({
        delete: true,
        key: "printProducts",
        cb: (product) => {
          return product.productUid === productToRemove.productUid;
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-body-content {
  overflow: hidden;
}
.toogle-display-countries {
  cursor: pointer;
}
</style>