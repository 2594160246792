import { fabric } from 'fabric';

export default () => {
  fabric.util.object.extend(fabric.MzCanvas.prototype, /** @lends fabric.MzCanvas.prototype */ {
    spaceIsDown: false,
    keyboardListenersEnabled: false,

    initKeyboardControl: function () {
      // bind event handlers so "this" scope wille be this class, not event target
      this.onKeyDown = this.onKeyDown.bind(this);
      this.onKeyUp = this.onKeyUp.bind(this);
      this.initKeyboardListeners = this.initKeyboardListeners.bind(this);
      this.removeKeyboardListeners = this.removeKeyboardListeners.bind(this);

      fabric.util.addListener(this.wrapperEl, 'mouseenter', this.initKeyboardListeners);
      fabric.util.addListener(this.wrapperEl, 'mouseleave', this.removeKeyboardListeners);

      // remove keyboard capture when editing text
      this.on("text:editing:entered", this.removeKeyboardListeners);
      this.on("text:editing:exited", this.initKeyboardListeners);
    },

    disableKeyboardControl: function () {
      fabric.util.removeListener(this.wrapperEl, 'mouseenter', this.initKeyboardListeners);
      fabric.util.removeListener(this.wrapperEl, 'mouseleave', this.removeKeyboardListeners);
      this.off("text:editing:entered", this.removeKeyboardListeners);
      this.off("text:editing:exited", this.initKeyboardListeners);
      this.removeKeyboardListeners();
    },

    initKeyboardListeners: function () {
      if (this.keyboardListenersEnabled) {
        return;
      }
      fabric.util.addListener(window, 'keydown', this.onKeyDown);
      fabric.util.addListener(window, 'keyup', this.onKeyUp);
      this.keyboardListenersEnabled = true;
    },
    removeKeyboardListeners: function () {
      if (!this.keyboardListenersEnabled) {
        return;
      }
      fabric.util.removeListener(window, 'keydown', this.onKeyDown);
      fabric.util.removeListener(window, 'keyup', this.onKeyUp);
      this.keyboardListenersEnabled = false;
      if (this.spaceIsDown) {
        this.spaceIsDown = false;
        this.setObjectsSelectable(true)
      }
    },

    onKeyUp: function (e) {
      e.preventDefault();
      switch (e.code) {
        case "Space": // rect
          this.spaceIsDown = false;
          this.setObjectsSelectable(true)
          break;

        default:
          break;
      }
    },

    onKeyDown: function (e) {
      e.preventDefault();
      switch (e.key) {
        case 'c':
          if (e.ctrlKey) {
            this.copy();
          }
          break;
        case 'v':
          if (e.ctrlKey) {
            this.paste();
          }
          break;
        case 'x':
          if (e.ctrlKey) {
            this.cut();
          }
          break;
        case 'z':
          if (e.ctrlKey) {
            fabric.history.undo()
          }
          break;
        case 'y':
          if (e.ctrlKey) {
            fabric.history.redo()
          }
          break;
        case 'Delete':
        case 'Backspace':
          this.delete();
          break;
        // Object moving with arrows
        case "ArrowDown":
          this.moveObject(0, e.shiftKey ? 10 : 1);
          break;
        case "ArrowUp":
          this.moveObject(0, e.shiftKey ? -10 : -1);
          break;
        case "ArrowLeft":
          this.moveObject(e.shiftKey ? -10 : -1, 0);
          break;
        case "ArrowRight":
          this.moveObject(e.shiftKey ? 10 : 1, 0);
          break;
        // Object creation
        case "l": // line
          this.drawType = 0;
          this.switchDrawMode(true);
          break;
        case "r": // rect
          this.drawType = 1;
          this.switchDrawMode(true);
          break;
        case " ": // space
          this.spaceIsDown = true;
          this.setObjectsSelectable(false)
          break;

        default:
          break;
      }
    },

  });
};
