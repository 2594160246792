import { fabric } from 'fabric';

export default () => {
  fabric.util.object.extend(
    fabric.Object.prototype,
    /** @lends fabric.Object.prototype */ {
      legendTextBoxId: null,
      legendImageId: null,
      legendTextBox: null,
      legendImage: null,

      initLegend: function (options) {
        this.set('legendTextBoxId', options.legendTextBoxId || null);
        this.set('legendImageId', options.legendImageId || null);
        this.toObjectProps.push('legendTextBoxId', 'legendImageId');
      },

      activateLegend: function () {
        // attach image to textbox when all items are loaded
        if (this.legendTextBoxId) {
          this.setLegendTextBox(this.canvas.getObjectById(this.legendTextBoxId));
        }
        if (this.legendImageId) {
          this.setLegendImage(this.canvas.getObjectById(this.legendImageId));
        }
      },

      setLegendTextBox: function (value) {
        if (value && this.type === 'MzImage' && value.type === 'MzTextbox') {
          this.legendTextBox = value;
          this.legendTextBoxId = value.id;
        } else {
          this.legendTextBox = null;
          this.legendTextBoxId = null;
        }
      },

      setLegendImage: function (value) {
        if (value && this.type === 'MzTextbox' && value.type === 'MzImage') {
          this.legendImage = value;
          this.legendImageId = value.id;
          if (this.blockType.indexOf('Legend') !== 0) {
            this.blockType = 'Legend';
          }
        } else {
          this.legendImage = null;
          this.legendImageId = null;
          if (this.blockType.indexOf('Legend') === 0) {
            this.blockType = 'Body';
          }
        }
      }

    }
  );
};
