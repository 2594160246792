<template>
  <el-row type="flex" align="left">
    <el-button
      v-if="checkPerm('users:invitAdmin')"
      type="primary"
      @click="showInvitAdminUserModal"
      >{{ $t("admin.users.invitNewAdminUsers") }}</el-button
    >

    <InvitAdminUserModal ref="invitAdminUserModal" />
  </el-row>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";

import InvitAdminUserModal from "~/components/user/InvitAdminUserModal";

export default {
  name: "AdminUsersHeader",
  components: { InvitAdminUserModal },

  data() {
    return {};
  },
  computed: {
    ...mapGetters("api", ["checkPerm"]),
  },

  methods: {
    showInvitAdminUserModal() {
      this.$refs.invitAdminUserModal.show();
    },
  },
};
</script>

<style scoped lang="scss">
</style>