import { fabric } from 'fabric';
import Utils from '~/common/utils/misc';

export default () => {
  fabric.util.object.extend(
    fabric.MzTextbox.prototype,
    /** @lends fabric.MzTextbox.prototype */
    {
      maxListIndent: 4,
      listSpaceCharBefore: ' ', // em space	U+2003	8195
      listSpaceCharAfter: ' ', // en space	U+2002	8194
      _bulletPoint: false,
      _defaultBulletPointCharacter: '•',
      bulletPointStyle: {
        fill: "",
        character: ""
      },
      _numericListSign: '.',
      _currentNumericIndex: [0],
      charsListByLines: [], // Map array

      initList: function (options) {
        if (options._bulletPoint !== true) {
          // bugfix previous bulletPoint params
          options._bulletPoint = false;
        }

        this.toObjectProps.push('_bulletPoint', 'bulletPointStyle');
        this._bulletPoint = options._bulletPoint === true;
        if (options.bulletPointStyle) {
          this.bulletPointStyle = options.bulletPointStyle;
        }
      },

      clearLineList: function () {
        this.charsListByLines = [];
        this.clearOffsetsOf('list');
      },

      setLineList: function (lineIndex, wrappedLineIndex) {
        const styleList = this.getStyleListByLine(wrappedLineIndex);
        const listAddChars = this.getListCharsToRender(lineIndex, styleList);

        if (listAddChars !== null) {
          const listGraphemeBox = this._getGraphemeBox(listAddChars, wrappedLineIndex, -1, null, false);
          this.setOffsetAt(wrappedLineIndex, 'list', listGraphemeBox.width);
          this.charsListByLines[wrappedLineIndex] = {
            charsToRender: listAddChars,
            graphemeBox: listGraphemeBox
          };
        }
      },

      getBulletPointCharacter() {
        if (this._bulletPoint && this.bulletPointStyle.character) {
          return this.bulletPointStyle.character;
        } else if (fabric.page.specificMagazineData && fabric.page.specificMagazineData.bulletPointStyle && fabric.page.specificMagazineData.bulletPointStyle.character) {
          return fabric.page.specificMagazineData.bulletPointStyle.character;
        } else {
          return this._defaultBulletPointCharacter;
        }
      },

      getBulletPointStyle() {
        if (this._bulletPoint && this.bulletPointStyle.fill && this.bulletPointStyle.fill.length > 0) {
          return { 'fill': this.bulletPointStyle.fill };
        } else if (fabric.page.specificMagazineData
          && fabric.page.specificMagazineData.bulletPointStyle
          && fabric.page.specificMagazineData.bulletPointStyle.fill
          && fabric.page.specificMagazineData.bulletPointStyle.fill.length > 0) {
          return { 'fill': fabric.page.specificMagazineData.bulletPointStyle.fill };
        } else {
          return null;
        }
      },

      getListCharsToRender: function (lineIndex, list) {
        if (lineIndex === 0) {
          this._currentNumericIndex = [0];
        } else if (list.type !== 'ordered') {
          for (let i = 0; i < this._currentNumericIndex.length; i++) {
            this._currentNumericIndex[i] = 0;
          }
        } else if (list.type === 'ordered') {
          for (let i = list.indent + 1; i < this._currentNumericIndex.length; i++) {
            this._currentNumericIndex[i] = 0;
          }
        }

        if (['ordered', 'bullet'].indexOf(list.type) === -1) {
          return null;
        }

        let insert = null;
        if (list.type === 'ordered') {
          if (this._currentNumericIndex[list.indent] === undefined) {
            this._currentNumericIndex[list.indent] = 0;
          }

          let indexToDisplay = list.listIndex ? list.listIndex + 1 : this._currentNumericIndex[list.indent] + 1;
          insert = this.getNumericGlyph(indexToDisplay, list.indent);
          this._currentNumericIndex[list.indent]++;
        } else if (list.type === 'bullet') {
          insert = this.getBulletPointCharacter();
        }

        //modify inserted chars
        return this.listSpaceCharBefore.repeat(list.indent + 1) + insert + this.listSpaceCharAfter;
      },

      getNumericGlyph: function (num, indent = 0) {
        if (indent % 3 === 0) {
          return num.toString() + this._numericListSign;
        } else if (indent % 3 === 1) {
          return String.fromCharCode(96 + num) + this._numericListSign;
        } else if (indent % 3 === 2) {
          return Utils.convertToRoman(num) + this._numericListSign;
        }
      }

    }
  );

  Object.defineProperty(fabric.MzTextbox.prototype, 'bulletPoint', {
    get: function () {
      return this._bulletPoint;
    },
    set(value) {
      this._bulletPoint = value;
      if (!value) {
        this.bulletPointStyle = {
          fill: "",
          character: ""
        };
      }
      this._forceClearCache = true;
    }
  });
};
