import { fabric } from 'fabric';

export default () => {
  let beforeTransformActionOverridden = fabric.MzCanvas.prototype._beforeTransform;
  let performTransformActionOverridden = fabric.MzCanvas.prototype._performTransformAction;

  /**
   * Add cropX and cropY to transform parameters
   */
  fabric.MzCanvas.prototype._beforeTransform = function (e) {
    this._currentTransform.cropX = this._currentTransform.target.cropX;
    this._currentTransform.cropY = this._currentTransform.target.cropY;
    return beforeTransformActionOverridden.call(this, e);
  };

  // Crop behavior when moving with shift key
  fabric.MzCanvas.prototype.cropTransform = function (target, transform, x, y) {
    const imageSize = target.getOriginalSize();
    let newX = Math.max(0, Math.min(imageSize.width - target.width, transform.cropX - (x - transform.lastX)));
    let newY = Math.max(0, Math.min(imageSize.height - target.height, transform.cropY - (y - transform.lastY)));

    let moveX = target.cropX !== newX;
    let moveY = target.cropY !== newY;

    target.set('cropX', newX);
    target.set('cropY', newY);

    return moveX || moveY;
  };

  /**
   * Override transform action to add custom ones
   * @override
   */
  fabric.MzCanvas.prototype._performTransformAction = function (e, transform, pointer) {
    let x = pointer.x,
      y = pointer.y,
      action = transform.action,
      actionPerformed = false,
      options = {
        target: transform.target,
        e: e,
        transform: transform,
        pointer: pointer
      };

    if (action === 'edit') {
      // Nothing to do here, edit text box action is implemented in onMouseDown event
      return;
    }
    else if (action === 'crop') {
      actionPerformed = this.cropTransform(transform.target, transform, x, y);
      if (actionPerformed) {
        this._fire('cropping', options);
        this.setCursor(options.target.moveCursor || this.moveCursor);
      }
      transform.actionPerformed = transform.actionPerformed || actionPerformed;
    } else {
      return performTransformActionOverridden.call(this, e, transform, pointer);
    }
  };

};
