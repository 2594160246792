import Vue from 'vue';
import packageJson from '~/package.json';

console.log('APP DEBUGGER create');

let appIsLoaded = false,
  pluginsIsLoaded = false,
  nuxtStore;

const toIgnore = [
  'ResizeObserver loop completed with undelivered notifications.'
];

let sendError = async function (from, data) {
  const displayAlert = !appIsLoaded,
    errorMessage = data && data.error && data.error.message ? data.error.message : '',
    dataToSend = {
      from: 'Nuxt ' + from + ' : ' + errorMessage,
      appIsLoaded,
      pluginsIsLoaded,
      ...data

    };

  if (nuxtStore) {
    await nuxtStore.dispatch('api/API_SEND_ERROR', dataToSend);
  } else {

    const version = packageJson.version || 0;

    // send error directly if app and nuxt store is not already set
    await fetch(process.env.VUE_API_URL + "/api/v1/users/sendError", {
      method: "post",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        ...dataToSend,
        appVersion: version
      })
    });
  }

  if (displayAlert) {
    let msg = 'An error occurred while loading external dependencies. Please try again later or contact us.'
    if (errorMessage && errorMessage.length > 0) {
      msg += "\n(" + errorMessage + ")";
    }
    alert(msg);
  }
}

Vue.config.errorHandler = function (error, vm, info) {
  console.error('APP DEBUGGER vue error', { error, vm, info });

  sendError('VueErrorHandler',
    {
      error: error ? {
        message: error.message,
        mzData: error.mzData,
        stack: error.stack
      } : null,
      vm,
      info
    });
}

window.onerror = function (msg, url, line, col, error) {
  if (toIgnore.indexOf(msg) >= 0) {
    console.warn('APP DEBUGGER ignore window error', { msg, url, line, col, error });
    return;
  }

  console.error('APP DEBUGGER window error', { msg, url, line, col, error });

  sendError('WindowError',
    {
      msg,
      error: error ? {
        message: error.message,
        mzData: error.mzData,
        stack: error.stack
      } : null,
      file: {
        url,
        line,
        col,
      }
    });


}

// window.addEventListener('unhandledrejection', function (event) {
//   console.log('window unhandledrejection', event);
// });

export default function ({ app, store }) {
  console.log('APP DEBUGGER start', app);
  pluginsIsLoaded = true;
  nuxtStore = store;

  setTimeout(() => {
    appIsLoaded = true;
  }, 1000);
}