import { fabric } from 'fabric';
import MzMixins from './mixins/MzCommonMixins';

export default () => {
  fabric.MzShapeGroup = fabric.util.createClass(fabric.Group, {
    type: 'MzShapeGroup',
    _fill: null,
    _stroke: null,
    _strokeWidth: 0,
    _strokeDashArray: null,

    variantGroup: null,
    _isFullTransparentCache: null,

    initialize: function (objects, options) {
      // inject Mz common properties
      Object.assign(this, MzMixins);
      options = this.initMzObject(options);

      this.toObjectProps.push('sourcePath');

      this.sourcePath = options.sourcePath || '';
      this.variantGroup = options.variantGroup || null;

      objects.forEach(obj => {
        obj.strokeUniform = false;
        if (!obj.backup) {
          obj.backup = {};
          obj.backup.fill = obj.fill;
          obj.backup.stroke = obj.stroke;
          obj.backup.strokeWidth = obj.strokeWidth;
          obj.backup.strokeDashArray = obj.strokeDashArray;
        }
      })

      this.callSuper('initialize', objects, options);
    },

    setChildProp: function (prop, value) {
      if (value) {
        this._objects.forEach(obj => {
          obj.set(prop, value)
        })
      } else {
        //restore value
        this._objects.forEach(obj => {
          obj.set(prop, obj.backup[prop]);
        })
      }
    },

    updateFromSvgUrl: function (url) {

      // remove previous content
      const objects = this.getObjects();
      for (let i = 0; i < objects.length; i++) {
        this.remove(objects[i]);
      }

      // update sourcePath
      this.sourcePath = url;

      this._isFullTransparentCache = null;

      return new Promise((resolve, reject) => {
        // load svg
        fabric.loadSVGFromURL(url, (objects, options) => {

          var newShapeGroup = fabric.MzShapeGroup.fromSVG(objects, options);
          newShapeGroup.forEachObject((object) => {
            if (this.fill) object.fill = this.fill;
            if (this.stroke) object.stroke = this.stroke;
            if (this.strokeWidth) object.strokeWidth = this.strokeWidth;
            if (this.strokeDashArray) object.strokeDashArray = this.strokeDashArray;

            object.strokeUniform = false;
            if (!object.backup) {
              object.backup = {};
              object.backup.fill = object.fill;
              object.backup.stroke = object.stroke;
              object.backup.strokeWidth = object.strokeWidth;
              object.backup.strokeDashArray = object.strokeDashArray;
            }
            this.add(object);
          });

          // update MzShapeGroup size and scale depending on new svg options size
          const originalWidth = this.width,
            originalHeight = this.height,
            originalScaleX = this.scaleX,
            originalScaleY = this.scaleY;
          this.width = options.width;
          this.height = options.height;
          this.scaleX = originalScaleX / (options.width / originalWidth);
          this.scaleY = originalScaleY / (options.height / originalHeight);

          //this.add(newShapeGroup);
          this.canvas.requestRenderAll();

          resolve();
        });
      })

    },

    isFullTransparent() {
      if (this._isFullTransparentCache !== null) {
        return this._isFullTransparentCache;
      }

      if (!this._objects || this._objects.length === 0) {
        return true;
      }

      if (this.opacity === 0) {
        return true;
      }

      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      context.width = this.width;
      context.height = this.height;

      for (var i = 0, len = this._objects.length; i < len; i++) {
        this._objects[i]._render(context);
      }

      let isTransparent = true;
      const data = context.getImageData(0, 0, canvas.width, canvas.height).data;
      for (let i = 3; i < data.length; i += 4) {
        if (data[i] > 0) {
          isTransparent = false;
          break; // Stop if color found
        }
      }

      this._isFullTransparentCache = isTransparent;
      return isTransparent;
    },

  });

  /**
     * Groups SVG elements (usually those retrieved from SVG document)
     * @static
     * @memberOf fabric.util
     * @param {Array} elements SVG elements to group
     * @param {Object} [options] Options object
     * @param {String} path Value to set sourcePath to
     * @return {fabric.Object|fabric.Group}
     */
  fabric.MzShapeGroup.fromSVG = function (elements, options, path) {
    var object;
    // if (elements && elements.length === 1) {
    //   return elements[0];
    // }
    if (options) {
      if (options.width && options.height) {
        options.centerPoint = {
          x: options.width / 2,
          y: options.height / 2
        };
      }
      else {
        delete options.width;
        delete options.height;
      }
    }
    object = new fabric.MzShapeGroup(elements, options);
    if (typeof path !== 'undefined') {
      object.sourcePath = path;
    }
    return object;
  };

  fabric.MzShapeGroup.fromSVGUrl = function (url, callback) {
    fabric.loadSVGFromURL(url, (objects, options) => {
      callback && callback(fabric.MzShapeGroup.fromSVG(objects, options, url));
    });
  }

  /**
   * Returns {@link fabric.Group} instance from an object representation
   * @static
   * @memberOf fabric.Group
   * @param {Object} object Object to create a group from
   * @param {Function} [callback] Callback to invoke when an group instance is created
   */
  fabric.MzShapeGroup.fromObject = function (object, callback) {
    fabric.util.enlivenObjects(object.objects, function (enlivenedObjects) {
      fabric.util.enlivenObjects([object.clipPath], function (enlivedClipPath) {
        var options = fabric.util.object.clone(object, true);
        options.clipPath = enlivedClipPath[0];
        delete options.objects;
        callback && callback(new fabric.MzShapeGroup(enlivenedObjects, options, true));
      });
    });
  };

  Object.defineProperty(fabric.MzShapeGroup.prototype, 'fill', {
    get: function () { return this._fill; },
    set(value) {
      this._fill = value;
      this.setChildProp('fill', value)
    }
  });
  Object.defineProperty(fabric.MzShapeGroup.prototype, 'stroke', {
    get: function () { return this._stroke; },
    set(value) {
      this._stroke = value;
      this.setChildProp('stroke', value)
    }
  });
  Object.defineProperty(fabric.MzShapeGroup.prototype, 'strokeWidth', {
    get: function () { return this._strokeWidth; },
    set(value) {
      this._strokeWidth = value;
      this.setChildProp('strokeWidth', value)
    }
  });
  Object.defineProperty(fabric.MzShapeGroup.prototype, 'strokeDashArray', {
    get: function () { return this._strokeDashArray; },
    set(value) {
      this._strokeDashArray = value;
      this.setChildProp('strokeDashArray', value)
    }
  });

};
