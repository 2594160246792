import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import Utils from '~/common/utils/store';
/**
 * Page store
 *
 * register methods in your components with import { mapGetters, mapActions, mapState, mapMutations } from "vuex";
 *
 * pick only the method you need
 *
   methods :{
  ...mapActions({
      loadPage: "page/LOAD_PAGE",
      loadPages:"page/LOAD_PAGES",
      savePage: "page/SAVE_PAGE",
      savePageField: "page/SAVE_PAGE_FIELD",
      deletePage: "page/DELETE_PAGE",
      loadAvailableTags: "page/LOAD_AVAILABLE_TAGS",
      insertCroppedImageToPage: "page/INSERT_CROPPED_IMAGE_TO_PAGE",
      generatePageThumb: "page/GENERATE_PAGE_THUMB",
      updatePageDataObjects: "page/UPDATE_PAGE_DATA_OBJECTS",
    }),
    ...mapMutations({
      updatePageField: "page/UPDATE_FIELD",
      updatePageRootField: "page/UPDATE_ROOT_FIELD",
      updatePageArray: "page/UPDATE_ARRAY",
      swapPageArray: "page/SWAP_ARRAY",
      updatePageRootArray: "page/UPDATE_ROOT_ARRAY",
      setCurrentPage:"page/SET_CURRENT_PAGE",
      setPages:"page/SET_PAGES",
      addPage:"page/ADD_PAGE",
      updatePage:"page/UPDATE_PAGE",
      removePage:"page/REMOVE_PAGE",
      resetPage:"page/RESET_PAGE",
      resetStatePage:"page/RESET_STATE",
      updatePageRedactorVariable: "page/UPDATE_REDACTOR_VARIABLE",
    }),
    },

      computed: {
    ...mapState("page", ["pages","page","pageTagIndex"]),
    ...mapGetters("page", ["getDefaultPage","getPages"]),
      }
 */
/**
 * return default page object
 */
function initialState() {
  return {
    _id: null,
    name: '',
    magazine: null, // references model Magazine
    deleted: false,
    locked: false,
    tags: [],
    pageData: null,
    isPaletteDependant: [],
    palette: null,
    double: false,
    redactorVariables: []
  };
}

function _formatPageForList(page) {
  // TODO : set/update specific field to set in Pages list if needed
  return page;
}

const getDefaultState = () => {
  return {
    pages: [],
    page: initialState(),
    pageTagIndex: []
  };
};

// initial state
const state = getDefaultState;

// getters are functions
const getters = {
  /**
   * return collection pages
   */
  getPages: state => state.pages,

  /**
   * return defaut state of page object
   */
  getDefaultPage: state => initialState()
};
// mutations
const mutations = {
  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {Object} pages
   *
   * set collections data pages
   */
  SET_PAGES: (state, pages) => {
    state.pages = pages;
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {payload} payload - https://vuex.vuejs.org/guide/mutations.html#commit-with-payload
   *
   * add page object in collection from payload.page parameter
   */
  ADD_PAGE: (state, payload) => {
    state.pages.push(_formatPageForList(payload.page));
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {payload} payload - https://vuex.vuejs.org/guide/mutations.html#commit-with-payload
   *
   * update page object in collection from payload.page parameter
   */
  UPDATE_PAGE: (state, payload) => {
    const _index = state.pages.findIndex(page => page._id === payload.page._id);
    if (_index >= 0) {
      Object.assign(state.pages[_index], _formatPageForList(payload.page));
    }
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {String} pageID
   *
   * remove page object from collection with pageID
   */
  REMOVE_PAGE: (state, pageID) => {
    const _index = state.pages.findIndex(page => page._id === pageID);
    if (_index >= 0) {
      state.pages.splice(_index, 1);
    }
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {String} pageID
   *
   * set current page object from collection with pageID
   */
  SET_CURRENT_PAGE: (state, pageID) => {
    if (pageID === null) {
      state.page = Object.assign({}, initialState());
    } else {
      state.page = Object.assign({}, state.page, state.pages.find(page => page._id == pageID));
    }
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {payload} payload - https://vuex.vuejs.org/guide/mutations.html#commit-with-payload
   *
   * update one field of state.page
   */
  UPDATE_FIELD: (state, { key, value }) => {
    Utils.setProperty(key, state.page, value);
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {payload} payload - https://vuex.vuejs.org/guide/mutations.html#commit-with-payload
   *
   * update one field of state
   */
  UPDATE_ROOT_FIELD: (state, { key, value }) => {
    Utils.setProperty(key, state, value);
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {payload} payload - https://vuex.vuejs.org/guide/mutations.html#commit-with-payload
   *
   * update one array field of state.page
   */
  UPDATE_ARRAY: (state, payload) => {
    if (payload.delete === true) {
      const _index = payload.cb
        ? state.page[payload.key].findIndex(payload.cb)
        : state.page[payload.key].indexOf(payload.value);
      if (_index >= 0) {
        state.page[payload.key].splice(_index, 1);
      }
    } else {
      state.page[payload.key].push(payload.value);
    }
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {payload} payload - https://vuex.vuejs.org/guide/mutations.html#commit-with-payload
   *
   * swap two elements of state.page
   */
  SWAP_ARRAY: (state, payload) => {
    let oldVal = state.page[payload.key][payload.from];
    state.page[payload.key][payload.from] = state.page[payload.key][payload.to];
    Vue.set(state.page[payload.key], payload.to, oldVal);
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {payload} payload - https://vuex.vuejs.org/guide/mutations.html#commit-with-payload
   *
   * update one array field of state
   */
  UPDATE_ROOT_ARRAY: (state, payload) => {
    if (payload.delete === true) {
      const _index = payload.cb ? state[payload.key].findIndex(payload.cb) : state[payload.key].indexOf(payload.value);
      if (_index >= 0) {
        state[payload.key].splice(_index, 1);
      }
    } else {
      state[payload.key].push(payload.value);
    }
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   *
   * reset state.page
   */
  RESET_PAGE: state => {
    state.page = Object.assign({}, initialState());
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   *
   * reset complete state to default value
   */
  RESET_STATE: state => {
    Object.assign(state, getDefaultState());
  },

  UPDATE_REDACTOR_VARIABLE: (state, { key, value }) => {
    const variableDataIndex = state.page.redactorVariables.findIndex(variableData => variableData.key === key);
    if (variableDataIndex >= 0) {
      if (value && value.trim().length > 0) {
        state.page.redactorVariables[variableDataIndex].value = value;
      } else {
        state.page.redactorVariables.splice(variableDataIndex, 1);
      }
    } else {
      if (value && value.trim().length > 0) {
        state.page.redactorVariables.push(
          {
            key,
            value
          }
        );
      }
    }
  },
};

// actions are functions that cause side effects and can involve
// asynchronous operations.
const actions = {
  /**
   * fetch collection of pages
   * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
   * @param {String} query - see more https://github.com/loris/api-query-params
   * @return Promise
   */
  LOAD_PAGES: async function (context, query = '') {

    return this.$axios
      .get('/api/v1/page' + query)
      .then(r => r.data)
      .then(
        ({ pages }) => {
          this.commit('page/SET_PAGES', pages);
          this.dispatch('api/API_SUCCESS', { type: 'info', message: 'PAGES are loaded' });

          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);

          return false;
        }
      );
  },

  /**
   * fetch current page object
   * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
   * @param {String} pageID
   * @param {String} query -see populate
   * @return Promise
   */
  LOAD_PAGE: async function (context, payload) {
    // don't reload page if already in state
    if (payload === context.state.page._id) {
      console.log('LOAD_PAGE already in state - ' + payload);
      return Promise.resolve(true);
    }

    return this.$axios
      .get('/api/v1/page/' + Utils.normalizePayloadURI(payload, 'pageId'))
      .then(r => r.data)
      .then(
        page => {
          this.commit('page/UPDATE_FIELD', { key: 'name', value: page.name });
          this.commit('page/UPDATE_FIELD', { key: 'magazine', value: page.magazine });
          this.commit('page/UPDATE_FIELD', { key: 'deleted', value: page.deleted });
          this.commit('page/UPDATE_FIELD', { key: 'locked', value: page.locked });
          this.commit('page/UPDATE_FIELD', { key: 'tags', value: page.tags });
          this.commit('page/UPDATE_FIELD', { key: 'pageData', value: JSON.parse(page.dt) });
          this.commit('page/UPDATE_FIELD', { key: 'isPaletteDependant', value: page.isPaletteDependant });
          this.commit('page/UPDATE_FIELD', { key: 'palette', value: page.palette });
          this.commit('page/UPDATE_FIELD', { key: 'double', value: page.double });
          this.commit('page/UPDATE_FIELD', { key: 'redactorVariables', value: page.redactorVariables });

          this.commit('page/UPDATE_FIELD', { key: '_id', value: page._id });
          this.dispatch('api/API_SUCCESS', { type: 'info', message: 'page is loaded' });

          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);
          return false;
        }
      );
  },

  /**
   * upsert current page object
   * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
   * @param {Object} payload
   * @return Promise
   */
  SAVE_PAGE: function (context, payload) {
    let method = context.state.page._id === null ? 'post' : 'put';
    let updateId = context.state.page._id === null ? '' : context.state.page._id;

    return this.$axios[method]('/api/v1/page/' + updateId, context.state.page)
      .then(r => r.data)
      .then(
        ({ page, magazineLastVisualChangeAt }) => {
          this.commit('page/UPDATE_FIELD', { key: '_id', value: page._id });

          this.commit('page/UPDATE_FIELD', { key: 'name', value: page.name });
          this.commit('page/UPDATE_FIELD', { key: 'magazine', value: page.magazine });
          this.commit('page/UPDATE_FIELD', { key: 'deleted', value: page.deleted });
          this.commit('page/UPDATE_FIELD', { key: 'locked', value: page.locked });
          this.commit('page/UPDATE_FIELD', { key: 'tags', value: page.tags });
          this.commit('page/UPDATE_FIELD', { key: 'pageData', value: JSON.parse(page.dt) });
          this.commit('page/UPDATE_FIELD', { key: 'isPaletteDependant', value: page.isPaletteDependant });
          this.commit('page/UPDATE_FIELD', { key: 'palette', value: page.palette });
          this.commit('page/UPDATE_FIELD', { key: 'double', value: page.double });
          this.commit('page/UPDATE_FIELD', { key: 'redactorVariables', value: page.redactorVariables });

          if (updateId.length === 0) {
            this.commit('page/ADD_PAGE', { page });
          } else {
            this.commit('page/UPDATE_PAGE', { page });

            // update magazine.pageOrder with new page data
            this.commit('magazine/UPDATE_CURRENT_PAGE_ORDER', {
              pageId: page._id,
              value: page
            });
          }

          // update magazine lastVisualChange
          this.commit('magazine/UPDATE_FIELD', { key: 'lastVisualChangeAt', value: magazineLastVisualChangeAt });

          this.dispatch('api/API_SUCCESS', { type: 'info', message: 'page is saved' });

          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);
          return false;
        }
      );
  },

  /**
   * update current page.pageData object
   * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
   * @param {Object} payload
   * @return Promise
   */
  UPDATE_PAGE_DATA_OBJECTS: function (context, { objects }) {
    //console.log('UPDATE_PAGE_DATA_OBJECTS result', JSON.parse(JSON.stringify(objects)));
    return this.$axios.put('/api/v1/page/' + context.state.page._id + '/pageData', { objects })
      .then(r => r.data)
      .then(
        ({ page, magazineLastVisualChangeAt }) => {
          //console.log('UPDATE_PAGE_DATA_OBJECTS result', JSON.parse(JSON.stringify(page)));

          this.commit('page/UPDATE_FIELD', { key: 'pageData', value: JSON.parse(page.dt) });
          this.commit('page/UPDATE_PAGE', { page: context.state.page });

          // update magazine lastVisualChange
          this.commit('magazine/UPDATE_FIELD', { key: 'lastVisualChangeAt', value: magazineLastVisualChangeAt });

          this.dispatch('api/API_SUCCESS', { type: 'info', message: 'page is saved' });

          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);
          return false;
        }
      );
  },

  /**
   * delete current page object
   * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
   * @param {Object} payload
   * @return Promise
   */
  DELETE_PAGE: function (context, payload) {
    let id = payload && payload.id ? payload.id : context.state.page._id;
    return this.$axios
      .delete('/api/v1/page/' + id)
      .then(r => r.data)
      .then(
        page => {
          this.commit('page/REMOVE_PAGE', id);
          this.dispatch('api/API_SUCCESS', { type: 'info', message: 'page has been deleted' });

          this.commit('page/RESET_PAGE');
          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);
          return false;
        }
      );
  },

  /**
   * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
   * @param {payload} payload - https://vuex.vuejs.org/guide/mutations.html#commit-with-payload
   *
   * update specific field on state.magazine.pageOrder and state.page if needed and send save to server
   */
  SAVE_PAGE_FIELD: function (context, { pageId, fields }) {
    // send save page to server
    return this.$axios
      .put('/api/v1/page/' + pageId + '/fields', fields)
      .then(r => r.data)
      .then(
        ({ page }) => {
          // update state.page
          if (context.state.page._id === pageId) {
            for (const key of Object.keys(fields)) {
              this.commit('page/UPDATE_FIELD', { key: key, value: page[key] });
            }
          }

          this.commit('page/UPDATE_PAGE', { page });

          // update magazine.pageOrder with new page data
          this.commit('magazine/UPDATE_CURRENT_PAGE_ORDER', {
            pageId: page._id,
            value: page
          });

          this.dispatch('api/API_SUCCESS', { type: 'info', message: 'page is saved' });

          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);
          return false;
        }
      );
  },

  /**
   * fetch all distinct tags present in subdocuments
   * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
   * @param {String} magazineId
   * @param {String query -see populate
   * @return Promise
   */
  LOAD_AVAILABLE_TAGS: async function (context, magazineId) {
    return this.$axios
      .get('/api/v1/page/allBy/tags?q[magazine]=' + magazineId)
      .then(r => r.data)
      .then(
        tag => {
          this.commit('page/UPDATE_ROOT_FIELD', {
            key: 'pageTagIndex',
            value: tag
          });

          return true;
        },
        err => {
          console.log(err);
          return false;
        }
      );
  },

  /**
     * fetch collection of pages
     * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
     * @param {Object} data
     *  data = {
            imgKey,
            imgID,
            libraryID,
            pageID,
            cropRect,
            scale,
            transform
          };
     * @return Promise
     */
  INSERT_CROPPED_IMAGE_TO_PAGE: async function (context, data) {
    if (data.cropRect) {
      data.cropRect.left = Math.round(data.cropRect.left);
      data.cropRect.top = Math.round(data.cropRect.top);
      data.cropRect.width = Math.round(data.cropRect.width);
      data.cropRect.height = Math.round(data.cropRect.height);
    }
    return this.$axios
      .post('/api/v1/image/insertToPage', data)
      .then(r => r.data)
      .then(
        cropped_ => {
          return cropped_;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);
          return null;
        }
      );
  },

  /**
   * fetch collection of pages
   * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
   * @param {Object}
   *          pageId - currenat page Id used to generate
   *          paletteId - current palette Id used to generate
   *          svgData  - this.$fabric.page.getSvg()
   *          thumbData  - this.$fabric.page.getThumb()
   * @return Promise
   */
  GENERATE_PAGE_THUMB: async function (context, { pageId, paletteId, isDefaultPalette, svgData, thumbData }) {
    console.log('GENERATE_PAGE_THUMB');

    return this.$axios
      .post('/api/v1/page/thumb/request', {
        pageId,
        paletteId,
        isDefaultPalette,
        svgData: svgData ? Buffer.from(svgData).toString('base64') : undefined,
        thumbData: thumbData
      })
      .then(res => {
        //console.log('page thumb uploaded', res);
      })
      .catch(function (error) {
        console.log(error);
      });
  }
};

let store = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default store;
