import Vue from "vue";
import Vuex from "vuex";

import Countries from "~/common/data/Countries";

Vue.use(Vuex);

import Utils from "~/common/utils/store";
/**
 * Order store
 *
 * register methods in your components with import { mapGetters, mapActions, mapState, mapMutations } from "vuex";
 *
 * pick only the method you need
 *
   methods :{
  ...mapActions({
      loadOrder: "order/LOAD_ORDER",
      loadUserOrders:"order/LOAD_USER_ORDERS",
      loadOrders:"order/LOAD_ORDERS",
      countOrders:"order/COUNT_ORDERS",
      retrieveCheckoutSession: "order/RETRIEVE_CHECKOUT_SESSION",
      refreshOrderStatus: "order/REFRESH_ORDER_STATUS",
      requestPrintProductQuote: "order/REQUEST_PRINT_PRODUCT_QUOTE",
      addQuoteToCart: "order/ADD_QUOTE_TO_CART",
      loadCurrentCart: "order/LOAD_CURRENT_CART",
      updateCurrentCart: "order/UPDATE_CURRENT_CART",
      confirmCurrentCartAddress: "order/CONFIRM_CURRENT_CART_ADDRESS"
    }),
    ...mapMutations({
      updateOrderField: "order/UPDATE_FIELD",
      updateOrderRootField: "order/UPDATE_ROOT_FIELD",
      updateOrderArray: "order/UPDATE_ARRAY",
      swapOrderArray: "order/SWAP_ARRAY",
      updateOrderRootArray: "order/UPDATE_ROOT_ARRAY",
      setCurrentOrder:"order/SET_CURRENT_ORDER",
      setOrders:"order/SET_ORDERS",
      addOrder:"order/ADD_ORDER",
      updateOrder:"order/UPDATE_ORDER",
      removeOrder:"order/REMOVE_ORDER",
      resetOrder:"order/RESET_ORDER",
      resetPrintQuote:"order/RESET_PRINT_QUOTE",
      resetStateOrder:"order/RESET_STATE"
    }),
    },

      computed: {
    ...mapState("order", ["orders","order","printQuote"]),
    ...mapGetters("order", ["getDefaultOrder","getOrders", "getAvailableCountries", "getAvailableQuantities"]),
      }
 */
/**
 * return default order object
 */
function initialState() {
  return {
    _id: null,
    orderReferenceId: null,
    organization: null,// references model Organization
    status: '',// values  in [waiting, paid, sended]
    deleted: false,
    price: null,
    currency: 'EUR',
    error: '',// values  in [invalid_currency, invalid_price, invalid_magazine, generate_pdf, gelato_get_quote, gelato_get_shipment, gelato_get_product, gelato_invalid_price, gelato_invalid_order]
    shippingAddress: null,
    printProducts: [],
    offerProducts: []
  }
}

function _formatOrderForList(order) {
  // TODO : set/update specific field to set in Orders list if needed
  return order;
}

const getDefaultState = () => {
  return {
    orders: [],
    ordersCount: null,
    order: initialState(),
    printQuote: null,
    currentCart: initialState()
  }
}

// initial state
const state = getDefaultState;

// getters are functions
const getters = {
  /**
   * return collection orders
   */
  getOrders: state => state.orders,

  /**
   * return defaut state of order object
   */
  getDefaultOrder: state => initialState(),

  getAvailableCountries() {
    return Object.values(Countries)
      .map((country) => {
        return {
          code: country.alpha2Code,
          name: country.name,
        };
      })
      .sort((country1, country2) =>
        country1.name.localeCompare(country2.name)
      );
  },

  getAvailableQuantities() {
    return [
      1, 5, 10, 15, 20, 30, 40, 50, 100, 150, 200, 250, 300, 350, 400, 450,
      500, 550, 600, 650, 700, 750, 800, 850, 900, 950, 1000,
    ];
  },
};
// mutations
const mutations = {
  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {Object} orders
   *
   * set collections data orders
   */
  SET_ORDERS: (state, orders) => {
    state.orders = orders;
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {payload} payload - https://vuex.vuejs.org/guide/mutations.html#commit-with-payload
   *
   * add order object in collection from payload.order parameter
   */
  ADD_ORDER: (state, payload) => {
    state.orders.push(_formatOrderForList(payload.order));
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {payload} payload - https://vuex.vuejs.org/guide/mutations.html#commit-with-payload
   *
   * update order object in collection from payload.order parameter
   */
  UPDATE_ORDER: (state, payload) => {
    const _index = state.orders.findIndex(order => order._id === payload.order._id);
    if (_index >= 0) {
      Object.assign(state.orders[_index], _formatOrderForList(payload.order));
    }
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {String} orderID
   *
   * remove order object from collection with orderID
   */
  REMOVE_ORDER: (state, orderID) => {
    const _index = state.orders.findIndex(order => order._id === orderID);
    if (_index >= 0) {
      state.orders.splice(_index, 1);
    }
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {String} orderID
   *
   * set current order object from collection with orderID
   */
  SET_CURRENT_ORDER: (state, orderID) => {
    if (orderID === null) {
      state.order = Object.assign({}, initialState());
    }
    else {
      state.order = Object.assign(
        {},
        state.order,
        state.orders.find(order => order._id == orderID)
      );
    }
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {payload} payload - https://vuex.vuejs.org/guide/mutations.html#commit-with-payload
   *
   * update one field of state.order
   */
  UPDATE_FIELD: (state, { key, value }) => {
    Utils.setProperty(key, state.order, value);
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {payload} payload - https://vuex.vuejs.org/guide/mutations.html#commit-with-payload
   *
   * update one field of state
   */
  UPDATE_ROOT_FIELD: (state, { key, value }) => {
    Utils.setProperty(key, state, value);
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {payload} payload - https://vuex.vuejs.org/guide/mutations.html#commit-with-payload
   *
   * update one array field of state.order
   */
  UPDATE_ARRAY: (state, payload) => {
    if (payload.delete === true) {
      const _index = (payload.cb) ? state.order[payload.key].findIndex(payload.cb) : state.order[payload.key].indexOf(payload.value);
      if (_index >= 0) {
        state.order[payload.key].splice(_index, 1);
      }
    }
    else {
      state.order[payload.key].push(payload.value);
    }
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {payload} payload - https://vuex.vuejs.org/guide/mutations.html#commit-with-payload
   *
   * swap two elements of state.order
   */
  SWAP_ARRAY: (state, payload) => {
    let oldVal = state.order[payload.key][payload.from];
    state.order[payload.key][payload.from] = state.order[payload.key][payload.to];
    Vue.set(state.order[payload.key], payload.to, oldVal)
  },

  /**
 * @param {Object} state - https://vuex.vuejs.org/guide/state.html
 * @param {payload} payload - https://vuex.vuejs.org/guide/mutations.html#commit-with-payload
 *
 * update one array field of state
 */
  UPDATE_ROOT_ARRAY: (state, payload) => {
    if (payload.delete === true) {
      const _index = (payload.cb) ? state[payload.key].findIndex(payload.cb) : state[payload.key].indexOf(payload.value);
      if (_index >= 0) {
        state[payload.key].splice(_index, 1);
      }
    }
    else {
      state[payload.key].push(payload.value);
    }
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   *
   * reset state.order
   */
  RESET_ORDER: (state) => {
    state.order = Object.assign({}, initialState());
  },

  /**
  * @param {Object} state - https://vuex.vuejs.org/guide/state.html
  *
  * reset state.printQuote
  */
  RESET_PRINT_QUOTE: (state) => {
    Utils.setProperty('printQuote', state, null);
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   *
   * reset complete state to default value
   */
  RESET_STATE: state => {
    Object.assign(state, getDefaultState());
  }

};

// actions are functions that cause side effects and can involve
// asynchronous operations.
const actions = {
  /**
   * fetch collection of orders
   * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
   * @param {String} query - see more https://github.com/loris/api-query-params
   * @return Promise
   */
  LOAD_USER_ORDERS: async function (context, payload) {
    return this.$axios.get("/api/v1/order/listByUser")
      .then(r => r.data)
      .then(
        orders => {
          this.commit("order/SET_ORDERS", orders);
          this.dispatch('api/API_SUCCESS', { type: 'info', 'message': 'ORDERS are loaded' });

          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);

          return false;
        }
      );
  },

  LOAD_ORDERS: async function (context, payload) {

    return this.$axios
      .get('/api/v1/order/list?filter=' + JSON.stringify(payload.filter) + '&sort=' + payload.sort + '&limit=' + payload.limit + '&skip=' + payload.skip)
      .then(r => r.data)
      .then(
        orders => {
          this.commit("order/SET_ORDERS", orders);
          this.dispatch('api/API_SUCCESS', { type: 'info', message: 'ORDERS are loaded' });
          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);
          return false;
        }
      );
  },

  /**
   * fetch number of orders
   * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
   * @param {String} query - see more https://github.com/loris/api-query-params
   * @return Promise
   */
  COUNT_ORDERS: async function (context, payload) {
    return this.$axios
      .get('/api/v1/order/count?filter=' + JSON.stringify(payload.filter))
      .then(r => r.data)
      .then(
        count => {
          this.commit('order/UPDATE_ROOT_FIELD', {
            key: 'ordersCount',
            value: count
          });
          this.dispatch('api/API_SUCCESS', { type: 'info', message: 'COUNT ORDERS are loaded' });
          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);
          return false;
        }
      );
  },


  /**
   * fetch current order object
   * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
   * @param {String} orderID
   * @param {String} query -see populate
   * @return Promise
   */
  LOAD_ORDER: async function (context, payload) {
    // don't reload order if already in state
    if (payload === context.state.order._id && !payload.force) {
      console.log('LOAD_ORDER already in state - ' + payload);
      return Promise.resolve(true);
    }

    return this.$axios.get("/api/v1/order/" + Utils.normalizePayloadURI(payload, 'orderId'))
      .then(r => r.data)
      .then(
        order => {
          this.commit("order/UPDATE_ORDER", { order });

          this.dispatch('api/API_SUCCESS', { type: 'info', 'message': 'order is loaded' });

          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);
          return false;
        }
      );
  },

  REFRESH_ORDER_STATUS: function (context, { orderId }) {
    return this.$axios.put("/api/v1/order/" + orderId + "/updateStatus")
      .then(r => r.data)
      .then(
        order => {
          this.commit("order/UPDATE_ORDER", { order });
          this.dispatch('api/API_SUCCESS', { type: 'info', 'message': 'order status is updated' });
          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);
          return false;
        }
      );
  },

  REQUEST_PRINT_PRODUCT_QUOTE: async function (context, { magazineId, printQuoteData }) {
    return this.$axios
      .post('/api/v1/order/print/quote', {
        magazineId,
        printQuoteData
      })
      .then(r => r.data)
      .then(
        quoteResult => {
          this.commit('order/UPDATE_ROOT_FIELD', {
            key: 'printQuote',
            value: quoteResult
          });
          this.dispatch('api/API_SUCCESS', { type: 'info', 'message': 'success get print quote' });

          return true;
        },
        err => {
          this.commit('order/RESET_PRINT_QUOTE');
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);
          return false;
        });
  },

  ADD_QUOTE_TO_CART: async function (context) {
    const quoteData = context.state.printQuote;
    if (!quoteData) {
      return false;
    }

    return this.$axios
      .post('/api/v1/order/print/addToCart', {
        printQuoteResult: quoteData
      })
      .then(r => r.data)
      .then(
        ({ order }) => {
          this.commit('order/RESET_PRINT_QUOTE');
          this.commit('order/UPDATE_ROOT_FIELD', {
            key: 'currentCart',
            value: order
          });
          this.dispatch('api/API_SUCCESS', { type: 'info', 'message': 'success add to cart' });
          return true;
        },
        err => {
          this.commit('order/RESET_PRINT_QUOTE');
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);
          return false;
        });

  },

  LOAD_CURRENT_CART: async function (context, payload) {

    return this.$axios
      .get('/api/v1/order/cart' + (payload && payload.orderId ? '?orderId=' + payload.orderId : ''))
      .then(r => r.data)
      .then(
        ({ order }) => {
          this.commit('order/UPDATE_ROOT_FIELD', {
            key: 'currentCart',
            value: order ? order : initialState()
          });
          this.dispatch('api/API_SUCCESS', { type: 'info', 'message': 'success load cart' });
          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);
          return false;
        });
  },

  UPDATE_CURRENT_CART: async function (context, { printProductId, key, value }) {
    return this.$axios
      .put('/api/v1/order/cart', { printProductId, key, value })
      .then(r => r.data)
      .then(
        ({ order }) => {
          this.commit('order/UPDATE_ROOT_FIELD', {
            key: 'currentCart',
            value: order ? order : initialState()
          });
          this.dispatch('api/API_SUCCESS', { type: 'info', 'message': 'success load cart' });
          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);
          return false;
        });
  },

  CONFIRM_CURRENT_CART_ADDRESS: async function (context, payload) {
    return this.$axios
      .post('/api/v1/order/cart/confirmAddress', payload)
      .then(r => r.data)
      .then(
        ({ order, checkout }) => {
          this.commit('order/UPDATE_ROOT_FIELD', {
            key: 'currentCart',
            value: order ? order : initialState()
          });
          this.dispatch('api/API_SUCCESS', { type: 'info', 'message': 'success confirm cart' });
          return { checkout };
        },
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);
          return false;
        });
  },

  RETRIEVE_CHECKOUT_SESSION: function (context, { stripeSessionId }) {
    return this.$axios.post("/api/v1/stripe/retrieve-session", {
      stripeSessionId: stripeSessionId
    })
      .then(r => r.data)
      .then(
        result => {
          this.dispatch('api/API_SUCCESS', { type: 'info', 'message': 'retrive session success' });
          return result;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);
          return false;
        }
      );
  },
};

let store = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default store;
