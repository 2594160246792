<template>
  <el-card class="menu">
    <!-- <div class="header">{{ $t("public.notifications.menu.title") }}</div> -->
    <div v-if="notifications && notifications.length > 0">
      <template v-for="notification in notifications">
        <Notification
          class="notification"
          :key="notification._id"
          :notification="notification"
        />
      </template>
    </div>
    <div v-else>{{ $t("public.notifications.menu.empty") }}</div>
  </el-card>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";

import Notification from "./notifications/Notification";

export default {
  name: "NotificationMenu",
  components: {
    Notification,
  },

  computed: {
    ...mapState("notification", ["notifications", "lastNotification"]),
  },
  mounted() {
    this.loadNotifications().then(() => {
      // notif unread previous notifications
      let index = 0;
      for (let notification of this.notifications
        .filter((notification) => !notification.read)
        .reverse()) {
        this.displayNewNotification(notification, 100 * index);
        index++;
      }
    });
  },
  beforeDestroy() {
    // close all open notifications
    this.$notify.closeAll();
  },
  methods: {
    ...mapActions({
      loadNotifications: "notification/LOAD_NOTIFICATIONS",
      setReadNotification: "notification/SET_READ_NOTIFICATION",
    }),

    displayNewNotification(notification, delay = 0) {
      const notifElement = this.$createElement(Notification, {
        class: {
          notification: true,
        },
        props: {
          notification: notification,
          i18n: this.$i18n,
          go2Route: this.$go2Route,
        },
        key: notification._id,
      });

      const notifParams = {
        message: notifElement,
        duration: 0,
        offset: 60,
        onClose: () => {
          this.setReadNotification({ notificationId: notification._id });
        },
      };

      setTimeout(() => {
        const notifyElementIo = this.$notify(notifParams);
        notifElement.componentInstance.setNotifyElementIo(notifyElementIo);
      }, delay);
    },
  },
  watch: {
    lastNotification(notification) {
      if (notification) {
        this.displayNewNotification(notification);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.menu {
  min-width: 20vw;
}

.header {
  font-weight: bold;
  font-size: 0.87rem;
  margin-bottom: 1rem;
}

.notification:not(:last-child) {
  margin-bottom: 1rem;
}
</style>