import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import Utils from '~/common/utils/store';
/**
 * Theme store
 *
 * register methods in your components with import { mapGetters, mapActions, mapState, mapMutations } from "vuex";
 *
 * pick only the method you need
 *
   methods :{
  ...mapActions({
      loadTheme: "theme/LOAD_THEME",
      saveTheme: "theme/SAVE_THEME",
      deleteTheme: "theme/DELETE_THEME",
      loadTemplateThemes:"theme/LOAD_TEMPLATE_THEMES",
      proceedUploadFonts:"theme/PROCEED_UPLOAD_FONTS",
    }),
    ...mapMutations({
      updateThemeField: "theme/UPDATE_FIELD",
      updateThemeRootField: "theme/UPDATE_ROOT_FIELD",
      swapThemeArray: "theme/SWAP_ARRAY",
      resetTheme:"theme/RESET_THEME",
      resetStateTheme:"theme/RESET_STATE",
      addThemePalette:"theme/ADD_THEME_PALETTE",
      removeThemePalette:"theme/REMOVE_THEME_PALETTE"
      removeStyle: "theme/REMOVE_STYLE",
      addOrUpdateStyle: "theme/ADD_OR_UPDATE_STYLE",
      updateStyleByName: "theme/UPDATE_STYLE_BY_NAME",
      addOrUpdateTextblock: "theme/ADD_OR_UPDATE_TEXTBLOCK",
      setCurrentFont: "theme/SET_CURRENT_FONT",
      setNewFont: "theme/SET_NEW_FONT",
      removeFont: "theme/REMOVE_FONT",
      addOrUpdateFont: "theme/ADD_OR_UPDATE_FONT",
    }),
    },

      computed: {
    ...mapState("theme", ["theme","templateThemes","currentFont"]),
    ...mapGetters("theme", ["getDefaultStyle"]),
      }
 */
/**
 * return default theme object
 */
function initialState() {
  return {
    _id: null,
    name: 'New Theme',
    template: null, // references model Magazine
    fonts: [],
    styles: [],
    paletteList: [], // references model Palette,
    textblockTypes: []
  };
}

const getDefaultState = () => {
  return {
    theme: initialState(),
    templateThemes: [],
    currentFont: emptyFont(),
  };
};

// initial state
const state = getDefaultState;

function emptyFont() {
  return {
    _id: null,
    name: '',
    url: '',
    styles: [],
    subsets: [],
    provider: ''
  };
}

// getters are functions
const getters = {
  getDefaultStyle(state) {
    return {
      key: null,
      name: '',
      element: '',
      style: '',
      htmlTag: null,
      isDefault: false
    };
  },

  getDefaultFabricTextStyle(state) {
    return {
      fill: "",
      stroke: "",
      strokeWidth: 0,
      fontFamily: "",
      fontSize: 18,
      fontStyle: "normal",
      fontWeight: "normal",
      underline: false,
      overline: false,
      linethrough: false,
      deltaY: 0,
      textBackgroundColor: "",
      lineHeight: 1.16,
      textAlign: "left",
      charSpacing: 0,
      textBackgroundPadding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
    }
  },

  getDefaultFabricDropcapStyle(state) {
    return {
      dropCapHeight: 3,
      dropCapSpacing: 0,
      dropCapYOffset: 0,
      dropCapFontSizeOffset: 0,
      fill: '',
      fontFamily: '',
      fontStyle: 'normal',
      fontWeight: 'normal'
    };
  },
  getDefaultFabricBulletPointStyle(state) {
    return {
      fill: '',
      character: ''
    };
  },
  getDefaultFabricEndSignStyle(state) {
    return {
      fill: '',
      character: ''
    };
  },
  getDefaultFabricTextLinkStyle(state) {
    return {
      fill: '',
      stroke: "",
      strokeWidth: 0,
      fontStyle: "normal",
      fontWeight: "normal",
      underline: false,
      overline: false,
      linethrough: false,
      textBackgroundColor: "",
      textBackgroundPadding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
    };
  },

  getFormatedStylesForFabric: (state, getters, rootState, rootGetters) => {
    const colorsByName = rootGetters['palette/getColorBySwatchName'];

    return state.theme.styles.reduce(
      (list, value) => {
        if (value.key) {
          try {
            let styleObj = JSON.parse(value.style);

            for (const paletteProp of rootState.palette.paletteDependantProperty) {
              if (styleObj[paletteProp]) {
                styleObj[paletteProp] = colorsByName[styleObj[paletteProp]];
              }
            }

            list[value.key] = styleObj;
          } catch (error) {
            // nothing to do
          }
        }
        return list;
      },
      {});
  },

  getTextblockTypes: state => {
    // add default textblock type in theme
    let defaultList = ['Header', 'Body', 'Box', 'Legend', 'Footer'].map((type => {
      return {
        value: type,
        label: type,
        type,
      }
    }));

    // add specific block type defined in theme
    return state.theme.textblockTypes.reduce((list, blockType) => {
      if (blockType.key) {
        list.push({
          value: blockType.type + '-' + blockType.key,
          label: blockType.name + ' (' + blockType.type + ')',
          type: blockType.type,
          key: blockType.key,
        });
      }
      return list;
    }, defaultList);
  },

  findTextblockTypeData: state => (type) => {
    if (!type) {
      return undefined;
    }

    const [extractType, extractKey] = type.split('-');
    return state.theme.textblockTypes.find(
      (textblockType) => textblockType.type === extractType && textblockType.key === extractKey
    );
  },

};
// mutations
const mutations = {

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {payload} payload - https://vuex.vuejs.org/guide/mutations.html#commit-with-payload
   *
   * update one field of state.theme
   */
  UPDATE_FIELD: (state, { key, value }) => {
    Utils.setProperty(key, state.theme, value);
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {payload} payload - https://vuex.vuejs.org/guide/mutations.html#commit-with-payload
   *
   * update one field of state
   */
  UPDATE_ROOT_FIELD: (state, { key, value }) => {
    Utils.setProperty(key, state, value);
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {payload} payload - https://vuex.vuejs.org/guide/mutations.html#commit-with-payload
   *
   * swap two elements of state.theme
   */
  SWAP_ARRAY: (state, payload) => {
    if (payload.swapType && payload.swapType === 'move') {
      // move from index to index
      console.log('SWAP_ARRAY', JSON.parse(JSON.stringify(payload)));
      let moveVal = state.theme[payload.key].splice(payload.from, 1)[0];

      console.log('SWAP_ARRAY moveVal', JSON.parse(JSON.stringify(moveVal)));
      state.theme[payload.key].splice(payload.to, 0, moveVal);

      console.log('SWAP_ARRAY state', JSON.parse(JSON.stringify(state.theme[payload.key])));
      Vue.set(state.theme, payload.key, state.theme[payload.key]);
    } else {
      //swap from index with to index
      let oldVal = state.theme[payload.key][payload.from];
      state.theme[payload.key][payload.from] = state.theme[payload.key][payload.to];
      Vue.set(state.theme[payload.key], payload.to, oldVal);
    }

  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   *
   * reset state.theme
   */
  RESET_THEME: state => {
    state.theme = Object.assign({}, initialState());
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   *
   * reset complete state to default value
   */
  RESET_STATE: state => {
    Object.assign(state, getDefaultState());
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {payload} payload - https://vuex.vuejs.org/guide/mutations.html#commit-with-payload
   *
   */
  ADD_THEME_PALETTE: (state, payload) => {
    const _palette = payload.palette,
      _themeId = payload.themeId;

    if (state.theme._id === _themeId) {
      state.theme.paletteList.push(_palette._id);
    }

    const _indexTemplateThemes = state.templateThemes.findIndex(theme => theme._id === _themeId);
    if (_indexTemplateThemes >= 0) {
      state.templateThemes[_indexTemplateThemes].paletteList.push(_palette._id);
    }
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {payload} payload - https://vuex.vuejs.org/guide/mutations.html#commit-with-payload
   *
   */
  REMOVE_THEME_PALETTE: (state, paletteId) => {
    if (state.theme._id !== null) {
      const _index = state.theme.paletteList.indexOf(paletteId);
      if (_index >= 0) {
        state.theme.paletteList.splice(_index, 1);
      }
    }

    if (state.templateThemes.length > 0) {
      state.templateThemes.forEach(theme_ => {
        const _index = theme_.paletteList.indexOf(paletteId);
        if (_index >= 0) {
          theme_.paletteList.splice(_index, 1);
        }
      });
    }
  },

  REMOVE_STYLE: (state, payload) => {
    let foundIndex = state.theme.styles.findIndex(e => e.name === payload.name);
    if (foundIndex != -1) {
      state.theme.styles.splice(foundIndex, 1);
    }
  },

  ADD_OR_UPDATE_STYLE: (state, payload) => {
    if (payload.isDefault) {
      state.theme.styles.forEach(style => {
        if (style.element === payload.element) {
          style.isDefault = false;
        }
      });
    }

    let foundIndex = payload._id ? state.theme.styles.findIndex(e => e._id === payload._id) : -1;
    if (foundIndex === -1) {
      state.theme.styles.push(payload);
    } else {
      state.theme.styles.splice(foundIndex, 1, payload);
    }
  },

  UPDATE_STYLE_BY_NAME: (state, { name, style }) => {
    let foundIndex = state.theme.styles.findIndex(e => e.name === name);
    if (foundIndex !== -1) {
      state.theme.styles.splice(foundIndex, 1, style);
    }
  },

  ADD_OR_UPDATE_TEXTBLOCK: (state, { textblock }) => {
    let foundIndex = textblock._id ? state.theme.textblockTypes.findIndex(e => e._id === textblock._id) : -1;
    if (foundIndex !== -1) {
      state.theme.textblockTypes.splice(foundIndex, 1, textblock);
    } else {
      state.theme.textblockTypes.push(textblock);
    }
  },

  SET_CURRENT_FONT: (state, payload) => {
    if (payload === null) {
      state.currentFont = this.theme.fonts[0] || emptyFont();
    } else {
      state.currentFont = payload;
    }
  },

  SET_NEW_FONT: (state, payload) => {
    state.currentFont = emptyFont();
  },

  REMOVE_FONT: (state, payload) => {
    let foundIndex = state.theme.fonts.findIndex(e => e.name === payload.name);
    if (foundIndex != -1) {
      state.theme.fonts.splice(foundIndex, 1);
    }
    state.currentFont = emptyFont();
  },

  ADD_OR_UPDATE_FONT: (state, payload) => {
    let foundIndex = state.theme.fonts.findIndex(e => e.name === payload.name);
    if (foundIndex === -1) {
      state.theme.fonts.push(payload);
    } else {
      state.theme.fonts[foundIndex] = payload;
    }
  }
};

// actions are functions that cause side effects and can involve
// asynchronous operations.
const actions = {


  /**
   * fetch current theme object
   * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
   * @param {String} themeID
   * @param {String} query -see populate
   * @return Promise
   */
  LOAD_THEME: async function (context, payload) {
    // don't reload theme if already in state
    if (payload === context.state.theme._id) {
      console.log('LOAD_THEME already in state - ' + payload);
      return Promise.resolve(true);
    } else {
      console.log('LOAD_THEME - ' + payload);
    }

    return this.$axios
      .get('/api/v1/theme/' + Utils.normalizePayloadURI(payload, 'themeId'))
      .then(r => r.data)
      .then(
        theme => {
          this.commit('theme/UPDATE_FIELD', { key: 'name', value: theme.name });
          this.commit('theme/UPDATE_FIELD', { key: 'template', value: theme.template });
          this.commit('theme/UPDATE_FIELD', { key: 'fonts', value: theme.fonts });
          this.commit('theme/UPDATE_FIELD', { key: 'styles', value: theme.styles });
          this.commit('theme/UPDATE_FIELD', { key: 'paletteList', value: theme.paletteList });
          this.commit('theme/UPDATE_FIELD', { key: 'textblockTypes', value: theme.textblockTypes });

          this.commit('theme/UPDATE_FIELD', { key: '_id', value: theme._id });
          this.dispatch('api/API_SUCCESS', { type: 'info', message: 'theme is loaded' });

          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);
          return false;
        }
      );
  },

  /**
   * upsert current theme object
   * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
   * @param {Object} payload
   * @return Promise
   */
  SAVE_THEME: function (context, payload) {
    let method = context.state.theme._id === null ? 'post' : 'put';
    let updateId = context.state.theme._id === null ? '' : context.state.theme._id;

    return this.$axios[method]('/api/v1/theme/' + updateId, context.state.theme)
      .then(r => r.data)
      .then(
        theme => {
          this.commit('theme/UPDATE_FIELD', { key: '_id', value: theme._id });

          this.commit('theme/UPDATE_FIELD', { key: 'name', value: theme.name });
          this.commit('theme/UPDATE_FIELD', { key: 'template', value: theme.template });
          this.commit('theme/UPDATE_FIELD', { key: 'fonts', value: theme.fonts });
          this.commit('theme/UPDATE_FIELD', { key: 'styles', value: theme.styles });
          this.commit('theme/UPDATE_FIELD', { key: 'paletteList', value: theme.paletteList });
          this.commit('theme/UPDATE_FIELD', { key: 'textblockTypes', value: theme.textblockTypes });

          this.dispatch('api/API_SUCCESS', { type: 'info', message: 'theme is saved' });

          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);
          return false;
        }
      );
  },

  /**
   * delete current theme object
   * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
   * @param {Object} payload
   * @return Promise
   */
  DELETE_THEME: function (context, payload) {
    let id = payload.id || context.state.theme._id;

    return this.$axios
      .delete('/api/v1/theme/' + id)
      .then(r => r.data)
      .then(
        theme => {
          this.dispatch('api/API_SUCCESS', { type: 'info', message: 'theme has been deleted' });

          this.commit('theme/RESET_THEME');
          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);
          return false;
        }
      );
  },

  /**
   * fetch collection of themes for a specific template
   * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
   * @param {String} templateId
   * @return Promise
   */
  LOAD_TEMPLATE_THEMES: async function (context, templateId) {
    // don't reload themes list if already in state
    if (context.state.templateThemes.length > 0) {
      if (context.state.templateThemes[0].template === templateId) {
        console.log('LOAD_TEMPLATE_THEMES already in state');
        return Promise.resolve(true);
      } else {
        // reset templatePalette list before reload for specific templateId
        this.commit('theme/UPDATE_ROOT_FIELD', {
          key: 'templateThemes',
          value: []
        });
      }
    }

    return this.$axios
      .get('/api/v1/theme?template=' + templateId)
      .then(r => r.data)
      .then(
        themes => {
          this.commit('theme/UPDATE_ROOT_FIELD', {
            key: 'templateThemes',
            value: themes
          });
          this.dispatch('api/API_SUCCESS', { type: 'info', message: 'THEMES are loaded' });

          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);

          return false;
        }
      );
  },

  PROCEED_UPLOAD_FONTS: function (context, { files, fontName, fontStyles }) {
    if (files && files.length > 0) {
      let data = new FormData();
      data.append('themeID', context.state.theme._id); //important, set text parameters before font binary
      data.append('fontName', fontName);
      data.append('fontStyles', JSON.stringify(fontStyles));
      for (const file of files) {
        data.append('font', file, file.name);
      }

      return this.$axios
        .post('/api/v1/theme/uploadFont/' + context.state.theme._id, data, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(r => r.data)
        .then(theme => {
          this.commit('theme/UPDATE_FIELD', { key: 'fonts', value: theme.fonts });
          return true;
        })
        .catch(error => {
          this.dispatch('api/API_ERROR', error.response.data);
          return false;
        });
    } else {
      return false;
    }
  },
};

let store = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default store;
