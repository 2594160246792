import uniqid from 'uniqid';

const MiscUtils = {
  /**
   * Debounce utility
   * @param {*} fn
   * @param {*} delay
   * @param {*} context
   * @param {*} slot
   * @usage
   *   MiscUtils.debounce( function(){ this.debounced(args)} , 1000, this, "slotA")()
   *   MiscUtils.debounce( function(){ this.debouncedTwo(args)} , 500, this, "slotB")()
   */
  debounce(fn, delay, context = this, slot = '_debounceSlot') {
    if (!context[slot]) {
      context[slot] = {};
    }
    if (context[slot].timeoutID) {
      clearTimeout(context[slot].timeoutID);
    }
    return (...args) => {
      context[slot].action = function () {
        fn.apply(context, args);
        delete context[slot];
      };
      context[slot].timeoutID = setTimeout(context[slot].action, delay);
    };
  },
  cancelDebounce(context = this, slot = '_debounceSlot') {
    if (context[slot] && context[slot].timeoutID) {
      clearTimeout(context[slot].timeoutID);
      delete context[slot];
    }
  },
  executeDebounceNow(context = this, slot = '_debounceSlot') {
    if (context[slot] && context[slot].timeoutID) {
      clearTimeout(context[slot].timeoutID);
      if (context[slot].action) {
        context[slot].action();
      }
    }
  },

  convertPixelToMilimeters(px, dpi) {
    return Math.round((px * 25.4) / dpi);
  },
  convertMilimetersToPixels(mm, dpi, round = true) {
    return round ? Math.round((mm * dpi) / 25.4) : (mm * dpi) / 25.4;
  },
  parseToPixel(value, dpi = 96) {
    var unit = /\D{0,2}$/.exec(value),
      number = parseFloat(value);

    switch (unit[0]) {
      case 'mm':
        return number * dpi / 25.4;

      case 'cm':
        return number * dpi / 2.54;

      case 'in':
        return number * dpi;

      case 'pt':
        return number * dpi / 72;

      default:
        return number;
    }
  },
  convertFromPixel(number, unit, dpi = 96) {
    switch (unit) {
      case 'mm':
        return number / dpi * 25.4;

      case 'cm':
        return number / dpi * 2.54;

      case 'in':
        return number / dpi;

      case 'pt':
        return number / dpi * 72;

      default:
        return number;
    }
  },

  /**
   * Round ton n decimal precision
   * @param {Number} val
   * @param {int} digits Nuber of decimal
   */
  roundToPrecision(val, digits = 1) {
    return Math.round(val * Math.pow(10, digits)) * Math.pow(10, -digits);
  },
  /**
   * Constrain value into min and max vules
   * @param {Number} min
   * @param {Number} val
   * @param {Number} max
   */
  bound(min, val, max) {
    let b = max > min ? Math.min(max, Math.max(min, val)) : Math.max(min, Math.min(max, val));
    return b;
  },

  between(val, a, b, inclusive = false) {
    return inclusive ? val >= Math.min(a, b) && val <= Math.max(a, b) : val > Math.min(a, b) && val < Math.max(a, b);
  },

  /**
   * Return the unicode lenght of a string
   * @param {String} str
   */
  unicodeLength(str) {
    return !str || str.length === 0 ? 0 : [...str].length;
  },

  /**
   * Test if a and b are near a range
   * @param {*} a
   * @param {*} b
   * @param {*} range
   */
  isWithinRange(a, b, range) {
    return Math.abs(a - b) <= range;
  },
  /**
   * Get nice random color
   * @param {number} s 0-100 saturation
   * @param {number} v 0-100 value (brightness)
   * @returns {Object} {h: 0-360, s: 0-100, v: 0-100}
   */
  randomHSVColor(s = 50, v = 50) {
    let golden_ratio_conjugate = 0.618033988749895;
    let h = Math.random();
    h += golden_ratio_conjugate;
    h %= 1;
    h *= 360;
    return { h: h, s: s, v: v };
  },

  /**
   * Add random char in uniqid to enhance its uniqueness
   */
  uniqidplus() {
    let uid = uniqid.time();
    let rand = Math.random();
    let i = Math.floor(rand * (uid.length - 1));
    let insert = rand.toString(36).substring(2, 5);
    return uid.substring(0, i).concat(insert, uid.substring(i));
  },

  uniqName(length = 50) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;

    let name = MiscUtils.uniqidplus();
    while (name.length < length) {
      name += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return name.substring(0, length);

  },

  randomName(length = 50) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;

    let name = "";
    while (name.length < length) {
      name += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return name.substring(0, length);

  },

  fontWeightIsBold(fontWeight) {
    if (fontWeight === 'bold') {
      return true;
    }
    if (fontWeight === 'normal') {
      return false;
    }
    let num = parseInt(fontWeight, 10);
    if (!isNaN(num) && num > 400) {
      return true;
    }

    return false;
  },

  parseFontStyle(style) {
    const fontStylesMap = new Map([['n', 'normal'], ['i', 'italic'], ['o', 'oblique']]);
    const fontWeightMap = new Map([
      ['1', '100'],
      ['2', '200'],
      ['3', '300'],
      ['4', '400'],
      ['5', '500'],
      ['6', '600'],
      ['7', '700'],
      ['8', '800'],
      ['9', '900'],
      ['4', 'normal'],
      ['7', 'bold']
    ]);

    let css = { fontStyle: 'normal', fontWeight: 'normal' };
    // basic notation
    if (style === 'italic' || style === 'i') {
      css.fontStyle = 'italic';
      return css;
    }
    if (style === 'bold' || style === 'b') {
      css.fontWeight = 'bold';
      return css;
    }
    if (style === 'bolditalic' || style === 'bi') {
      css.fontStyle = 'italic';
      css.fontWeight = 'bold';
      return css;
    }

    // fvd notation https://github.com/typekit/fvd
    if (style.length == 2) {
      let arr = style.split('');
      css.fontStyle = fontStylesMap.get(arr[0]);
      css.fontWeight = fontWeightMap.get(arr[1]);
      return css;
    }

    if (style.endsWith('i')) {
      css.fontWeight = style.substring(0, style.length - 1);
      css.fontStyle = 'italic';
    } else {
      css.fontWeight = style;
    }
    return css;
  },

  /**
   * function - convert croppie points to a standard region object
   * @param Array - Array of crop region coord - [topLeftX, topLeftY, bottomRightX, bottomRightY]
   *
   * @return object region to crop
   */
  normalizeCroppieRect(croppieRegionPoints) {
    let [topLeftX, topLeftY, bottomRightX, bottomRightY] = croppieRegionPoints.map(Number);

    return {
      left: topLeftX,
      top: topLeftY,
      width: bottomRightX - topLeftX,
      height: bottomRightY - topLeftY
    };
  },
  retrieveCroppiePoints(croppieRect) {
    return [
      croppieRect.left,
      croppieRect.top,
      croppieRect.width + croppieRect.left,
      croppieRect.height + croppieRect.top
    ];
  },

  /**
   *
   * @param {*} orientation
   * 1 unchanged
   * 2 flipped horizontally
   *  3 rotated 180 degrees
   *  4 flipped vertically
   *  5 flipped horizontally, then rotated left by 90 degrees
   *  6 rotated clockwise by 90 degrees
   *  7 flipped horizontally, then rotated right by 90 degrees
   *  8 rotated counter-clockwise by 90 degrees
   */
  normalizeCroppieOrientation(orientation) {
    let obj = {
      flip: false,
      flop: false,
      rotate: 0
    };
    if (orientation <= 1) {
      return obj;
    }

    if (orientation === 2 || orientation === 5) {
      obj.flop = true;
    }

    if (orientation === 4 || orientation === 7) {
      obj.flip = true;
    }

    switch (orientation) {
      case 3:
        obj.rotate = 180;
        break;
      case 5:
      case 8:
        obj.rotate = -90;
        break;
      case 6:
      case 7:
        obj.rotate = 90;
        break;

      default:
        break;
    }

    return obj;
  },
  retrieveCroppieOrientation(transform) {
    if (!transform || Object.keys(transform).length === 0) return 1;

    if (transform.flop) {
      if (transform.rotate === 0) return 2;
      else if (transform.rotate === -90) return 5;
    }
    if (transform.flip) {
      if (transform.rotate === 0) return 4;
      else if (transform.rotate === 90) return 7;
    }
    switch (transform.rotate) {
      case 180:
        return 3;
      case -90:
        return 8;
      case 90:
        return 6;
      default:
        return 1;
    }
  },
  convertToRoman(num) {
    const roman = {
      M: 1000, CM: 900, D: 500, CD: 400, C: 100, XC: 90, L: 50, XL: 40, X: 10, IX: 9, V: 5, IV: 4, I: 1
    };
    let str = '';
    for (let i of Object.keys(roman)) {
      const q = Math.floor(num / roman[i]);
      num -= q * roman[i];
      str += i.repeat(q);
    }
    return str;
  }
};

export default MiscUtils;