<template>
  <div class="single-line-text" @mouseenter="onMouseEnter">
    <slot>single line text here</slot>
  </div>
</template>

<script>
export default {
  name: "MzSingleLineText",
  props: [],

  computed: {},
  methods: {
    onMouseEnter(event) {
      if (event && event.target) {
        const domElt = event.target;
        if (domElt.offsetWidth < domElt.scrollWidth) {
          domElt.title = domElt.innerHTML;
        } else {
          domElt.title = "";
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
// @import "~assets/css/mz-variables.scss";

// a {
//   color: $public-color-black;
// }
</style>