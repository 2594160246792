import {
  fabric
} from 'fabric';
fabric.history = {

  MAX_STACK_LENGTH: 20,
  canvas: null,
  done: [],
  undone: [],
  dirty: false,

  init: function (canvas) {
    this.canvas = canvas;
    this.objectTransformHandler = this.objectTransformHandler.bind(this)
    this.initListeners();
  },
  kill: function () {
    this.done = [];
    this.undone = [];
    this.removeListeners();
  },

  initListeners: function () {
    this.canvas.on("object:moved", this.objectTransformHandler);
    this.canvas.on("object:scaled", this.objectTransformHandler);
    this.canvas.on("object:rotated", this.objectTransformHandler);
    this.canvas.on("object:skewed", this.objectTransformHandler);
  },

  removeListeners: function () {
    this.canvas.off("object:moved", this.objectTransformHandler);
    this.canvas.off("object:scaled", this.objectTransformHandler);
    this.canvas.off("object:rotated", this.objectTransformHandler);
    this.canvas.off("object:skewed", this.objectTransformHandler);
  },

  canUndo: function () {
    return this.done.length > 0
  },

  canRedo: function () {
    return this.undone.length > 0
  },

  undo: function () {
    if (!this.done.length) {
      console.log("cannot undo")
      return;
    }
    console.log("undo")
    let lastAction = this.done.pop();
    let target = this.canvas.getObjectById(lastAction.id);
    let currentTransform = {};
    for (const key in lastAction.transform) {
      currentTransform[key] = target[key]; // save current state
      target.set(key, lastAction.transform[key]) // then apply previous one
    }
    // push to undone list
    this.addRedo({
      id: lastAction.id,
      transform: currentTransform
    });

    target.setCoords();
    this.canvas.requestRenderAll();
  },

  redo: function () {
    if (!this.undone.length) {
      console.log("cannot redo")
      return;
    }
    console.log("redo")
    let lastAction = this.undone.pop();
    let target = this.canvas.getObjectById(lastAction.id);
    let currentTransform = {};
    for (const key in lastAction.transform) {
      currentTransform[key] = target[key]; // save current state
      target.set(key, lastAction.transform[key])
    }

    // push to done list
    this.addUndo({
      id: lastAction.id,
      transform: currentTransform
    });

    target.setCoords();
    this.canvas.requestRenderAll();
  },

  addRedo(action) {
    this.undone.push(action);
    // remove 1st action if limit is reached
    if (this.undone.length >= this.MAX_STACK_LENGTH) {
      this.undone.shift();
    }
  },

  addUndo(action) {
    this.done.push(action);
    // remove 1st action if limit is reached
    if (this.done.length >= this.MAX_STACK_LENGTH) {
      this.done.shift();
    }
  },

  objectTransformHandler: function (e) {
    this.addUndo({
      id: e.target.id,
      transform: e.transform.original
    })
  },

}
