import { fabric } from 'fabric';
import GraphicUtils from '~/common/utils/graphicUtils';

export default () => {
  fabric.util.object.extend(fabric.MzCanvas.prototype, /** @lends fabric.MzCanvas.prototype */ {

    effectCanvasEl: null,
    contextEffect: null,

    effectType: 'border', // blur or border
    useCssBlurFilter: false,
    effectPadding: 10,

    effectEnabled: false,
    overObjects: null,
    selectedObjects: null,
    updateEffectCanvasOnRender: false,

    initEffectCanvas(options) {
      this.createEffectCanvas();
    },

    /**
     * Overlay canvas for blur. fork of native canvas function to creat top canvas
     */
    createEffectCanvas() {
      var lowerCanvasClass = this.lowerCanvasEl.className.replace(/\s*lower-canvas\s*/, '');

      // there is no need to create a new upperCanvas element if we have already one.
      if (this.effectCanvasEl) {
        this.effectCanvasEl.className = '';
      } else {
        this.effectCanvasEl = this._createCanvasElement();
      }
      fabric.util.addClass(this.effectCanvasEl, 'effect-canvas ' + lowerCanvasClass);

      this.wrapperEl.insertBefore(this.effectCanvasEl, this.upperCanvasEl);

      this._copyCanvasStyle(this.lowerCanvasEl, this.effectCanvasEl);
      this._applyCanvasStyle(this.effectCanvasEl);

      this.contextEffect = this.effectCanvasEl.getContext('2d');

      if (this.effectType === 'blur') {
        if (this.useCssBlurFilter === true) {
          this.effectCanvasEl.style.filter = "blur(2px)";
        } else {
          this.contextEffect.filter = "blur(2px)";
        }
      }
    },

    renderEffect(force = false) {
      if (this.contextEffect === null) {
        return;
      }

      if (this.effectEnabled === true) {
        // clear current context
        this.clearContext(this.contextEffect);

        // define padding
        this.effectPadding = 10 * this.getZoom();

        // set updateEffectCanvasOnRender to be redraw on next canvasRender
        this.updateEffectCanvasOnRender = true;

        if (force === true) {
          this.renderAll();
        }

      } else if (force === true) {
        this.clearContext(this.contextEffect);
      }
    },

    /* @override for redraw blur after background canvas render finished */
    renderCanvas(ctx, objects) {
      if (ctx === null) {
        return;
      }

      this.callSuper('renderCanvas', ctx, objects);

      if (this.effectEnabled === true && this.updateEffectCanvasOnRender === true) {
        this.updateEffectCanvasOnRender = false;
        if (this.contextEffect !== null) {
          if (this.effectType === 'blur') {
            this.drawBlur(this.contextEffect);
          } else if (this.effectType === 'border') {
            this.drawBorder(this.contextEffect);
          }
        }
      }
    },

    addOverEffect(object) {
      if (this.contextEffect === null) {
        return;
      }

      if (object.type === 'MzTextbox' && object.hasReflow === true) {
        const textgroup = fabric.textGroups.get(object.textgroupId);
        if (textgroup) {
          this.overObjects = [...textgroup.textBoxes];
        } else {
          this.overObjects = [object];
        }
      } else {
        this.overObjects = [object];
      }

      this.effectEnabled = true;
      this.renderEffect(true);
    },

    removeOverEffect() {
      this.overObjects = null;
      this.effectEnabled = this.overObjects !== null || this.selectedObjects !== null;
      this.renderEffect(true);
    },

    addSelectedEffect(object) {
      if (this.contextEffect === null) {
        return;
      }

      if (object.type === 'MzTextbox' && object.hasReflow === true) {
        const textgroup = fabric.textGroups.get(object.textgroupId);
        if (textgroup) {
          this.selectedObjects = [...textgroup.textBoxes];
        } else {
          this.selectedObjects = [object];
        }
      } else {
        this.selectedObjects = [object];
      }

      this.effectEnabled = true;
      this.renderEffect();
    },

    removeSelectedEffect() {
      this.selectedObjects = null;
      this.effectEnabled = this.overObjects !== null || this.selectedObjects !== null;
      this.renderEffect(true);
    },

    drawBorder(ctxEffect) {
      // draw object bounds
      if (this.selectedObjects !== null) {
        this.drawBorderForObjects(ctxEffect, this.selectedObjects, "#0d1787");
      }
      if (this.overObjects !== null) {
        this.drawBorderForObjects(ctxEffect, this.overObjects, "#345dee");
      }
    },

    drawBorderForObjects(ctxEffect, objects, borderColor) {
      ctxEffect.lineWidth = 2;
      ctxEffect.strokeStyle = borderColor;

      const imageBorder = 6;

      // draw bounding object
      for (let i = 0; i < objects.length; i++) {
        // specific case for SVG (MzShapeGroup)
        if (false && objects[i].type === 'MzShapeGroup') {
          // disable specific effect for SVG (WIP)
          const bound = objects[i].group ? objects[i].group.getBoundingRect() : objects[i].getBoundingRect(),
            objectOpacity = objects[i].opacity;
          if (objectOpacity !== 1) {
            objects[i].opacity = 1;
          }
          objects[i].cloneAsImage(image => {
            if (objectOpacity !== 1) {
              objects[i].opacity = objectOpacity;
            }
            ctxEffect.drawImage(GraphicUtils.tintImage(image._element, borderColor, 1), bound.left - imageBorder, bound.top - imageBorder, bound.width + imageBorder * 2, bound.height + imageBorder * 2);
            ctxEffect.drawImage(image._element, bound.left, bound.top, bound.width, bound.height);
          });

        } else {
          // TODO : need to get coordinate from object inside a group!!!
          const bound = objects[i].group ? objects[i].group.getBoundingRect() : objects[i].getBoundingRect();
          GraphicUtils.drawRoundedRect(ctxEffect, bound.left - this.effectPadding, bound.top - this.effectPadding, bound.width + this.effectPadding * 2, bound.height + this.effectPadding * 2, this.effectPadding);
        }
      }
      // clear inside of object bounds if multi objects
      if (objects.length > 1) {
        for (let i = 0; i < objects.length; i++) {
          // TODO : need to get coordinate from object inside a group!!!
          const bound = objects[i].group ? objects[i].group.getBoundingRect() : objects[i].getBoundingRect();
          GraphicUtils.clearRoundedRect(
            ctxEffect,
            bound.left - this.effectPadding + 1,
            bound.top - this.effectPadding + 1,
            bound.width + this.effectPadding * 2 - 2,
            bound.height + this.effectPadding * 2 - 2,
            this.effectPadding
          );

        }
      }
    },

    // TODO : implement selectedObjects for blur effect if needed
    drawBlur(ctxEffect) {
      const viewBound = { width: this.width * this._canvasRatioWidth, height: this.height };
      const pageBound = fabric.page.displayBackground.getBoundingRect();

      // check viewport limit
      if (pageBound.left < 0) {
        pageBound.width -= pageBound.left;
        pageBound.left = 0;
      }
      if (pageBound.left + pageBound.width > viewBound.width) {
        pageBound.width = viewBound.width - pageBound.left;
      }
      if (pageBound.top < 0) {
        pageBound.height -= pageBound.top;
        pageBound.top = 0;
      }
      if (pageBound.top + pageBound.height > viewBound.height) {
        pageBound.height = viewBound.height - pageBound.top;
      }

      // get background image data
      const imgData = this.contextContainer.getImageData(pageBound.left, pageBound.top, pageBound.width, pageBound.height);

      if (this.useCssBlurFilter === true) {
        // draw background as image
        ctxEffect.putImageData(imgData, pageBound.left, pageBound.top);
        // draw other elements
        this.drawBlurFilterAndClearObjectRect(ctxEffect, pageBound);
      } else {
        createImageBitmap(imgData)
          .then(bitmap => {
            // draw background as image
            ctxEffect.drawImage(bitmap, pageBound.left, pageBound.top);
            // draw other elements
            this.drawBlurFilterAndClearObjectRect(ctxEffect, pageBound);
          });
      }
    },

    drawBlurFilterAndClearObjectRect(ctxEffect, filterBound) {
      // draw black opacity filter
      ctxEffect.fillStyle = "#00000015";
      ctxEffect.fillRect(filterBound.left, filterBound.top, filterBound.width, filterBound.height)

      // cut object bounds
      for (let i = 0; i < this.overObjects.length; i++) {
        const bound = this.overObjects[i].getBoundingRect();
        ctxEffect.clearRect(bound.left - this.effectPadding, bound.top - this.effectPadding, bound.width + this.effectPadding * 2, bound.height + this.effectPadding * 2)
      }
    }

  });
};
