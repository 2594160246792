import { fabric } from 'fabric';

export default () => {
  fabric.util.object.extend(
    fabric.MzTextbox.prototype,
    /** @lends fabric.MzTextbox.prototype */
    {
      getFirstCharStyleByLine(lineIndex) {
        const map = this._styleMap[lineIndex];
        if (map && this.styles[map.line]) {
          return this.styles[map.line][0];
        } else {
          return null;
        }
      },

      getStylesByLine(lineIndex) {
        let startIndex = 0,
          endIndex = 0;
        if (this._textLines) {
          for (let i = 0; i < lineIndex; i++) {
            if (this._textLines[i]) {
              startIndex += this._textLines[i].length + 1;
            }
          }
          if (this._textLines[lineIndex]) {
            endIndex = startIndex + this._textLines[lineIndex].length;
          }
        }
        return this.getSelectionStyles(startIndex, endIndex);
      },

      getStyleLineHeightByLine(lineIndex) {
        if (this.__lineStyleLineHeight[lineIndex]) {
          return this.__lineStyleLineHeight[lineIndex];
        }

        let lineHeight = 0;
        const _styles = this.getStylesByLine(lineIndex);
        if (_styles) {
          for (const charStyle of Object.values(_styles)) {
            if (charStyle.lineHeight) {
              lineHeight = Math.max(lineHeight, charStyle.lineHeight);
            }
          }
        }
        if (lineHeight <= 0) {
          lineHeight = this.lineHeight;
        }

        this.__lineStyleLineHeight[lineIndex] = lineHeight;
        return lineHeight;
      },

      getStyleTextAlignByLine(lineIndex) {
        if (this.__lineStyleTextAlign[lineIndex]) {
          return this.__lineStyleTextAlign[lineIndex];
        }

        let textAlign = this.textAlign;

        // get real first char style of unwrapped line
        const _firstCharStyle = this.getFirstCharStyleByLine(lineIndex)
        if (_firstCharStyle && _firstCharStyle.textAlign) {
          textAlign = _firstCharStyle.textAlign;
        } else {
          // inverse textbox textAlign by page%2 if no specific text align in style
          textAlign = this.shouldInverseTextAlignByVariable(textAlign);
        }

        this.__lineStyleTextAlign[lineIndex] = textAlign;
        return textAlign;
      },

      getStyleListByLine(lineIndex) {
        if (this.__lineStyleList[lineIndex]) {
          return this.__lineStyleList[lineIndex];
        }

        let list = {
          type: null,
          indent: 0
        };
        const _firstCharStyle = this.getFirstCharStyleByLine(lineIndex)
        if (_firstCharStyle && _firstCharStyle.list) {
          list.type = _firstCharStyle.list;
          list.indent = _firstCharStyle.indent || 0;
          list.listIndex = _firstCharStyle.listIndex || undefined;
        }

        this.__lineStyleList[lineIndex] = list;
        return list;
      },

      /**
       * @private
       * @override
       */
      _clearCache: function () {
        this.__lineWidths = [];
        this.__lineHeights = [];
        this.__lineStyleList = [];
        this.__charBounds = [];
        this.__lineStyleTextAlign = [];
        this.__lineStyleLineHeight = [];
      },

      /**
       * Calculate height of line at 'lineIndex'
       * @param {Number} lineIndex index of line to calculate
       * @return {Number}
       * @override
       */
      getHeightOfLine: function (lineIndex) {
        if (this.__lineHeights[lineIndex]) {
          //console.log('getHeightOfLine CACHE for ' + lineIndex + ':' + this.__lineHeights[lineIndex])
          return this.__lineHeights[lineIndex];
        }

        if (!this._textLines[lineIndex] || this._textLines[lineIndex].length === 0) {
          // default height
          return this.fontSize * this.lineHeight * this._fontSizeMult;
        }

        var line = this._textLines[lineIndex],
          // char 0 is measured before the line cycle because it nneds to char
          // emptylines
          maxHeight = this.getHeightOfChar(lineIndex, 0);
        for (var i = 1, len = line.length; i < len; i++) {
          maxHeight = Math.max(this.getHeightOfChar(lineIndex, i), maxHeight);
          this.getHeightOfChar(lineIndex, i)
        }

        // check if current line has lineHeight style
        const lineHeight = maxHeight * this.getStyleLineHeightByLine(lineIndex) * this._fontSizeMult;
        this.__lineHeights[lineIndex] = lineHeight;
        //console.log('getHeightOfLine for ' + lineIndex + ':' + lineHeight)

        return lineHeight;
      },

      getTopOfLine: function (lineIndex) {
        let top = 0;
        for (let i = 0; i < lineIndex; i++) {
          top += this.getHeightOfLine(i);
        }
        return top;
      },

      /**
       * Enlarge space boxes and shift the others
       * @override
       */
      enlargeSpaces: function () {
        var diffSpace, currentLineWidth, numberOfSpaces, accumulatedSpace, line, charBound, spaces;
        for (var i = 0, len = this._textLines.length; i < len; i++) {
          const textAlign = this.getStyleTextAlignByLine(i);
          if (textAlign.indexOf('justify') === -1) {
            continue;
          }
          const isLastline = !this.hasReflow && i === len - 1;
          if (textAlign !== 'justify' && (isLastline || this.isEndOfWrapping(i))) {
            continue;
          }
          accumulatedSpace = 0;
          line = this._textLines[i];
          currentLineWidth = this.getLineWidth(i);
          if (currentLineWidth < this.width && (spaces = this.textLines[i].match(this._reSpacesAndTabs))) {
            numberOfSpaces = spaces.length;
            diffSpace = (this.width - currentLineWidth) / numberOfSpaces;
            for (var j = 0, jlen = line.length; j <= jlen; j++) {
              charBound = this.__charBounds[i][j];
              if (this._reSpaceAndTab.test(line[j])) {
                charBound.width += diffSpace;
                charBound.kernedWidth += diffSpace;
                charBound.left += accumulatedSpace;
                accumulatedSpace += diffSpace;
              } else {
                charBound.left += accumulatedSpace;
              }
            }
          }
        }
      },

      /**
     * @private
     * @param {CanvasRenderingContext2D} ctx Context to render on
     * @override
     */
      _renderText: function (ctx) {
        this._cleanTextLinkZone();
        if (this.paintFirst === 'stroke') {
          this._renderTextStroke(ctx);
          this._renderTextFill(ctx);
        }
        else {
          this._renderTextFill(ctx);
          this._renderTextStroke(ctx);
        }
      },

      /**
       * @private
       * @param {String} method
       * @param {CanvasRenderingContext2D} ctx Context to render on
       * @param {String} line Content of the line
       * @param {Number} left
       * @param {Number} top
       * @param {Number} lineIndex
       * @param {Number} charOffset
       * @override
       */
      _renderChars: function (method, ctx, line, left, top, lineIndex) {
        // set proper line offset
        var lineHeight = this.getHeightOfLine(lineIndex),
          isJustify = this.getStyleTextAlignByLine(lineIndex).indexOf('justify') !== -1,
          actualStyle,
          nextStyle,
          charsToRender = '',
          charBox,
          boxWidth = 0,
          timeToRender,
          shortCut = !isJustify && this.charSpacing === 0 && this.isEmptyStyles(lineIndex);

        ctx.save();
        top -= (lineHeight * this._fontSizeFraction) / this.lineHeight;
        if (shortCut) {
          // render all the line in one pass without checking
          this._renderChar(method, ctx, lineIndex, 0, this.textLines[lineIndex], left, top, lineHeight);
          ctx.restore();
          return;
        }

        // implement list characters to render by line
        if (this.charsListByLines[lineIndex]) {
          // set -1 to charIndex to identify style of bullet point list
          this._renderChar(method, ctx, lineIndex, -1, this.charsListByLines[lineIndex].charsToRender, left - this.charsListByLines[lineIndex].graphemeBox.width, top, lineHeight);
        }

        for (var i = 0, len = line.length - 1; i <= len; i++) {
          timeToRender = i === len || this.charSpacing;

          charsToRender += line[i];
          charBox = this.__charBounds[lineIndex][i];
          if (boxWidth === 0) {
            left += charBox.kernedWidth - charBox.width;
            boxWidth += charBox.width;
          } else {
            boxWidth += charBox.kernedWidth;
          }
          if (isJustify && !timeToRender) {
            if (this._reSpaceAndTab.test(line[i])) {
              timeToRender = true;
            }
          }
          if (!timeToRender) {
            // if we have charSpacing, we render char by char
            actualStyle = actualStyle || this.getCompleteStyleDeclaration(lineIndex, i);
            nextStyle = this.getCompleteStyleDeclaration(lineIndex, i + 1);
            timeToRender = this._hasStyleChanged(actualStyle, nextStyle) || actualStyle.link !== nextStyle.link;
          }
          if (timeToRender) {
            this._renderChar(method, ctx, lineIndex, i, charsToRender, left, top, lineHeight);

            charsToRender = '';
            actualStyle = nextStyle;
            left += boxWidth;
            boxWidth = 0;
          }
        }
        ctx.restore();
      },

      /**
         * @private
         * @param {String} method
         * @param {CanvasRenderingContext2D} ctx Context to render on
         * @param {Number} lineIndex
         * @param {Number} charIndex
         * @param {String} _char
         * @param {Number} left Left coordinate
         * @param {Number} top Top coordinate
         * @param {Number} lineHeight Height of the line
         * @override
         */
      _renderChar: function (method, ctx, lineIndex, charIndex, _char, left, top) {
        var decl = this._getStyleDeclaration(lineIndex, charIndex),
          fullDecl = this.getCompleteStyleDeclaration(lineIndex, charIndex),
          shouldFill = method === 'fillText' && fullDecl.fill,
          shouldStroke = method === 'strokeText' && fullDecl.stroke && fullDecl.strokeWidth;

        if (!shouldStroke && !shouldFill) {
          return;
        }
        decl && ctx.save();

        this._applyCharStyles(method, ctx, lineIndex, charIndex, fullDecl);

        if (decl && decl.textBackgroundColor) {
          this._removeShadow(ctx);
        }
        if (decl && decl.deltaY) {
          top += decl.deltaY;
        }

        shouldFill && ctx.fillText(_char, left, top);
        shouldStroke && ctx.strokeText(_char, left, top);

        if (decl && decl.link) {
          const textMetrics = ctx.measureText(_char);

          const bounds = {
            left: left - textMetrics.actualBoundingBoxLeft,
            top: top - textMetrics.actualBoundingBoxAscent,
            width: textMetrics.actualBoundingBoxRight - textMetrics.actualBoundingBoxLeft,
            height: textMetrics.actualBoundingBoxAscent - textMetrics.actualBoundingBoxDescent
          }
          this._declareTextLinkZone(ctx, bounds, decl.link);
        }

        decl && ctx.restore();
      },


      // /**
      //  * Renders text selection
      //  * @param {Object} boundaries Object with left/top/leftOffset/topOffset
      //  * @param {CanvasRenderingContext2D} ctx transformed context to draw on
      //  * @override
      //  */
      // renderSelection: function (boundaries, ctx) {
      //   var selectionStart = this.inCompositionMode ? this.hiddenTextarea.selectionStart : this.selectionStart,
      //     selectionEnd = this.inCompositionMode ? this.hiddenTextarea.selectionEnd : this.selectionEnd,
      //     start = this.get2DCursorLocation(selectionStart),
      //     end = this.get2DCursorLocation(selectionEnd),
      //     startLine = start.lineIndex,
      //     endLine = end.lineIndex,
      //     startChar = start.charIndex < 0 ? 0 : start.charIndex,
      //     endChar = end.charIndex < 0 ? 0 : end.charIndex;

      //   for (var i = startLine; i <= endLine; i++) {
      //     var lineOffset = this._getLineLeftOffset(i) || 0,
      //       lineHeight = this.getHeightOfLine(i),
      //       realLineHeight = 0,
      //       boxStart = 0,
      //       boxEnd = 0;

      //     if (i === startLine) {
      //       boxStart = this.__charBounds[startLine][startChar].left;
      //     }
      //     if (i >= startLine && i < endLine) {
      //       const isJustify = this.getStyleTextAlignByLine(i).indexOf('justify') !== -1;
      //       boxEnd = isJustify && !this.isEndOfWrapping(i) ? this.width : this.getLineWidth(i) || 5; // WTF is this 5?
      //     } else if (i === endLine) {
      //       if (endChar === 0) {
      //         boxEnd = this.__charBounds[endLine][endChar].left;
      //       } else {
      //         var charSpacing = this._getWidthOfCharSpacing();
      //         boxEnd =
      //           this.__charBounds[endLine][endChar - 1].left +
      //           this.__charBounds[endLine][endChar - 1].width -
      //           charSpacing;
      //       }
      //     }
      //     realLineHeight = lineHeight;
      //     if (this.lineHeight < 1 || (i === endLine && this.lineHeight > 1)) {
      //       lineHeight /= this.lineHeight;
      //     }
      //     if (this.inCompositionMode) {
      //       ctx.fillStyle = this.compositionColor || 'black';
      //       ctx.fillRect(
      //         boundaries.left + lineOffset + boxStart,
      //         boundaries.top + boundaries.topOffset + lineHeight,
      //         boxEnd - boxStart,
      //         1
      //       );
      //     } else {
      //       ctx.fillStyle = this.selectionColor;
      //       ctx.fillRect(
      //         boundaries.left + lineOffset + boxStart,
      //         boundaries.top + boundaries.topOffset,
      //         boxEnd - boxStart,
      //         lineHeight
      //       );
      //     }

      //     boundaries.topOffset += realLineHeight;
      //   }
      // },

      /**
       * @override
       */
      _setSVGTextLineText: function (textSpans, lineIndex, textLeftOffset, textTopOffset) {
        // set proper line offset
        var lineHeight = this.getHeightOfLine(lineIndex),
          isJustify = this.getStyleTextAlignByLine(lineIndex).indexOf('justify') !== -1,
          charsToRender = '',
          charBox,
          style,
          boxWidth = 0,
          line = this._textLines[lineIndex],
          timeToRender;

        textTopOffset += (lineHeight * (1 - this._fontSizeFraction)) / this.lineHeight;

        // implement list characters to render by line
        if (this.charsListByLines[lineIndex]) {
          style = this._getStyleDeclaration(lineIndex, -1) || {};
          textSpans.push(this._createTextCharSpan(this.charsListByLines[lineIndex].charsToRender, style, textLeftOffset - this.charsListByLines[lineIndex].graphemeBox.width, textTopOffset));
        }

        for (var i = 0, len = line.length - 1; i <= len; i++) {
          timeToRender = i === len || this.charSpacing;
          charsToRender += line[i];
          charBox = this.__charBounds[lineIndex][i];
          if (boxWidth === 0) {
            textLeftOffset += charBox.kernedWidth - charBox.width;
            boxWidth += charBox.width;
          } else {
            boxWidth += charBox.kernedWidth;
          }
          if (isJustify && !timeToRender) {
            if (this._reSpaceAndTab.test(line[i])) {
              timeToRender = true;
            }
          }
          if (!timeToRender) {
            // if we have charSpacing, we render char by char
            const actualStyle = this.getCompleteStyleDeclaration(lineIndex, i),
              nextStyle = this.getCompleteStyleDeclaration(lineIndex, i + 1);
            timeToRender = this._hasStyleChangedForSvg(actualStyle, nextStyle);
          }
          if (timeToRender) {
            style = this._getStyleDeclaration(lineIndex, i) || {};
            if (charsToRender.length === 1 && charsToRender.codePointAt(0) === 0x00AD) {
              // bug fix for U+00ad character if only this is displayed => add space for correct renderer
              charsToRender += " ";
            }
            textSpans.push(this._createTextCharSpan(charsToRender, style, textLeftOffset, textTopOffset));
            charsToRender = '';
            textLeftOffset += boxWidth;
            boxWidth = 0;
          }
        }
      }
    }
  );
};
