import { fabric } from 'fabric';
import Utils from '~/common/utils/misc';

export default () => {

  fabric.MzTextGroup = function () {
    this.id = 0;
    this.textBoxes = [];
    this.groupOverflow = false;

    this.init = function () {
      this.id = Utils.uniqidplus();
      fabric.textGroups.set(this.id, this)
    }

    this.delete = function () {
      for (let textBox of this.textBoxes) {
        textBox.textgroupId = null;
        textBox.parentTextBoxId = null;
        textBox.childTextBoxId = null;
        textBox.hasReflow = false;
      }
      fabric.textGroups.delete(this.id)
    }

    this.add = function (textBox) {
      this.textBoxes.push(textBox)

      textBox.textgroupId = this.id;
    }

    this.containsTextbox = function (id) {
      return this.textBoxes.find(textBox => textBox.id === id) !== undefined;
    }

    this.getRootTextbox = function () {
      return this.textBoxes[0];
    }

    this.getLastTexBox = function () {
      return this.textBoxes[this.textBoxes.length - 1];
    }

    this.updateGroupTextOverflow = function () {
      const lastTbOverflow = this.textBoxes[this.textBoxes.length - 1].textOverflow,
        lastTbOverflowLength = this.textBoxes[this.textBoxes.length - 1].textOverflowLength;
      for (let i = 0; i < this.textBoxes.length; i++) {
        this.textBoxes[i].textOverflow = lastTbOverflow;
        this.textBoxes[i].textOverflowLength = lastTbOverflowLength;
      }
    }

    /**
     * Draw selected borders when root textbox is redering (so we skip index 0)
     */
    this.drawAllBorders = function (ctx) {
      for (let i = 1; i < this.textBoxes.length; i++) {
        this.textBoxes[i]._renderControls(ctx)
      }
    }
  }

  fabric.textGroups = new Map();
};
