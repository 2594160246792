import { fabric } from 'fabric';
import Utils from '~/common/utils/misc';

export default () => {
  fabric.MzCanvas = fabric.util.createClass(fabric.Canvas, {
    type: 'MzCanvas',
    _clipboard: null,
    enableSelectionRect: false,
    initialize: function (element, options) {
      options.fireRightClick = true;
      options.stopContextMenu = true;

      // set custom selection appearence
      fabric.Object.prototype.set({
        transparentCorners: false,
        borderColor: options.adminMode ? '#b7b8b9' : '#345DEE',
        cornerColor: '#b7b8b9',
        cornerSize: 10,
        cornerStyle: 'circle'
      });

      this.preserveObjectStacking = true;

      // initialize modules
      this.initShapeDraw(options);
      this.initViewportControl(options);
      this.callSuper('initialize', element, options);

      if (options.adminMode) {
        // init grid after super init
        this.initGrid(options);

        this.on("selection:created", this.onAdminSelectionUpdate);
        this.on("selection:updated", this.onAdminSelectionUpdate);
      } else {
        // init effect canvas after super init
        this.initEffectCanvas(options);

        // init mouse over effect
        this.on('mouse:over', this.onMouseOver);
        this.on('mouse:out', this.onMouseOut);

        // init selected effect
        this.on("selection:created", this.onClientSelectionUpdate);
        this.on("selection:updated", this.onClientSelectionUpdate);
        this.on("selection:cleared", this.onClientSelectionUpdate);
      }

      //finally init keyboard events if object not locked
      if (options.adminMode) {
        console.log("====================== keyboard control on")
        this.initKeyboardControl(options);
      }
    },

    dispose: function () {
      //TODO clear shape draw
      this.off('mouse:over', this.onMouseOver);
      this.off('mouse:out', this.onMouseOut);
      this.off("selection:created", this.onAdminSelectionUpdate);
      this.off("selection:updated", this.onAdminSelectionUpdate);
      this.off("selection:created", this.onClientSelectionUpdate);
      this.off("selection:updated", this.onClientSelectionUpdate);
      this.off("selection:cleared", this.onClientSelectionUpdate);

      this.disableSnapping();
      this.disableViewportControl();
      this.disableKeyboardControl();

      return this.callSuper('dispose');
    },

    onMouseOver: function (event) {
      if (event.target && event.target.id) {
        this.addOverEffect(event.target);
      }
    },
    onMouseOut: function (event) {
      this.removeOverEffect();
    },

    onAdminSelectionUpdate(event) {
      if (event.target && event.target.type === 'activeSelection') {
        // lock scale active selection
        event.target.set({
          lockScalingX: true,
          lockScalingY: true,
        });
      }
    },

    onClientSelectionUpdate(event) {
      if (event.target && event.target.id) {
        this.addSelectedEffect(event.target);
      }
      else {
        this.removeSelectedEffect(event.target);
      }
    },

    /**
    * @override
    * @private
    */
    _createActiveSelection: function (target, e) {
      var currentActives = this.getActiveObjects(), group = this._createGroup(target);
      this._hoveredTarget = group;
      // ISSUE 4115: should we consider subTargets here?
      // this._hoveredTargets = [];
      // this._hoveredTargets = this.targets.concat();
      this._setActiveObject(group, e);
      this._fireSelectionEvents(currentActives, e);

      setTimeout(() => {
        if (group.angle !== 0) {
          // bugfix remove angle to group apply by fabric if object inside group as angle on cretaion
          group.rotate(0);
          group.setCoords();
          this.requestRenderAll();
        }
      }, 50);

    },

    /**
     * Override native canvas function to prevent drawing selection rectangle
     * @param {} ctx
     */
    renderTopLayer: function (ctx) {
      ctx.save();
      if (this.isDrawingMode && this._isCurrentlyDrawing) {
        this.freeDrawingBrush && this.freeDrawingBrush._render();
        this.contextTopDirty = true;
      }
      if (this.enableSelectionRect === false) {
        this._groupSelector = null;
      }
      // we render the top context - last object
      if (this.enableSelectionRect && this.selection && this._groupSelector) {
        this._drawSelection(ctx);
        this.contextTopDirty = true;
      }
      ctx.restore();
    },

    /**
     * Divides objects in two groups, one to render immediately
     * and one to render as activeGroup.
     * @return {Array} objects to render immediately and pushes the other in the activeGroup.
     */
    _chooseObjectsToRender: function () {
      let activeObjects = this.getActiveObjects();
      let objsToRender = [];

      if (activeObjects.length > 0 && !this.preserveObjectStacking) {
        let activeGroupObjects = [];
        for (let i = 0, length = this._objects.length; i < length; i++) {
          let object = this._objects[i];
          if (activeObjects.indexOf(object) === -1) {
            objsToRender.push(object);
          } else {
            activeGroupObjects.push(object);
          }
        }
        if (activeObjects.length > 1) {
          this._activeObject._objects = activeGroupObjects;
        }
        objsToRender.push.apply(objsToRender, activeGroupObjects);
      } else {
        // render back layer first and top layer last
        let backLayerObjects = [];
        let topLayer = [];
        for (let j = 0, length = this._objects.length; j < length; j++) {
          let object = this._objects[j];
          if (object.topLayer) {
            topLayer.push(object);
          } else if (object.backLayer) {
            backLayerObjects.push(object);
          } else {
            objsToRender.push(object);
          }
        }
        objsToRender.unshift(...backLayerObjects);
        objsToRender.push(...topLayer);
      }
      return objsToRender;
    },

    /**
     * Override active Object selection to select grouped textboxes' root parent instead of children
     * @param {*} object
     * @param {*} e
     */
    setActiveObject: function (object, e) {
      var currentActives = this.getActiveObjects();
      if (!this.adminMode && object.hasReflow) {
        if (object.parentTextBoxId) {
          return this.setActiveObject(object.getRootTextbox(), e)
        }
      }
      this._setActiveObject(object, e);
      this._fireSelectionEvents(currentActives, e);
      return this;
    },

    drawControls: function (ctx) {
      let activeObject = this._activeObject;
      if (activeObject) {
        activeObject._renderControls(ctx);
        // draw multiple selection border of linked textboxes
        if (!this.adminMode && activeObject.hasReflow) {
          const textboxRoot = activeObject.getRootTextbox();
          if (textboxRoot && textboxRoot.id === activeObject.id) {
            fabric.textGroups.get(activeObject.textgroupId).drawAllBorders(ctx)
          }
        }
      }
    },

    copy: function () {
      let obj = this.getActiveObject();
      if (!obj || obj.isEditing) {
        return;
      }
      // clone what are you copying since you
      // may want copy and paste on different moment.
      // and you do not want the changes happened
      // later to reflect on the copy.
      return new Promise((resolve) => {
        obj.clone(cloned => {
          this._clipboard = cloned;
          resolve(cloned.toJSON());
        });
      })

    },

    cut: function () {
      this.copy();
      this.delete();
    },

    paste: function () {
      if (!this._clipboard) {
        return;
      }
      let obj = this.getActiveObject();
      if (obj && obj.isEditing) {
        return;
      }
      // clone again, so you can do multiple copies.
      this._clipboard.clone(clonedObj => {
        this.discardActiveObject();
        clonedObj.set({ evented: true });
        // affect new id
        clonedObj.id = Utils.uniqidplus();
        if (clonedObj.type === 'group' || clonedObj.type === 'MzGroup' || clonedObj.type === 'activeSelection') {
          clonedObj._objects.forEach(o => {
            o.id = Utils.uniqidplus();
          });
        }

        if (clonedObj.type === 'activeSelection') {
          // active selection needs a reference to the canvas.
          clonedObj.canvas = this;
          clonedObj.forEachObject(obj => {
            this.add(obj);
          });
          // this should solve the unselectability
          clonedObj.setCoords();
        } else {
          this.add(clonedObj);
        }
        this.setActiveObject(clonedObj);
        this.requestRenderAll();
      });
    },

    pasteFromJson: function (objectToPaste) {
      if (!objectToPaste) {
        return;
      }

      let fabricObjectType;
      if (objectToPaste.type === 'activeSelection') {
        fabricObjectType = 'ActiveSelection';
      } else {
        fabricObjectType = objectToPaste.type;
      }

      if (!fabricObjectType || !fabric[fabricObjectType]) {
        console.warn('invalid paste object type', objectToPaste);
        return;
      }

      const cloneObjectToPaste = JSON.parse(JSON.stringify(objectToPaste));
      fabric[fabricObjectType].fromObject(cloneObjectToPaste, (fabricObject) => {
        this._clipboard = fabricObject;
        this.paste();
      })
    },

    /**
     * Delete active Object
     */
    delete: function () {
      let obj = this.getActiveObject();
      if (!obj || obj.isEditing) {
        return;
      }
      this.remove(obj);
    },

    moveObject: function (x, y) {
      let obj = this.getActiveObject();
      if (!obj || obj.isEditing) {
        return;
      }
      obj.left += x;
      obj.top += y;

      obj.setCoords();
      this.requestRenderAll();
    },

    /**
     * @override
     */
    // _translateObject: function (x, y) {
    //   var transform = this._currentTransform,
    //     target = transform.target,
    //     newLeft = x - transform.offsetX,
    //     newTop = y - transform.offsetY,
    //     moveX = !target.get('lockMovementX') && target.left !== newLeft,
    //     moveY = !target.get('lockMovementY') && target.top !== newTop;

    //   moveX && target.set('left', newLeft);
    //   moveY && target.set('top', newTop);

    //   if (this.clipPath) {
    //     for (const object of clipPath.getObjects())
    //       object._translateObject(x, y);
    //   }

    //   return moveX || moveY;
    // },

    setObjectsSelectable: function (val) {
      this.discardActiveObject();

      this.forEachObject(function (o) {
        if (o.type != 'MzNonInteractiveRect' && o.type != 'MzNonInteractiveLine' && o.type != 'MzNonInteractiveGroup' && !o.locked) {
          o.selectable = val;
        }
        // val ? o.unfreeze() : o.freeze();
      });
    },

    getObjectById(id) {
      const recursiveFindObject = (objects) => {
        for (let object of objects) {
          if (object.id === id) {
            return object;
          }
          if (object.type === "group" || object.type === "MzGroup") {
            if (object._objects && object._objects.length > 0) {
              const findChild = recursiveFindObject(object._objects);
              if (findChild) {
                return findChild;
              }
            }
          }
        }
        return null;
      };
      let res = recursiveFindObject(this.getObjects());
      return res;
    },

    /**
     * return all objects on page by type
     * @param {String} type
     */
    getObjectsOfType(type) {
      const recursiveExtractObjects = (objects) => {
        return objects.reduce((list, object) => {
          if (object.type === type) {
            list.push(object);
          }
          if (object.type === "group" || object.type === "MzGroup") {
            if (object._objects && object._objects.length > 0) {
              list.push(...recursiveExtractObjects(object._objects));
            }
          }
          return list;
        }, []);
      };
      let res = recursiveExtractObjects(this.getObjects());
      return res;
    },

    selectObjectById(id) {
      let res = this.getObjectById(id);
      if (res) {
        this.setActiveObject(res);
        this.requestRenderAll();
      }
    },

    /**
     * Return textboxes available for Rich Text Editor (not child of parent texbox)
     */
    getEditableTextboxes() {
      let textboxes = this.getObjectsOfType("MzTextbox");
      let editablesTbx = [];
      for (let i = 0; i < textboxes.length; i++) {
        if (!textboxes[i].parentTextBoxId) {
          editablesTbx.push(textboxes[i]);
        }
      }
      return editablesTbx
    },

    unselectActiveObject() {
      this.discardActiveObject();
      this.requestRenderAll();
    },

    ungroup(group = null) {
      if (group === null) {
        group = this.getActiveObject();
      }

      if (group.type === 'group' || group.type === 'MzGroup') {
        let objects = group._objects;
        group._restoreObjectsState();
        this.remove(group);

        this.renderAll();
        for (var i = 0; i < objects.length; i++) {
          this.add(objects[i]);
          // disable force hasControl=true. Some object in client editor has no control by default
          // this.item(this.size()-1).hasControls = true;
        }
        this.renderAll();

        return objects;
      }
      else if (group.type === 'MzShapeGroup') {
        return this.explodeShapeGroup(group);
      }
      else {
        console.log('ERROR : try to ungroup non Group Object : ' + group.type)
      }

      return null;
    },

    /**
     * Working group method using ActiveSelection.
     * Group native method is not reliable because it mess with transforms
     * @param {Array} objects Objects to group, will take selected objects if null
     */
    group(objects = null, userEditorProcess = false) {
      objects = objects ? objects : this.getActiveObjects();

      if (!objects) {
        return null;
      }

      // discard current active selection to replace it with our own
      this.discardActiveObject();

      let objectsToGroup;
      if (userEditorProcess) {
        // group is only implemented on first level in editor process , we can't set a group of group
        // check if group in objects list for ungroup before re-group
        objectsToGroup = [];
        for (let object of objects) {
          if (object.type === 'group' || object.type === 'MzGroup') {
            const ungroupedObjects = this.ungroup(object);
            if (ungroupedObjects && ungroupedObjects.length > 0) {
              objectsToGroup.push(...ungroupedObjects);
            }
          } else {
            objectsToGroup.push(object);
          }
        }
      } else {
        objectsToGroup = objects
      }

      // create active selection from objects
      let selection = new fabric.ActiveSelection(objectsToGroup, {
        canvas: this
      });
      this.setActiveObject(selection);

      // create and add group to canvas from ActiveSelection, then return it
      if (userEditorProcess) {
        // use specific MzGroup for editor process
        return this.selectionToGroup(selection);
      } else {
        return selection.toGroup();
      }
    },

    /* override activeSelection.toGroup for use MzGroup*/
    selectionToGroup: function (selection) {
      var objects = selection._objects.concat();
      selection._objects = [];
      var options = fabric.Object.prototype.toObject.call(selection);
      var newGroup = new fabric.MzGroup([]);
      delete options.type;
      newGroup.set(options);
      objects.forEach(function (object) {
        object.canvas.remove(object);
        object.group = newGroup;
      });
      newGroup._objects = objects;
      if (!selection.canvas) {
        return newGroup;
      }
      var canvas = selection.canvas;
      canvas.add(newGroup);
      canvas._activeObject = newGroup;
      newGroup.setCoords();
      return newGroup;
    },

    convertToShapeGroup(objects = null) {
      objects = objects ? objects : this.getActiveObjects();
      this.discardActiveObject();

      // remove images from shape group (can't be scalabe and src is not persistant if not used in image by page)
      objects = objects.filter(object => object.type !== 'MzImage');
      if (objects.length > 0) {
        this.add(new fabric.MzShapeGroup(objects, {}));
        for (let i = 0; i < objects.length; i++) {
          this.remove(objects[i]);
        }
      }
    },

    explodeShapeGroup(group = null) {
      if (group === null) {
        group = this.getActiveObject();
      }

      if (group.type === 'MzShapeGroup') {
        let objects = group._objects;
        if (objects.find(object => ['MzRect', 'MzCircle', 'MzTriangle', 'MzLine', 'MzShapeGroup', 'MzGroup', 'MzImage', 'MzTextbox'].indexOf(object.type) === -1)) {
          // invalid type of object find inside group, don't ungroup
          console.log('ERROR : try to ungroup invalid Object in ShapeGroup')
          return null;
        }
        group._restoreObjectsState();
        this.remove(group);

        this.renderAll();
        for (var i = 0; i < objects.length; i++) {
          this.add(objects[i]);
          // disable force hasControl=true. Some object in client editor has no control by default
          // this.item(this.size()-1).hasControls = true;
        }
        this.renderAll();

        return objects;
      } else {
        console.log('ERROR : try to ungroup non ShapeGroup Object : ' + group.type)
      }

      return null;
    },

    /**
     * @override createSVGFontFacesMarkup for match with MzTextbox
    */
    createSVGFontFacesMarkup: function () {
      var markup = '', fontList = {}, obj, fontFamily,
        style, row, rowIndex, _char, charIndex, i, len,
        fontPaths = fabric.fontPaths,
        textStylesList = fabric.textStylesList,
        objects = this._objects;

      for (i = 0, len = objects.length; i < len; i++) {
        obj = objects[i];
        if (obj.type !== 'MzTextbox') {
          continue;
        }
        // add textbox fontFamily
        fontFamily = obj.fontFamily;
        if (!fontList[fontFamily] && fontPaths[fontFamily]) {
          fontList[fontFamily] = true;
        }
        // add textbox styles fontFamily
        if (obj.styles) {
          style = obj.styles;
          if (style) {
            for (rowIndex in style) {
              if (rowIndex !== undefined && rowIndex !== null) {
                row = style[rowIndex];
                for (charIndex in row) {
                  if (charIndex !== undefined && charIndex !== null) {
                    _char = row[charIndex];

                    if (_char) {
                      if (_char.fontFamily) {
                        fontFamily = _char.fontFamily;
                      }
                      else if (_char.textStyleKey && textStylesList && textStylesList[_char.textStyleKey]) {
                        fontFamily = textStylesList[_char.textStyleKey].fontFamily;
                      }
                      if (fontFamily && !fontList[fontFamily] && fontPaths[fontFamily]) {
                        fontList[fontFamily] = true;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        // add textbox dropcap fontFamily
        if (obj.dropCapStyle) {
          if (obj.dropCapStyle.textStyleKey && textStylesList && textStylesList[obj.dropCapStyle.textStyleKey]) {
            fontFamily = textStylesList[obj.dropCapStyle.textStyleKey].fontFamily;
          } else {
            fontFamily = obj.dropCapStyle.fontFamily;
          }
          if (fontFamily && !fontList[fontFamily] && fontPaths[fontFamily]) {
            fontList[fontFamily] = true;
          }
        }
      }

      for (var j in fontList) {
        // @font-face not standard, replace by @import in svg markup
        /*
        markup += [
          '\t\t@font-face {\n',
          '\t\t\tfont-family: \'', j, '\';\n',
          '\t\t\tsrc: url(\'', fontPaths[j], '\');\n',
          '\t\t}\n'
        ].join('');
        */
        markup += '\t\t@import url(' + fontPaths[j] + ');\n'
      }

      if (markup) {
        markup = [
          '\t<style type="text/css">',
          '<![CDATA[\n',
          markup,
          ']]>',
          '</style>\n'
        ].join('');
      }

      return markup;
    },

    /**
    * Checks point is inside the object.
    * @param {Object} [pointer] x,y object of point coordinates we want to check.
    * @param {fabric.Object} obj Object to test against
    * @param {Object} [globalPointer] x,y object of point coordinates relative to canvas used to search per pixel target.
    * @return {Boolean} true if point is contained within an area of given object
    * @private
    * @override
    */
    _checkTarget: function (pointer, obj, globalPointer) {
      let isOverBounds = false, isOverPointer = false;

      if (obj &&
        obj.visible &&
        obj.evented &&
        this.containsPoint(null, obj, pointer)) {
        isOverBounds = true;
        if ((this.perPixelTargetFind || obj.perPixelTargetFind) && !obj.isEditing) {
          if (obj.type === 'MzTextbox' && obj.text.trim().length === 0) {
            // specific case for empty Textbox, don't check per pixels with opacity, force is over
            isOverPointer = true;
          } else if (obj.type === 'MzImage' && obj.isFullTransparent()) {
            // specific case for full transparency image, don't check per pixels with opacity, force is over
            isOverPointer = true;
          } else if (obj.type === 'MzShapeGroup' && obj.isFullTransparent()) {
            // specific case for full transparency svg, don't check per pixels with opacity, force is over
            isOverPointer = true;
          } else {
            let restoreOpacity = obj.opacity === 0;
            if (restoreOpacity) {
              obj.opacity = 0.1;
            }

            // check 10px arround the text for pixel perfect
            this.targetFindTolerance = obj.type === 'MzTextbox' ? Math.round(10 * this.viewportTransform[0]) : 0; // multiply by current canvas zoom

            var isTransparent = this.isTargetTransparent(obj, globalPointer.x, globalPointer.y);
            if (!isTransparent) {
              isOverPointer = true;
            }

            if (restoreOpacity) {
              obj.opacity = 0;
            }
          }
        }
        else {
          isOverPointer = true;
        }
      }

      return { isOverPointer, isOverBounds };
    },

    /**
    * Function used to search inside objects an object that contains pointer in bounding box or that contains pointerOnCanvas when painted
    * @param {Array} [objects] objects array to look into
    * @param {Object} [pointer] x,y object of point coordinates we want to check.
    * @return {fabric.Object} object that contains pointer
    * @private
    * @override
    */
    _searchPossibleTargets: function (objects, pointer) {
      // Cache all targets where their bounding box contains point.
      var target, i = objects.length, subTarget, firstTargetWithoutPerPixel;
      // Do not check for currently grouped objects, since we check the parent group itself.
      // until we call this function specifically to search inside the activeGroup
      while (i--) {
        var objToCheck = objects[i];
        var pointerToUse = objToCheck.group && objToCheck.group.type !== 'activeSelection' ?
          this._normalizePointer(objToCheck.group, pointer) : pointer;
        var checkTarget = this._checkTarget(pointerToUse, objToCheck, pointer);
        if (checkTarget.isOverPointer) {
          target = objects[i];
          if ((target.subTargetCheck && target instanceof fabric.Group) ||
            (target.type === 'MzGroup' && target.selectInsideGroup)) {
            subTarget = this._searchPossibleTargets(target._objects, pointer);

            // force subTarget as target for select object isnide group without select group
            if (target.type === 'MzGroup' && target.selectInsideGroup) {
              return subTarget;
            }

            subTarget && this.targets.push(subTarget);
          }
          break;
        } else {
          if (!firstTargetWithoutPerPixel && checkTarget.isOverBounds) {
            if (objToCheck.type === 'MzTextbox') {
              firstTargetWithoutPerPixel = objToCheck;
            }
          }
        }
      }
      return target ? target : firstTargetWithoutPerPixel;
    },

    toObject: function () {
      return fabric.util.object.extend(this.callSuper('toObject'), {});
    }
  });
};
