<template>
  <div>
    <el-alert
      v-show="displayAlert"
      :type="typeAlert"
      :closable="alertIsClosable"
      center
      effect="dark"
      class="maintenance-alert"
    >
      <span slot="title" v-html="titleAlert"></span>
    </el-alert>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters, mapActions } from "vuex";

export default {
  name: "MaintenanceAlert",

  data() {
    return {};
  },
  computed: {
    ...mapGetters("app", [
      "maintenanceMode",
      "publicMessage",
      "publicMessageClosable",
      "forceMaintenanceAccess",
    ]),

    displayAlert() {
      return this.maintenanceMode || this.publicMessage;
    },

    titleAlert() {
      return this.maintenanceMode
        ? "Application is currently in maintenance"
        : decodeURIComponent(this.publicMessage);
    },

    typeAlert() {
      return this.maintenanceMode ? "error" : "warning";
    },

    alertIsClosable() {
      return this.maintenanceMode ? false : this.publicMessageClosable;
    },
  },
  watch: {
    maintenanceMode(value) {
      if (value && !this.forceMaintenanceAccess) {
        this.$go2Route({ name: "maintenance" });
      }
    },
  },
};
</script>

<style scoped>
.maintenance-alert {
  z-index: 10;
}
</style>