import { fabric } from 'fabric';

export default () => {
  fabric.util.object.extend(
    fabric.MzCanvas.prototype,
    /** @lends fabric.MzCanvas.prototype */ {
      origX: 0,
      origY: 0,
      drawingObject: null,
      isDown: false,
      drawModeEnabled: false,
      drawType: 0, //0 Line, 1 rect, 2 text, 3 circle, 4 triangle
      drawOptions: null,
      drawColor: "#000000",

      initShapeDraw(options) { },

      switchDrawMode: function (active) {
        if (active === true) {
          this.initDraw();
        } else {
          this.stopDraw();
        }
      },

      initDraw: function () {
        if (this.drawModeEnabled === true) {
          return;
        }
        this.drawModeEnabled = true;
        this.setObjectsSelectable(false);
        this.on('mouse:down', this.drawStart);
        this.on('mouse:move', this.drawChange);
        this.on('mouse:up', this.drawEnds);
      },

      stopDraw: function () {
        this.drawModeEnabled = false;
        this.setObjectsSelectable(true);
        this.off('mouse:down', this.drawStart);
        this.off('mouse:move', this.drawChange);
        this.off('mouse:up', this.drawEnds);
      },

      drawStart: async function (o) {
        this.isDown = true;
        let pointer = this.getPointer(o.e);
        let pt = this.snapPointToGrid(pointer);
        this.origX = pt.x;
        this.origY = pt.y;

        switch (this.drawType) {
          case 0: //line
            this.drawingObject = new fabric.MzLine(
              [this.origX, this.origY, pointer.x - this.origX, pointer.y - this.origY],
              {
                strokeWidth: 2,
                stroke: this.drawColor,
                ...this.drawOptions
              }
            );
            break;
          case 1: //rect
            this.drawingObject = new fabric.MzRect({
              left: this.origX,
              top: this.origY,
              originX: 'left',
              originY: 'top',
              width: pointer.x - this.origX,
              height: pointer.y - this.origY,
              angle: 0,
              fill: this.drawColor,
              stroke: null,
              strokeWidth: 0,
              ...this.drawOptions
            });
            break;
          case 2: //text
            this.drawingObject = new fabric.MzTextbox("Enter Text Here", {
              left: this.origX,
              top: this.origY,
              originX: 'left',
              originY: 'top',
              width: pointer.x - this.origX,
              height: pointer.y - this.origY,
              angle: 0,
              fill: this.drawColor,
              fontSize: 50,
              stroke: null,
              strokeWidth: 0,
              ...this.drawOptions
            });
            break;
          case 3: //circle
            this.drawingObject = new fabric.MzCircle({
              left: this.origX,
              top: this.origY,
              originX: 'left',
              originY: 'top',
              rx: pointer.x - this.origX,
              ry: pointer.y - this.origY,
              angle: 0,
              fill: this.drawColor,
              stroke: null,
              strokeWidth: 0,
              ...this.drawOptions
            });
            break;
          case 4: //triangle
            this.drawingObject = new fabric.MzTriangle({
              left: this.origX,
              top: this.origY,
              originX: 'left',
              originY: 'top',
              width: pointer.x - this.origX,
              height: pointer.y - this.origY,
              angle: 0,
              fill: this.drawColor,
              stroke: null,
              strokeWidth: 0,
              ...this.drawOptions
            });
            break;
        }
        this.drawingObject.hasControls = false;
        //this.drawingObject.hasBorders = false;

        this.add(this.drawingObject);
        this.requestRenderAll();
        this.setActiveObject(this.drawingObject);
      },

      drawChange: function (o) {
        if (!this.isDown) return;
        let pointer = this.getPointer(o.e);

        let pt = this.snapPointToGrid(pointer);
        let tx = pt.x;
        let ty = pt.y;
        let width = Math.abs(this.origX - tx);
        let height = Math.abs(this.origY - ty);

        switch (this.drawType) {
          case 0: //line
            if (o.e.ctrlKey === true) {
              // hold ctrl to draw lines snapped to 15 deg angle
              let x2 = tx - this.origX;
              let y2 = ty - this.origY;
              let r = Math.sqrt(x2 * x2 + y2 * y2);
              let angle = (Math.atan2(y2, x2) / Math.PI) * 180;
              angle = (angle % 360) + 180;
              // snap to 15 deg
              angle = parseInt(((angle + 7.4) % 360) / 15) * 15;
              angle -= 180;

              let cosx = r * Math.cos((angle * Math.PI) / 180);
              let sinx = r * Math.sin((angle * Math.PI) / 180);

              this.drawingObject.set({
                x2: cosx + this.origX,
                y2: sinx + this.origY
              });
            } else {
              this.drawingObject.set({ x2: tx, y2: ty });
            }
            break;

          case 1: //rect, text, circle, triangle
          case 2:
          case 3:
          case 4:
          default:
            if (this.origX > tx) {
              this.drawingObject.set({ left: Math.abs(tx) });
            }
            if (this.origY > ty) {
              this.drawingObject.set({ top: Math.abs(ty) });
            }
            if (o.e.ctrlKey === true) {
              // hold ctrl to draw shape with same width and height
              width = height = Math.max(width, height);
            }
            this.drawingObject.set({ width: width });
            this.drawingObject.set({ height: height });
            break;
        }
        this.renderAll();
      },

      drawEnds: function (o) {
        this.isDown = false;
        this.drawingObject.hasControls = true;
        this.drawingObject.hasBorders = true;
        this.requestRenderAll();
        this.drawingObject.setCoords();
        this.stopDraw();

        this.setActiveObject(this.drawingObject);
      }
    }
  );
};
