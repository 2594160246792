import Vue from 'vue'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from './components/nuxt-error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_pluginrouting_ab7eab40 from 'nuxt_plugin_pluginrouting_ab7eab40' // Source: ./nuxt-i18n/plugin.routing.js (mode: 'all')
import nuxt_plugin_pluginmain_0faf5d0f from 'nuxt_plugin_pluginmain_0faf5d0f' // Source: ./nuxt-i18n/plugin.main.js (mode: 'all')
import nuxt_plugin_axios_5636b0a6 from 'nuxt_plugin_axios_5636b0a6' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_appdebugger_fccd267a from 'nuxt_plugin_appdebugger_fccd267a' // Source: ../plugins/app-debugger (mode: 'client')
import nuxt_plugin_localStorage_830ec59e from 'nuxt_plugin_localStorage_830ec59e' // Source: ../plugins/localStorage.js (mode: 'client')
import nuxt_plugin_bus_5e46274a from 'nuxt_plugin_bus_5e46274a' // Source: ../plugins/bus (mode: 'client')
import nuxt_plugin_axios_3566aa80 from 'nuxt_plugin_axios_3566aa80' // Source: ../plugins/axios (mode: 'client')
import nuxt_plugin_sessioncheck_335950bc from 'nuxt_plugin_sessioncheck_335950bc' // Source: ../plugins/session-check.js (mode: 'client')
import nuxt_plugin_unit_1466658e from 'nuxt_plugin_unit_1466658e' // Source: ../plugins/filters/unit (mode: 'client')
import nuxt_plugin_history_1e0f185e from 'nuxt_plugin_history_1e0f185e' // Source: ../plugins/history (mode: 'client')
import nuxt_plugin_i18nrouting_76994ae6 from 'nuxt_plugin_i18nrouting_76994ae6' // Source: ../plugins/i18n-routing (mode: 'client')
import nuxt_plugin_elementui_a6a1b20a from 'nuxt_plugin_elementui_a6a1b20a' // Source: ../plugins/element-ui (mode: 'client')
import nuxt_plugin_vuecroppie_078e327f from 'nuxt_plugin_vuecroppie_078e327f' // Source: ../plugins/vue-croppie (mode: 'client')
import nuxt_plugin_vueclipboard_a146e34e from 'nuxt_plugin_vueclipboard_a146e34e' // Source: ../plugins/vue-clipboard (mode: 'client')
import nuxt_plugin_quilleditor_64dcffab from 'nuxt_plugin_quilleditor_64dcffab' // Source: ../plugins/quill-editor (mode: 'client')
import nuxt_plugin_vuemoment_1fc97fc6 from 'nuxt_plugin_vuemoment_1fc97fc6' // Source: ../plugins/vue-moment (mode: 'client')
import nuxt_plugin_fabric_68ecb0ca from 'nuxt_plugin_fabric_68ecb0ca' // Source: ../plugins/fabric (mode: 'client')
import nuxt_plugin_fontManager_5c853328 from 'nuxt_plugin_fontManager_5c853328' // Source: ../plugins/fontManager (mode: 'client')
import nuxt_plugin_FabricExtendFunctions_fe5f6796 from 'nuxt_plugin_FabricExtendFunctions_fe5f6796' // Source: ../plugins/fabricEditor/FabricExtendFunctions (mode: 'client')
import nuxt_plugin_MzNonInteractiveGroup_176ba694 from 'nuxt_plugin_MzNonInteractiveGroup_176ba694' // Source: ../plugins/fabricEditor/MzNonInteractiveGroup (mode: 'client')
import nuxt_plugin_MzNonInteractiveRect_734100cd from 'nuxt_plugin_MzNonInteractiveRect_734100cd' // Source: ../plugins/fabricEditor/MzNonInteractiveRect (mode: 'client')
import nuxt_plugin_MzNonInteractiveLine_733e56dd from 'nuxt_plugin_MzNonInteractiveLine_733e56dd' // Source: ../plugins/fabricEditor/MzNonInteractiveLine (mode: 'client')
import nuxt_plugin_MzRect_452ae058 from 'nuxt_plugin_MzRect_452ae058' // Source: ../plugins/fabricEditor/MzRect (mode: 'client')
import nuxt_plugin_MzCircle_e6cb1378 from 'nuxt_plugin_MzCircle_e6cb1378' // Source: ../plugins/fabricEditor/MzCircle (mode: 'client')
import nuxt_plugin_MzTriangle_fb299308 from 'nuxt_plugin_MzTriangle_fb299308' // Source: ../plugins/fabricEditor/MzTriangle (mode: 'client')
import nuxt_plugin_MzLine_45283668 from 'nuxt_plugin_MzLine_45283668' // Source: ../plugins/fabricEditor/MzLine (mode: 'client')
import nuxt_plugin_MzImage_5fb5f167 from 'nuxt_plugin_MzImage_5fb5f167' // Source: ../plugins/fabricEditor/MzImage (mode: 'client')
import nuxt_plugin_MzTextbox_f8f1faec from 'nuxt_plugin_MzTextbox_f8f1faec' // Source: ../plugins/fabricEditor/MzTextbox (mode: 'client')
import nuxt_plugin_MzShapeGroup_c24b9a5c from 'nuxt_plugin_MzShapeGroup_c24b9a5c' // Source: ../plugins/fabricEditor/MzShapeGroup (mode: 'client')
import nuxt_plugin_MzCanvas_e7afa268 from 'nuxt_plugin_MzCanvas_e7afa268' // Source: ../plugins/fabricEditor/MzCanvas (mode: 'client')
import nuxt_plugin_MzGroup_5f9c3e8b from 'nuxt_plugin_MzGroup_5f9c3e8b' // Source: ../plugins/fabricEditor/MzGroup (mode: 'client')
import nuxt_plugin_MzPage_4529e903 from 'nuxt_plugin_MzPage_4529e903' // Source: ../plugins/fabricEditor/MzPage (mode: 'client')
import nuxt_plugin_MzTextGroup_87555144 from 'nuxt_plugin_MzTextGroup_87555144' // Source: ../plugins/fabricEditor/MzTextGroup (mode: 'client')
import nuxt_plugin_MzCommonMixins_2dfde200 from 'nuxt_plugin_MzCommonMixins_2dfde200' // Source: ../plugins/fabricEditor/mixins/MzCommonMixins (mode: 'client')
import nuxt_plugin_canvasTransformActions_d1fd5a10 from 'nuxt_plugin_canvasTransformActions_d1fd5a10' // Source: ../plugins/fabricEditor/modules/canvasTransformActions (mode: 'client')
import nuxt_plugin_canvasGrid_26e7eccd from 'nuxt_plugin_canvasGrid_26e7eccd' // Source: ../plugins/fabricEditor/modules/canvasGrid (mode: 'client')
import nuxt_plugin_canvasEffect_088bd558 from 'nuxt_plugin_canvasEffect_088bd558' // Source: ../plugins/fabricEditor/modules/canvasEffect (mode: 'client')
import nuxt_plugin_canvasKeyboardControl_5dc1db8f from 'nuxt_plugin_canvasKeyboardControl_5dc1db8f' // Source: ../plugins/fabricEditor/modules/canvasKeyboardControl (mode: 'client')
import nuxt_plugin_canvasShapeDraw_7e5eb35e from 'nuxt_plugin_canvasShapeDraw_7e5eb35e' // Source: ../plugins/fabricEditor/modules/canvasShapeDraw (mode: 'client')
import nuxt_plugin_canvasViewportControl_2ff851f0 from 'nuxt_plugin_canvasViewportControl_2ff851f0' // Source: ../plugins/fabricEditor/modules/canvasViewportControl (mode: 'client')
import nuxt_plugin_canvasSnapObjects_7b9dc2fa from 'nuxt_plugin_canvasSnapObjects_7b9dc2fa' // Source: ../plugins/fabricEditor/modules/canvasSnapObjects (mode: 'client')
import nuxt_plugin_MzObjectControls_82f7b1de from 'nuxt_plugin_MzObjectControls_82f7b1de' // Source: ../plugins/fabricEditor/modules/MzObjectControls (mode: 'client')
import nuxt_plugin_noScaleBehavior_141dc6cc from 'nuxt_plugin_noScaleBehavior_141dc6cc' // Source: ../plugins/fabricEditor/modules/noScaleBehavior (mode: 'client')
import nuxt_plugin_MzTextboxStyle_deb29462 from 'nuxt_plugin_MzTextboxStyle_deb29462' // Source: ../plugins/fabricEditor/modules/MzTextboxStyle (mode: 'client')
import nuxt_plugin_MzTextboxWithVariable_1c616b84 from 'nuxt_plugin_MzTextboxWithVariable_1c616b84' // Source: ../plugins/fabricEditor/modules/MzTextboxWithVariable (mode: 'client')
import nuxt_plugin_MzTextboxReflow_fcc1acfa from 'nuxt_plugin_MzTextboxReflow_fcc1acfa' // Source: ../plugins/fabricEditor/modules/MzTextboxReflow (mode: 'client')
import nuxt_plugin_MzTextboxList_8b56c680 from 'nuxt_plugin_MzTextboxList_8b56c680' // Source: ../plugins/fabricEditor/modules/MzTextboxList (mode: 'client')
import nuxt_plugin_MzTextboxEndSign_11c8fdd6 from 'nuxt_plugin_MzTextboxEndSign_11c8fdd6' // Source: ../plugins/fabricEditor/modules/MzTextboxEndSign (mode: 'client')
import nuxt_plugin_MzTextboxTextLink_f555d42e from 'nuxt_plugin_MzTextboxTextLink_f555d42e' // Source: ../plugins/fabricEditor/modules/MzTextboxTextLink (mode: 'client')
import nuxt_plugin_MzTextboxDodging_42be991c from 'nuxt_plugin_MzTextboxDodging_42be991c' // Source: ../plugins/fabricEditor/modules/MzTextboxDodging (mode: 'client')
import nuxt_plugin_MzTextboxIndent_72c15c4e from 'nuxt_plugin_MzTextboxIndent_72c15c4e' // Source: ../plugins/fabricEditor/modules/MzTextboxIndent (mode: 'client')
import nuxt_plugin_MzTextboxDropCap_374471fe from 'nuxt_plugin_MzTextboxDropCap_374471fe' // Source: ../plugins/fabricEditor/modules/MzTextboxDropCap (mode: 'client')
import nuxt_plugin_MzSpecificControls_2f80bb04 from 'nuxt_plugin_MzSpecificControls_2f80bb04' // Source: ../plugins/fabricEditor/modules/MzSpecificControls (mode: 'client')
import nuxt_plugin_MzTextboxLineStyle_0f00a18a from 'nuxt_plugin_MzTextboxLineStyle_0f00a18a' // Source: ../plugins/fabricEditor/modules/MzTextboxLineStyle (mode: 'client')
import nuxt_plugin_MzTextboxPadding_418644af from 'nuxt_plugin_MzTextboxPadding_418644af' // Source: ../plugins/fabricEditor/modules/MzTextboxPadding (mode: 'client')
import nuxt_plugin_MzImageLegend_7f9e7a68 from 'nuxt_plugin_MzImageLegend_7f9e7a68' // Source: ../plugins/fabricEditor/modules/MzImageLegend (mode: 'client')
import nuxt_plugin_online_66c9680e from 'nuxt_plugin_online_66c9680e' // Source: ../plugins/online.js (mode: 'client')
import nuxt_plugin_now_4c037b52 from 'nuxt_plugin_now_4c037b52' // Source: ../plugins/now.js (mode: 'client')
import nuxt_plugin_websocket_0f1bcf46 from 'nuxt_plugin_websocket_0f1bcf46' // Source: ../plugins/websocket.js (mode: 'client')
import nuxt_plugin_subscriber_48c868d9 from 'nuxt_plugin_subscriber_48c868d9' // Source: ../plugins/subscriber.js (mode: 'client')
import nuxt_plugin_vuematomo_4825ec29 from 'nuxt_plugin_vuematomo_4825ec29' // Source: ../plugins/vue-matomo.js (mode: 'client')
import nuxt_plugin_vuestripe_03930b4d from 'nuxt_plugin_vuestripe_03930b4d' // Source: ../plugins/vue-stripe.js (mode: 'client')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":true,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

async function createApp (ssrContext) {
  const router = await createRouter(ssrContext)

  const store = createStore(ssrContext)
  // Add this.$router into store actions/mutations
  store.$router = router

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        const nuxt = this.nuxt || this.$options.nuxt
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    ssrContext
  })

  const inject = function (key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error('inject(key, value) has no value provided')
    }

    key = '$' + key
    // Add into app
    app[key] = value

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Plugin execution

  if (typeof nuxt_plugin_pluginrouting_ab7eab40 === 'function') {
    await nuxt_plugin_pluginrouting_ab7eab40(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginmain_0faf5d0f === 'function') {
    await nuxt_plugin_pluginmain_0faf5d0f(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_5636b0a6 === 'function') {
    await nuxt_plugin_axios_5636b0a6(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_appdebugger_fccd267a === 'function') {
    await nuxt_plugin_appdebugger_fccd267a(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_localStorage_830ec59e === 'function') {
    await nuxt_plugin_localStorage_830ec59e(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_bus_5e46274a === 'function') {
    await nuxt_plugin_bus_5e46274a(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_axios_3566aa80 === 'function') {
    await nuxt_plugin_axios_3566aa80(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_sessioncheck_335950bc === 'function') {
    await nuxt_plugin_sessioncheck_335950bc(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_unit_1466658e === 'function') {
    await nuxt_plugin_unit_1466658e(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_history_1e0f185e === 'function') {
    await nuxt_plugin_history_1e0f185e(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_i18nrouting_76994ae6 === 'function') {
    await nuxt_plugin_i18nrouting_76994ae6(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_elementui_a6a1b20a === 'function') {
    await nuxt_plugin_elementui_a6a1b20a(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuecroppie_078e327f === 'function') {
    await nuxt_plugin_vuecroppie_078e327f(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vueclipboard_a146e34e === 'function') {
    await nuxt_plugin_vueclipboard_a146e34e(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_quilleditor_64dcffab === 'function') {
    await nuxt_plugin_quilleditor_64dcffab(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuemoment_1fc97fc6 === 'function') {
    await nuxt_plugin_vuemoment_1fc97fc6(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_fabric_68ecb0ca === 'function') {
    await nuxt_plugin_fabric_68ecb0ca(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_fontManager_5c853328 === 'function') {
    await nuxt_plugin_fontManager_5c853328(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_FabricExtendFunctions_fe5f6796 === 'function') {
    await nuxt_plugin_FabricExtendFunctions_fe5f6796(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_MzNonInteractiveGroup_176ba694 === 'function') {
    await nuxt_plugin_MzNonInteractiveGroup_176ba694(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_MzNonInteractiveRect_734100cd === 'function') {
    await nuxt_plugin_MzNonInteractiveRect_734100cd(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_MzNonInteractiveLine_733e56dd === 'function') {
    await nuxt_plugin_MzNonInteractiveLine_733e56dd(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_MzRect_452ae058 === 'function') {
    await nuxt_plugin_MzRect_452ae058(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_MzCircle_e6cb1378 === 'function') {
    await nuxt_plugin_MzCircle_e6cb1378(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_MzTriangle_fb299308 === 'function') {
    await nuxt_plugin_MzTriangle_fb299308(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_MzLine_45283668 === 'function') {
    await nuxt_plugin_MzLine_45283668(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_MzImage_5fb5f167 === 'function') {
    await nuxt_plugin_MzImage_5fb5f167(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_MzTextbox_f8f1faec === 'function') {
    await nuxt_plugin_MzTextbox_f8f1faec(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_MzShapeGroup_c24b9a5c === 'function') {
    await nuxt_plugin_MzShapeGroup_c24b9a5c(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_MzCanvas_e7afa268 === 'function') {
    await nuxt_plugin_MzCanvas_e7afa268(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_MzGroup_5f9c3e8b === 'function') {
    await nuxt_plugin_MzGroup_5f9c3e8b(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_MzPage_4529e903 === 'function') {
    await nuxt_plugin_MzPage_4529e903(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_MzTextGroup_87555144 === 'function') {
    await nuxt_plugin_MzTextGroup_87555144(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_MzCommonMixins_2dfde200 === 'function') {
    await nuxt_plugin_MzCommonMixins_2dfde200(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_canvasTransformActions_d1fd5a10 === 'function') {
    await nuxt_plugin_canvasTransformActions_d1fd5a10(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_canvasGrid_26e7eccd === 'function') {
    await nuxt_plugin_canvasGrid_26e7eccd(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_canvasEffect_088bd558 === 'function') {
    await nuxt_plugin_canvasEffect_088bd558(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_canvasKeyboardControl_5dc1db8f === 'function') {
    await nuxt_plugin_canvasKeyboardControl_5dc1db8f(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_canvasShapeDraw_7e5eb35e === 'function') {
    await nuxt_plugin_canvasShapeDraw_7e5eb35e(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_canvasViewportControl_2ff851f0 === 'function') {
    await nuxt_plugin_canvasViewportControl_2ff851f0(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_canvasSnapObjects_7b9dc2fa === 'function') {
    await nuxt_plugin_canvasSnapObjects_7b9dc2fa(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_MzObjectControls_82f7b1de === 'function') {
    await nuxt_plugin_MzObjectControls_82f7b1de(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_noScaleBehavior_141dc6cc === 'function') {
    await nuxt_plugin_noScaleBehavior_141dc6cc(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_MzTextboxStyle_deb29462 === 'function') {
    await nuxt_plugin_MzTextboxStyle_deb29462(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_MzTextboxWithVariable_1c616b84 === 'function') {
    await nuxt_plugin_MzTextboxWithVariable_1c616b84(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_MzTextboxReflow_fcc1acfa === 'function') {
    await nuxt_plugin_MzTextboxReflow_fcc1acfa(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_MzTextboxList_8b56c680 === 'function') {
    await nuxt_plugin_MzTextboxList_8b56c680(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_MzTextboxEndSign_11c8fdd6 === 'function') {
    await nuxt_plugin_MzTextboxEndSign_11c8fdd6(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_MzTextboxTextLink_f555d42e === 'function') {
    await nuxt_plugin_MzTextboxTextLink_f555d42e(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_MzTextboxDodging_42be991c === 'function') {
    await nuxt_plugin_MzTextboxDodging_42be991c(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_MzTextboxIndent_72c15c4e === 'function') {
    await nuxt_plugin_MzTextboxIndent_72c15c4e(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_MzTextboxDropCap_374471fe === 'function') {
    await nuxt_plugin_MzTextboxDropCap_374471fe(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_MzSpecificControls_2f80bb04 === 'function') {
    await nuxt_plugin_MzSpecificControls_2f80bb04(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_MzTextboxLineStyle_0f00a18a === 'function') {
    await nuxt_plugin_MzTextboxLineStyle_0f00a18a(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_MzTextboxPadding_418644af === 'function') {
    await nuxt_plugin_MzTextboxPadding_418644af(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_MzImageLegend_7f9e7a68 === 'function') {
    await nuxt_plugin_MzImageLegend_7f9e7a68(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_online_66c9680e === 'function') {
    await nuxt_plugin_online_66c9680e(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_now_4c037b52 === 'function') {
    await nuxt_plugin_now_4c037b52(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_websocket_0f1bcf46 === 'function') {
    await nuxt_plugin_websocket_0f1bcf46(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_subscriber_48c868d9 === 'function') {
    await nuxt_plugin_subscriber_48c868d9(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuematomo_4825ec29 === 'function') {
    await nuxt_plugin_vuematomo_4825ec29(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuestripe_03930b4d === 'function') {
    await nuxt_plugin_vuestripe_03930b4d(app.context, inject)
  }

  // If server-side, wait for async component to be resolved first
  if (process.server && ssrContext && ssrContext.url) {
    await new Promise((resolve, reject) => {
      router.push(ssrContext.url, resolve, () => {
        // navigated to a different route in router guard
        const unregister = router.afterEach(async (to, from, next) => {
          ssrContext.url = to.fullPath
          app.context.route = await getRouteData(to)
          app.context.params = to.params || {}
          app.context.query = to.query || {}
          unregister()
          resolve()
        })
      })
    })
  }

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
