import Vue from 'vue';
import { WebsocketJoinEvents } from "~/common/data/WebsocketEvents"

let ws;

const actionsWaitingForConnection = []

const subscriptions = []

// TODO add retry policy

let shouldReconnect = true,
  reconnectNbTry = 0,
  reconnectTimeout = null;

const WS_ENDPOINT = process.env.websocketUrl;
let callbacks = [];
let localToken = "";
let connectionId = null;

async function connect(token) {
  //console.log('WEBSOCKET connect to ' + WS_ENDPOINT, ws);
  if ((!ws || (ws && (ws.readyState === 2 || ws.readyState === 3))) && token) {
    localToken = token;
    shouldReconnect = true;
    connectionId = null;

    try {
      //console.log('WEBSOCKET instantiate');
      ws = new WebSocket(`${WS_ENDPOINT}?token=${token}`);
    } catch (error) {
      console.log('WEBSOCKET instantiate error', error);
    }

    ws.addEventListener('message', (event => {
      console.log('WEBSOCKET message', event);
      if (reconnectTimeout) {
        clearTimeout(reconnectTimeout);
        reconnectTimeout = null;
      }

      const data = event.data;
      if (data) {
        let decodedData;
        try {
          decodedData = JSON.parse(data);
        }
        catch (e) {
          console.log('WEBSOCKET message invalid decode data', e);
        }
        if (decodedData && decodedData.action && decodedData.data) {
          //received connectionId to websocket server
          if (decodedData.action === WebsocketJoinEvents.GetConnectionId) {
            connectionId = decodedData.data.connectionId;
          }
          // send callback to listener
          callbacks.filter(cb => cb.topic === decodedData.action).forEach(cb => cb.listener(decodedData.data))
        }
      }
    }))
    // TODO handle 2hours time to live from aws
    ws.addEventListener('close', (() => {
      //console.log('WEBSOCKET close');
      connectionId = null;

      if (reconnectTimeout) {
        clearTimeout(reconnectTimeout);
        reconnectTimeout = null;
      }

      if (shouldReconnect) {
        // try to reconnect in 5 seconds
        reconnectNbTry++;
        reconnectTimeout = setTimeout(() => { connect(localToken) }, Math.min(60000, 5000 * reconnectNbTry));
      }
    }))

    return new Promise((resolve, reject) => {
      ws.addEventListener('open', ((event) => {
        //console.log('WEBSOCKET open', event);

        reconnectNbTry = 0;
        if (reconnectTimeout) {
          clearTimeout(reconnectTimeout);
          reconnectTimeout = null;
        }

        actionsWaitingForConnection.forEach(item => item.resolve());
        actionsWaitingForConnection.splice(0, actionsWaitingForConnection.length);

        //ask connectionId to websocket server
        send({
          action: WebsocketJoinEvents.GetConnectionId,
          data: {}
        });

        resolve();
      }))

      ws.addEventListener('error', (err => {
        console.log('WEBSOCKET error', err);
        actionsWaitingForConnection.forEach(item => item.reject());
        actionsWaitingForConnection.splice(0, actionsWaitingForConnection.length);
        reject(err);
      }))
    })
  }
}

async function close() {
  //console.log('WEBSOCKET ask close');
  if (ws) {
    shouldReconnect = false;
    reconnectNbTry = 0;
    connectionId = null;
    await ws.close();
    ws = null;
  }
}

async function send(data) {
  //console.log('WEBSOCKET ask send', data);
  if (ws && (ws.readyState === 2 || ws.readyState === 3)) {
    await connect(localToken)
  }

  if (data && ws) {
    if (typeof data === 'object') {
      if (ws.readyState === 0) {
        const promise = new Promise((resolve, reject) => {
          actionsWaitingForConnection.push({
            resolve,
            reject
          })
        })
        await promise;
      }
      //console.log('WEBSOCKET send', data);
      ws.send(JSON.stringify(data))
    }
  }
}

async function addListener(topic, listener) {
  if (topic && listener) {
    callbacks.push({ topic, listener })
  }
}

async function removeListener(topic, listener) {
  if (ws && topic && listener) {
    const index = callbacks.findIndex(callback => callback.topic === topic && callback.listener === listener)
    if (index !== -1) {
      callbacks.splice(index, 1)
    }
  }
}

async function joinMagazine(magazineId) {
  if (!subscriptions.includes(`magazine#${magazineId}`)) {
    subscriptions.push(`magazine#${magazineId}`)
    send({
      action: WebsocketJoinEvents.JoinMagazine,
      data: {
        magazine: magazineId
      }
    })
  }
}

async function leaveMagazine(magazineId) {
  send({
    action: WebsocketJoinEvents.LeaveMagazine,
    data: {
      magazine: magazineId
    }
  })
  const index = subscriptions.indexOf(`magazine#${magazineId}`)
  if (index != -1) {
    subscriptions.splice(index, 1)
  }
}

const Socket = {
  connect,
  close,
  joinMagazine,
  leaveMagazine,
  addListener,
  removeListener,
  getConnectionId: () => { return connectionId; }
}

Vue.prototype.$socket = Socket

export default (ctx, inject) => {
  inject('socket', Socket)
}