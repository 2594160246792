
import { fabric } from "fabric";

/**
 * Non interactive Rectangle used to be drawn on background canvas in MZPage
 */
export default () => {
  fabric.MzNonInteractiveRect = fabric.util.createClass(fabric.Rect, {
    type: 'MzNonInteractiveRect',
    topLayer: false,
    backLayer: false,

    initialize: function (options = {}) {
      this.topLayer = options.topLayer || false;
      this.backLayer = options.backLayer || false;
      options.selectable = false;
      options.evented = false;
      options.hasControls = false;
      options.hasBorders = false;
      options.excludeFromExport = true;
      this.callSuper('initialize', options);
    },

  });

  fabric.MzNonInteractiveRect.fromObject = function (object, callback) {
    return fabric.Object._fromObject('MzNonInteractiveRect', object, callback);
  }
}