import Vue from 'vue'

// Reactive value to use in the plugin
const onlineState = Vue.observable({
  online: true,
  retries: 0,
  lastRetryTime: 0,
  nextRetryTime: 0
})

// Determine how long we have to wait, based on the number of retries
function getDelay(retries) {
  if (retries < 5)
    return 2000;
  else if (retries < 10)
    return 10000;
  else return 60000;
}

export default ({ $axios, store }) => {
  Vue.prototype.$online = onlineState

  let timeoutCheck,
    timeoutTriggerOffline;

  function setTimeoutCheck() {
    const retryDelta = getDelay(onlineState.retries);
    console.log('ONLINE setTimeoutCheck ', retryDelta);
    onlineState.retries++
    onlineState.lastRetryTime = Date.now()
    onlineState.nextRetryTime = Date.now() + retryDelta

    store.dispatch("api/API_STATUS").then((result) => {
      console.log('ONLINE check API_STATUS ', result);
      if (!result && onlineState.online) {
        onlineState.online = false;
      }
    }).catch(error => {
      console.log('ONLINE check API_STATUS error ', error);
      if (onlineState.online) {
        onlineState.online = false;
      }
    });

    clearTimeout(timeoutCheck);
    timeoutCheck = setTimeout(setTimeoutCheck, retryDelta);

  }

  const triggerOffline = function (data) {
    console.log('ONLINE triggerOffline ' + data);
    clearTimeout(timeoutTriggerOffline);

    timeoutTriggerOffline = setTimeout(() => {
      if (onlineState.online) {
        setTimeoutCheck();
      }
    }, 1500);
  }

  const triggerOnline = function () {
    clearTimeout(timeoutCheck)
    clearTimeout(timeoutTriggerOffline)

    if (!onlineState.online) {
      console.log('ONLINE triggerOnline');
      onlineState.online = true
      onlineState.retries = 0
      onlineState.lastRetryTime = 0
      onlineState.nextRetryTime = 0
    }
  }

  $axios.interceptors.response.use(response => {
    triggerOnline();
    return response;
  }, error => {
    if (!error.response) {
      // network error (server is down or no internet)
      triggerOffline('no response');
    } else {
      triggerOnline();
      // http status code
      const code = error.response.status
      if (code === 502) { //aws load balancer timeout
        triggerOffline('502 response');
      }
    }
    return Promise.reject(error)
  })
}