var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-col',{staticClass:"el-col-cardoffer"},[(_vm.offer)?_c('el-card',{staticClass:"m-1 p-2",class:{
      'alternate-color': _vm.alternateColor,
    },attrs:{"shadow":"none","body-style":_vm.bodyStyle}},[_c('div',{staticClass:"el-header"},[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('h2',[_vm._v(_vm._s(_vm.offer.name))]),_vm._v(" "),_c('h2',[_vm._v(_vm._s(_vm.productPrice))])])]),_vm._v(" "),_c('el-row',{staticClass:"mt-2"},[_vm._v("\n      "+_vm._s(_vm.$t("public.card_magazine_offer.forOnePublication"))+"\n    ")]),_vm._v(" "),_c('el-row',{staticClass:"mt-0"},[_vm._v("\n      "+_vm._s(_vm.$t("public.card_magazine_offer.templateChoice"))+"\n    ")]),_vm._v(" "),_c('el-row',{staticClass:"mt-0"},[_vm._v("\n      "+_vm._s(_vm.$t("public.card_magazine_offer.interactivity"))+"\n    ")]),_vm._v(" "),_c('el-row',{staticClass:"mt-0"},[_vm._v("\n      "+_vm._s(_vm.offer.offerSettings.limits.numberCollaborators === -1
          ? _vm.$t("public.card_magazine_offer.illimitedCollaborator")
          : _vm.$tc(
              "public.card_magazine_offer.numberOfCollaborator",
              _vm.offer.offerSettings.limits.numberCollaborators - 1,
              { nb: _vm.offer.offerSettings.limits.numberCollaborators - 1 }
            ))+"\n    ")]),_vm._v(" "),_c('el-row',{staticClass:"mt-0"},[_vm._v("\n      "+_vm._s(_vm.offer.offerSettings.accessList.indexOf("magazine:trackingViewer") >= 0
          ? _vm.$t("public.card_magazine_offer.advancedTracking")
          : _vm.$t("public.card_magazine_offer.basicTracking"))+"\n    ")]),_vm._v(" "),(
        _vm.offer.offerSettings.accessList.indexOf('magazine:pdfRequest:web') >= 0
      )?_c('el-row',{staticClass:"mt-0"},[_vm._v("\n      "+_vm._s(_vm.$t("public.card_magazine_offer.pdfWeb"))+"\n    ")]):_vm._e(),_vm._v(" "),(
        _vm.offer.offerSettings.accessList.indexOf('magazine:pdfRequest:print') >=
        0
      )?_c('el-row',{staticClass:"mt-0"},[_vm._v("\n      "+_vm._s(_vm.$t("public.card_magazine_offer.pdfPrint"))+"\n    ")]):_vm._e(),_vm._v(" "),(
        _vm.offer.offerSettings.accessList.indexOf(
          'magazine:privacyManagement'
        ) >= 0
      )?_c('el-row',{staticClass:"mt-0"},[_vm._v("\n      "+_vm._s(_vm.$t("public.card_magazine_offer.privacyManagement"))+"\n    ")]):_vm._e(),_vm._v(" "),(
        _vm.offer.offerSettings.accessList.indexOf(
          'magazine:personnalizeViewer'
        ) >= 0
      )?_c('el-row',{staticClass:"mt-0"},[_vm._v("\n      "+_vm._s(_vm.$t("public.card_magazine_offer.personnalizeViewer"))+"\n    ")]):_vm._e(),_vm._v(" "),(
        _vm.offer.offerSettings.accessList.indexOf(
          'magazine:pdfRequest:viewercode'
        ) >= 0
      )?_c('el-row',{staticClass:"mt-0"},[_vm._v("\n      "+_vm._s(_vm.$t("public.card_magazine_offer.viewerCode"))+"\n    ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"card-footer"},[_c('el-row',{staticClass:"mt-2",attrs:{"type":"flex","justify":"center"}},[(_vm.isCurrent)?_c('el-button',{attrs:{"disabled":true,"type":"info"}},[_vm._v("\n          "+_vm._s(_vm.$t("public.card_magazine_offer.btnIsCurrent"))+"\n        ")]):_c('el-button',{attrs:{"disabled":_vm.disabled,"type":"primary"},on:{"click":function($event){return _vm.$emit('select', _vm.offer)}}},[_vm._v("\n          "+_vm._s(_vm.$t("public.card_magazine_offer.btnChoose"))+"\n        ")])],1),_vm._v(" "),_c('el-row',{staticClass:"mt-2",attrs:{"type":"flex","justify":"center"}},[_c('el-link',{attrs:{"type":"primary","href":"https://madmagz.com/pricing","target":"_blank"}},[_vm._v("\n          "+_vm._s(_vm.$t("public.card_magazine_offer.viewMore"))+"\n        ")])],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }