import { fabric } from 'fabric';
import Utils from "~/common/utils/misc";

export default () => {
  fabric.util.object.extend(
    fabric.MzCanvas.prototype,
    /** @lends fabric.MzCanvas.prototype */
    {
      maxScrollY: 0,
      maxScrollX: 0,
      useSpaceForPanning: true,
      _canvasRatioWidth: 1,

      initViewportControl(options) {
        this.maxScrollY = options.maxScrollY;
        this.maxScrollX = options.maxScrollX;
        this.useSpaceForPanning = options.useSpaceForPanning;
        this.initZoom();
        this.initImageControls();
        this.initPanning();
      },
      disableViewportControl() {
        this.disablePanning();
        this.disableZoom();
        this.disableImageControl();
      },

      initZoom: function () {
        this.on("mouse:wheel", this.onZoom);
      },
      disableZoom: function () {
        this.off("mouse:wheel", this.onZoom);
      },

      /**
       * Allow panning when middle click+drag or alt + drag
       */
      initPanning() {
        this.on("mouse:down", this.onMouseDown);
      },
      disablePanning() {
        this.off("mouse:down", this.onMouseDown);
        this.off("mouse:move", this.onPanMove);
        this.off("mouse:up", this.onPanStop);
      },

      /**
       * Image controls for cropping when moving objects
       */
      initImageControls() {
        this.on("before:transform", this.onObjectMove);
      },
      disableImageControl() {
        this.off("before:transform", this.onObjectMove);
      },

      /**
       * When shit+drag on image, set action to crop
       * (see canvasTransformAction.js that handle custom transformations)
       * @param {*} opt
       */
      onObjectMove(opt) {
        if (
          opt.transform.target.type === "MzImage" &&
          opt.transform.shiftKey &&
          opt.transform.action === "drag"
        ) {
          opt.transform.action = "crop";
        }
      },

      onMouseDown(opt) {
        //disable if drawing
        if (this.drawModeEnabled) {
          return;
        }
        // if shift key hold, draw selection rectangle and no pan
        if (opt.e.shiftKey) {
          this.enableSelectionRect = true;

          this.on("mouse:up", this.onSelectStop);
          return;
        }

        let canPan = false;
        if (this.useSpaceForPanning) {
          canPan = this.spaceIsDown;
        } else {
          canPan = opt.target === null || opt.button === 3;
        }

        if (canPan) {
          this.startPan(opt);
        }

        if (
          opt.transform &&
          opt.transform.action === "edit"
        ) {
          if (opt.transform.target.type === "MzTextbox") {
            this.fire("text:editContent");
          } else if (opt.transform.target.type === "MzImage") {
            this.fire("image:editContent");
          }
          return;
        }
      },
      startPan(opt) {
        this.discardActiveObject();
        this.isDragging = true;
        this.selection = false;
        this.lastPosX = opt.e.clientX;
        this.lastPosY = opt.e.clientY;
        this.on("mouse:move", this.onPanMove);
        this.on("mouse:up", this.onPanStop);
        this.off("mouse:down", this.onMouseDown);
      },
      onPanMove(opt) {
        if (this.isDragging) {
          var vpt = this.viewportTransform.slice(0);
          vpt[4] += opt.e.clientX - this.lastPosX;
          vpt[5] += opt.e.clientY - this.lastPosY;
          this.setViewportTransform(vpt);
          this.zoomOrPanHasChange();
          this.lastPosX = opt.e.clientX;
          this.lastPosY = opt.e.clientY;
        }
      },
      onPanStop(opt) {
        this.off("mouse:move", this.onPanMove);
        this.off("mouse:up", this.onPanStop);
        this.isDragging = false;
        this.selection = true;
        const objects = this.getObjects();
        for (let i = 0; i < objects.length; i++) {
          objects[i].setCoords();
        }
        this.on("mouse:down", this.onMouseDown);
      },

      onSelectStop(opt) {
        this.off("mouse:up", this.onSelectStop);
        this.enableSelectionRect = false;
      },

      // override fabric setZoom to center viewport content and check limit
      setZoom: function (value) {
        const viewWidth = this.getWidth() * this._canvasRatioWidth,
          viewHeight = this.getHeight();
        const contentWidth = this.maxScrollX * this.viewportTransform[0],
          contentHeight = this.maxScrollY * this.viewportTransform[3];
        const left = this.viewportTransform[4],
          top = this.viewportTransform[5];
        const isZoomUpper = this.viewportTransform[0] < value;

        // center zoom to page content
        let zoomToX = viewWidth / 2;
        if (left + contentWidth / 2 < viewWidth / 2 - 100) {
          zoomToX = isZoomUpper ? left : left + contentWidth;
        } else if (left + contentWidth / 2 > viewWidth / 2 + 100) {
          zoomToX = isZoomUpper ? left + contentWidth : left;
        }
        let zoomToY = viewHeight / 2;
        if (top + contentHeight / 2 < viewHeight / 2 - 100) {
          zoomToY = isZoomUpper ? top : top + contentHeight;
        } else if (top + contentHeight / 2 > viewHeight / 2 + 100) {
          zoomToY = isZoomUpper ? top + contentHeight : top;
        }

        this.zoomToPoint(
          new fabric.Point(zoomToX, zoomToY),
          value
        );

        this.zoomOrPanHasChange();
        return this;
      },

      onZoom(opt) {
        opt.e.preventDefault();
        opt.e.stopPropagation();
        //prevent zoom if ctrl key (reserved for image scaling)
        if (opt.target && opt.e.shiftKey) {
          return this.onWheelScale(opt);
        }

        let delta = opt.e.deltaMode === 0 ?
          (Math.round(opt.e.deltaY / 3.5) * -1) // chrome
          :
          (Math.round(opt.e.deltaY * 5) * -1); // firefox

        // don't zoom if to small wheel
        if (delta > -15 && delta < 15) {
          return;
        }
        // limit zoom at 15 max
        if (delta < -15) {
          delta = -15;
        } else if (delta > 15) {
          delta = 15;
        }

        let zoom = Math.min(
          3,
          Math.max(0.1, Math.round(this.getZoom() * 100 + delta) * 0.01)
        );
        zoom = Math.round(zoom / 0.05) * 0.05; // round modulo 5
        // remove zoom to mouse cursor, always zoom or unzoom to center of screen
        // this.zoomToPoint({
        //   x: opt.e.offsetX,
        //   y: opt.e.offsetY
        // }, zoom);
        //
        //this.zoomOrPanHasChange();

        this.setZoom(zoom)

        this.fire("canvas:zoom", {
          zoom: zoom
        });
      },

      zoomOrPanHasChange() {
        this.checkViewportLimits();
        this.renderGrid();
        this.renderEffect();
      },

      checkViewportLimits: function () {
        const zoom = this.getZoom();

        const contentWidth = this.maxScrollX * zoom;
        const viewWidth = this.getWidth() * this._canvasRatioWidth;
        const contentHeight = this.maxScrollY * zoom;
        const viewHeight = this.getHeight();

        let maxX, minX, maxY, minY;
        // for keep all page in viewport area if possible
        // maxX = contentWidth <= viewWidth ? viewWidth - contentWidth : 100;
        // minX =
        //   contentWidth <= viewWidth ? 0 : viewWidth - contentWidth - 100;
        // maxY =
        //   contentHeight <= viewHeight ? viewHeight - contentHeight + 100 : 100;
        // minY =
        //   contentHeight <= viewHeight ? -100 : viewHeight - contentHeight - 100;

        // for keep only ..px minimum visible in viewport area
        const minVisiblePx = 30;
        maxX = viewWidth - minVisiblePx;
        minX = -contentWidth + minVisiblePx;
        maxY = viewHeight - minVisiblePx;
        minY = -contentHeight + minVisiblePx;


        if (this.viewportTransform[4] > maxX) {
          this.viewportTransform[4] = maxX;
        } else if (this.viewportTransform[4] < minX) {
          this.viewportTransform[4] = minX;
        }

        if (this.viewportTransform[5] > maxY) {
          this.viewportTransform[5] = maxY;
        } else if (this.viewportTransform[5] < minY) {
          this.viewportTransform[5] = minY;
        }

        this.requestRenderAll();
      },

      /**
       * Scale Object (Images) using mouse wheel
       * @param {*} opt
       */
      onWheelScale(opt) {
        let target = opt.target;
        const delta = Math.round(opt.e.deltaY / 50) * 0.01;
        let sx = Utils.roundToPrecision(target.scaleX * 100, 2) * 0.01 - delta;
        let sy = Utils.roundToPrecision(target.scaleY * 100, 2) * 0.01 - delta;

        sx = Math.min(1, Math.max(0.01, sx));
        sy = Math.min(1, Math.max(0.01, sy));
        target.set("scaleX", sx);
        target.set("scaleY", sy);

        let options = {
          target: target,
          e: opt.e,
          transform: {
            action: "scale"
          },
          pointer: opt.absolutePointer
        };

        target.setCoords();
        this.fire("object:wheelScaling", options);
        this.requestRenderAll();
      },

      zoomToObject(object, zoom = 1) {
        this.setZoom(1); // reset zoom so pan actions work as expected
        let vpw = this.width / zoom;
        let vph = this.height / zoom;
        let x = object.left - vpw / 2; // x is the location where the top left of the viewport should be
        let y = object.top - vph / 2; // y idem
        this.absolutePan({
          x: x,
          y: y
        });
        // this.setZoom(zoom)
      },

      zoomToTexBox(object, animateDuration = 0) {
        const marginHT = 220;
        const marginHB = 90;
        const marginW = 40;
        const maxZoom = 1.5;

        let pointTL = object.aCoords.tl,
          pointBR = object.aCoords.br;

        if (object.hasReflow) {
          const textgroup = fabric.textGroups.get(object.textgroupId);
          if (textgroup) {
            for (let i = 0; i < textgroup.textBoxes.length; i++) {
              const otherTL = textgroup.textBoxes[i].aCoords.tl,
                otherBR = textgroup.textBoxes[i].aCoords.br;
              if (otherTL.x < pointTL.x) {
                pointTL.x = otherTL.x;
              }
              if (otherTL.y < pointTL.y) {
                pointTL.y = otherTL.y;
              }
              if (otherBR.x > pointBR.x) {
                pointBR.x = otherBR.x;
              }
              if (otherBR.y > pointBR.y) {
                pointBR.y = otherBR.y;
              }
            }
          }
        }

        const left = pointTL.x,
          top = pointTL.y,
          width = pointBR.x - pointTL.x,
          height = pointBR.y - pointTL.y;

        // unzoom to match container
        const zoomX =
          Math.floor(
            ((0.5 * this.width) / (width + 2 * marginW)) * 10
          ) * 0.1,
          zoomY =
            Math.floor((this.height / (height + marginHT + marginHB)) * 10) *
            0.1,
          zoom = Math.min(maxZoom, zoomX, zoomY);

        const viewWidth = this.getWidth() * this._canvasRatioWidth;

        let x = left * zoom - ((viewWidth - width * zoom) / 2);
        let y = top * zoom - marginHT;

        this.setZoomAndPosition(zoom, -x, -y, animateDuration);

        return zoom;
      },

      setZoomAndPosition(zoom, x, y, animateDuration = 0) {
        if (animateDuration <= 0) {
          var vpt = this.viewportTransform.slice(0);
          vpt[0] = vpt[3] = zoom; // setZoom
          vpt[4] = x; // absolutePan X
          vpt[5] = y; // absolutePan Y
          this.setViewportTransform(vpt);
        } else {
          let _this = this;
          const _startX = this.viewportTransform[4],
            _startY = this.viewportTransform[5],
            _startZoom = this.viewportTransform[0],
            _diffX = x - _startX,
            _diffY = y - _startY,
            _diffZoom = zoom - _startZoom;

          fabric.util.animate({
            startValue: 0,
            endValue: 1,
            duration: animateDuration,
            onChange: function (value) {
              //_this.setZoom(_startZoom + _diffZoom * value);
              //_this.absolutePan({ x: _startX + _diffX * value, y: _startY + _diffY * value });
              // override for merge setZoom and absolutePan
              var vpt = _this.viewportTransform.slice(0);
              vpt[0] = vpt[3] = _startZoom + _diffZoom * value; // setZoom
              vpt[4] = (_startX + _diffX * value); // absolutePan X
              vpt[5] = (_startY + _diffY * value); // absolutePan Y
              return _this.setViewportTransform(vpt);
            },
            onComplete: function () {
              // _this.setZoom(zoom);
              // _this.absolutePan({ x: x, y: y });
              // override for merge setZoom and absolutePan
              var vpt = _this.viewportTransform.slice(0);
              vpt[0] = vpt[3] = zoom; // setZoom
              vpt[4] = x; // absolutePan X
              vpt[5] = y; // absolutePan Y
              return _this.setViewportTransform(vpt);
            }
          });
        }
      },
      centerOnScreen(margin = {}, animateDuration = 0) {
        const viewWidth = (this.getWidth() * this._canvasRatioWidth) - (margin.left || 0);
        const viewHeight = this.getHeight() - (margin.top || 0);
        // unzoom to match container
        const zoomX = Math.floor((viewWidth / this.maxScrollX) * 10) / 10;
        const zoomY = Math.floor((viewHeight / this.maxScrollY) * 10) / 10;
        const zoom = Math.min(1, zoomX, zoomY);

        // center in container page
        let translateX =
          (viewWidth - this.maxScrollX * zoom) * 0.5 + (margin.left || 0);
        let translateY =
          (viewHeight - this.maxScrollY * zoom) * 0.5 + (margin.top || 0);

        this.setZoomAndPosition(zoom, translateX, translateY, animateDuration);

        return zoom;
      },
      setCanvasRatioWidth(value) {
        this._canvasRatioWidth = Math.min(1, Math.max(0, value));
      },

      /* override */
      mouseDownTimeout: null,
      mouseDownTimeoutCallback: null,
      __onMouseDown(e) {
        if (this.useSpaceForPanning) {
          this.callSuper('__onMouseDown', e);
          return;
        }

        if (this.mouseDownTimeoutCallback !== null) {
          this.mouseDownTimeoutCallback();
        }

        this.on("mouse:move", this.invalidateOnMouseDown);
        this.on("mouse:up:before", this.validateOnMouseDown);

        this.mouseDownTimeoutCallback = (validate = true) => {
          this.mouseDownTimeoutCallback = null;
          if (this.mouseDownTimeout !== null) {
            clearTimeout(this.mouseDownTimeout);
            this.mouseDownTimeout = null;
          }

          this.off("mouse:move", this.invalidateOnMouseDown);
          this.off("mouse:up:before", this.validateOnMouseDown);

          if (validate) {
            this.callSuper('__onMouseDown', e);
          }
        }
        this.mouseDownTimeout = setTimeout(this.mouseDownTimeoutCallback, 150);
      },

      invalidateOnMouseDown(opt) {
        if (this.mouseDownTimeoutCallback !== null) {
          this.mouseDownTimeoutCallback(false);
        }

        // start pan action
        this.startPan(opt);
      },

      validateOnMouseDown() {
        if (this.mouseDownTimeoutCallback !== null) {
          this.mouseDownTimeoutCallback(true);
        }
      }

    }
  );
};
