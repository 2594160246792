var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-container',{attrs:{"id":"app"}},[_c('el-header',{staticClass:"app-header"},[_c('span',{staticClass:"nav-button",on:{"click":_vm.toogleLeftMenu}},[_c('img',{attrs:{"src":"/icons/nav.svg"}})]),_vm._v(" "),_c('span',{staticClass:"header-content ml-2"},[(_vm.headerType === 'templates')?_c('TemplatesHeader'):(_vm.headerType === 'editTemplate')?_c('EditTemplateHeader'):(_vm.headerType === 'formats')?_c('FormatsHeader'):(_vm.headerType === 'adminUsers')?_c('AdminUsersHeader'):(_vm.headerType === 'offers')?_c('OffersHeader'):(_vm.headerType === 'organizations')?_c('OrganizationsHeader'):(_vm.headerType === 'authStrategies')?_c('AuthStrategiesHeader'):(_vm.headerType === 'groupOffers')?_c('GroupOffersHeader'):_c('img',{attrs:{"src":"/logo-admin.svg"}})],1),_vm._v(" "),_c('span',{staticClass:"mr-2 text-right"},[_vm._v("\n      "+_vm._s(_vm.nameFromEmail)+"\n      "),_c('br'),_vm._v(" "),_vm._l((_vm.user.roles),function(role){return _c('el-tooltip',{key:role,attrs:{"content":role}},[_c('img',{staticClass:"mr-0 ml-0",attrs:{"src":'/icons/admin-role-' + role + '.svg'}})])})],2)]),_vm._v(" "),(_vm.menuIsVisible)?_c('div',{staticClass:"app-menu-clickout",on:{"click":_vm.closeLeftMenu}}):_vm._e(),_vm._v(" "),_c('transition',{attrs:{"name":"openMenu"}},[(_vm.menuIsVisible)?_c('el-aside',{staticClass:"app-menu"},[_c('el-container',{staticClass:"app-menu-content"},[_c('el-row',{attrs:{"type":"flex","justify":"center"}},[_c('img',{attrs:{"src":"/logo-admin.svg"}})]),_vm._v(" "),_c('el-collapse',{staticClass:"mt-2",attrs:{"accordion":""},model:{value:(_vm.currentCollapseOpened),callback:function ($$v) {_vm.currentCollapseOpened=$$v},expression:"currentCollapseOpened"}},[_c('el-row',{staticClass:"mt-1",attrs:{"type":"flex","align":"middle"}},[_c('img',{attrs:{"src":"/icons/menu-stats.svg"}}),_vm._v(" "),_c('el-link',{staticClass:"header-link-primary",class:{
                'active-link': _vm.activeRouteName === 'admin',
              },on:{"click":function($event){return _vm.onClickMenuLink({ name: 'admin' })}}},[_vm._v(_vm._s(_vm.$t("admin.layout.stats")))])],1),_vm._v(" "),_c('el-collapse-item',{staticClass:"mt-1",attrs:{"name":"editor","disabled":!_vm.checkPerm('template:list')}},[_c('template',{slot:"title"},[_c('img',{attrs:{"src":"/icons/menu-editor.svg"}}),_vm._v(" "),_c('el-link',{staticClass:"header-link-primary",class:{
                  'active-link': _vm.activeRouteName === 'admin-template',
                },on:{"click":function($event){return _vm.onClickMenuLink({ name: 'admin-template' })}}},[_vm._v(_vm._s(_vm.$t("admin.layout.editor")))])],1),_vm._v(" "),_c('el-row',[_c('el-link',{staticClass:"header-link-submenu",class:{
                  'active-link': _vm.activeRouteName === 'admin-template-list',
                },on:{"click":function($event){return _vm.onClickMenuLink({ name: 'admin-template-list' })}}},[_vm._v(_vm._s(_vm.$t("admin.layout.templates")))])],1),_vm._v(" "),_c('el-row',[_c('el-link',{staticClass:"header-link-submenu",class:{
                  'active-link': _vm.activeRouteName === 'admin-format-list',
                },on:{"click":function($event){return _vm.onClickMenuLink({ name: 'admin-format-list' })}}},[_vm._v(_vm._s(_vm.$t("admin.layout.formats")))])],1)],2),_vm._v(" "),_c('el-row',{staticClass:"mt-1",attrs:{"type":"flex","align":"middle"}},[_c('img',{attrs:{"src":"/icons/menu-users.svg"}}),_vm._v(" "),_c('el-link',{staticClass:"header-link-primary",class:{
                'active-link': _vm.activeRouteName === 'admin-users-clientList',
              },attrs:{"disabled":!_vm.checkPerm('users:clientList')},on:{"click":function($event){return _vm.onClickMenuLink({ name: 'admin-users-clientList' })}}},[_vm._v(_vm._s(_vm.$t("admin.layout.users")))])],1),_vm._v(" "),_c('el-row',{staticClass:"mt-1",attrs:{"type":"flex","align":"middle"}},[_c('img',{attrs:{"src":"/icons/menu-organizations.svg"}}),_vm._v(" "),_c('el-link',{staticClass:"header-link-primary",class:{
                'active-link':
                  _vm.activeRouteName === 'admin-organization-list-byGroupOffer',
              },attrs:{"disabled":!_vm.checkPerm('organization:list')},on:{"click":function($event){return _vm.onClickMenuLink({
                  name: 'admin-organization-list-byGroupOffer',
                })}}},[_vm._v(_vm._s(_vm.$t("admin.layout.groupOffers")))])],1),_vm._v(" "),_c('el-row',{staticClass:"mt-1",attrs:{"type":"flex","align":"middle"}},[_c('img',{attrs:{"src":"/icons/menu-organizations.svg"}}),_vm._v(" "),_c('el-link',{staticClass:"header-link-primary",class:{
                'active-link': _vm.activeRouteName === 'admin-organization-list',
              },attrs:{"disabled":!_vm.checkPerm('organization:list')},on:{"click":function($event){return _vm.onClickMenuLink({
                  name: 'admin-organization-list',
                })}}},[_vm._v(_vm._s(_vm.$t("admin.layout.organizations")))])],1),_vm._v(" "),_c('el-row',{staticClass:"mt-1",attrs:{"type":"flex","align":"middle"}},[_c('img',{attrs:{"src":"/icons/menu-organizations.svg"}}),_vm._v(" "),_c('el-link',{staticClass:"header-link-primary",class:{
                'active-link': _vm.activeRouteName === 'admin-workspace-list',
              },attrs:{"disabled":!_vm.checkPerm('workspace:list')},on:{"click":function($event){return _vm.onClickMenuLink({
                  name: 'admin-workspace-list',
                })}}},[_vm._v(_vm._s(_vm.$t("admin.layout.workspaces")))])],1),_vm._v(" "),_c('el-row',{staticClass:"mt-1",attrs:{"type":"flex","align":"middle"}},[_c('img',{attrs:{"src":"/icons/menu-magazines.svg"}}),_vm._v(" "),_c('el-link',{staticClass:"header-link-primary",class:{
                'active-link': _vm.activeRouteName === 'admin-magazine-list',
              },attrs:{"disabled":!_vm.checkPerm('magazine:clientList')},on:{"click":function($event){return _vm.onClickMenuLink({ name: 'admin-magazine-list' })}}},[_vm._v(_vm._s(_vm.$t("admin.layout.magazines")))])],1),_vm._v(" "),_c('el-row',{staticClass:"mt-1",attrs:{"type":"flex","align":"middle"}},[_c('img',{attrs:{"src":"/icons/menu-orders.svg"}}),_vm._v(" "),_c('el-link',{staticClass:"header-link-primary",class:{
                'active-link': _vm.activeRouteName === 'admin-order-list',
              },attrs:{"disabled":!_vm.checkPerm('order:adminList')},on:{"click":function($event){return _vm.onClickMenuLink({ name: 'admin-order-list' })}}},[_vm._v(_vm._s(_vm.$t("admin.layout.orders")))])],1),_vm._v(" "),_c('el-collapse-item',{staticClass:"mt-1",attrs:{"name":"settings"}},[_c('template',{slot:"title"},[_c('img',{attrs:{"src":"/icons/menu-settings.svg"}}),_vm._v(" "),_c('el-link',{staticClass:"header-link-primary",class:{
                  'active-link': _vm.activeRouteName === 'admin-settings',
                },on:{"click":function($event){return _vm.onClickMenuLink({ name: 'admin-settings' })}}},[_vm._v(_vm._s(_vm.$t("admin.layout.settings")))])],1),_vm._v(" "),_c('el-row',[_c('el-link',{staticClass:"header-link-submenu",class:{
                  'active-link': _vm.activeRouteName === 'admin-users-adminList',
                },attrs:{"disabled":!_vm.checkPerm('users:adminList')},on:{"click":function($event){return _vm.onClickMenuLink({ name: 'admin-users-adminList' })}}},[_vm._v(_vm._s(_vm.$t("admin.layout.adminUsers")))])],1),_vm._v(" "),_c('el-row',[_c('el-link',{staticClass:"header-link-submenu",class:{
                  'active-link':
                    _vm.activeRouteName === 'admin-authStrategy-list',
                },attrs:{"disabled":!_vm.checkPerm('authStrategy:adminList')},on:{"click":function($event){return _vm.onClickMenuLink({ name: 'admin-authStrategy-list' })}}},[_vm._v("Auth Strategies")])],1),_vm._v(" "),_c('el-row',[_c('el-link',{staticClass:"header-link-submenu",class:{
                  'active-link': _vm.activeRouteName === 'admin-offer-list',
                },attrs:{"disabled":!_vm.checkPerm('offer:list')},on:{"click":function($event){return _vm.onClickMenuLink({ name: 'admin-offer-list' })}}},[_vm._v(_vm._s(_vm.$t("admin.layout.offers")))])],1),_vm._v(" "),_c('el-row',[_c('el-link',{staticClass:"header-link-submenu",class:{
                  'active-link':
                    _vm.activeRouteName === 'admin-settings-maintenance',
                },attrs:{"disabled":!_vm.checkPerm('maintenance:update')},on:{"click":function($event){return _vm.onClickMenuLink({ name: 'admin-settings-maintenance' })}}},[_vm._v(_vm._s(_vm.$t("admin.layout.maintenance")))])],1),_vm._v(" "),_c('el-row',[_c('el-link',{staticClass:"header-link-submenu",class:{
                  'active-link': _vm.activeRouteName === 'admin-settings-message',
                },attrs:{"disabled":!_vm.checkPerm('maintenance:update')},on:{"click":function($event){return _vm.onClickMenuLink({ name: 'admin-settings-message' })}}},[_vm._v(_vm._s(_vm.$t("admin.layout.message")))])],1)],2)],1),_vm._v(" "),_c('el-row',{staticClass:"app-menu-fit-height"}),_vm._v(" "),_c('el-row',{staticClass:"mt-3",attrs:{"type":"flex","align":"middle"}},[_c('img',{attrs:{"src":"/icons/menu-logout.svg"}}),_vm._v(" "),_c('el-link',{staticClass:"header-link-secondary",on:{"click":function($event){return _vm.logout()}}},[_vm._v(_vm._s(_vm.$t("public.layout.logout")))])],1),_vm._v(" "),(_vm.checkPerm('editor:all'))?_c('el-row',{staticClass:"mt-1"},[_c('el-link',{staticClass:"header-link-client-interface",attrs:{"type":"primary"},on:{"click":function($event){return _vm.onClickMenuLink({ name: 'client' })}}},[_c('img',{attrs:{"src":"/icons/open_external_link.svg"}}),_vm._v("\n            "+_vm._s(_vm.$t("admin.layout.client_interface"))+"\n          ")])],1):_vm._e()],1),_vm._v(" "),_c('el-container',{staticClass:"app-menu-extend-button"},[_c('img',{attrs:{"src":"/icons/extend.svg"},on:{"click":_vm.closeLeftMenu}})])],1):_vm._e()],1),_vm._v(" "),(_vm.isAutorizedUser)?_c('el-main',{staticClass:"app-main"},[_c('nuxt')],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"version"},[_vm._v("api: "+_vm._s(_vm.apiBuild)+" | app: "+_vm._s(_vm.appBuild))]),_vm._v(" "),_c('MaintenanceAlert'),_vm._v(" "),_c('InactiveUserAlert'),_vm._v(" "),_c('OfflineModal',{attrs:{"visible":_vm.isOffline}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }