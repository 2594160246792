<template>
  <div>
    <el-dialog
      :visible.sync="displayWaitingDialog"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      append-to-body
    >
      <span slot="title" class="el-dialog__title">
        {{ dialogTitle }}
        <button
          @click="cancelProcess"
          type="button"
          aria-label="Close"
          class="el-dialog__headerbtn"
        >
          <i class="el-dialog__close el-icon el-icon-close"></i>
        </button>
      </span>

      <el-row class="content-page-editor">
        <PageEditor
          ref="pageEditorModal"
          :editDisabled="true"
          :lowDefinitionImage="true"
          :backgroundProcess="true"
          @ready="onEditorReady"
        />
      </el-row>

      <el-row type="flex" align="middle">
        <el-col>
          <el-progress
            :text-inside="true"
            :stroke-width="26"
            :percentage="globalPercentage"
          ></el-progress>
        </el-col>

        <el-button
          v-if="displayCallbackButton"
          @click.native="onClickCallbackButton"
          class="ml-2"
          :disabled="disabledCallbackButton"
          size="medium"
          type="primary"
        >
          {{ callbackButtonLabel }}
        </el-button>
      </el-row>

      <el-row class="mt-1" v-if="checkPerm('debug:all')">
        <small>
          Pages to generate:
          {{
            this.pagesToGenerate
              ? this.currentGenerateIndex + "/" + this.pagesToGenerate.length
              : "-"
          }}
          | Pdf status : {{ pdfStatus.pdfProgressState }}
        </small>
      </el-row>

      <!-- <el-row v-if="displayPageState">
        <el-col
          :span="6"
          v-for="page in generatePageState"
          :key="page.key"
          class="mt-1"
        >
          <span>{{ page.name }}</span>
          <span v-if="page.palette" class="ml-0">({{ page.palette }})</span>

          <el-tag
            class="ml-1"
            effect="dark"
            :type="page.stateTagType"
            size="mini"
            >{{ page.state }}</el-tag
          >
        </el-col>
      </el-row> -->
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import FontLoaderUtils from "~/common/utils/fontLoaderUtils";

import PageEditor from "~/components/editor/PageEditor";
import {
  PdfProgressStatus,
  PdfProgressPercentageByStatus,
} from "~/common/data/RequestPdfStatus";

export default {
  name: "RequestPdfOrPublishModal",
  components: { PageEditor },

  props: [],
  data() {
    return {
      dialogTitle: "",
      displayWaitingDialog: false,
      currentGenerateIndex: 0,
      pagesToGenerate: null,
      callbackAfterGenerate: null,
      statusInterval: null,
      callbackOnHideDialog: null,
      isPublishProcess: false,
      displayCallbackButton: false,
      disabledCallbackButton: false,
      callbackButtonLabel: "",
      forceCompletePourcentage: false,
    };
  },
  computed: {
    ...mapState("magazine", ["magazine", "pdfStatus"]),
    ...mapState("theme", ["theme"]),
    ...mapGetters("api", ["checkPerm"]),
    ...mapGetters("app", ["adminMode"]),
    ...mapGetters("theme", ["getFormatedStylesForFabric"]),

    pageStatePercentage() {
      return this.pagesToGenerate
        ? Math.round(
            (this.currentGenerateIndex * 100) / this.pagesToGenerate.length
          )
        : 0;
    },
    pdfStatePercentage() {
      if (
        this.pdfStatus &&
        this.pdfStatus.pdfProgressState &&
        PdfProgressPercentageByStatus[this.pdfStatus.pdfProgressState]
      ) {
        return PdfProgressPercentageByStatus[this.pdfStatus.pdfProgressState];
      } else {
        return 0;
      }
    },
    globalPercentage() {
      if (this.forceCompletePourcentage) {
        return 100;
      }

      if (this.pagesToGenerate === null) {
        // no page to generate in client side, return only percentage of server state
        return this.pdfStatePercentage;
      } else {
        // return percentage of current generate page && percentage of server side progress
        return Math.round(
          (this.pageStatePercentage + this.pdfStatePercentage) / 2
        );
      }
    },
  },
  beforeDestroy() {
    this.stopRequestStatusInterval();
  },
  methods: {
    ...mapActions({
      loadMagazine: "magazine/LOAD_MAGAZINE",
      publishMagazine: "magazine/PUBLISH_MAGAZINE",
      requestExportMagazinePdf: "magazine/REQUEST_EXPORT_MAGAZINE_PDF",
      requestExportMagazinePdfStatus:
        "magazine/REQUEST_EXPORT_MAGAZINE_PDF_STATUS",
      generatePageThumb: "page/GENERATE_PAGE_THUMB",
    }),
    ...mapMutations({
      resetPdfStatus: "magazine/RESET_PDF_STATUS_STATE",
    }),

    reloadDataAndHideDialog(executeCallback = true) {
      //force reload magazine before hide dialog
      this.loadMagazine({
        magazineId: this.magazine._id,
        loadType: "current",
      }).then(() => {
        this.hideDialog(executeCallback);
      });
    },
    hideDialog(executeCallback = true) {
      this.forceCompletePourcentage = true;
      this.stopRequestStatusInterval();

      if (this.$refs.pageEditorModal) {
        this.$refs.pageEditorModal.unloadEditor();
      }

      if (executeCallback && this.callbackOnHideDialog) {
        if (this.displayCallbackButton) {
          this.disabledCallbackButton = false;
          return;
        } else {
          this.callbackOnHideDialog();
        }
      }

      this.displayWaitingDialog = false;
      this.displayCallbackButton = false;
      this.disabledCallbackButton = false;
      this.callbackOnHideDialog = null;
      this.isPublishProcess = false;
      this.forceCompletePourcentage = false;
    },

    onClickCallbackButton() {
      if (this.callbackOnHideDialog) {
        this.callbackOnHideDialog();
      }
      this.hideDialog(false);
    },

    askCleanBeforeRequestPdf(version, callbackFct = null) {
      if (this.displayWaitingDialog) {
        return;
      }

      this.$confirm(
        "Do you wan't to clean all data (existing SVG and PDF files) before request the new PDF version ?",
        "Clean all data before request",
        {
          confirmButtonText: "No, just request the pdf with existing files",
          cancelButtonText: "Yes, clean all",
          distinguishCancelAndClose: true,
        }
      )
        .then(() => {
          this.requestPdf(version, callbackFct);
        })
        .catch((action) => {
          if (action === "cancel") {
            this.requestPdf(version, callbackFct, true);
          }
        });
    },

    requestPdf(
      version,
      callbackFct = null,
      forceCleanAll = false,
      callbackButtonLabel = null
    ) {
      if (this.displayWaitingDialog) {
        return;
      }

      this.resetPdfStatus();

      this.forceCompletePourcentage = false;
      this.pagesToGenerate = null;
      this.callbackAfterGenerate = null;
      this.callbackOnHideDialog = callbackFct;
      this.isPublishProcess = false;
      if (callbackButtonLabel) {
        this.displayCallbackButton = true;
        this.disabledCallbackButton = true;
        this.callbackButtonLabel = callbackButtonLabel;
      }

      if (version == "viewercode") {
        this.dialogTitle = this.$t("public.regenModal.requestViewerCodeTitle");
      } else if (version == "draft") {
        this.dialogTitle = this.$t("public.regenModal.requestPreviewTitle");
      } else {
        this.dialogTitle = this.$t("public.regenModal.requestPdfTitle");
      }

      if (
        !this.adminMode &&
        (version == "draft" || version == "web" || version == "viewercode")
      ) {
        this.displayWaitingDialog = true;
      }

      this._sendRequestPdf(version, forceCleanAll);
    },

    _sendRequestPdf(
      version,
      forceCleanAll = false,
      forceGenerateWithInvalidPage = false
    ) {
      this.requestExportMagazinePdf({
        magazineId: this.magazine._id,
        version,
        forceCleanAll,
        isAdminRequest: this.adminMode,
        forceGenerateWithInvalidPage,
      }).then((response) => {
        if (response && response.missingSvgs) {
          console.log("missing svgs", response.missingSvgs);
          this.displayWaitingDialog = true;
          this.callbackAfterGenerate = () => {
            this._sendRequestPdf(version, false, true);
          };
          this.regenMissingSvgs(response.missingSvgs);
        } else if (
          !response ||
          (version != "draft" && version != "web" && version != "viewercode")
        ) {
          this.reloadDataAndHideDialog();
        } else {
          this.startRequestStatusInterval(version);
        }
      });
    },

    requestPdfStatus(version) {
      if (this.displayWaitingDialog) {
        return;
      }

      this.requestExportMagazinePdfStatus({
        magazineId: this.magazine._id,
        version,
      }).then((response) => {
        if (!response) {
          console.log("no response for requestPdfStatus");
          // Websocket notif has been lost... refresh magazine data
          this.reloadDataAndHideDialog();
        } else if (response && response.missingSvgs) {
          this.resetPdfStatus();
          this.displayWaitingDialog = true;
          this.dialogTitle = this.$t("public.regenModal.requestPdfTitle");
          this.callbackAfterGenerate = () => {
            this._sendRequestPdf(version);
          };
          this.regenMissingSvgs(response.missingSvgs);
        } else {
          this.reloadDataAndHideDialog();
        }
      });
    },

    switchPublish(callbackFct = null) {
      if (this.displayWaitingDialog) {
        return;
      }

      this.isPublishProcess = false;

      if (this.magazine.status !== "Published") {
        let confirmMsg = this.magazine.isTemplate
          ? this.$t("admin.publish_template.publish_message")
          : this.$t("public.publish_magazine.publish_message");
        const confirmTitle = this.magazine.isTemplate
            ? this.$t("admin.publish_template.publish_title")
            : this.$t("public.publish_magazine.publish_title"),
          confirmBtn = this.magazine.isTemplate
            ? this.magazine.isPrivate
              ? this.$t("admin.publish_template.private_publish_button")
              : this.$t("admin.publish_template.public_publish_button")
            : this.$t("public.publish_magazine.publish_button");

        if (!this.magazine.isTemplate) {
          console.log("check mag page " + this.magazine.pageCount);
          if (
            this.magazine.pageCount < 8 ||
            this.magazine.pageCount % 4 !== 0
          ) {
            confirmMsg += this.$t(
              "public.publish_magazine.message_invalid_page_count"
            );
          }
        }

        this.$confirm(confirmMsg, confirmTitle, {
          dangerouslyUseHTMLString: true,
          confirmButtonText: confirmBtn,
          cancelButtonText: this.$t("public.alert.cancel"),
        })
          .then(() => {
            this.confirmPublish(callbackFct);
          })
          .catch(() => {
            // cancel
          });
      } else {
        const confirmMsg = this.magazine.isTemplate
            ? this.$t("admin.publish_template.unpublish_message")
            : this.$t("public.publish_magazine.unpublish_message"),
          confirmTitle = this.magazine.isTemplate
            ? this.$t("admin.publish_template.unpublish_title")
            : this.$t("public.publish_magazine.unpublish_title"),
          confirmBtn = this.magazine.isTemplate
            ? this.$t("admin.publish_template.unpublish_button")
            : this.$t("public.publish_magazine.unpublish_button");

        this.$confirm(confirmMsg, confirmTitle, {
          confirmButtonText: confirmBtn,
          cancelButtonText: this.$t("public.alert.cancel"),
        })
          .then(() => {
            this.confirmUnpublish(callbackFct);
          })
          .catch(() => {
            // cancel
          });
      }
    },
    confirmPublish(callbackFct = null) {
      if (this.displayWaitingDialog) {
        return;
      }

      this.resetPdfStatus();

      this.forceCompletePourcentage = false;
      this.pagesToGenerate = null;
      this.callbackAfterGenerate = null;
      this.isPublishProcess = true;
      this.callbackOnHideDialog = callbackFct;
      this.dialogTitle = this.$t("public.regenModal.publishTitle");
      this.displayCallbackButton = false;
      this.displayWaitingDialog = true;

      // force flatplan to close current page
      this.$bus.$emit("unselectPageFlatplan", true);

      this._sendPublish();
    },
    _sendPublish() {
      this.publishMagazine({ publish: true }).then((response) => {
        console.log(
          "_sendPublish response",
          JSON.parse(JSON.stringify(response))
        );
        if (response && response.missingSvgs) {
          this.callbackAfterGenerate = () => {
            this._sendPublish();
          };
          this.regenMissingSvgs(response.missingSvgs);
        } else if (response && response.invalidPages) {
          this.callbackAfterGenerate = () => {
            this._sendPublish();
          };
          this.regenInvalidPages(response.invalidPages);
        } else if (!response) {
          this.reloadDataAndHideDialog();
        } else {
          this.startRequestStatusInterval("web");
        }
      });
    },
    confirmUnpublish(callbackFct = null) {
      // force flatplan to close current page
      this.$bus.$emit("unselectPageFlatplan", true);

      this.publishMagazine({ publish: false }).then(() => {
        if (callbackFct) {
          callbackFct();
        }
      });
    },
    regenMissingSvgs(missingSvgs) {
      this.pagesToGenerate = missingSvgs.map((pageId) => {
        return {
          page: pageId,
          palette: this.magazine.isTemplate
            ? this.theme.paletteList[0]._id
            : null,
          missingSvg: true,
          missingPng: false,
        };
      });
      this.startGeneratePageThumb();
    },
    regenInvalidPages(invalidPages) {
      this.pagesToGenerate = invalidPages;
      this.startGeneratePageThumb();
    },
    startGeneratePageThumb() {
      this.currentGenerateIndex = 0;
      this.displayWaitingDialog = true;

      console.log(
        "startGeneratePageThumb",
        JSON.parse(JSON.stringify(this.pagesToGenerate))
      );
      this.generateNextPageThumb();
    },
    generateNextPageThumb() {
      if (this.currentGenerateIndex < this.pagesToGenerate.length) {
        if (!this.$refs.pageEditorModal) {
          // bug fix wait editor is renderer
          this.displayWaitingDialog = true;
          this.$nextTick(this.generateNextPageThumb);
          return;
        }
        const currentGenerateData =
          this.pagesToGenerate[this.currentGenerateIndex];
        this.$refs.pageEditorModal.loadEditorForPage(
          currentGenerateData.page,
          currentGenerateData.palette
        );
      } else {
        // all pages has been generate

        // force flatplan to reload current page
        this.$bus.$emit("forceReloadPageFlatplan");

        // resend action after 0.25sec
        setTimeout(() => {
          if (this.callbackAfterGenerate) {
            this.callbackAfterGenerate();
            this.callbackAfterGenerate = null;
          } else {
            this.hideDialog();
          }
        }, 250);
      }
    },
    onEditorReady() {
      if (!this.displayWaitingDialog) {
        // action has been cancel during loading
        return;
      }

      const currentGenerateData =
        this.pagesToGenerate[this.currentGenerateIndex];

      const svgData = currentGenerateData.missingSvg
        ? this.$fabric.page.getSVG({
            fonts: FontLoaderUtils.fontListUrl([
              ...this.$fontManager.defaultFonts,
              ...this.theme.fonts,
            ]),
            styles: this.getFormatedStylesForFabric,
          })
        : undefined;
      const thumbData = currentGenerateData.missingPng
        ? this.$fabric.page.getThumb()
        : undefined;

      this.generatePageThumb({
        pageId: currentGenerateData.page,
        paletteId: currentGenerateData.palette,
        isDefaultPalette:
          currentGenerateData.palette &&
          currentGenerateData.palette === this.theme.paletteList[0]._id,
        svgData,
        thumbData,
      }).then(() => {
        // generate next thumb
        this.currentGenerateIndex++;
        this.generateNextPageThumb();
      });
    },

    startRequestStatusInterval(version) {
      console.log("startRequestStatusInterval for " + version);
      // start interval for refresh status if websocket is HS
      if (this.statusInterval) {
        this.stopRequestStatusInterval();
      }
      this.statusInterval = setInterval(() => {
        console.log("startRequestStatusInterval send for " + version);
        this.requestExportMagazinePdfStatus({
          magazineId: this.magazine._id,
          version,
          updateStore: true,
        }).then((response) => {
          if (!response) {
            console.log("no response for startRequestStatusInterval");
            // Websocket notif has been lost... refresh magazine data
            this.reloadDataAndHideDialog();
          }
        });
      }, 5000);
    },
    stopRequestStatusInterval() {
      if (this.statusInterval) {
        clearInterval(this.statusInterval);
        this.statusInterval = null;
      }
    },
    cancelProcess() {
      if (this.isPublishProcess && this.magazine.status === "Published") {
        this.publishMagazine({ publish: false });
      }

      this.hideDialog(false);
    },
  },
  watch: {
    pdfStatus() {
      if (
        this.displayWaitingDialog &&
        this.pdfStatus.pdfProgressState === PdfProgressStatus.EndProcess
      ) {
        this.reloadDataAndHideDialog();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.content-page-editor {
  //display: none;
  height: 1px;
  width: 1px;
}
</style>