import Vue from 'vue'

const REFRESH_DELAY = 500

/**
 * Reactive internal clock in the app, can be used for reactive timers
 */

// Reactive value to use in the plugin
const timeState = Vue.observable({
  time: Date.now(),
})

setInterval(() => timeState.time = Date.now(), REFRESH_DELAY)

export default () => {
  Vue.prototype.$now = timeState
}