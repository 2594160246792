import { fabric } from 'fabric';
import MzMixins from './mixins/MzCommonMixins';

/**
 * Rectangle that rezize instead of scaling
 */
export default () => {


  fabric.MzRect = fabric.util.createClass(fabric.Rect, {
    type: 'MzRect',

    initialize: function (options) {
      // inject Mz common properties
      Object.assign(this, MzMixins);
      options = this.initMzObject(options);

      this.callSuper('initialize', options);
      this.initNoScale();
    },
    initNoScale: function () {
      this.on({
        scaled: e => {
          let w = this.width * this.scaleX;
          let h = this.height * this.scaleY;

          this.set({
            height: h,
            width: w,
            scaleX: 1,
            scaleY: 1
          });
        }
      });
    },
  });

  fabric.MzRect.fromObject = function (object, callback) {
    return fabric.Object._fromObject('MzRect', object, callback);
  };

};
