import Vue from 'vue';
import VueMatomo from 'vue-matomo';

export default ({ app }) => {
  if (process.env.VUE_MATOMO_ID && process.env.VUE_MATOMO_URL) {
    console.log('instantiate matomo plugin for ' + process.env.VUE_MATOMO_URL);
    Vue.use(VueMatomo, {
      router: app.router,
      host: process.env.VUE_MATOMO_URL,
      siteId: process.env.VUE_MATOMO_ID,
      /** Other configuration options **/
    });
  }
}