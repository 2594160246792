import { fabric } from 'fabric';
import Utils from "~/common/utils/misc";

export default () => {
  let setObjectScaleOverridden = fabric.MzCanvas.prototype._setObjectScale;

  /**
   * Override scale behavior for MzTextbox to resize it instead of scaling
   * @override
   */
  fabric.MzCanvas.prototype._setObjectScale = function (localMouse, transform,
    lockScalingX, lockScalingY, by, lockScalingFlip, _dim) {

    // constraint position to grid if enabled
    let pt = this.snapPointToGrid(localMouse, transform.target);
    // snap to objects if active
    pt = this.scaleSnapTo(transform.target, by, pt);

    let mouseX = pt.x;
    let mouseY = pt.y;

    let t = transform.target,
      scaled;
    let scaleX = Utils.roundToPrecision(mouseX * t.scaleX / _dim.x, 2);
    let scaleY = Utils.roundToPrecision(mouseY * t.scaleY / _dim.y, 2);

    // Textbox resize behavior
    if (t.type === 'MzTextbox') {
      let tt = t._getTransformedDimensions();
      let w = Utils.roundToPrecision(t.width * (mouseX / tt.x), 2);
      let h = Utils.roundToPrecision(t.height * (mouseY / tt.y), 2);
      transform.newScaleX = scaleX;
      transform.newScaleY = scaleY;

      if ((by === 'x' || by === 'equally') && w >= t.getMinWidth()) {
        scaled = w !== t.width;
        t.set('width', w);
      }
      if ((by === 'y' || by === 'equally') && h >= t.getMinHeight()) {
        scaled = h !== t.height || scaled;
        t.set('height', h);
      }
      return scaled;
    }

    // image Resize Behavior
    // if (t.type === 'MzImage') {
    //   let tt = t._getTransformedDimensions();
    //   let w = t.width * (mouseX / tt.x);
    //   let h = t.height * (mouseY / tt.y)
    //   transform.newScaleX = scaleX;
    //   transform.newScaleY = scaleY;

    //   if (by === 'x' || by === 'equally') {
    //     scaled = w !== t.width;
    //     t.set('width', Utils.bound(10, Math.round(w), t._originalElement.width)); // remove check max witdh on resize image
    //   }

    //   if (by === 'y' || by === 'equally') {
    //     scaled = h !== t.height || scaled;
    //     t.set('height', Utils.bound(10, Math.round(h), t._originalElement.height)); // remove check max height on resize image
    //   }
    //   return scaled
    // } else {
    return setObjectScaleOverridden.call(fabric.MzCanvas.prototype, {
      x: mouseX,
      y: mouseY
    }, transform,
      lockScalingX, lockScalingY, by, lockScalingFlip, _dim);
    //}
  };


}
