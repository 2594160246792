<template>
  <el-row type="flex" align="left">
    <el-button
      type="primary"
      v-if="checkPerm('authStrategy:adminCreate')"
      @click="$refs.editAuthStrategyModal.showCreate()"
      >Create an auth strategy</el-button
    >

    <EditAuthStrategyModal ref="editAuthStrategyModal" />
  </el-row>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";

import EditAuthStrategyModal from "~/components/authStrategy/EditAuthStrategyModal";

export default {
  name: "AuthStrategiesHeader",

  components: { EditAuthStrategyModal },

  props: [],
  data() {
    return {};
  },
  computed: {
    ...mapGetters("api", ["checkPerm"]),
  },

  methods: {},
};
</script>

<style scoped lang="scss">
</style>