import { fabric } from 'fabric';
import MzMixins from './mixins/MzCommonMixins';

/**
 * Rectangle that rezize instead of scaling
 */
export default () => {


  fabric.MzGroup = fabric.util.createClass(fabric.Group, {
    type: 'MzGroup',
    selectInsideGroup: false,

    initialize: function (objects, options, isAlreadyGrouped) {
      // inject Mz common properties
      Object.assign(this, MzMixins);
      options = this.initMzObject(options);

      this.lockScalingX = true;
      this.lockScalingY = true;

      this.callSuper('initialize', objects, options, isAlreadyGrouped);
    }
  });

  fabric.MzGroup.fromObject = function (object, callback) {
    fabric.util.enlivenObjects(object.objects, function (enlivenedObjects) {
      fabric.util.enlivenObjects([object.clipPath], function (enlivedClipPath) {
        var options = fabric.util.object.clone(object, true);
        options.clipPath = enlivedClipPath[0];
        delete options.objects;
        callback && callback(new fabric.MzGroup(enlivenedObjects, options, true));
      });
    });
  };

};
