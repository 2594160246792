<template>
  <el-row type="flex" align="left">
    <el-button
      v-if="checkPerm('organization:create')"
      type="primary"
      @click="showNewOrganizationModal"
      >{{ $t("admin.organizations.createNewOrganization") }}</el-button
    >

    <NewOrganizationModal ref="newOrganizationModal" />
  </el-row>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";

import NewOrganizationModal from "~/components/organization/NewOrganizationModal";

export default {
  name: "OrganizationsHeader",

  components: { NewOrganizationModal },

  props: [],
  data() {
    return {};
  },
  computed: {
    ...mapGetters("api", ["checkPerm"]),
  },

  methods: {
    showNewOrganizationModal() {
      this.$refs.newOrganizationModal.show();
    },
  },
};
</script>

<style scoped lang="scss">
</style>