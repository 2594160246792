<template>
  <el-dialog
    :title="$t('layout.invit_user_modal.title')"
    :visible.sync="displayModal"
    :close-on-click-modal="false"
    width="50%"
    append-to-body
  >
    <div v-loading="loading">
      <el-row class="mt-1">
        <el-col :span="2">
          <h2 v-t="'layout.invit_user_modal.action'"></h2>
        </el-col>
        <el-col :span="10" :offset="4">
          <el-checkbox v-model="form.sendInvit">{{
            $t("layout.invit_user_modal.send_invit_mail")
          }}</el-checkbox>
          <el-checkbox v-model="exportToCSV">{{
            $t("layout.invit_user_modal.export_to_csv")
          }}</el-checkbox>
        </el-col>
        <el-col :span="10" :offset="4">
          <small>Invitation mail will only send for new created users</small>
        </el-col>
      </el-row>

      <el-row class="mt-2">
        <el-col :span="8">
          <h2>Organization</h2>
        </el-col>
        <el-col :span="14" class="ml-2">
          <el-select
            class="el-col el-col-24"
            v-model="form.organization"
            filterable
            placeholder="Select an organization"
          >
            <el-option
              v-for="organization in organizations"
              :key="organization._id"
              :label="organization.name"
              :value="organization._id"
            >
            </el-option>
          </el-select>
        </el-col>
      </el-row>

      <el-row class="mt-2">
        <el-col :span="2">
          <h2 v-t="'layout.invit_user_modal.roles'"></h2>
        </el-col>
        <el-col :span="18">
          <el-row type="flex" align="middle" justify="center">
            <div v-for="role in formatedRoles" :key="role.name" class="mr-2">
              <div class="text-style-3 text-grey">{{ role.label }}</div>
              <el-switch
                v-model="role.selected"
                class="mt-0"
                :disabled="!checkPerm(role.updatePerm)"
                @change="changeRole(role.name, $event)"
              ></el-switch>
            </div>
          </el-row>
        </el-col>
      </el-row>
      <el-row class="mt-2">
        <el-col :span="2">
          <h2 v-t="'layout.invit_user_modal.tag'"></h2>
        </el-col>
        <el-col :span="10" :offset="4">
          <el-input
            v-model="form.tag"
            :placeholder="$t('layout.invit_user_modal.tag_placeholder')"
            clearable
          />
        </el-col>
      </el-row>

      <br />

      <h2>
        {{ $t("layout.invit_user_modal.emails") }}
        <el-radio-group
          v-model="displayTextareaList"
          size="mini"
          style="float: right"
        >
          <el-radio-button :label="false">{{
            $t("layout.invit_user_modal.display_input_list")
          }}</el-radio-button>
          <el-radio-button :label="true">{{
            $t("layout.invit_user_modal.display_textarea_list")
          }}</el-radio-button>
        </el-radio-group>
      </h2>

      <el-row class="mt-1">
        <el-col :span="18" :offset="2">
          <el-form
            label-position="left"
            label-width="50px"
            :model="form"
            ref="form"
            @submit.native.prevent
          >
            <el-form-item v-if="displayTextareaList">
              <el-input
                type="textarea"
                :rows="5"
                :placeholder="
                  $t('layout.invit_user_modal.textarea_placeholder')
                "
                v-model="emailsList"
              ></el-input>
            </el-form-item>

            <el-form-item
              v-else
              v-for="(email, index) in form.emails"
              :label="'#' + (index + 1)"
              :key="index"
              :prop="'emails.' + index + '.value'"
              :rules="[
                {
                  type: 'email',
                  message: $t('layout.invit_user_modal.check_valid_email'),
                  trigger: ['blur', 'change'],
                },
              ]"
            >
              <el-row type="flex">
                <el-select v-model="email.userType" class="mr-1">
                  <el-option value="internal" />
                  <el-option value="guest" />
                </el-select>
                <el-input v-model="email.value">
                  <el-button
                    slot="append"
                    icon="el-icon-la-trash"
                    @click.prevent="removeEmail(index)"
                  ></el-button>
                </el-input>
                <el-button
                  icon="el-icon-la-plus"
                  type="success"
                  circle
                  class="add-email-button"
                  @click.prevent="addEmail()"
                  v-if="index === form.emails.length - 1"
                ></el-button>
              </el-row>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="onClickCancel" v-t="'public.alert.cancel'"></el-button>
      <el-button
        type="primary"
        v-t="'public.alert.confirm'"
        :disabled="validateButtonDisabled"
        @click="onClickValidate"
      ></el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "InvitAdminUserModal",

  props: {},
  computed: {
    ...mapState("user", ["roles"]),
    ...mapGetters("api", ["checkPerm"]),
    ...mapState("organization", ["organizations"]),

    validateButtonDisabled() {
      return (
        this.loading === true ||
        this.form.organization === null ||
        this.form.roles.length === 0 ||
        this.form.emails.filter((email) => email.value !== "").length === 0
      );
    },
    formatedRoles() {
      return this.roles.reduce((list, role) => {
        list.push({
          ...role,
          selected: this.form.roles.indexOf(role.name) !== -1,
        });
        return list;
      }, []);
    },
    emailsList: {
      get() {
        const emails = this.form.emails.reduce((list, email) => {
          list.push(email.value);
          return list;
        }, []);
        return emails.join(";");
      },
      set(value) {
        const emails = value.split(";");
        if (emails.length === 0) {
          // add empty first emails
          this.form.emails.push({ value: "", userType: "internal" });
        } else {
          this.form.emails = emails.reduce((list, email) => {
            list.push({
              value: email.trim(),
              userType: "internal",
            });
            return list;
          }, []);
        }
      },
    },
  },
  data() {
    return {
      loading: false,
      displayModal: false,
      displayTextareaList: false,
      exportToCSV: false,
      form: {
        emails: [{ value: "", userType: "internal" }],
        roles: ["user"],
        tag: null,
        organization: null,
        sendInvit: true,
      },
    };
  },
  methods: {
    ...mapActions({
      loadOrganizations: "organization/LOAD_ORGANIZATIONS",
      invitAdminUsers: "user/INVIT_ADMIN_USERS",
    }),

    hide() {
      this.displayModal = false;
    },
    show() {
      this.loadOrganizations({ filter: {} });
      this.displayModal = true;
    },
    onClickCancel() {
      this.hide();
    },
    onClickValidate() {
      // display list emails before validate form
      this.displayTextareaList = false;

      this.$nextTick(() => {
        this.$refs.form.validate((valid) => {
          if (valid === true) {
            this.loading = true;
            this.invitAdminUsers({
              organization: this.form.organization,
              emails: this.form.emails,
              roles: this.form.roles,
              tag: this.form.tag,
              sendInvit: this.form.sendInvit,
            }).then((users) => {
              this.loading = false;
              if (users) {
                if (this.form.sendInvit && users.length > 0) {
                  this.$message({
                    type: "success",
                    message: this.$t(
                      "layout.invit_user_modal.invit_users_success"
                    ),
                  });
                }
                if (this.exportToCSV && users.length > 0) {
                  this.exportUsersToCSV(users);
                }

                this.form = {
                  organization: null,
                  emails: [{ value: "", userType: "internal" }],
                  roles: ["user"],
                  tag: null,
                  sendInvit: true,
                };
                this.hide();
                this.$emit("invitFinish");
              }
            });
          } else {
            this.$message({
              type: "error",
              message: this.$t("layout.invit_user_modal.invalid_email"),
            });
          }
        });
      });
    },
    exportUsersToCSV(users) {
      if (users.length > 0) {
        const keysToExport = [
          "_id",
          "name",
          "email",
          "tag",
          "invitationCode",
          "status",
          "createdAt",
        ];

        let csvContent = "data:text/csv;charset=utf-8,";
        csvContent += [
          keysToExport.join(","),
          ...users.map((item) => {
            const _formated = [];
            for (let i = 0; i < keysToExport.length; i++) {
              _formated.push(item[keysToExport[i]]);
            }
            return _formated.join(",");
          }),
        ].join("\n");

        const data = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", data);
        link.setAttribute("download", "export_invitation_user_list.csv");
        link.click();
      }
    },
    addEmail() {
      this.form.emails.push({ value: "", userType: "internal" });
    },
    removeEmail(index) {
      if (this.form.emails.length > 1) {
        this.form.emails.splice(index, 1);
      } else {
        this.form.emails[0].value = "";
        this.form.emails[0].userType = "internal";
      }
    },
    changeRole(role, value) {
      if (value) {
        if (this.form.roles.indexOf(role) === -1) {
          this.form.roles.push(role);
        }
      } else {
        const _index = this.form.roles.indexOf(role);
        if (_index >= 0) {
          this.form.roles.splice(_index, 1);
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.add-email-button {
  right: -75px;
  position: absolute;
}
</style>