
export const WebsocketJoinEvents = {
  GetConnectionId: "GET_CONNECTION_ID",
  JoinMagazine: "JOIN_MAGAZINE",
  LeaveMagazine: "LEAVE_MAGAZINE",
  /*JoinWorkspace: "JOIN_WORKSPACE",*/
}

export const WebsocketDispatchTypeEvents = {
  DispatchEventToUser: "DISPATCH_TO_USER",
  DispatchEventToMagazine: "DISPATCH_TO_MAGAZINE"
}

export const WebsocketEvents = {
  LogoutUser: "LOGOUT_USER",
  UpdateUserAccess: "UPDATE_USER_ACCESS",
  UpdatePage: "UPDATE_PAGE",
  UpdateThumbnail: "UPDATE_THUMBNAIL",
  UpdateMagazine: "UPDATE_MAGAZINE",
  UpdateMagazinesList: "UPDATE_MAGAZINE_LIST",
  UpdateLibrary: "UPDATE_LIBRARY",
  UpdateCollaboration: "UPDATE_COLLABORATION",
  UpdateOrder: "UPDATE_ORDER",
  RemoveOrder: "REMOVE_ORDER",
  NewNotification: "NEW_NOTIFICATION",
  UpdatePdfStatus: "UPDATE_PDF_STATUS",
  UpdateMaintenance: "UPDATE_MAINTENANCE",
  UpdateWorkspace: "UPDATE_WORKSPACE",
}