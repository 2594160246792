export default {

  isUrl(str) {
    if (!str || str.length < 1) {
      return false;
    }
    const regexp = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
    return regexp.test(str);
  },

  isEmail(str) {
    if (!str || str.length < 1) {
      return false;
    }
    const regexp = /^\w+([\.\-\\+]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,99})+$/;
    return regexp.test(str);
  },

  isVideoUrl(str) {
    const youtubeReg = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/i,
      vimeoReg = /^.*vimeo.com\/(\d+)($|\/|\b)/i,
      dailymotionReg = /^.*(?:\/video|dai.ly)\/([A-Za-z0-9]+)([^#\&\?]*).*/i;

    return youtubeReg.test(str) || vimeoReg.test(str) || dailymotionReg.test(str);
  },

  isValidPassword(str) {
    if (!str || str.length < 8) {
      return false;
    }
    const strongReg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
    return strongReg.test(str)
  }

}