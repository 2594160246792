<template>
  <el-row type="flex" align="left">
    <el-button
      type="primary"
      v-if="checkPerm('offer:create')"
      @click="$refs.editOfferModal.showCreate()"
      v-t="'admin.offers.createOffer'"
    ></el-button>

    <EditOfferModal ref="editOfferModal" />
  </el-row>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";

import EditOfferModal from "~/components/offer/EditOfferModal";

export default {
  name: "OffersHeader",

  components: { EditOfferModal },

  props: [],
  data() {
    return {};
  },
  computed: {
    ...mapGetters("api", ["checkPerm"]),
  },

  methods: {},
};
</script>

<style scoped lang="scss">
</style>