import Vue from 'vue'

const bus = new Vue()

const eventBus = {}

eventBus.install = function (Vue) {
  Vue.prototype.$bus = bus
}

Vue.use(eventBus)

export default (ctx, inject) => {
  inject('bus', bus)
}