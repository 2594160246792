
import { fabric } from "fabric";

/**
 * Non interactive Rectangle used to be drawn on background canvas in MZPage
 */
export default () => {
  fabric.MzNonInteractiveLine = fabric.util.createClass(fabric.Line, {
    type: 'MzNonInteractiveLine',
    topLayer: false,
    backLayer: false,

    initialize: function (element, options = {}) {
      this.topLayer = options.topLayer || false;
      this.backLayer = options.backLayer || false;
      options.selectable = false;
      options.evented = false;
      options.hasControls = false;
      options.hasBorders = false;
      options.excludeFromExport = true;
      this.callSuper('initialize', element, options);
    },

  });

  fabric.MzNonInteractiveLine.fromObject = function (object, callback) {
    return fabric.Object._fromObject('MzNonInteractiveLine', object, callback);
  }
}