import { fabric } from 'fabric';
import GraphicUtils from '~/common/utils/graphicUtils';

export default () => {
  fabric.util.object.extend(
    fabric.MzTextbox.prototype,
    /** @lends fabric.MzTextbox.prototype */
    {
      /**
       * Override control drawing to displaye linked textbox
       * @param {*} ctx
       * @param {*} styleOverride
       */
      drawControls: function (ctx, styleOverride) {
        styleOverride = styleOverride || {};
        if (this.hasReflow) {
          let oDimm = this._calculateCurrentDimensions(); // origin x: width, y: height
          let origin = new fabric.Point(oDimm.x * 0.5, oDimm.y * 0.5);

          if (this.childTextBoxId) {
            let childTextBox = this.canvas.getObjectById(this.childTextBoxId);
            if (childTextBox) {
              GraphicUtils.drawArrow(ctx, origin, this.getObjectLocalCoord(childTextBox, 'tl'), 14, '#b7b8b9');
            }
          }

          if (this.parentTextBoxId) {
            let parentTextBox = this.canvas.getObjectById(this.parentTextBoxId);
            if (parentTextBox) {
              origin = origin.multiply(-1);
              GraphicUtils.drawArrow(ctx, this.getObjectLocalCoord(parentTextBox, 'br'), origin, 14, '#b7b8b9');
            }
          }
        }

        if (this.hasRestoreLastValidProps) {
          styleOverride.cornerColor = '#ff0000';
        }

        this.callSuper('drawControls', ctx, styleOverride);
      },

      /**
       * Override border drawing for any objects
       * @param {*} ctx
       * @param {*} styleOverride
       * @override
       */
      drawBorders: function (ctx, styleOverride) {
        styleOverride = styleOverride || {};
        // set red line box when text is out of limits
        if (this.hasRestoreLastValidProps || this.textOverflow) {
          styleOverride.borderColor = '#ff0000';
          setTimeout(() => {
            this.hasRestoreLastValidProps = false;
          }, 300);
        }

        // skip custom border rendering if Admin mode
        if (this.canvas.adminMode === true) {
          return this.callSuper('drawBorders', ctx, styleOverride);
        }

        // don't show textbox border in client mode
        return this;
        /*
                // override native method:
                const wh = this._calculateCurrentDimensions(),
                  strokeWidth = 1 / this.borderScaleFactor,
                  width = wh.x + strokeWidth,
                  height = wh.y + strokeWidth,
                  drawRotatingPoint = typeof styleOverride.hasRotatingPoint !== 'undefined' ?
                    styleOverride.hasRotatingPoint : this.hasRotatingPoint,
                  hasControls = typeof styleOverride.hasControls !== 'undefined' ?
                    styleOverride.hasControls : this.hasControls,
                  rotatingPointOffset = typeof styleOverride.rotatingPointOffset !== 'undefined' ?
                    styleOverride.rotatingPointOffset : this.rotatingPointOffset;

                ctx.save();
                ctx.strokeStyle = styleOverride.borderColor || this.borderColor;
                this._setLineDash(ctx, styleOverride.borderDashArray || this.borderDashArray, null);

                ctx.lineWidth = 2;
                const padding = 0;

                GraphicUtils.drawRoundedRect(
                  ctx,
                  -width / 2 - padding,
                  -height / 2 - padding,
                  width + 2 * padding,
                  height + 2 * padding,
                  4
                );

                if (drawRotatingPoint && this.isControlVisible('mtr') && hasControls) {
                  const rotateHeight = -height / 2;
                  ctx.beginPath();
                  ctx.moveTo(0, rotateHeight);
                  ctx.lineTo(0, rotateHeight - rotatingPointOffset);
                  ctx.stroke();
                }

                ctx.restore();
                return this;
        */
      },

      _drawControl: function (control, ctx, methodName, left, top, styleOverride) {
        if (!this.isControlVisible(control)) {
          return;
        }

        if (control === 'tr') {
          const iconImage = new Image();
          iconImage.src = "/icons/edit.svg";
          ctx.drawImage(iconImage, left, top, 15, 15);
          return;
        }

        return this.callSuper('_drawControl', control, ctx, methodName, left, top, styleOverride);
      }
    }
  );

  fabric.util.object.extend(
    fabric.MzImage.prototype,
    /** @lends fabric.MzImage.prototype */
    {
      _drawControl: function (control, ctx, methodName, left, top, styleOverride) {
        if (!this.isControlVisible(control)) {
          return;
        }

        if (control === 'tr') {
          const iconImage = new Image();
          iconImage.src = "/icons/edit.svg";
          ctx.drawImage(iconImage, left, top, 15, 15);
          return;
        }

        return this.callSuper('_drawControl', control, ctx, methodName, left, top, styleOverride);
      }
    }
  );

  /**
   * add specific action and icon to corner
   * https://stackoverflow.com/questions/35630508/add-delete-button-on-element-in-canvas-fabric-js
   * @override
   */
  fabric.util.object.extend(
    fabric.MzCanvas.prototype, {
    _getActionFromCorner: function (alreadySelected, corner, e) {
      if (!corner || !alreadySelected) {
        return 'drag';
      }

      switch (corner) {
        case 'mtr':
          return 'rotate';
        case 'mr':
        case 'ml':
          return e[this.altActionKey] ? 'skewY' : 'scaleX';
        case 'mt':
        case 'mb':
          return e[this.altActionKey] ? 'skewX' : 'scaleY';
        case 'tr':
          if (this._activeObject.type === 'MzTextbox' || this._activeObject.type === 'MzImage') {
            return 'edit';
          }
        default:
          return 'scale';
      }
    },

    getCornerCursor: function (corner, target, e) {
      if (this.actionIsDisabled(corner, target, e)) {
        return this.notAllowedCursor;
      } else if (corner === 'tr' && (this._activeObject.type === 'MzTextbox' || this._activeObject.type === 'MzImage')) {
        return 'context-menu'
      } else {
        return this.callSuper('getCornerCursor', corner, target, e);
      }
    }
  });
}
