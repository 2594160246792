<template>
  <el-row type="flex" align="left">
    <el-button
      type="primary"
      v-if="checkPerm('format:create')"
      @click="onClickCreateFormat"
      v-t="'admin.formats.createFormat'"
    ></el-button>

    <AddOrEditFormatModal ref="createFormatModal" />
  </el-row>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";

import AddOrEditFormatModal from "~/components/format/AddOrEditFormatModal";

export default {
  name: "FormatsHeader",

  components: { AddOrEditFormatModal },

  props: [],
  data() {
    return {};
  },
  computed: {
    ...mapGetters("api", ["checkPerm"]),
  },

  methods: {
    onClickCreateFormat() {
      this.$refs.createFormatModal.showCreate();
    },
  },
};
</script>

<style scoped lang="scss">
</style>