import { fabric } from 'fabric';
import MzMixins from './mixins/MzCommonMixins';

/**
 * Circle that rezize instead of scaling
 */
export default () => {

  fabric.MzCircle = fabric.util.createClass(fabric.Ellipse, {
    type: 'MzCircle',

    initialize: function (options) {
      // inject Mz common properties
      Object.assign(this, MzMixins);
      options = this.initMzObject(options);

      this.callSuper('initialize', options);
      this.initNoScale();
    },
    initNoScale: function () {
      this.on({
        scaled: e => {
          let w = this.width * this.scaleX;
          let h = this.height * this.scaleY;

          this.set({
            rx: w / 2,
            ry: h / 2,
            scaleX: 1,
            scaleY: 1
          });
        }
      });
    },
    /**
     * @override
     * @private
     * @param {String} key
     * @param {*} value
     * @return {fabric.Ellipse} thisArg
     */
    _set: function (key, value) {

      this.callSuper('_set', key, value);
      switch (key) {
        case 'rx':
          this.rx = value;
          this.width = value * 2;
          break;

        case 'ry':
          this.ry = value;
          this.height = value * 2;
          break;

        case 'width':
          this.width = value;
          this.rx = value / 2;
          break;

        case 'height':
          this.height = value;
          this.ry = value / 2;
          break;
      }

      return this;
    },
  });

  fabric.MzCircle.fromObject = function (object, callback) {
    return fabric.Object._fromObject('MzCircle', object, callback);
  };

};
