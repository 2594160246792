<template>
  <el-dialog
    :title="dialogTitle"
    :visible.sync="displayModal"
    :close-on-click-modal="false"
    width="75%"
    append-to-body
  >
    <div v-loading="loading">
      <el-row type="flex" justify="center">
        <el-col :span="24">
          <el-form label-width="250px" :disabled="editDisabled">
            <el-row type="flex" justify="start" align="top" class="mt-2">
              <el-col :span="12">
                <el-form-item label="Name">
                  <el-input
                    v-model="name"
                    placeholder="please input text"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-divider> Attributes </el-divider>

            <el-row>
              <el-button
                style="float: right"
                size="small"
                @click="addAttribute"
              >
                <i class="el-icon-plus mr-1" />Add
              </el-button>
            </el-row>

            <div v-for="(attribute, index) in attributes" :key="index">
              <el-row class="mt-1">
                <el-col :span="4">
                  <el-select
                    v-model="attribute.key"
                    :placeholder="'Select a key'"
                    class="el-col el-col-22"
                    @change="onChangeAttributeKey(index, $event)"
                  >
                    <el-option
                      v-for="item in listAuthResultMapKey"
                      :key="item.key"
                      :label="item.key"
                      :value="item.key"
                    >
                    </el-option>
                  </el-select>
                </el-col>
                <el-col :span="16">
                  <el-select
                    v-model="attribute.maps"
                    :clearable="true"
                    multiple
                    filterable
                    allow-create
                    default-first-option
                    :placeholder="'Add a map'"
                    class="el-col el-col-22"
                  >
                    <el-option
                      v-for="(item, idx) in attribute.maps"
                      :key="idx + item"
                      :value="item"
                    ></el-option>
                  </el-select>
                </el-col>
                <el-col :span="4">
                  <el-button
                    size="small"
                    type="danger"
                    plain
                    @click="removeAttribute(index)"
                  >
                    <i class="el-icon-minus mr-1" />Remove
                  </el-button>
                </el-col>
              </el-row>

              <div v-if="attribute.values">
                <el-row
                  v-for="(value, indexValue) in attribute.values"
                  :key="indexValue"
                  class="mt-1"
                >
                  <el-col :span="2" :offset="4">
                    <small class="ml-2">{{ value.key }}</small>
                  </el-col>
                  <el-col :span="13">
                    <el-select
                      size="small"
                      v-model="value.maps"
                      :clearable="true"
                      multiple
                      filterable
                      allow-create
                      default-first-option
                      :placeholder="'Add a map'"
                      class="el-col el-col-22"
                    >
                      <el-option
                        v-for="(item, idx) in value.maps"
                        :key="idx + item"
                        :value="item"
                      ></el-option>
                    </el-select>
                  </el-col>
                </el-row>
              </div>
            </div>
          </el-form>
        </el-col>
      </el-row>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="onClickCancel" v-t="'public.alert.cancel'"></el-button>
      <el-button
        v-if="!editDisabled"
        type="primary"
        v-t="'public.alert.confirm'"
        :disabled="validateButtonDisabled"
        @click="onClickValidate"
      ></el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "EditAuthResultMapModal",

  components: {},

  computed: {
    ...mapState("authStrategy", ["authResultMap"]),
    ...mapGetters("authStrategy", ["listAuthResultMapKey"]),

    dialogTitle() {
      if (this.editDisabled) {
        return "Auth result map information";
      } else {
        return this.authResultMap._id
          ? "Edit auth result map"
          : "Create auth result map";
      }
    },

    name: {
      get() {
        return this.authResultMap.name;
      },
      set(val) {
        this.updateAuthResultMapField({ key: "name", value: val });
      },
    },

    validateButtonDisabled() {
      return (
        this.loading === true ||
        this.name === null ||
        this.name.trim().length < 2
      );
    },
  },
  data() {
    return {
      loading: false,
      displayModal: false,
      editDisabled: false,
      attributes: [],
    };
  },

  methods: {
    ...mapActions({
      loadAuthResultMap: "authStrategy/LOAD_AUTHRESULTMAP",
      saveAuthResultMap: "authStrategy/SAVE_AUTHRESULTMAP",
    }),
    ...mapMutations({
      updateAuthResultMapField: "authStrategy/UPDATE_AUTHRESULTMAP_FIELD",
      resetAuthResultMap: "authStrategy/RESET_AUTHRESULTMAP",
    }),

    hide() {
      this.displayModal = false;
    },
    show(authResultMapId) {
      this.loading = true;
      this.loadAuthResultMap(authResultMapId).then(() => {
        this.attributes = JSON.parse(
          JSON.stringify(this.authResultMap.attributes)
        );
        this.loading = false;
      });
      this.editDisabled = true;
      this.displayModal = true;
    },
    showCreate() {
      this.resetAuthResultMap();
      this.attributes = [];
      this.editDisabled = false;
      this.displayModal = true;
    },
    showEdit(authResultMapId) {
      this.loading = true;
      this.loadAuthResultMap(authResultMapId).then(() => {
        this.attributes = JSON.parse(
          JSON.stringify(this.authResultMap.attributes)
        );
        this.loading = false;
      });
      this.editDisabled = false;
      this.displayModal = true;
    },
    showDuplicate(authResultMapId) {
      this.loading = true;
      this.resetAuthResultMap();
      this.loadAuthResultMap(authResultMapId).then(() => {
        this.updateAuthResultMapField({
          key: "_id",
          value: null,
        });
        this.updateAuthResultMapField({
          key: "name",
          value: this.authResultMap.name + " duplicated",
        });
        this.attributes = JSON.parse(
          JSON.stringify(this.authResultMap.attributes)
        );
        this.loading = false;
      });
      this.editDisabled = false;
      this.displayModal = true;
    },
    onClickCancel() {
      this.hide();
    },
    onClickValidate() {
      if (this.editDisabled) {
        this.hide();
        return;
      }

      let hasError = false;
      const filteredAttributes = this.attributes.reduce(
        (filtered, attribute) => {
          if (attribute.key && attribute.key.length > 0) {
            if (filtered[attribute.key]) {
              hasError = true;
              this.$messageError(
                "Attribute with key '" +
                  attribute.key +
                  "' is already declared."
              );
            }
            filtered[attribute.key] = attribute;
          }
          return filtered;
        },
        {}
      );

      if (hasError) {
        return;
      }

      this.loading = true;

      this.updateAuthResultMapField({
        key: "attributes",
        value: this.attributes.filter((item) => {
          return item.key && item.key.length > 0;
        }),
      });

      this.saveAuthResultMap().then((success) => {
        this.loading = false;
        if (success) {
          this.$emit("save", this.authResultMap);
          this.hide();
        }
      });
    },
    addAttribute() {
      this.attributes.push({
        key: "",
        maps: [],
      });
    },
    removeAttribute(index) {
      this.attributes.splice(index, 1);
    },
    onChangeAttributeKey(index, value) {
      const mapItem = this.listAuthResultMapKey.find(
        (item) => item.key === value
      );

      let values = null;
      if (mapItem && mapItem.values) {
        values = mapItem.values.map((value) => {
          return {
            key: value,
            maps: [],
          };
        });
      }

      this.$set(this.attributes[index], "values", values);
    },
  },
};
</script>

<style scoped lang="scss">
.el-form-item {
  margin-bottom: 1rem;
}
</style>