import { fabric } from 'fabric';
import Utils from '~/common/utils/misc';

export default () => {
  fabric.util.object.extend(
    fabric.MzTextbox.prototype,
    /** @lends fabric.MzTextbox.prototype */
    {
      _textLink: false,
      textLinkStyle: {
        fill: '',
        underline: false,
        fontStyle: 'normal',
        fontWeight: 'normal'
      },
      _textLinkZone: [],

      initTextLink: function (options) {
        this._styleProperties.push('link');
        this.toObjectProps.push('_textLink', 'textLinkStyle');
        this._textLink = options._textLink === true;
        if (options.textLinkStyle) {
          this.textLinkStyle = options.textLinkStyle;
        }
      },

      getTextLinkStyle() {
        if (this._textLink && this.textLinkStyle) {
          return {
            fill: this.textLinkStyle.fill && this.textLinkStyle.fill.length > 0 ? this.textLinkStyle.fill : undefined,
            fontStyle: this.textLinkStyle.fontStyle && this.textLinkStyle.fontStyle.length > 0 ? this.textLinkStyle.fontStyle : undefined,
            fontWeight: this.textLinkStyle.fontWeight && this.textLinkStyle.fontWeight.length > 0 ? this.textLinkStyle.fontWeight : undefined,
            linethrough: this.textLinkStyle.linethrough !== undefined && this.textLinkStyle.linethrough !== null ? this.textLinkStyle.linethrough : undefined,
            underline: this.textLinkStyle.underline !== undefined && this.textLinkStyle.underline !== null ? this.textLinkStyle.underline : undefined,
            overline: this.textLinkStyle.overline !== undefined && this.textLinkStyle.overline !== null ? this.textLinkStyle.overline : undefined,
            textBackgroundColor: this.textLinkStyle.textBackgroundColor && this.textLinkStyle.textBackgroundColor.length > 0 ? this.textLinkStyle.textBackgroundColor : undefined,
            textBackgroundPadding: this.textLinkStyle.textBackgroundPadding

          };
        } else if (fabric.page.specificMagazineData
          && fabric.page.specificMagazineData.textLinkStyle) {
          return {
            fill: fabric.page.specificMagazineData.textLinkStyle.fill && fabric.page.specificMagazineData.textLinkStyle.fill.length > 0 ? fabric.page.specificMagazineData.textLinkStyle.fill : undefined,
            fontStyle: fabric.page.specificMagazineData.textLinkStyle.fontStyle && fabric.page.specificMagazineData.textLinkStyle.fontStyle.length > 0 ? fabric.page.specificMagazineData.textLinkStyle.fontStyle : undefined,
            fontWeight: fabric.page.specificMagazineData.textLinkStyle.fontWeight && fabric.page.specificMagazineData.textLinkStyle.fontWeight.length > 0 ? fabric.page.specificMagazineData.textLinkStyle.fontWeight : undefined,
            linethrough: fabric.page.specificMagazineData.textLinkStyle.linethrough !== undefined && fabric.page.specificMagazineData.textLinkStyle.linethrough !== null ? fabric.page.specificMagazineData.textLinkStyle.linethrough : undefined,
            underline: fabric.page.specificMagazineData.textLinkStyle.underline !== undefined && fabric.page.specificMagazineData.textLinkStyle.underline !== null ? fabric.page.specificMagazineData.textLinkStyle.underline : undefined,
            overline: fabric.page.specificMagazineData.textLinkStyle.overline !== undefined && fabric.page.specificMagazineData.textLinkStyle.overline !== null ? fabric.page.specificMagazineData.textLinkStyle.overline : undefined,
            textBackgroundColor: fabric.page.specificMagazineData.textLinkStyle.textBackgroundColor && fabric.page.specificMagazineData.textLinkStyle.textBackgroundColor.length > 0 ? fabric.page.specificMagazineData.textLinkStyle.textBackgroundColor : undefined,
            textBackgroundPadding: fabric.page.specificMagazineData.textLinkStyle.textBackgroundPadding
          };

        } else {
          return { 'underline': true };
        }
      },

      _cleanTextLinkZone() {
        this._textLinkZone = [];
      },

      _declareTextLinkZone(ctx, bounds, link) {

        // use ctx for debug and display link zone into canvas
        // ctx.strokeStyle = "red";
        // ctx.strokeRect(
        //   bounds.left,
        //   bounds.top,
        //   bounds.width,
        //   bounds.height
        // );

        const linkZone = {
          link,
          left: bounds.left + this.width / 2,
          top: bounds.top + this.height / 2,
          width: bounds.width,
          height: bounds.height
        }
        this._textLinkZone.push(linkZone);
        //console.log('addTextLinkZone:' + link, linkZone);
      },

      getPointerOverLink() {
        // get position of mouse cursor inside textbox
        const cursorPoint = this.canvas.getPointer();
        cursorPoint.x -= this.left;
        cursorPoint.y -= this.top;

        for (let linkZone of this._textLinkZone) {
          if (cursorPoint.x >= linkZone.left && cursorPoint.x <= linkZone.left + linkZone.width
            && cursorPoint.y >= linkZone.top && cursorPoint.y <= linkZone.top + linkZone.height) {
            //console.log('find link', linkZone);
            return linkZone.link;
          }
        }
      }
    }
  );

  Object.defineProperty(fabric.MzTextbox.prototype, 'textLink', {
    get: function () {
      return this._textLink;
    },
    set(value) {
      this._textLink = value;
      if (!value) {
        this.textLinkStyle = {
          fill: '',
          underline: false,
          fontStyle: 'normal',
          fontWeight: 'normal'
        };
      }
      this._forceClearCache = true;
    }
  });
};
