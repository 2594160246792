import { render, staticRenderFns } from "./MaintenanceAlert.vue?vue&type=template&id=fa471d14&scoped=true&"
import script from "./MaintenanceAlert.vue?vue&type=script&lang=js&"
export * from "./MaintenanceAlert.vue?vue&type=script&lang=js&"
import style0 from "./MaintenanceAlert.vue?vue&type=style&index=0&id=fa471d14&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fa471d14",
  null
  
)

export default component.exports