import { fabric } from 'fabric';

import StoreUtils from "./store";
import ImageUtils from "./imageUtils";


const FabricUtils = {
  generateSpecificMagazineData({ template, magazine, page, palette, theme }) {
    const magazineData = {},
      swatchesColorByName = FabricUtils.extractSwatchesColorByName({
        palette: palette
      });

    // get default page background color
    const settings = (magazine.isTemplate === false && template) ? template.settings : magazine.settings;
    if (settings && settings.backgroundFillSwatchName) {
      if (swatchesColorByName && swatchesColorByName[settings.backgroundFillSwatchName]) {
        magazineData.defaultPageBackgroundColor = swatchesColorByName[settings.backgroundFillSwatchName];
      }
    }

    // search for default bullet point style
    const defaultBulletPoint = theme.styles.find(style => {
      return style.element === 'bulletPoint' && style.isDefault;
    });
    if (defaultBulletPoint) {
      const bulletPointStyle = JSON.parse(defaultBulletPoint.style);
      if (swatchesColorByName && bulletPointStyle.fill && swatchesColorByName[bulletPointStyle.fill]) {
        bulletPointStyle.fill = swatchesColorByName[bulletPointStyle.fill];
      }
      magazineData.bulletPointStyle = bulletPointStyle;
    }

    // search for default text link style
    const defaultTextLink = theme.styles.find(style => {
      return style.element === 'textLink' && style.isDefault;
    });
    if (defaultTextLink) {
      const textLinkStyle = JSON.parse(defaultTextLink.style);
      if (swatchesColorByName && textLinkStyle.fill && swatchesColorByName[textLinkStyle.fill]) {
        textLinkStyle.fill = swatchesColorByName[textLinkStyle.fill];
      }
      magazineData.textLinkStyle = textLinkStyle;
    }

    // get footer text variables
    const footerVariables = FabricUtils.extractVariableByBlocktype("Footer", {
      template: template,
      magazine: magazine,
      page: page,
      swatchesColorByName
    });
    if (footerVariables) {
      magazineData.footerVariables = footerVariables;
    }

    return magazineData;
  },

  formatPageDataBeforeImport({ magazine, page, palette, lowDefinitionImage }) {
    const formatedPageData = typeof page.pageData === 'string' ? JSON.parse(page.pageData) : JSON.parse(JSON.stringify(page.pageData)),
      swatchesColorByName = FabricUtils.extractSwatchesColorByName({
        palette: palette
      });

    // apply page backgound color swatch
    if (formatedPageData.pageData) {
      if (swatchesColorByName && formatedPageData.pageData.paletteSwatchName !== undefined) {
        for (let [key, value] of Object.entries(formatedPageData.pageData.paletteSwatchName)) {
          if (swatchesColorByName[value] !== undefined) {
            StoreUtils.setProperty(
              key,
              formatedPageData.pageData,
              swatchesColorByName[value],
              false
            );
          }
        }
      }
    }

    for (const objectIndex in formatedPageData.objects) {
      FabricUtils.formatObjectBeforeImport(
        formatedPageData.objects[objectIndex],
        {
          swatchesColorByName: swatchesColorByName,
          lowDefinitionImage: lowDefinitionImage
        }
      );
    }

    return formatedPageData;
  },

  formatObjectBeforeImport(object, { swatchesColorByName, lowDefinitionImage }) {

    // change object color from defined palette
    if (swatchesColorByName && object.paletteSwatchName !== undefined) {
      // generic process : set properties to fabric object data
      for (let [key, value] of Object.entries(object.paletteSwatchName)) {
        if (swatchesColorByName[value] !== undefined) {
          StoreUtils.setProperty(
            key,
            object,
            swatchesColorByName[value],
            false
          );
        }
      }
      // specific process for MzTextbox selection : set properties in object.styles
      if (object.type === "MzTextbox" && object.styles) {
        for (let lineStyle_ of Object.values(object.styles)) {
          if (lineStyle_) {
            for (let charStyle_ of Object.values(lineStyle_)) {
              if (charStyle_) {
                for (let [key, value] of Object.entries(charStyle_)) {
                  if (
                    key && value &&
                    key.indexOf("swatch_") === 0 &&
                    swatchesColorByName[value] !== undefined
                  ) {
                    const _property = key.split("_")[1];
                    charStyle_[_property] = swatchesColorByName[value];
                  }
                }
              }
            }
          }
        }
      }
    }
    // specific process for image : change src to load low def
    if (object.type === "MzImage") {
      if (lowDefinitionImage) {
        const neededScale = 1;

        // save original definition data for image src/scale/width/height
        object.originalDefinitionData = {
          src: object.src,
          width: object.width,
          height: object.height,
          scaleX: object.scaleX,
          scaleY: object.scaleY
        };


        const resizeData = ImageUtils.getFabricImageResizedData(
          {
            src: object.src,
            width: object.width,
            height: object.height,
            scaleX: object.scaleX,
            scaleY: object.scaleY
          },
          neededScale
        );

        if (resizeData) {
          object.src = resizeData.src;
          object.width = resizeData.width;
          object.height = resizeData.height;
          object.scaleX = resizeData.scaleX;
          object.scaleY = resizeData.scaleY;
        }
        object.crossOrigin = "anonymous";

      } else {
        // update S3 source if data come from another server (beta, dev, staging or prod)
        const imgKey = ImageUtils.getImageBaseKey(object.src);
        if (imgKey) {
          object.src = process.env.COMMON_AWS_S3_URL + imgKey;
        }
      }
    }

    // parse group object
    if (object.type === "MzGroup" && object.objects) {
      for (const childIndex in object.objects) {
        FabricUtils.formatObjectBeforeImport(object.objects[childIndex], { swatchesColorByName, lowDefinitionImage });
      }
    }

    return object;
  },

  extractSwatchesColorByName({ palette }) {
    return palette.swatches.reduce((obj, swatch) => {
      obj[swatch.name] = swatch.color;
      return obj;
    }, {});
  },

  extractVariableByBlocktype(blocktype, { template, magazine, page, swatchesColorByName }) {
    if (blocktype.indexOf('Footer') === 0) {
      let currentPageIndex = 0;
      const pageList = [...magazine.pageOrder].reduce(
        (list, magPage) => {
          // insert empty missing page for complete double page
          if (magPage.double && currentPageIndex % 2 === 0) {
            currentPageIndex++;
          }
          list[magPage._id] = magPage.double ? (currentPageIndex + 1) + '-' + (currentPageIndex + 2) : (currentPageIndex + 1);
          currentPageIndex += magPage.double ? 2 : 1;
          return list;
        },
        {}
      );

      const redactorVariable = page.redactorVariables ?
        page.redactorVariables
          .reduce((list, variableData) => {
            list[variableData.key] = variableData.value;
            return list;
          }, {}) :
        {}

      const result = {
        list: {
          nb: currentPageIndex,
          name: magazine.name,
          issue: magazine.issue,
          var1: '', // redactor specific variables #1
          var2: '', // redactor specific variables #2
          var3: '', // redactor specific variables #3
          ...redactorVariable
        },
        footer: null
      };

      if (magazine.isTemplate === false) {
        result.footer = template && template.settings ? { ...template.settings.footer } : null;
      } else {
        result.footer = magazine.settings ? { ...magazine.settings.footer } : null;

        // define default variables for test on template
        result.list.issue = "Template edition";
        result.list.var1 = "Redactor variable #1";
        result.list.var2 = "Redactor variable #2";
        result.list.var3 = "Redactor variable #3";
      }

      const pageIndex = pageList[page._id];
      if (pageIndex && typeof pageIndex === 'string' && pageIndex.indexOf('-')) {
        // specific case for double page
        const arr = pageIndex.split('-');
        result.list.doublePage = { left: parseInt(arr[0]), right: parseInt(arr[1]) }
      } else {
        result.list.page = parseInt(pageIndex);
      }

      if (swatchesColorByName) {
        // apply palette color to footer settings
        for (let footerKey in result.footer) {
          const footer = JSON.parse(result.footer[footerKey]);
          if (footer && footer.flatStyle) {
            for (let charStyle_ of Object.values(footer.flatStyle)) {
              for (let [key, value] of Object.entries(charStyle_)) {
                if (
                  key.indexOf("swatch_") === 0 &&
                  swatchesColorByName[value] !== undefined
                ) {
                  const _property = key.split("_")[1];
                  charStyle_[_property] = swatchesColorByName[value];
                }
              }
            }
            result.footer[footerKey] = JSON.stringify(footer);
          }
        }
      }

      return result;
    } else {
      return null;
    }
  }

}

export default FabricUtils;